// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { CoreConstants } from '@/core/constants';
import { Injectable } from '@angular/core';
import { FreemiumSites } from '@freemium/overrides/core/services/sites';
import { CoreAnalyticsEvent, CoreAnalyticsEventType, CoreAnalyticsHandler } from '@services/analytics';
import { CoreNetwork } from '@services/network';
import { CoreSites } from '@services/sites';
import { CoreUtils } from '@services/utils/utils';
import { CoreWS } from '@services/ws';
import { makeSingleton } from '@singletons';
import { CorePath } from '@singletons/path';
import moment from 'moment';
import { Md5 } from 'ts-md5/dist/md5';

/**
 * Analytics handler.
 */
@Injectable({ providedIn: 'root' })
export class FreemiumMatomoAnalyticsHandlerService implements CoreAnalyticsHandler {

    name = 'FreemiumMatomoAnalyticsHandler';

    /**
     * @inheritdoc
     */
    async isEnabled(): Promise<boolean> {
        const site = await CoreUtils.ignoreErrors(FreemiumSites.getSite());
        const subscription = await site?.getSubscription();

        if (subscription?.matomo) {
            return subscription.matomo.enabled;
        }

        return !!(CoreConstants.CONFIG.matomoAnalytics && CoreConstants.CONFIG.matomoAnalytics.url);
    }

    /**
     * @inheritdoc
     */
    async logEvent(event: CoreAnalyticsEvent): Promise<void> {
        const site = await CoreUtils.ignoreErrors(FreemiumSites.getSite());
        const subscription = await site?.getSubscription();
        const trackingUrl = subscription?.matomo?.trackingUrl ?? CoreConstants.CONFIG.matomoAnalytics?.url;
        const siteId = subscription?.matomo?.siteId ?? CoreConstants.CONFIG.matomoAnalytics?.siteId;

        if (
            !trackingUrl ||
            !siteId ||
            !CoreNetwork.isOnline() ||
            event.type === CoreAnalyticsEventType.PUSH_NOTIFICATION
        ) {
            return;
        }

        const staticParams = {
            ...(subscription?.matomo?.staticParams ?? CoreConstants.CONFIG.matomoAnalytics?.staticParams ?? {}),
        };

        if (staticParams['_id'] !== undefined) {
            staticParams['_id'] = Md5.hashAsciiStr(CoreSites.getCurrentSiteUserId()).substring(0, 16);
        }

        const data: Record<string, string|number|boolean> = {
            ...staticParams,
            idsite: siteId,
            rec: 1,
            urlref: 'https://moodlemobile',
            h: moment().hour(),
            m: moment().minute(),
            s: moment().second(),
            rand: Math.round(Math.random() * 1000000),
            apiv: 1,
        };

        switch (event.type) {
            case CoreAnalyticsEventType.VIEW_ITEM:
            case CoreAnalyticsEventType.VIEW_ITEM_LIST:
                if (!event.url) {
                    return;
                }

                data.url = event.url;
                data.action_name = event.name;
                break;
            case CoreAnalyticsEventType.DOWNLOAD_FILE:
                data.download = event.fileUrl;
                break;
            case CoreAnalyticsEventType.OPEN_LINK:
                data.link = event.link;
                break;
            default:
                // Event not supported.
                return;
        }

        await CoreWS.sendHTTPRequest(CorePath.concatenatePaths(trackingUrl, 'matomo.php'), {
            method: 'post',
            responseType: 'text',
            data,
        });
    }

}

export const FreemiumMatomoAnalyticsHandler = makeSingleton(FreemiumMatomoAnalyticsHandlerService);
