// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { CoreConstants } from '@/core/constants';
import { Injectable } from '@angular/core';
import { CoreWS } from '@services/ws';
import { makeSingleton } from '@singletons';

/**
 * Error tracking service.
 */
@Injectable({ providedIn: 'root' })
export class FreemiumErrorTrackingService {

    /**
     * Send error report.
     *
     * @param siteUrl Site url.
     * @param options Error options.
     */
    async report(siteUrl: string, options: FreemiumErrorTrackingErrorReportOptions = {}): Promise<void> {
        const params: FreemiumErrorTrackingReportErrorWSParams = {
            appid: CoreConstants.CONFIG.app_id,
            siteurl: siteUrl,
        };

        if (options.errorCode) {
            params.errorcode = options.errorCode;
        }

        if (options.errorDetails) {
            params.errordetails = options.errorDetails;
        }

        return CoreWS.callAjax(
            'local_apps_report_error',
            params,
            { siteUrl: CoreConstants.CONFIG.appServicesUrl, noLogin: true },
        );
    }

}

export const FreemiumErrorTracking = makeSingleton(FreemiumErrorTrackingService);

/**
 * Error report options.
 */
export interface FreemiumErrorTrackingErrorReportOptions {
    errorCode?: string;
    errorDetails?: string;
}

/**
 * Params of core_badges_get_user_badges WS.
 */
type FreemiumErrorTrackingReportErrorWSParams = {
    appid: string; // Application ID.
    siteurl: string; // Site URL.
    errorcode?: string; // Error code.
    errordetails?: string; // Error details
};
