// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';
import { CoreError } from '@classes/errors/error';
import { CoreSiteWSPreSets } from '@classes/sites/authenticated-site';
import { CoreSites } from '@services/sites';
import { CoreWSExternalWarning } from '@services/ws';
import { makeSingleton, Translate } from '@singletons';
import { WorkplaceService } from '@workplace/services/workplace';

export enum WorkplaceTeamsOrgStructure {
    OPERATOR_DIRECT_ONLY = 1,
    OPERATOR_EVERYONE = 2,
    OPERATOR_CUSTOM = 3,
}

/**
 * Service that provides workplace my teams features.
 */
@Injectable({ providedIn: 'root' })
export class WorkplaceTeamsService {

    static readonly ROOT_CACHE_KEY = WorkplaceService.ROOT_CACHE_KEY + 'teams:';
    static readonly USERS_LIST_LIMIT = 50; // Max of users to retrieve in each WS call.

    static readonly DEFAULT_TEAM_FILTERS: WorkplaceTeamsFilterValues = {
        departmentid: 0,
        includesubdepts: 0,
        positionid: 0,
        includesubpos: 0,
        fullnametype: 0,
        fullname: '',
        orgstructuretype: WorkplaceTeamsOrgStructure.OPERATOR_DIRECT_ONLY,
    };

    /**
     * Get cache key for get team overview WS call.
     *
     * @returns Cache key.
     */
    protected getTeamOverviewCacheKey(): string {
        return `${WorkplaceTeamsService.ROOT_CACHE_KEY}overview`;
    }

    /**
     * Get cache key for get managed users WS call.
     *
     * @returns Cache key.
     * @deprecatedonmoodle since WP 4.1
     */
    protected getManagedUsersCacheKey(): string {
        return `${WorkplaceService.ROOT_CACHE_KEY}managerusers`;
    }

    /**
     * Get cache key for list of filters on the teams tab WS call.
     *
     * @returns Cache key.
     */
    protected getTeamFiltersCacheKey(): string {
        return `${WorkplaceTeamsService.ROOT_CACHE_KEY}filters`;
    }

    /**
     * Get cache key for list of filters on the teams tab WS call (legacy WS).
     *
     * @returns Cache key.
     * @deprecatedonmoodle since WP 4.1
     */
    protected getLegacyTeamFiltersCacheKey(): string {
        return `${WorkplaceService.ROOT_CACHE_KEY}teamfilters`;
    }

    /**
     * Get cache key for learning status for a user WS call.
     *
     * @param userId userId.
     * @returns Cache key.
     */
    protected getLearningStatusCacheKey(userId: number): string {
        return `${WorkplaceService.ROOT_CACHE_KEY}learningstatus:${userId}`;
    }

    /**
     * Return the list of users in the team.
     *
     * @param limitFrom Position of the list to start listing.
     * @param filters Filtering options.
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved when the info is retrieved.
     */
    async getTeamOverview(
        limitFrom: number = 0,
        filters: WorkplaceTeamsFilterValues = WorkplaceTeamsService.DEFAULT_TEAM_FILTERS,
        siteId?: string,
    ): Promise<WorkplaceBlockMyteamsGetTeamOverviewWSResponse> {
        const available = await this.isGetTeamOverviewAvailable(siteId);
        if (!available) {
            return this.getManagedUsers(limitFrom, filters, siteId);
        }

        const site = await CoreSites.getSite(siteId);
        const preSets: CoreSiteWSPreSets = {
            cacheKey: this.getTeamOverviewCacheKey(),
        };

        const params: WorkplaceBlockMyteamsGetTeamOverviewWSParams = {
            limitfrom: limitFrom,
            limitnumber: WorkplaceTeamsService.USERS_LIST_LIMIT,
            ...filters,
        };

        return site.read('block_myteams_get_team_overview', params, preSets);
    }

    /**
     * Return the list of users in the team.
     *
     * @param limitFrom Position of the list to start listing.
     * @param filters Filtering options.
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved when the info is retrieved.
     * @deprecatedonmoodle since WP 4.1
     */
    protected async getManagedUsers(
        limitFrom: number = 0,
        filters: WorkplaceTeamsFilterValues = WorkplaceTeamsService.DEFAULT_TEAM_FILTERS,
        siteId?: string,
    ): Promise<WorkplaceBlockMyteamsGetTeamOverviewWSResponse> {
        const site = await CoreSites.getSite(siteId);
        const preSets: CoreSiteWSPreSets = {
            cacheKey: this.getManagedUsersCacheKey(),
        };

        delete filters.orgstructuretype;

        const params: WorkplaceToolOrganisationGetManagerUsersWSParams = {
            limitfrom: limitFrom,
            limitnumber: WorkplaceTeamsService.USERS_LIST_LIMIT,
            ...filters,
        };

        const response = await site.read<WorkplaceToolOrganisationGetManagerUsersWSResponse>(
            'tool_organisation_get_managed_users',
            params,
            preSets,
        );

        const managedusers: WorkplaceTeamsManagedUser[] = response.managedusers.map((user) => {

            const jobsSections: WorkplaceTeamsManagedUserSection = {
                name: Translate.instant('workplace.teams.jobsnumber'),
                link: '',
                order: 3,
                items: [],
                showmorecount: 0,
            };

            jobsSections.items = user.jobs.map((job) => {
                const badges: WorkplaceTeamsManagedUserSectionItemBadge[] = [];
                if (job.ismanager) {
                    badges.push({
                        label: Translate.instant('workplace.teams.globalmanager'),
                        type: 'info',
                    });
                }
                if (job.isdepartmentmanager) {
                    badges.push({
                        label: Translate.instant('workplace.teams.departmentmanager'),
                        type: 'info',
                    });
                }

                return {
                    title: job.position_name,
                    subtitle: job.department_name,
                    badges,
                };
            });

            return {
                user: {
                    id: user.id,
                    fullname: user.fullname,
                    profileimageurl: user.profileimageurl,
                    email: '',
                    idnumber: '',
                    phone1: '',
                    phone2: '',
                    department: '',
                    institution: '',
                    identity: '',
                    profileurl: user.profileimageurl,
                    profileimageurlsmall: user.profileimageurl,
                },
                lastaccess: 0,
                isoverdue: !!user.hasexpiredcertifications,
                sections: [jobsSections],
            };
        });

        return {
            managedusers,
            totalcount: response.totalcount,
        };
    }

    /**
     * Return the list of filters on the user managed list.
     *
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved when the info is retrieved.
     */
    async getTeamFilters(siteId?: string): Promise<WorkplaceBlockMyteamsGetTeamOverviewFiltersOptionsWSResponse> {
        const available = await this.isGetTeamOverviewAvailable(siteId);
        if (!available) {
            return this.getLegacyTeamFilters(siteId);
        }

        const site = await CoreSites.getSite(siteId);
        const preSets: CoreSiteWSPreSets = {
            cacheKey: this.getTeamFiltersCacheKey(),
            getFromCache: false,
        };

        return site.read('block_myteams_get_team_overview_filters_options', undefined, preSets);
    }

    /**
     * Return the list of filters on the user managed list (legacy WS).
     *
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved when the info is retrieved.
     * @deprecatedonmoodle since WP 4.1
     */
    protected async getLegacyTeamFilters(siteId?: string): Promise<WorkplaceBlockMyteamsGetTeamOverviewFiltersOptionsWSResponse> {

        const site = await CoreSites.getSite(siteId);
        const preSets: CoreSiteWSPreSets = {
            cacheKey: this.getLegacyTeamFiltersCacheKey(),
            getFromCache: false,
        };

        const response = await site.read<WorkplaceToolOrganisationGetTeamsTabFiltersWSResponse>(
            'tool_organisation_get_teams_tab_filters',
            undefined,
            preSets,
        );

        // Adapt filter identifiers to new WS response.
        const fullnameFilter = response.fullname_filters.map((value) => {
            value.identifier = 'filter' + value.identifier;

            return value;
        });

        fullnameFilter.unshift({
            id: 0,
            identifier: 'filterisanyvalue',
            component: '',
        });

        return {
            departments: response.departments,
            positions: response.positions,
            fullname_filter: fullnameFilter, // eslint-disable-line @typescript-eslint/naming-convention
            orgstructuretype_filter: [], // eslint-disable-line @typescript-eslint/naming-convention
        };
    }

    /**
     * Get a particular user overview.
     *
     * @param userId userId.
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved when the info is retrieved.
     */
    async getUserLearningOverview(userId: number, siteId?: string): Promise<WorkplaceTeamsManagedUser | undefined> {
        siteId = siteId ?? CoreSites.getCurrentSiteId();

        const recursiveFunction =
            async (userId: number, siteId?: string, limitFrom = 0): Promise<WorkplaceTeamsManagedUser | undefined> => {
                const teams = await this.getTeamOverview(0, undefined, siteId);
                const found = teams.managedusers.find((user) => user.user.id === userId);
                if (found) {
                    return found;
                }

                const count = teams.managedusers.length;
                if (count + limitFrom < teams.totalcount) {
                    return recursiveFunction(userId, siteId, count + limitFrom);
                }
            };

        return  await recursiveFunction(userId, siteId);
    }

    /**
     * Return learning info for a user.
     *
     * @param userId userId.
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved when the info is retrieved.
     */
    async getUserLearningInfo(userId: number, siteId?: string): Promise<WorkplaceTeamsManagedUserSection[]> {
        siteId = siteId ?? CoreSites.getCurrentSiteId();

        const userInfo = await this.getUserLearningOverview(userId, siteId);

        let sections = userInfo?.sections || [];

        const available = await this.isGetTeamOverviewAvailable(siteId);
        if (!available || !userInfo) {
            // Use the fallback function if the user is not found on the team overview or the new WS is not available.
            const extraSections = await this.getLearningStatus(userId, siteId);

            sections = sections.concat(...extraSections);
            sections.sort((a, b) => a.order - b.order);
        }

        return sections;
    }

    /**
     * Return learning statuses for a user.
     *
     * @param userId userId.
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved when the info is retrieved.
     * @deprecatedonmoodle since WP 4.1
     */
    protected async getLearningStatus(
        userId: number,
        siteId?: string,
    ): Promise<WorkplaceTeamsManagedUserSection[]> {
        const site = await CoreSites.getSite(siteId);
        const preSets: CoreSiteWSPreSets = {
            cacheKey: this.getLearningStatusCacheKey(userId),
        };
        const params: WorkplaceToolProgramGetUserLearningStatusesWSParams = {
            userid: userId,
        };

        const result =
            await site.read<WorkplaceToolProgramGetUserLearningStatusesWSResponse>(
                'tool_program_get_user_learning_statuses',
                params,
                preSets,
            );

        if (!result.result) {
            throw new CoreError('Error on tool_program_get_user_learning_statuses');
        }

        /**
         * Helper function to get status color.
         *
         * @param status Complete status text.
         * @returns Decided color.
         */
        const getStatusColor = (status: string): string => {
            switch (status) {
                case 'completed':
                case 'certified':
                    return 'success';
                case 'suspended':
                case 'expired':
                case 'deleted':
                    return 'danger';
                case 'overdue':
                    return 'warning';
                case 'open':
                case 'futureallocation':
                case 'active':
                default:
                    return 'info';
            }
        };

        const sections: WorkplaceTeamsManagedUserSection[] = [];
        if (result.certifications.length) {
            const section: WorkplaceTeamsManagedUserSection = {
                name: Translate.instant('workplace.certifications'),
                link: '',
                order: 1,
                items: [],
                showmorecount: 0,
            };

            section.items = result.certifications.map((cert) => {
                const badges = cert.statuses.map((status) => ({
                    label: Translate.instant('workplace.' + status.stringid),
                    type: getStatusColor(status.stringid),
                }));

                return {
                    title: cert.fullname,
                    subtitle: '',
                    badges,
                };
            });

            sections.push(section);
        }

        if (result.programs.length) {
            const section: WorkplaceTeamsManagedUserSection = {
                name: Translate.instant('workplace.programs'),
                link: '',
                order: 2,
                items: [],
                showmorecount: 0,
            };

            section.items = result.programs.map((program) => {
                const badges = program.statuses.map((status) => ({
                    label: Translate.instant('workplace.' + status.stringid),
                    type: getStatusColor(status.stringid),
                }));

                return {
                    title: program.fullname,
                    subtitle: '',
                    badges,
                };
            });

            sections.push(section);
        }

        return sections;
    }

    /**
     * Check if block_myteams_get_team_overview WS is available.
     *
     * @param siteId Site ID. If not defined, use current site.
     * @returns Whether the webservice is available.
     * @since 4.1
     */
    async isGetTeamOverviewAvailable(siteId?: string): Promise<boolean> {
        const site = await CoreSites.getSite(siteId);

        return site?.wsAvailable('block_myteams_get_team_overview');
    }

    /**
     * Check if get_managed_users WS is available.
     *
     * @param siteId Site ID. If not defined, use current site.
     * @returns Whether the webservice is available.
     * @since 3.10
     * @deprecatedonmoodle since WP 4.1
     */
    protected async isGetManagedUsersAvailable(siteId?: string): Promise<boolean> {
        const site = await CoreSites.getSite(siteId);

        return site?.wsAvailable('tool_organisation_get_managed_users');
    }

    /**
     * Check if get_user_learning_statuses WS is available.
     *
     * @returns Whether the webservice is available.
     * @since 3.10
     */
    isGetLearningStatusAvailable(): boolean {
        return CoreSites.wsAvailableInCurrentSite('tool_program_get_user_learning_statuses');
    }

    /**
     * Checks if the current user is a manager to show teams tab.
     *
     * @param siteId Site ID. If not defined, use current site.
     * @returns Whether the user is a manager.
     */
    async isManager(siteId?: string): Promise<boolean> {
        let available = await this.isGetTeamOverviewAvailable(siteId);
        if (!available) {
            available = await this.isGetManagedUsersAvailable(siteId);
        }

        if (!available) {
            return false;
        }

        const site = await CoreSites.getSite(siteId);

        return site?.canUseAdvancedFeature('wp_teammanagerview', false);
    }

    /**
     * Check if teams tab is enabled on settings.
     *
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved with the setting.
     * @deprecatedonmoodle since WP 4.0 (not used on 4.0 onwards).
     */
    async isTeamsTabEnabled(siteId?: string): Promise<boolean> {

        const isManager = await this.isManager(siteId);

        if (!isManager) {
            return false;
        }

        const site = await CoreSites.getSite(siteId);

        try {
            // Setting removed on 4.0.
            const enabled = await site?.getConfig('theme_workplace_dashboardteams', true);

            return enabled != '0' && enabled != 'false';
        } catch {
            // Not available.
            return true;
        }
    }

    /**
     * Invalidates get team overview WS call.
     *
     * @param siteId Site ID to invalidate. If not defined, use current site.
     * @returns Promise resolved when the data is invalidated.
     */
    async invalidateTeamOverview(siteId?: string): Promise<void> {
        const available = await this.isGetTeamOverviewAvailable(siteId);
        if (!available) {
            return this.invalidateManagedUsers(siteId);
        }

        const site = await CoreSites.getSite(siteId);

        return site.invalidateWsCacheForKey(this.getTeamOverviewCacheKey());
    }

    /**
     * Invalidates get managed users WS call.
     *
     * @param siteId Site ID to invalidate. If not defined, use current site.
     * @returns Promise resolved when the data is invalidated.
     * @deprecatedonmoodle since WP 4.1
     */
    protected async invalidateManagedUsers(siteId?: string): Promise<void> {
        const site = await CoreSites.getSite(siteId);

        return site.invalidateWsCacheForKey(this.getManagedUsersCacheKey());
    }

    /**
     * Invalidates get managed users WS call.
     *
     * @param siteId Site ID to invalidate. If not defined, use current site.
     * @returns Promise resolved when the data is invalidated.
     */
    async invalidateTeamsFilters(siteId?: string): Promise<void> {
        const available = await this.isGetTeamOverviewAvailable(siteId);
        if (!available) {
            return this.invalidateLegacyTeamsFilters(siteId);
        }

        const site = await CoreSites.getSite(siteId);

        return site.invalidateWsCacheForKey(this.getTeamFiltersCacheKey());
    }

    /**
     * Invalidates get managed users WS call (legacy WS).
     *
     * @param siteId Site ID to invalidate. If not defined, use current site.
     * @returns Promise resolved when the data is invalidated.
     * @deprecatedonmoodle since WP 4.1
     */
    protected async invalidateLegacyTeamsFilters(siteId?: string): Promise<void> {
        const site = await CoreSites.getSite(siteId);

        return site.invalidateWsCacheForKey(this.getLegacyTeamFiltersCacheKey());
    }

    /**
     * Invalidates get managed users WS call.
     *
     * @param userId userId.
     * @param siteId Site ID to invalidate. If not defined, use current site.
     * @returns Promise resolved when the data is invalidated.
     */
    async invalidateLearningStatus(userId: number, siteId?: string): Promise<void> {
        const site = await CoreSites.getSite(siteId);

        return site.invalidateWsCacheForKey(this.getLearningStatusCacheKey(userId));
    }

}

export const WorkplaceTeams = makeSingleton(WorkplaceTeamsService);

/**
 * Data returned by tool_organisation_get_teams_tab_filters WS.
 *
 * @deprecatedonmoodle since WP 4.1
 */
export type WorkplaceToolOrganisationGetTeamsTabFiltersWSResponse = {
    departments: WorkplaceBlockMyteamsDepartmentsFilter[];
    positions: WorkplaceBlockMyteamsPositionsFilter[];
    fullname_filters: WorkplaceBlockMyteamsFullnameFilter[]; // eslint-disable-line @typescript-eslint/naming-convention
};

/**
 * Data returned by block_myteams_get_team_overview_filters_options WS.
 *
 * @since WP 4.1
 */
export type WorkplaceBlockMyteamsGetTeamOverviewFiltersOptionsWSResponse = {
    departments: WorkplaceBlockMyteamsDepartmentsFilter[];
    positions: WorkplaceBlockMyteamsPositionsFilter[];
    fullname_filter: WorkplaceBlockMyteamsFullnameFilter[]; // eslint-disable-line @typescript-eslint/naming-convention
    // eslint-disable-next-line @typescript-eslint/naming-convention
    orgstructuretype_filter: WorkplaceBlockMyteamsOrgstructuretypeFilter[];
    warnings?: CoreWSExternalWarning[];
};

/**
 * Teams filter values.
 */
export type WorkplaceTeamsFilterValues = {
    fullnametype?: number; // Filter type for fullname.
    fullname?: string; // Fullname to search for.
    orgstructuretype?: WorkplaceTeamsOrgStructure; // Organisation structure filter type. // @since WP 4.1
    includesubdepts?: number; // Include subdepartments.
    departmentid?: number; // Departmend ID.
    positionid?: number; // Position iD.
    includesubpos?: number; // Include subpositions.
};

export type WorkplaceBlockMyteamsTreeListItem = {
    id: number;
    parentid: number;
    name: string;
};

type WorkplaceBlockMyteamsPositionsFilter = WorkplaceBlockMyteamsTreeListItem;

type WorkplaceBlockMyteamsDepartmentsFilter = WorkplaceBlockMyteamsTreeListItem;

type WorkplaceBlockMyteamsFullnameFilter = {
    id: number;
    identifier: string;
    component: string;
};

type WorkplaceBlockMyteamsOrgstructuretypeFilter = {
    id: WorkplaceTeamsOrgStructure;
    identifier: string;
    component: string;
};

/**
 * Params of tool_organisation_get_managed_users WS.
 */
type WorkplaceToolOrganisationGetManagerUsersWSParams = Omit<WorkplaceTeamsFilterValues, 'orgstructuretype'>  & {
    limitfrom?: number; // Limitfrom (integer) sql limit from.
    limitnumber?: number; // Limitnumber (integer) maximum number of returned users.
};

/**
 * Data returned by tool_organisation_get_managed_users WS.
 *
 * @deprecatedonmoodle since WP 4.1
 */
export type WorkplaceToolOrganisationGetManagerUsersWSResponse = {
    user: WorkplaceToolOrganisationGetManagerUserBase & {
        jobs: WorkplaceToolOrganisationGetManagerUserJobBase[];
    };
    managedusers: (WorkplaceToolOrganisationGetManagerUserBase & {
        hasexpiredcertifications: number;
        jobs: (WorkplaceToolOrganisationGetManagerUserJobBase & {
            ismanager: number;
            isdepartmentmanager: number;
        })[];
    })[];
    totalcount: number;
};

type WorkplaceToolOrganisationGetManagerUserBase = {
    id: number;
    fullname: string;
    profileimageurl: string;
    ismanager: number;
    isdepartmentmanager: number;
    isglobalmanager: number;
};

type WorkplaceToolOrganisationGetManagerUserJobBase = {
    jobid: number;
    positionid: number;
    position_name: string; // eslint-disable-line @typescript-eslint/naming-convention
    departmentid: number;
    department_name: string; // eslint-disable-line @typescript-eslint/naming-convention
    startdate: number;
    enddate: number;
};

/**
 * Type of a user in a team.
 */
export type WorkplaceTeamsManagedUser = {
    user: {
        id: number; // Id.
        email: string; // Email.
        idnumber: string; // Idnumber.
        phone1: string; // Phone1.
        phone2: string; // Phone2.
        department: string; // Department.
        institution: string; // Institution.
        fullname: string; // Fullname.
        identity: string; // Identity.
        profileurl: string; // Profileurl.
        profileimageurl: string; // Profileimageurl.
        profileimageurlsmall: string; // Profileimageurlsmall.
    };
    lastaccess: number;
    isoverdue: boolean;
    sections: WorkplaceTeamsManagedUserSection[];
};

export type WorkplaceTeamsManagedUserSection = {
    name: string; // Name.
    link: string; // Link.
    order: number; // Order.
    items: { // Items.
        title: string; // Title.
        subtitle: string; // Subtitle.
        badges?: WorkplaceTeamsManagedUserSectionItemBadge[]; // Badges.
    }[];
    showmorecount: number; // Showmorecount.
};

type WorkplaceTeamsManagedUserSectionItemBadge = {
    label: string; // Label.
    type: string; // Type.
};

/**
 * Params of tool_program_get_user_learning_statuses WS.
 */
type WorkplaceToolProgramGetUserLearningStatusesWSParams = {
    userid: number;
};

/**
 * Data returned by tool_program_get_user_learning_statuses WS.
 */
export type WorkplaceToolProgramGetUserLearningStatusesWSResponse = {
    result: boolean;
    programs: WorkplaceLearningStatusProgram[];
    certifications: WorkplaceLearningStatusCertification[];
    hasexpiredcertifications: number;
};

export type WorkplaceLearningStatusProgram = {
    id: number; // The id of the program.
    fullname: string; // The fullname of the program.
    statuses: WorkplaceLearningStatus[];
};

export type WorkplaceLearningStatusCertification = {
    id: number; // The id of the certification.
    fullname: string; // The fullname of the certification.
    statuses: WorkplaceLearningStatus[];
};

type WorkplaceLearningStatus = {
    stringid: string; // Status string id.
    color?: string; // Added by the app.
};

/**
 * Params of block_myteams_get_team_overview WS.
 *
 * @since WP 4.1
 */
export type WorkplaceBlockMyteamsGetTeamOverviewWSParams = WorkplaceTeamsFilterValues & {
    limitfrom?: number; // Limitfrom (integer) sql limit from.
    limitnumber?: number; // Limitnumber (integer) maximum number of returned users.
};

/**
 * Data returned by block_myteams_get_team_overview WS.
 *
 * @since WP 4.1
 */
export type WorkplaceBlockMyteamsGetTeamOverviewWSResponse = {
    managedusers: WorkplaceTeamsManagedUser[];
    totalcount: number;
    warnings?: CoreWSExternalWarning[];
};
