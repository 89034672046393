// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';

import { CoreConstants } from '@/core/constants';
import { CoreEvents } from '@singletons/events';

import { FreemiumSite } from '@freemium/overrides/core/classes/sites/site';
import { FreemiumSites } from '@freemium/overrides/core/services/sites';
import { FreemiumStorage } from '@freemium/services/storage';
import { SiteSubscriptionPlan } from '@freemium/constants';
import { CoreLocalNotifications } from '@/core/services/local-notifications';
import { CoreObject } from '@singletons/object';
import { AlertController, makeSingleton, Translate } from '@singletons';
import { CoreMath } from '@singletons/math';
import { CoreNavigator } from '@services/navigator';

// Subscription data that has been used to configure upsells.
const SUBSCRIPTION_KEY = 'upsells-subscription';

// Last time when the upsell alert was shown.
const ALERT_TIME_KEY = 'upsells-alert-time';

// Notification component name.
const NOTIFICATION_COMPONENT = 'Upsells';

// Notification ids.
const NOTIFICATION_IDS = [1, 2, 3];

/**
 * Subscription data that is relevant to displaying upsells.
 */
interface SubscriptionData {
    plan: SiteSubscriptionPlan;
    expirationTimestamp: number | null;
}

/**
 * Service to manage upsell messages.
 */
@Injectable({ providedIn: 'root' })
export class SiteSubscriptionUpsellsService {

    /**
     * Register service listeners to configure upsells.
     */
    registerListeners(): void {
        CoreEvents.on(CoreEvents.LOGIN, async ({ siteId }): Promise<void> => {
            const site = await FreemiumSites.getSite(siteId);

            await this.configureUpsellMessages(site);
        });

        CoreEvents.on(CoreEvents.LOGOUT, async ({ siteId }): Promise<void> => {
            const site = await FreemiumSites.getSite(siteId);

            // TODO what if site does not exist!?

            if (site.isLoggedOut()) {
                await this.removeUpsellMessages(site);
            }
        });

        CoreLocalNotifications.registerClick(
            NOTIFICATION_COMPONENT,
            () => CoreNavigator.navigateToSitePath('subscription'),
        );
    }

    /**
     * Configure upsells for a site depending on user role and subscription plan.
     *
     * @param site Site.
     */
    protected async configureUpsellMessages(site: FreemiumSite): Promise<void> {
        // Only show upsells for site admins.
        if (!site.isAdmin()) {
            return;
        }

        // Get subscription data.
        const storage = FreemiumStorage.forSite(site);
        const subscriptionData: SubscriptionData = {
            plan: site.subscription.plan,
            expirationTimestamp: site.subscription.getValidUntilTime(),
        };
        const previousSubscriptionData = await storage.get<SubscriptionData>(SUBSCRIPTION_KEY);

        if (previousSubscriptionData && CoreObject.deepEquals(subscriptionData, previousSubscriptionData)) {
            return;
        }

        // Show alerts for subscriptions with free plans.
        if (subscriptionData.plan === SiteSubscriptionPlan.Free) {
            await this.removeLocalNotifications(site);

            const time = await storage.get<number | null>(ALERT_TIME_KEY);

            // Avoid showing alerts more than once per month.
            if (time === null || time + CoreConstants.MILLISECONDS_MONTH < Date.now()) {
                this.showAlert(site);
            }
        }

        // Schedule local notifications for subscriptions with non-free plans.
        else if (!previousSubscriptionData ||
            previousSubscriptionData.expirationTimestamp !== subscriptionData.expirationTimestamp) {

            await this.removeLocalNotifications(site);

            // Only schedule the expiring notifications if the subscription expires.
            if (site.subscription.hasValidUntil()) {
                await this.scheduleLocalNotifications(site);
            }
        }

        await storage.set(SUBSCRIPTION_KEY, subscriptionData);
    }

    /**
     * Remove upsells from a site.
     *
     * @param site Site.
     */
    protected async removeUpsellMessages(site: FreemiumSite): Promise<void> {
        await FreemiumStorage.forSite(site).remove(ALERT_TIME_KEY);
        await this.removeLocalNotifications(site);
    }

    /**
     * Show an upsell alert.
     *
     * @param site Site.
     */
    protected async showAlert(site: FreemiumSite): Promise<void> {
        const alert = await AlertController.create({
            header: Translate.instant('freemium.upsell_upgradefreetitle'),
            message: Translate.instant('freemium.upsell_upgradefreemessage'),
            buttons: [
                {
                    text: Translate.instant('freemium.upsell_upgradefreepostpone'),
                },
                {
                    text: Translate.instant('freemium.upsell_upgradefreeaccept'),
                    handler: () => CoreNavigator.navigateToSitePath('subscription'),
                },
            ],
        });

        await alert.present();

        // eslint-disable-next-line promise/catch-or-return
        alert.onDidDismiss().then(() => FreemiumStorage.forSite(site).set(ALERT_TIME_KEY, Date.now()));
    }

    /**
     * Schedule upsell notifications.
     *
     * @param site Site.
     */
    protected async scheduleLocalNotifications(site: FreemiumSite): Promise<void> {
        const expirationDate = this.adjustNotificationTime(site.subscription.validUntil as Date);

        await Promise.all([
            // Approximately 15 days before expiring.
            this.scheduleRenewalNotification(NOTIFICATION_IDS[0], site, this.adjustNotificationDay(expirationDate, 15)),

            // Approximately 7 days before expiring.
            this.scheduleRenewalNotification(NOTIFICATION_IDS[1], site, this.adjustNotificationDay(expirationDate, 7)),

            // The day the subscription expires.
            this.scheduleRenewalNotification(
                NOTIFICATION_IDS[2],
                site,
                expirationDate,
                Translate.instant('freemium.upsell_renewexpired'),
            ),
        ]);
    }

    /**
     * Remove all scheduled notifications for a site.
     *
     * @param site Site.
     */
    protected async removeLocalNotifications(site: FreemiumSite): Promise<void> {
        for (const notificationId of NOTIFICATION_IDS) {
            CoreLocalNotifications.cancel(notificationId, NOTIFICATION_COMPONENT, site.id!);
        }
    }

    /**
     * Schedule a notification suggesting the user to renew the subscription.
     *
     * @param id Notification id.
     * @param site Site.
     * @param date Notification date.
     * @param message Notification message.
     */
    protected async scheduleRenewalNotification(
        id: number,
        site: FreemiumSite,
        date: Date,
        message: string | null = null,
    ): Promise<void> {
        // Ignore past notifications.
        if (date.getTime() < Date.now()) {
            return;
        }

        // Schedule renewal notification.
        const expirationDate = site.subscription.validUntil!;

        await CoreLocalNotifications.schedule(
            {
                id,
                title: Translate.instant('freemium.upsell_renewtitle'),
                text: message || Translate.instant('freemium.upsell_renewexpiring', {
                    days: Math.floor((expirationDate.getTime() - date.getTime()) / CoreConstants.MILLISECONDS_DAY),
                }),
                trigger: { at: date },
            },
            NOTIFICATION_COMPONENT,
            site.id!,
        );
    }

    /**
     * Adjust date time to be appropiate for the user (within 9 to 17).
     *
     * @param date Date.
     * @returns Adjusted date.
     */
    protected adjustNotificationTime(date: Date): Date {
        const adjustedDate = new Date(date);

        adjustedDate.setHours(CoreMath.clamp(adjustedDate.getHours(), 9, 17));

        return adjustedDate;
    }

    /**
     * Adjust date day to be appropiate for the user (within a weekday).
     *
     * @param date Date.
     * @param daysBefore How many days before the date to use as a starting point.
     * @returns Adjusted date.
     */
    protected adjustNotificationDay(date: Date, daysBefore: number): Date {
        const adjustedDate = new Date(date.getTime() - daysBefore * CoreConstants.MILLISECONDS_DAY);

        // Adjust Sundays to Fridays.
        if (adjustedDate.getDay() === 0) {
            return new Date(adjustedDate.getTime() - CoreConstants.MILLISECONDS_DAY * 2);
        }

        // Adjust Saturdays to Fridays.
        if (adjustedDate.getDay() === 6) {
            return new Date(adjustedDate.getTime() - CoreConstants.MILLISECONDS_DAY);
        }

        // Keep any other day unadjusted.
        return adjustedDate;
    }

}

export const SiteSubscriptionUpsells = makeSingleton(SiteSubscriptionUpsellsService);
