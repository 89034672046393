// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';
import { makeSingleton, Translate } from '@singletons';
import { BIOMETRIC_TYPE, FingerprintAIO } from '@awesome-cordova-plugins/fingerprint-aio';
import { CoreDomUtils } from '@services/utils/dom';
import { FreemiumSites } from '@freemium/overrides/core/services/sites';
import { FreemiumSite } from '@freemium/overrides/core/classes/sites/site';
import { FreemiumStorage } from './storage';
import { CoreEvents } from '@singletons/events';
import { FreemiumBiometricLoginModalComponent } from '@freemium/features/biometriclogin/components/modal/modal';
import { CorePlatform } from '@services/platform';
export type FreemiumBiometricMethodAvailable = BIOMETRIC_TYPE;

export const USER_BIOMETRIC_LOGIN_CHANGED = 'user_biometric_login_changed';

/**
 * Class to provide biometric authentication
 */
@Injectable({ providedIn: 'root' })
export class FreemiumBiometricLoginService {

    protected readonly BIOMETRIC_LOGIN_ADVICES_DISPLAYED = 'FreemiumEnableBiometricLoginAdvicesDisplayed';
    protected readonly USER_ENABLED_BIOMETRIC_LOGIN = 'FreemiumUserEnableBiometricLogin';
    protected readonly BIOMETRIC_LOGIN_MAX_ADVICES = 3;
    protected biometricMethodAvailable?: BIOMETRIC_TYPE | null;
    protected site?: FreemiumSite;

    constructor() {
        CoreEvents.on(CoreEvents.MAIN_HOME_LOADED, async () => {
            const showBiometricLoginAdvice = await this.canShowAdviceModal();
            if (!showBiometricLoginAdvice) {
                return;
            }
            this.incrementAdvicesDisplayed();
            await CoreDomUtils.openModal({
                component: FreemiumBiometricLoginModalComponent,
                cssClass: 'core-modal-transparent-no-filter',
                closeOnNavigate: false,
                componentProps: { biometricMethod: await this.getBiometricMethodAvailable() },
            });
        });
    }

    /**
     * Show native biometric popup if the device has any biometric tool such as Fingerprint reader, FingerID, FaceID, etc.
     *
     * @returns {Promise<boolean>} true if process finish successfully, false if not.
     */
    async login(): Promise<boolean> {
        try {
            const canShowBiometricLogin = await this.canShowBiometricLogin();
            if (!canShowBiometricLogin) {
                return false;
            }
            await FingerprintAIO.show({
                cancelButtonTitle: Translate.instant('core.cancel'),
                description: Translate.instant('freemium.biometriclogindescription'),
                fallbackButtonTitle: Translate.instant('freemium.accept'),
                title: Translate.instant('freemium.biometriclogin'),
            });

            return true;
        } catch {
            return false;
        }
    }

    /**
     * Get biometric method available in the device.
     *
     * @returns {Promise<FreemiumBiometricMethodAvailable | null>} method available for the current device.
     */
    async getBiometricMethodAvailable(): Promise<FreemiumBiometricMethodAvailable | null> {
        try {
            if (this.biometricMethodAvailable === undefined) {
                this.biometricMethodAvailable = await FingerprintAIO.isAvailable() || null;
            }

            return this.biometricMethodAvailable || null;
        } catch {
            return null;
        }
    }

    /**
     * Get site biometric login status (if biometric login is disabled or not).
     *
     * @returns {Promise<boolean>} True if site has enable biometric login, false if disabled.
     */
    async isEnabledBySite(): Promise<boolean> {
        const site = await FreemiumSites.getSite();
        const subscription = await site.getSubscription();

        return !!subscription?.biometricLogin;
    }

    /**
     * Read from local site config if user enabled biometric login.
     *
     * @returns {Promise<boolean>} Gives if user enabled biometric login.
     */
    async isEnabledByUser(): Promise<boolean> {
        try {
            const site = await FreemiumSites.getSite();

            return await FreemiumStorage.forSite(site).get(this.USER_ENABLED_BIOMETRIC_LOGIN) ?? false;
        } catch (error) {
            return false;
        }
    }

    /**
     * Save user biometric login config (enabled or desabled).
     *
     * @param {boolean} enabled ´true´: enabled, ´false´: disabled
     */
    async setUserEnabledBiometricLogin(enabled: boolean): Promise<void> {
        const site = await FreemiumSites.getSite();

        await FreemiumStorage.forSite(site).set(this.USER_ENABLED_BIOMETRIC_LOGIN, enabled);
        CoreEvents.trigger(USER_BIOMETRIC_LOGIN_CHANGED, { enabled });
    }

    /**
     * Get number of advices displayed to the current user.
     *
     * @returns {Promise<number>} Gives how many retries did the current user.
     */
    protected async getAdvicesDisplayed(): Promise<number> {
        try {
            const site = await FreemiumSites.getSite();

            return await FreemiumStorage.forSite(site).get(this.BIOMETRIC_LOGIN_ADVICES_DISPLAYED) ?? 0;
        } catch (error) {
            return 0;
        }
    }

    /**
     * Save number of advices displayed in local site config.
     *
     * @param {number} advicesDisplayed number of advices displayed.
     */
    protected async setAdvicesDisplayed(advicesDisplayed: number): Promise<void> {
        const site = await FreemiumSites.getSite();

        await FreemiumStorage.forSite(site).set(this.BIOMETRIC_LOGIN_ADVICES_DISPLAYED, advicesDisplayed);
    }

    /**
     * Increments attemps displayed in 1.
     */
    protected async incrementAdvicesDisplayed(): Promise<void> {
        const currentAttemps = await this.getAdvicesDisplayed();
        this.setAdvicesDisplayed(currentAttemps + 1);
    }

    /**
     * Give if can show biometric login advice modal.
     *
     * @returns {Promise<boolean>} Give if can show biometric login advice modal.
     */
    async canShowAdviceModal(): Promise<boolean> {
        const isEnabledBySite = await this.isEnabledBySite();
        const isEnabledByUser = await this.isEnabledByUser();
        const retries = await this.getAdvicesDisplayed();
        const isBiometricAvailable = await this.getBiometricMethodAvailable();

        return isEnabledBySite && !isEnabledByUser && !!isBiometricAvailable && retries < this.BIOMETRIC_LOGIN_MAX_ADVICES;
    }

    /**
     * Give if the user can show biometric login.
     *
     * @returns {Promise<boolean>} Give if the user can show biometric login.
     */
    async canShowBiometricLogin(): Promise<boolean> {
        if (!CorePlatform.isMobile()) {
            return false;
        }

        const availableMethod = await this.getBiometricMethodAvailable();
        const isEnabledBySite = await this.isEnabledBySite();
        const isEnabledByUser = await this.isEnabledByUser();

        return !!availableMethod && isEnabledBySite && isEnabledByUser;
    }

}

export const FreemiumBiometricLogin = makeSingleton(FreemiumBiometricLoginService);
