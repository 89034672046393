// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';
import { CoreMainMenuHomeHandler, CoreMainMenuHomeHandlerToDisplay } from '@features/mainmenu/services/home-delegate';
import { makeSingleton } from '@singletons';
import { WorkplaceCustompage } from '@workplace/features/custompage/services/custompage';
import { WorkplaceTeams } from '../teams';

/**
 * Handler to add learning tab into home page.
 */
@Injectable({ providedIn: 'root' })
export class WorkplaceTeamsHomeHandlerService implements CoreMainMenuHomeHandler {

    static readonly PAGE_NAME = 'teams';

    name = 'WorkplaceTeamsHome';
    priority = 1300;

    /**
     * @inheritdoc
     */
    async isEnabled(): Promise<boolean> {
        return this.isEnabledForSite();
    }

    /**
     * @inheritdoc
     */
    async isEnabledForSite(siteId?: string): Promise<boolean> {
        const [customPagesWSAvailable, teamsEnabled] = await Promise.all([
            WorkplaceCustompage.isAvailable(siteId),
            WorkplaceTeams.isTeamsTabEnabled(siteId),
        ]);

        // If custompages are enabled, then teams should not be displayed.
        return teamsEnabled && !customPagesWSAvailable;
    }

    /**
     * Returns the data needed to render the handler.
     *
     * @returns Data needed to render the handler.
     */
    getDisplayData(): CoreMainMenuHomeHandlerToDisplay {
        return {
            title: 'workplace.teams.pluginname',
            page: WorkplaceTeamsHomeHandlerService.PAGE_NAME,
            class: 'workplace-teams-home-handler',
            icon: 'fas-user-group',
        };
    }

}

export const WorkplaceTeamsHomeHandler = makeSingleton(WorkplaceTeamsHomeHandlerService);
