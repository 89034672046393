// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';
import { CoreSiteWSPreSets } from '@classes/sites/authenticated-site';
import { CoreBlockDelegate } from '@features/block/services/block-delegate';
import { CoreCourseBlock } from '@features/course/services/course';
import { CoreMainMenuHomeDelegate } from '@features/mainmenu/services/home-delegate';
import { CoreSites } from '@services/sites';
import { makeSingleton } from '@singletons';
import { CoreEvents } from '@singletons/events';
import { CoreLogger } from '@singletons/logger';
import { WorkplaceCustomPageHomeHandlerService } from './handlers/custompage-home';

/**
 * Service that provides workplace custom page features.
 *
 * @since 4.0
 */
@Injectable({ providedIn: 'root' })
export class WorkplaceCustompageService {

    protected logger: CoreLogger;
    static readonly CUSTOM_PAGES_LOADED = 'custom_pages_loaded';
    static readonly ROOT_CACHE_KEY = 'WorkplaceCustomPage:';

    constructor() {
        this.logger = CoreLogger.getInstance('WorkplaceCustompageService');
    }

    /**
     * Initialize.
     */
    initialize(): void {
        // Fetch the custom pages on login.
        CoreEvents.on(CoreEvents.LOGIN, async (data) => {
            try {
                if (data.siteId != CoreSites.getCurrentSiteId()) {
                    return;
                }

                // Load custom pages.
                await this.registerCustomPages();

                CoreEvents.trigger(WorkplaceCustompageService.CUSTOM_PAGES_LOADED, {}, data.siteId);
            } catch (error) {
                this.logger.error(error);
            }
        });

    }

    /**
     * Get cache key for list of custom pages WS call.
     *
     * @returns Cache key.
     */
    protected getCustomPageListCacheKey(): string {
        return WorkplaceCustompageService.ROOT_CACHE_KEY + 'list';
    }

    /**
     * Get cache key for a custom pages WS call.
     *
     * @param pageId Page Id.
     * @returns Cache key.
     */
    protected getCustomPageCacheKey(pageId: number): string {
        return WorkplaceCustompageService.ROOT_CACHE_KEY + 'page:' + pageId;
    }

    /**
     * Check if user custompage WS are available.
     *
     * @param siteId Site ID. If not defined, use current site.
     * @returns Whether the webservice is available.
     */
    async isAvailable(siteId?: string): Promise<boolean> {
        const site = await CoreSites.getSite(siteId);

        return site?.wsAvailable('tool_custompage_page_listing');
    }

    /**
     * Check if user custompage WS are available and blocks enabled.
     *
     * @param siteId Site ID. If not defined, use current site.
     * @returns Whether the webservice is available and blocks enabled.
     */
    async isEnabled(siteId?: string): Promise<boolean> {
        const site = await CoreSites.getSite(siteId);

        const [customPagesWSAvailable, blocksDisabled] = await Promise.all([
            this.isAvailable(site.getId()),
            CoreBlockDelegate.areBlocksDisabled(site.getId()),
        ]);

        return customPagesWSAvailable && !blocksDisabled;
    }

    /**
     * Register custom pages to Main menu home delegate.
     *
     * @returns Promise resolved when done.
     */
    async registerCustomPages(): Promise<void> {
        const list = await WorkplaceCustompage.getCustomPages();

        list.forEach((page) => {
            CoreMainMenuHomeDelegate.registerHandler(
                new WorkplaceCustomPageHomeHandlerService(page.id, page.title, page.name, page.weight),
            );
        });

    }

    /**
     * Returns the list of custom pages of the current site to be shown in the App, if any.
     *
     * @returns List of custom pages.
     */
    async getCustomPages(): Promise<WorkplaceCustompageElement[]> {
        const siteId = CoreSites.getCurrentSiteId();
        if (!siteId) {
            return [];
        }

        const customPagesWSAvailable = await WorkplaceCustompage.isEnabled(siteId);

        if (!customPagesWSAvailable){
            return [];
        }

        return await WorkplaceCustompage.getCustomPageList(siteId);
    }

    /**
     * Return the list of custom pages with compatible blocks.
     *
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved when the info is retrieved.
     */
    protected async getCustomPageList(siteId?: string): Promise<WorkplaceCustompageElement[]> {
        const site = await CoreSites.getSite(siteId);
        siteId = site.getId();

        const candidatePages = await this.getCustomPageListFromWS(siteId);
        const pages: WorkplaceCustompageElement[] = [];

        await Promise.all(candidatePages.map(async (page) => {
            const pageBlocks = await this.getCustomPageBlocksFromWS(page.id, siteId);

            if (CoreBlockDelegate.hasSupportedBlock(pageBlocks)) {
                pages.push(page);
            }
        }));

        return pages;
    }

    /**
     * Return the list of custom pages.
     *
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved when the info is retrieved.
     */
    protected async getCustomPageListFromWS(siteId?: string): Promise<WorkplaceCustompageElement[]> {
        const site = await CoreSites.getSite(siteId);
        const preSets: CoreSiteWSPreSets = {
            cacheKey: this.getCustomPageListCacheKey(),
            getFromCache: false,
        };

        return site.read('tool_custompage_page_listing', undefined, preSets);
    }

    /**
     * Return the blocks of a custom page.
     *
     * @param pageId Page Id.
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved when the info is retrieved.
     */
    protected async getCustomPageBlocksFromWS(pageId: number, siteId?: string): Promise<CoreCourseBlock[]> {
        const site = await CoreSites.getSite(siteId);
        const preSets: CoreSiteWSPreSets = {
            cacheKey: this.getCustomPageCacheKey(pageId),
        };

        const params: WorkplaceCustompagePageGetWSParams = {
            pageid: pageId,
        };

        const page = await site.read<WorkplaceCustompagePageGetWSResponse>('tool_custompage_page_get', params, preSets);

        return page.blocks;
    }

    /**
     * Get custom page blocks divided by region.
     *
     * @param pageId Page Id.
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved when the info is retrieved.
     */
    async getCustomPageBlocks(pageId: number, siteId?: string): Promise<WorkplaceCustompageBlocks> {
        const blocks = await this.getCustomPageBlocksFromWS(pageId, siteId);

        let mainBlocks: CoreCourseBlock[] = [];
        let sideBlocks: CoreCourseBlock[] = [];

        blocks.forEach((block) => {
            if (block.region == 'content' || block.region == 'main') {
                mainBlocks.push(block);
            } else {
                sideBlocks.push(block);
            }
        });

        if (mainBlocks.length == 0) {
            mainBlocks = [];
            sideBlocks = [];

            blocks.forEach((block) => {
                if (block.region.match('side')) {
                    sideBlocks.push(block);
                } else {
                    mainBlocks.push(block);
                }
            });
        }

        return { mainBlocks, sideBlocks };

    }

    /**
     * Invalidates custom page  WS call.
     *
     * @param pageId Page Id.
     * @param siteId Site ID to invalidate. If not defined, use current site.
     * @returns Promise resolved when the data is invalidated.
     */
    async invalidateCustomPage(pageId: number, siteId?: string): Promise<void> {
        const site = await CoreSites.getSite(siteId);

        return site.invalidateWsCacheForKey(this.getCustomPageCacheKey(pageId));
    }

}

export const WorkplaceCustompage = makeSingleton(WorkplaceCustompageService);

/**
 * Params of tool_custompage_page_get WS.
 */
export type WorkplaceCustompagePageGetWSParams = {
    pageid: number; // Page ID.
};

/**
 * Data returned by tool_custompage_page_get WS.
 */
export type WorkplaceCustompagePageGetWSResponse = {
    name: string; // Page name.
    title: string; // Page title (optional).
    blocks: CoreCourseBlock[];
};

/**
 * Data returned by tool_custompage_page_listing WS.
 */
export type WorkplaceCustompagePageListingWSResponse = WorkplaceCustompageElement[];

/**
 * Single custom page element.
 */
export type WorkplaceCustompageElement = {
    id: number; // Page ID.
    weight: number; // Page weight.
    name: string; // Page name.
    title: string; // Page title (optional).
};

export type  WorkplaceCustompageBlocks = {
    mainBlocks: CoreCourseBlock[];
    sideBlocks: CoreCourseBlock[];
};
