<ion-content>
    <ion-refresher slot="fixed" [disabled]="!loaded" (ionRefresh)="refreshBlocks($event.target)">
        <ion-refresher-content pullingText="{{ 'core.pulltorefresh' | translate }}" />
    </ion-refresher>

    <core-loading [hideUntil]="loaded">
        <ion-list class="list-item-limited-width">
            <ion-item class="ion-text-wrap divider" *ngIf="title">
                <ion-label>
                    <h2 class="big">{{ title }}</h2>
                </ion-label>
            </ion-item>
            <ng-container *ngFor="let block of blocks">
                <core-block *ngIf="block.visible" [block]="block" contextLevel="user" [instanceId]="userId" />
            </ng-container>
        </ion-list>

        <core-block-side-blocks-button slot="fixed" *ngIf="hasSideBlocks" contextLevel="user" [instanceId]="userId"
            [myDashboardPage]="'custompage-'+pageId" />

        <core-empty-box *ngIf="!hasMainBlocks" icon="fas-cubes" [message]="'core.course.nocontentavailable' | translate" />
    </core-loading>
</ion-content>
