// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';

import { CoreMainMenuProvider, CoreMainMenuCustomItem } from '@features/mainmenu/services/mainmenu';
import { makeSingleton } from '@singletons';

import { FreemiumFeature } from '@freemium/constants';
import { FreemiumSites } from '@freemium/overrides/core/services/sites';

/**
 * Override the base main menu provider to limit custom items based on subscription.
 */
@Injectable({ providedIn: 'root' })
export class FreemiumMainMenuProvider extends CoreMainMenuProvider {

    /**
     * @inheritdoc
     */
    async getCustomMenuItemsFromSite(siteId?: string): Promise<CoreMainMenuCustomItem[]> {
        const items = await super.getCustomMenuItemsFromSite(siteId);
        const site = await FreemiumSites.getSite(siteId);
        const maxItems = site.subscription.getFeatureLimit(FreemiumFeature.CustomMenuItems);

        if (maxItems !== null && items.length > maxItems) {
            items.splice(maxItems, items.length - maxItems);
        }

        return items;
    }

    /**
     * Get a list of custom menu items for a certain site without applying freemium restrictions.
     *
     * @param siteId Site ID. If not defined, current site.
     * @returns List of custom menu items without freemium restrictions.
     */
    async getUnrestrictedCustomMenuItemsFromSite(siteId?: string): Promise<CoreMainMenuCustomItem[]> {
        return super.getCustomMenuItemsFromSite(siteId);
    }

}

export const FreemiumMainMenu = makeSingleton<FreemiumMainMenuProvider>(CoreMainMenuProvider);
