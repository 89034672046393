// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { CoreConstants } from '@/core/constants';
import { Injectable } from '@angular/core';
import { AppSubscription, AppSubscriptionJson, DefaultAppSubscription } from '@freemium/classes/app-subscription';
import { FreemiumStorage } from '@freemium/services/storage';
import { SubscriptionResponse } from '@freemium/utils/subscriptions';
import { CoreCacheManager } from '@services/cache-manager';
import { CoreConfig } from '@services/config';
import { CoreUtils } from '@services/utils/utils';
import { CoreWS } from '@services/ws';
import { makeSingleton } from '@singletons';
import { CoreLogger } from '@singletons/logger';
import { CoreObject } from '@singletons/object';

const SUBSCRIPTION_KEY = 'app-subscription';
const LAST_SUBSCRIPTION_UPDATE_KEY = 'app-lastSubscriptionUpdate';
const SUBSCRIPTION_EXPIRATION_TIME = CoreConstants.MILLISECONDS_DAY;

/**
 * Manage app subscription.
 */
@Injectable({ providedIn: 'root' })
export class FreemiumAppSubscriptionService {

    protected logger: CoreLogger;
    protected subscription?: AppSubscription;

    constructor() {
        this.logger = CoreLogger.getInstance('FreemiumAppSubscriptionService');
    }

    /**
     * Initialize subscription.
     */
    async initialize(): Promise<void> {
        await this.initializeListeners();
        await this.initializeSubscription();
    }

    /**
     * Initialize listeners.
     */
    protected async initializeListeners(): Promise<void> {
        CoreCacheManager.registerInvalidateListener(async () => {
            await FreemiumStorage.remove(LAST_SUBSCRIPTION_UPDATE_KEY);
        });
    }

    /**
     * Initialize subscription.
     */
    protected async initializeSubscription(): Promise<void> {
        if (!CoreConstants.CONFIG.enableAppSubscription) {
            this.logger.debug('Disabled');

            return;
        }

        const restored = await this.restoreCachedSubscription();

        if (restored) {
            return;
        }

        await Promise.race([
            CoreUtils.wait(10 * CoreConstants.MILLISECONDS_SECOND),
            this.updateSubscription(),
        ]);
    }

    /**
     * Set current app subscription.
     *
     * @param subscription App subscription.
     */
    protected async setSubscription(subscription: AppSubscription): Promise<void> {
        this.logger.debug('Setting subscription', subscription);

        this.subscription = subscription;

        if (subscription.appConfig) {
            CoreConfig.patchEnvironment(subscription.appConfig, { patchDefault: true });
        }

        await this.storeSubscriptionInCache(subscription);
    }

    /**
     * Get a subscription from the cache if it isn't expired.
     *
     * @returns Whether subscription was restored or not.
     */
    protected async restoreCachedSubscription(): Promise<boolean> {
        // Check if the subscription data has expired.
        this.logger.debug('Restoring cached subscription');

        const lastSubscriptionUpdate = await FreemiumStorage.get(LAST_SUBSCRIPTION_UPDATE_KEY, 0);

        if (Date.now() >= lastSubscriptionUpdate + SUBSCRIPTION_EXPIRATION_TIME) {
            // Expired, ignore storage.
            this.logger.debug('Cached subscription expired or missing');

            return false;
        }

        const subscriptionJson = await FreemiumStorage.get<AppSubscriptionJson>(SUBSCRIPTION_KEY);

        if (!subscriptionJson) {
            // Missing from storage.
            this.logger.debug('Cached subscription missing');

            return false;
        }

        await this.setSubscription(AppSubscription.fromJSON(subscriptionJson));

        return true;
    }

    /**
     * Fetch new app subscription.
     */
    protected async updateSubscription(): Promise<void> {
        try {
            this.logger.debug('Updating subscription');

            const response = await CoreWS.callAjax<SubscriptionResponse>(
                'local_apps_get_subscription',
                { appid: CoreConstants.CONFIG.app_id },
                { siteUrl: CoreConstants.CONFIG.appServicesUrl, noLogin: true },
            );

            await this.setSubscription(this.parseSubscriptionResponse(response) ?? new DefaultAppSubscription());
        } catch (error) {
            this.logger.error(error);

            const subscriptionJson = await FreemiumStorage.get<AppSubscriptionJson>(SUBSCRIPTION_KEY);

            if (subscriptionJson) {
                this.logger.debug('Reusing expired cached subscription');

                await this.setSubscription(AppSubscription.fromJSON(subscriptionJson));

                return;
            }

            await this.setSubscription(new DefaultAppSubscription());
        }
    }

    /**
     * Store subscription in cache.
     *
     * @param subscription Subscription to store.
     * @returns Promise resolved when done.
     */
    protected async storeSubscriptionInCache(subscription: AppSubscription): Promise<void> {
        await Promise.all([
            FreemiumStorage.set(SUBSCRIPTION_KEY, subscription.toJSON()),
            FreemiumStorage.set(LAST_SUBSCRIPTION_UPDATE_KEY, Date.now()),
        ]);
    }

    /**
     * Parse a response made on the App Services endpoint.
     *
     * @param response Response.
     * @param response.subscription Response subscription.
     * @returns Subscription info.
     */
    protected parseSubscriptionResponse({ subscription }: SubscriptionResponse): AppSubscription | null {
        if (!subscription) {
            return null;
        }

        const json: AppSubscriptionJson = CoreObject.withoutUndefined({
            validUntilTimestamp: subscription.expiretime ? subscription.expiretime * CoreConstants.MILLISECONDS_SECOND : undefined,
        });

        // Parse app config.
        if (subscription.appconfig) {
            json.appConfig = JSON.parse(subscription.appconfig);
        }

        return AppSubscription.fromJSON(json);
    }

}

export const FreemiumAppSubscription = makeSingleton(FreemiumAppSubscriptionService);
