// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';
import { CoreSiteWSPreSets } from '@classes/sites/authenticated-site';
import { CoreSites } from '@services/sites';
import { CoreWSExternalWarning } from '@services/ws';
import { makeSingleton } from '@singletons';
import { CoreCourseSummary } from '@features/course/services/course';

/**
 * Service that provides workplace my available block features.
 */
@Injectable({ providedIn: 'root' })
export class WorkplaceMyAvailableService {

    static readonly ROOT_CACHE_KEY = 'Workplace:MyAvailable';

    /**
     * Get cache key for get my available courses WS call.
     *
     * @returns Cache key.
     */
    protected getMyAvailableCoursesCacheKey(): string {
        return WorkplaceMyAvailableService.ROOT_CACHE_KEY;
    }

    /**
     * Check if block WS is available
     *
     * @param siteId Site ID. If not defined, use current site.
     * @returns Whether the webservice is available.
     * @since 4.0
     */
    async isWsAvailable(siteId?: string): Promise<boolean> {
        const site = await CoreSites.getSite(siteId);

        return site?.wsAvailable('block_myavailable_get_available_courses');
    }

    /**
     * Invalidates get my available courses WS call.
     *
     * @param siteId Site ID to invalidate. If not defined, use current site.
     * @returns Promise resolved when the data is invalidated.
     */
    async invalidateMyAvailableCourses(siteId?: string): Promise<void> {
        const site = await CoreSites.getSite(siteId);

        return site.invalidateWsCacheForKey(this.getMyAvailableCoursesCacheKey());
    }

    /**
     * Return the list of courses to be shown in the block.
     *
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved with the retrieved info.
     */
    async getMyAvailableCourses(siteId?: string): Promise<WorkplaceMyAvailableCourse[]> {
        const site = await CoreSites.getSite(siteId);

        const preSets: CoreSiteWSPreSets = {
            cacheKey: this.getMyAvailableCoursesCacheKey(),
        };

        const result =
            await site.read<WorkplaceMyAvailableGetAvailableCoursesWSResponse>(
                'block_myavailable_get_available_courses',
                undefined,
                preSets,
            );

        return result.data.courses || [];
    }

}

export const WorkplaceMyAvailable = makeSingleton(WorkplaceMyAvailableService);

/**
 * Data returned by block_myavailable_get_available_courses WS.
 */
type WorkplaceMyAvailableGetAvailableCoursesWSResponse = {
    data: {
        courses: WorkplaceMyAvailableCourse[]; // Courses.
    };
    warnings?: CoreWSExternalWarning[];
};

export type WorkplaceMyAvailableCourse = Omit<CoreCourseSummary, 'timemodified'>;
