<ion-list class="addon-qtype-calculated-container" *ngIf="question && (question.text || question.text === '')">
    <ion-item class="ion-text-wrap">
        <ion-label>
            <core-format-text [component]="component" [componentId]="componentId" [text]="question.text" [contextLevel]="contextLevel"
                [contextInstanceId]="contextInstanceId" [courseId]="courseId" />
        </ion-label>
    </ion-item>

    <!-- Display unit options before the answer input. -->
    <ng-container *ngIf="question.options && question.options.length && question.optionsFirst">
        <ng-container *ngTemplateOutlet="radioUnits" />
    </ng-container>

    <ion-item *ngIf="question.input" class="ion-text-wrap core-{{question.input.correctIconColor}}-item">
        <div class="flex-row ion-align-items-end">
            <!-- Display unit select before the answer input. -->
            <ng-container *ngIf="question.select && question.selectFirst">
                <ng-container *ngTemplateOutlet="selectUnits" />
            </ng-container>

            <!-- Input to enter the answer. -->
            <ion-input labelPlacement="stacked" type="text" [attr.name]="question.input.name"
                [placeholder]="question.input.readOnly ? '' : 'core.question.answer' | translate" [value]="question.input.value"
                [disabled]="question.input.readOnly" autocorrect="off" [label]="'addon.mod_quiz.answercolon' | translate" />

            <!-- Display unit select after the answer input. -->
            <ng-container *ngIf="question.select && !question.selectFirst">
                <ng-container *ngTemplateOutlet="selectUnits" />
            </ng-container>
        </div>
        <ion-icon *ngIf="question.input.correctIcon" class="core-correct-icon ion-align-self-center" slot="end"
            [name]="question.input.correctIcon" [color]="[question.input.correctIconColor]" />
    </ion-item>

    <!-- Display unit options after the answer input. -->
    <ng-container *ngIf="question.options && question.options.length && !question.optionsFirst">
        <ng-container *ngTemplateOutlet="radioUnits" />
    </ng-container>
</ion-list>

<!-- Template for units entered using a select. -->
<ng-template #selectUnits>
    <label *ngIf="question!.select!.accessibilityLabel" class="accesshide" for="{{question!.select!.id}}">
        {{ question!.select!.accessibilityLabel }}
    </label>
    <ion-select id="{{question!.select!.id}}" [name]="question!.select!.name" [(ngModel)]="question!.select!.selected"
        interface="action-sheet" [disabled]="question!.select!.disabled" [slot]="question?.selectFirst ? 'start' : 'end'"
        [cancelText]="'core.cancel' | translate" [interfaceOptions]="{header: 'addon.mod_quiz.unit' | translate}">
        <ion-select-option *ngFor="let option of question!.select!.options" [value]="option.value">
            {{option.label}}
        </ion-select-option>
    </ion-select>
</ng-template>

<!-- Template for units entered using radio buttons. -->
<ng-template #radioUnits>
    <ion-radio-group [(ngModel)]="question!.unit" [name]="question!.optionsName">
        <ion-item class="ion-text-wrap" *ngFor="let option of question!.options">
            <ion-radio [value]="option.value" [disabled]="option.disabled || question!.input?.readOnly"
                [color]="question!.input?.correctIconColor">
                {{ option.text }}
            </ion-radio>
        </ion-item>

        <!-- ion-radio doesn't use an input. Create a hidden input to hold the selected value. -->
        <input type="hidden" [ngModel]="question!.unit" [attr.name]="question!.optionsName">
    </ion-radio-group>
</ng-template>
