<core-loading [hideUntil]="loaded" [fullscreen]="false" class="margin">

    @if (hasContent) {
        <ion-row class="ion-justify-content-between ion-align-items-center addon-block-mycourses-filter">
            <ion-col size="auto">
                <core-combobox [label]="'core.courses.filtermycourses' | translate" [selection]="controls.filter"
                    (onChange)="filterChanged($event)">
                    @for ( filter of FILTERS; track filter.value) {
                        <ion-select-option [value]="filter.value" [class]="'ion-text-wrap ' + filter.class">
                            {{ filter.label | translate }}
                        </ion-select-option>
                    }
                </core-combobox>
            </ion-col>
            <ion-col size="auto">
                <core-combobox [label]="'core.sortby' | translate" [selection]="controls.sort" (onChange)="sortChanged($event)"
                    icon="fas-arrow-down-short-wide" class="no-border">
                    @for ( filter of SORTINGS; track filter.value) {
                        <ion-select-option [value]="filter.value" class="ion-text-wrap">
                            {{ filter.label | translate }}
                        </ion-select-option>
                    }
                </core-combobox>
            </ion-col>
        </ion-row>
    }

    <div class="list-item-limited-width">
        <!-- List of programs and courses. -->
        @for (item of filteredItems; track item.itemid) {
            <ion-card [attr.program-color]="item.itemid % 10">
                <ion-item button class="ion-text-wrap has-corner-button" lines="none" (click)="open(item)" [attr.aria-label]="item.fullname"
                    [detail]="false">
                    @if (item.image) {
                        <ion-avatar slot="start" class="core-course-thumb">
                            @if (item.dataImage) {
                                <div class="data-image" alt="" style='background-image: url("{{item.image}}")'></div>
                            } @else {
                                <img [url]="item.image" core-external-content alt="" />
                            }

                        </ion-avatar>
                    } @else {
                        <ion-icon name="fas-graduation-cap" slot="start" class="course-icon core-course-thumb"
                            aria-hidden="true" />
                    }

                    <ion-label>
                        <div class="core-course-maininfo">
                            <p class="item-heading">
                                <core-format-text [text]="item.fullname" />
                                @if (prefetchIcons && prefetchIcons[getPrefetchIconKey(item)]?.status === 'downloaded') {
                                    <ion-icon class="downloaded-icon" name="fam-cloud-done" color="success" role="status"
                                        [attr.aria-label]="'core.downloaded' | translate" />
                                }
                                @if (item.duedatebadgestr) {
                                    <ion-badge class="workplace-mycourses-duedate" [color]="item.duedatebadgetype || 'warning'">
                                        {{ item.duedatebadgestr }}
                                    </ion-badge>
                                }
                            </p>

                            @if (item.isprogram) {
                                <p class="workplace-mycourses-program">
                                    <ion-icon name="far-folder" aria-hidden="true" />
                                    {{ 'workplace.program' | translate }}
                                    <ion-badge class="workplace-mycourses-courses" color="secondary">
                                        {{ item.numcourses }} {{ 'workplace.courses' | translate }}
                                    </ion-badge>
                                </p>
                            }

                            @if (item.categoryname) {
                                <ion-chip color="primary" class="core-course-category core-course-additional-info ion-text-nowrap">
                                    <span class="sr-only">{{ 'core.courses.aria:coursecategory' | translate }}</span>
                                    <ion-label>
                                        <core-format-text [text]="item.categoryname" [contextLevel]="'coursecat'" />
                                    </ion-label>
                                </ion-chip>
                            }

                            @if (item.showhiddencoursebadge) {
                                <ion-chip color="secondary" class="ion-text-nowrap">
                                    <ion-label>
                                        {{ 'workplace.hiddenfromlearners' | translate}}
                                    </ion-label>
                                </ion-chip>
                            }
                        </div>
                        @if (item.showprogress !== false && item.progress !== null && item.progress >= 0) {
                            <div class="core-course-progress">
                                <core-progress-bar [progress]="item.progress"
                                    [a11yText]="'workplace.progress' | translate:{$a: item.progress}" />
                            </div>
                        }
                    </ion-label>

                    @if (!item.islocked && prefetchIcons[getPrefetchIconKey(item)] && downloadCourseEnabled) {
                        <div class="core-button-spinner corner-button" slot="end">
                            <core-download-refresh [status]="prefetchIcons[getPrefetchIconKey(item)].status"
                                [enabled]="downloadCourseEnabled"
                                [statusTranslatable]="prefetchIcons[getPrefetchIconKey(item)].statusTranslatable" [canTrustDownload]="false"
                                [loading]="prefetchIcons[getPrefetchIconKey(item)].loading" (action)="prefetchProgramOrCourse(item)" />
                        </div>
                    }
                </ion-item>
            </ion-card>
        } @empty {
            <core-empty-box image="assets/img/icons/courses.svg">
                <p class="item-heading">
                    @if (hasContent) {
                        {{'addon.block_myoverview.noresult' | translate}}
                    } @else {
                        {{'addon.block_myoverview.nocoursesenrolled' | translate}}
                    }
                </p>
                @if (searchEnabled) {
                    <p class="subdued">
                        @if (hasContent) {
                            {{'addon.block_myoverview.noresultdescription' | translate}}
                        } @else {
                            {{'addon.block_myoverview.nocoursesenrolleddescription' | translate}}
                        }
                    </p>
                    <ion-button (click)="openSearch()" fill="outline">
                        <ion-icon name="fas-magnifying-glass" slot="start" aria-hidden="true" />
                        {{'addon.block_myoverview.browseallcourses' | translate}}
                    </ion-button>
                }
            </core-empty-box>
            }
    </div>
</core-loading>
