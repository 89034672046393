<ion-header collapsible>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button [text]="'core.back' | translate" />
        </ion-buttons>
        <ion-title>
            <h1>
                <core-format-text [text]="title" contextLevel="module" [contextInstanceId]="module.id" [courseId]="courseId" />
            </h1>
        </ion-title>

        <ion-buttons slot="end">
            <ion-button fill="clear" *ngIf="displayOpenInBrowser && module.url && module.uservisible && !unsupported" [href]="module.url"
                core-link [showBrowserWarning]="false" [ariaLabel]="'core.openinbrowser' | translate">
                <ion-icon name="fas-up-right-from-square" slot="icon-only" aria-hidden="true" />
            </ion-button>
            <ion-button fill="clear" (click)="openModuleSummary()" aria-haspopup="true" [ariaLabel]="'core.info' | translate">
                <ion-icon name="fas-circle-info" slot="icon-only" aria-hidden="true" />
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class="limited-width">
    <ion-refresher slot="fixed" [disabled]="!loaded" (ionRefresh)="doRefresh($event.target)">
        <ion-refresher-content pullingText="{{ 'core.pulltorefresh' | translate }}" />
    </ion-refresher>
    <core-loading [hideUntil]="loaded" class="has-spacer">
        <core-course-module-info [module]="module" [courseId]="courseId" [description]="module.description" [component]="module.modname"
            [componentId]="module.id" [expandDescription]="true" [showAvailabilityInfo]="true" (completionChanged)="onCompletionChange()"
            [showManualCompletion]="showManualCompletion">

            <div class="core-module-additional-info">
                <p *ngIf="module.handlerData?.extraBadge" class="ion-text-wrap" [innerHTML]="module.handlerData?.extraBadge">
                </p>

                <!-- Hidden badges -->
                <ion-badge color="secondary" *ngIf="module.visible === 0" class="ion-text-wrap">
                    <ion-icon name="far-eye-slash" aria-hidden="true" />
                    {{ 'core.course.hiddenfromstudents' | translate }}
                </ion-badge>
                <ion-badge color="secondary" *ngIf="module.visible !== 0 && module.isStealth" class="ion-text-wrap">
                    <ion-icon name="fas-eye-low-vision" aria-hidden="true" />
                    {{ 'core.course.hiddenoncoursepage' | translate }}
                </ion-badge>
            </div>
        </core-course-module-info>

        <core-course-unsupported-module *ngIf="unsupported" [module]="module" />
    </core-loading>

    <core-course-module-navigation collapsible-footer appearOnBottom [hidden]="!loaded" [courseId]="courseId"
        [currentModuleId]="module.id" />
</ion-content>
