// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';
import { CoreSitesProvider, CoreLoginSiteInfo, CORE_SITE_CURRENT_SITE_ID_CONFIG } from '@services/sites';

import { FreemiumSite } from '@freemium/overrides/core/classes/sites/site';
import { SiteSubscription } from '@freemium/classes/site-subscription';
import { FreemiumSitesFactory } from '@freemium/overrides/core/services/sites-factory';
import { CoreApp } from '@services/app';
import { CoreDomUtils } from '@services/utils/dom';
import { CoreWS } from '@services/ws';
import { CoreConstants } from '@/core/constants';
import { makeSingleton } from '@singletons';
import { CoreSitePublicConfigResponse } from '@classes/sites/unauthenticated-site';
import { CoreConfig } from '@services/config';
import { CorePlatform } from '@services/platform';

/**
 * Override sites provider to add functionality specific to freemium.
 */
@Injectable({ providedIn: 'root' })
export class FreemiumSitesProvider extends CoreSitesProvider {

    /**
     * @inheritdoc
     */
    getSite!: (siteId?: string) => Promise<FreemiumSite>;

    /**
     * @inheritdoc
     */
    getSiteFromDB!: (siteId: string) => Promise<FreemiumSite>;

    /**
     * @inheritdoc
     */
    getCurrentSite!: () => FreemiumSite;

    /**
     * @inheritdoc
     */
    async findSites(search: string): Promise<CoreLoginSiteInfo[]> {
        if (CorePlatform.isAutomated()) {
            return [];
        }

        return CoreWS.callAjax(
            'local_apps_find_sites',
            { search, appid: CoreConstants.CONFIG.app_id },
            { siteUrl: CoreConstants.CONFIG.appServicesUrl, noLogin: true },
        );
    }

    /**
     * @inheritdoc
     */
    hasDefaultImage(site: CoreLoginSiteInfo): boolean {
        return !!site.imageurl?.endsWith('defaultsitelisting-bw.png')
            || !!site.imageurl?.endsWith('defaultsitelisting-color.png');
    }

    /**
     * @inheritdoc
     */
    async checkApplication(config?: CoreSitePublicConfigResponse): Promise<void> {
        const siteUrl = config && (config.httpswwwroot || config.wwwroot);

        if (!siteUrl) {
            return;
        }

        const subscription = await this.getSubscriptionForSiteUrl(siteUrl);

        if (subscription.restrictAccess) {
            await this.showMainApplicationNoticeModal(subscription, subscription.restrictAccess.message);

            if (subscription.restrictAccess.enforce) {
                await this.logout();

                throw new Error('This application is not allowed to be used with this site.');
            }
        }

        await super.checkApplication(config);
    }

    /**
     * Get a site subscription given its URL.
     *
     * @param url Site URL.
     * @returns Promise resolved with the site subscription.
     */
    getSubscriptionForSiteUrl(url: string): Promise<SiteSubscription> {
        const tmpSite = FreemiumSitesFactory.makeSite('', url, '');

        return tmpSite.getSubscription();
    }

    /**
     * Show dialog suggesting to download the main application.
     *
     * @param subscription Subscription.
     * @param message Message.
     */
    async showMainApplicationNoticeModal(subscription: SiteSubscription, message: string): Promise<void> {
        const link = CoreApp.getAppStoreUrl(subscription.storeConfig || {});

        await CoreDomUtils.showDownloadAppNoticeModal(message, link);
    }

    /**
     * Get current site subscription directly from the database, without using any optimizations.
     *
     * @returns Subscription of the current site, if any.
     */
    async getCurrentSiteSubscriptionFromDB(): Promise<SiteSubscription | null> {
        try {
            const siteId = await CoreConfig.getFromDB<string>(CORE_SITE_CURRENT_SITE_ID_CONFIG);
            const site = await this.getSiteFromDB(siteId);

            return site.getCachedSubscriptionFromDB();
        } catch {
            // If some of the queries fails, it means that the current site is not set up or it's missing a subscription.

            return null;
        }
    }

}

export const FreemiumSites = makeSingleton<FreemiumSitesProvider>(CoreSitesProvider);
