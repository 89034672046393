// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { IonRefresher } from '@ionic/angular';

import { CoreDomUtils } from '@services/utils/dom';
import { CoreCourseBlock } from '@features/course/services/course';
import { CoreBlockComponent } from '@features/block/components/block/block';
import { CoreBlockDelegate } from '@features/block/services/block-delegate';
import { WorkplaceCustompage } from '../../services/custompage';
import { CoreSites } from '@services/sites';
import { CoreNavigator } from '@services/navigator';

/**
 * Page that displays a workplace custompage.
 */
@Component({
    selector: 'page-workplace-custompage',
    templateUrl: 'custompage.html',
})
export class WorkplaceCustompagePage implements OnInit {

    @ViewChildren(CoreBlockComponent) blocksComponents?: QueryList<CoreBlockComponent>;

    hasMainBlocks = false;
    hasSideBlocks = false;
    pageId!: number;
    blocks: Partial<CoreCourseBlock>[] = [];
    loaded = false;
    userId?: number;
    title = '';

    /**
     * @inheritdoc
     */
    ngOnInit(): void {
        this.title = CoreNavigator.getRouteParam('title') || '';

        try {
            this.pageId = CoreNavigator.getRequiredRouteNumberParam('id');
        } catch (error) {
            CoreDomUtils.showErrorModal(error);
            CoreNavigator.back();

            return;
        }

        this.loadContent();
    }

    /**
     * Convenience function to fetch the dashboard data.
     *
     * @returns Promise resolved when done.
     */
    protected async loadContent(): Promise<void> {
        const customPagesWSAvailable = await WorkplaceCustompage.isEnabled();

        if (customPagesWSAvailable && this.pageId) {
            this.userId = CoreSites.getCurrentSiteUserId();

            try {
                const blocks = await WorkplaceCustompage.getCustomPageBlocks(this.pageId);

                this.blocks = blocks.mainBlocks;

                this.hasMainBlocks = CoreBlockDelegate.hasSupportedBlock(blocks.mainBlocks);
                this.hasSideBlocks = CoreBlockDelegate.hasSupportedBlock(blocks.sideBlocks);

                if (!this.hasMainBlocks && this.hasSideBlocks) {
                    this.blocks = blocks.sideBlocks;
                    this.hasMainBlocks = true;
                    this.hasSideBlocks = false;
                }
            } catch (error) {
                CoreDomUtils.showErrorModal(error);
            }
        } else {
            // Disabled.
            this.blocks = [];
            this.hasMainBlocks = false;
            this.hasSideBlocks = false;
        }

        this.loaded = true;
    }

    /**
     * Refresh the dashboard data.
     *
     * @param refresher Refresher.
     */
    refreshBlocks(refresher: IonRefresher): void {
        const promises: Promise<void>[] = [];

        promises.push(WorkplaceCustompage.invalidateCustomPage(this.pageId));

        // Invalidate the blocks.
        this.blocksComponents?.forEach((blockComponent) => {
            promises.push(blockComponent.invalidate().catch(() => {
                // Ignore errors.
            }));
        });

        Promise.all(promises).finally(() => {
            this.loadContent().finally(() => {
                refresher?.complete();
            });
        });
    }

}
