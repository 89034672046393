<ion-header [collapsible]="!this.pageId && !this.pageTitle">
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button [text]="'core.back' | translate" />
        </ion-buttons>
        <ion-title>
            <h1>
                <core-format-text [text]="title" contextLevel="module" [contextInstanceId]="module.id" [courseId]="courseId" />
            </h1>
        </ion-title>

        <ion-buttons slot="end" />
    </ion-toolbar>
</ion-header>
<ion-content class="limited-width">
    <ion-refresher slot="fixed" [disabled]="activityComponent?.showLoading" (ionRefresh)="activityComponent?.doRefresh($event.target)">
        <ion-refresher-content pullingText="{{ 'core.pulltorefresh' | translate }}" />
    </ion-refresher>

    <addon-mod-wiki-index [module]="module" [courseId]="courseId" [action]="action" [pageId]="pageId" [pageTitle]="pageTitle"
        [subwikiId]="subwikiId" [userId]="userId" [groupId]="groupId" (dataRetrieved)="updateData($event)" />
</ion-content>
