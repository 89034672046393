<!-- Display a loading until the iframe page is loaded. -->
<core-loading [hideUntil]="!loading && (safeUrl || launchExternalLabel)" />

<!--The iframe needs to be outside of core-loading, otherwise the request is canceled while loading. -->
<!-- Don't add the iframe until safeUrl is set, adding an iframe with null as src causes the iframe to load the whole app. -->
<ng-container *ngIf="safeUrl && !launchExternalLabel">
    <core-navbar-buttons slot="end" prepend *ngIf="initialized && showFullscreenOnToolbar && !loading">
        <ion-button fill="clear" (click)="toggleFullscreen()"
            [ariaLabel]="(fullscreen ? 'core.disablefullscreen' : 'core.fullscreen') | translate">
            <ion-icon [name]="fullscreen ? 'fas-compress' : 'fas-expand'" slot="icon-only" aria-hidden="true" />
        </ion-button>
    </core-navbar-buttons>

    <!-- allowfullscreen cannot be set dynamically using attribute binding, define 2 iframe elements. -->
    <ng-container *ngIf="allowFullscreen">
        <iframe #iframe class="core-iframe" [attr.id]="id" [ngStyle]="{'width': iframeWidth, 'height': iframeHeight}" [src]="safeUrl"
            allowfullscreen="true" [class.core-iframe-loading]="loading">
        </iframe>
    </ng-container>
    <ng-container *ngIf="!allowFullscreen">
        <iframe #iframe class="core-iframe" [attr.id]="id" [ngStyle]="{'width': iframeWidth, 'height': iframeHeight}" [src]="safeUrl"
            [class.core-iframe-loading]="loading">
        </iframe>
    </ng-container>

    <ion-button *ngIf="!loading && displayHelp" expand="block" fill="clear" (click)="openIframeHelpModal()" aria-haspopup="dialog"
        class="core-button-as-link core-iframe-help">
        {{ 'core.iframehelp' | translate }}
    </ion-button>
</ng-container>

<!-- Iframe content needs to be launched in an external app. -->
<ion-button *ngIf="launchExternalLabel" expand="block" class="ion-text-wrap ion-margin" (click)="launchExternal()">
    {{ launchExternalLabel }}
</ion-button>
