// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

export const ADDON_MOD_WORKSHOP_ASSESSMENT_STRATEGY_ACCUMULATIVE_NAME = 'AddonModWorkshopAssessmentStrategyAccumulative';
export const ADDON_MOD_WORKSHOP_ASSESSMENT_STRATEGY_ACCUMULATIVE_STRATEGY_NAME = 'accumulative';

export const ADDON_MOD_WORKSHOP_ASSESSMENT_STRATEGY_COMMENTS_NAME = 'AddonModWorkshopAssessmentStrategyComments';
export const ADDON_MOD_WORKSHOP_ASSESSMENT_STRATEGY_COMMENTS_STRATEGY_NAME = 'comments';

export const ADDON_MOD_WORKSHOP_ASSESSMENT_STRATEGY_NUMERRORS_NAME = 'AddonModWorkshopAssessmentStrategyNumErrors';
export const ADDON_MOD_WORKSHOP_ASSESSMENT_STRATEGY_NUMERRORS_STRATEGY_NAME = 'numerrors';

export const ADDON_MOD_WORKSHOP_ASSESSMENT_STRATEGY_RUBRIC_NAME = 'AddonModWorkshopAssessmentStrategyRubric';
export const ADDON_MOD_WORKSHOP_ASSESSMENT_STRATEGY_RUBRIC_STRATEGY_NAME = 'rubric';
