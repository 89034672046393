// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Component, Input, OnInit } from '@angular/core';
import { CoreUtils, TreeNode } from '@services/utils/utils';
import { ModalController } from '@singletons';
import {
    Workplace,
    WorkplaceToolCatalogueCourseOnSetData,
    WorkplaceToolCatalogueGetUserCatalogueProgramStructure,
    WorkplaceToolCatalogueProgramData,
    WorkplaceToolCatalogueSetData,
} from '@workplace/services/workplace';

const PROGRAM_HELP_IDENTIFIER = 'tool_catalogue_hide_program_cover_help';

/**
 * Display info about a program.
 */
@Component({
    selector: 'workplace-program-info',
    templateUrl: 'program-info.html',
    styleUrls: ['program-info.scss'],
})
export class WorkplaceProgramInfoComponent implements OnInit {

    @Input() program!: WorkplaceToolCatalogueProgramData; // The program to render.
    @Input() programstructure!: WorkplaceToolCatalogueGetUserCatalogueProgramStructure; // The program structure to render.
    @Input() firstEnter = false;

    collapsed: Record<string, boolean> = {};

    baseSet?: WorkplaceToolCatalogueSetData;
    structure?: WorkplaceToolCatalogueSetStructureData;
    dismissed = false;

    /**
     * @inheritdoc
     */
    async ngOnInit(): Promise<void> {
        this.dismissed = await Workplace.getDismissMessageStatus(PROGRAM_HELP_IDENTIFIER);

        this.baseSet = this.programstructure.sets.find((set) => set.parentsetid === 0);

        const sets: WorkplaceToolCatalogueSetStructureData[] = CoreUtils.clone(this.programstructure.sets);
        this.programstructure.courses.forEach((course) => {
            const set = sets.find((set) => set.setid === course.setid);
            if (!set) {
                return;
            }

            if (set.children === undefined) {
                set.children = [];
                this.collapsed['structure-'+set.setid] = true;
            }

            set.children.push(course);
        });
        this.structure = CoreUtils.formatTree(sets, 'parentsetid', 'setid', 0)[0] || undefined;
    }

    /**
     * Close the modal.
     */
    closeModal(): void {
        ModalController.dismiss();
    }

    /**
     * Toggles an expandable part.
     *
     * @param part Part name.
     */
    toggle(part: string): void {
        this.collapsed[part] = !this.collapsed[part];
    }

    /**
     * Dismiss program help info.
     *
     * @param e Event.
     */
    dismiss(e: Event): void {
        e.stopPropagation();
        e.preventDefault();

        this.dismissed = true;

        Workplace.dismissMessage(PROGRAM_HELP_IDENTIFIER);
    }

}

type WorkplaceToolCatalogueSetStructureData = WorkplaceToolCatalogueSetData & {
    children?: (TreeNode<WorkplaceToolCatalogueSetStructureData> | WorkplaceToolCatalogueCourseOnSetData)[];
};
