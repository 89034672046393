// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';
import { CoreSites } from '@services/sites';
import { makeSingleton } from '@singletons';
import { CoreSiteWSPreSets } from '@classes/sites/authenticated-site';
import { CoreWS, CoreWSAjaxPreSets, CoreWSExternalWarning } from '@services/ws';
import { CoreEvents } from '@singletons/events';
import { CoreCourseBasicData, CoreCourseListItem, CoreCourses } from '@features/courses/services/courses';
import { CoreUser, CoreUserSummary } from '@features/user/services/user';
import { CorePrefetchStatusInfo, CoreCourseHelper } from '@features/course/services/course-helper';
import { CoreDomUtils } from '@services/utils/dom';
import { WorkplaceHelper } from './workplace-helper';
import { CoreFilepool } from '@services/filepool';
import { CoreCoursesHelper } from '@features/courses/services/courses-helper';
import { CoreCourseOptionsDelegate } from '@features/course/services/course-options-delegate';
import { CoreFilterHelper } from '@features/filter/services/filter-helper';
import { CoreEnrol } from '@features/enrol/services/enrol';
import { ContextLevel } from '@/core/constants';

/**
 * Service that provides workplace features.
 */
@Injectable({ providedIn: 'root' })
export class WorkplaceService {

    static readonly PROGRAMS_OVERVIEW_ENROL_EVENT = 'programs_overview_enrol';
    static readonly ROOT_CACHE_KEY = 'Workplace:';

    /**
     * Get cache key for get user catalogue value WS call.
     *
     * @returns Cache key.
     */
    protected getUserCatalogueCacheKey(): string {
        return WorkplaceService.ROOT_CACHE_KEY + ':catalogue';
    }

    /**
     * Get cache key for get user catalogue program value WS call.
     *
     * @param programId Program ID.
     * @returns Cache key.
     */
    protected getUserCatalogueProgramCacheKey(programId: number): string {
        const prefix = this.getUserCatalogueCacheKey();

        return `${prefix}:program:${programId}`;
    }

    /**
     * Get cache key for get user catalogue program content value WS call.
     *
     * @param programId Program ID.
     * @returns Cache key.
     */
    protected getUserCatalogueProgramContentCacheKey(programId: number): string {
        const prefix = this.getUserCatalogueProgramCacheKey(programId);

        return `${prefix}:content`;
    }

    /**
     * Get cache key for get user catalogue course value WS call.
     *
     * @param courseId Course ID.
     * @returns Cache key.
     */
    protected getUserCatalogueCourseCacheKey(courseId: number): string {
        const prefix = this.getUserCatalogueCacheKey();

        return `${prefix}:course:${courseId}`;
    }

    /**
     * Get cache key for get user programs value WS call.
     *
     * @returns Cache key.
     */
    protected getUserProgramsCacheKey(): string {
        return WorkplaceService.ROOT_CACHE_KEY + ':programs';
    }

    /**
     * Return the list of programs user is allocated to, with the list of the sets and courses and the user progress in each
     * program/course/set.
     *
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved with the retrieved info.
     */
    async getUserCatalogue(siteId?: string): Promise<WorkplaceToolCatalogueItem[]> {
        const site = await CoreSites.getSite(siteId);

        const preSets: CoreSiteWSPreSets = {
            cacheKey: this.getUserCatalogueCacheKey(),
        };

        const result =
            await site.read<WorkplaceToolCatalogueGetUserCatalogueWSResponse>(
                'tool_catalogue_get_user_catalogue',
                undefined,
                preSets,
            );

        return result.catalogue.listitems || [];
    }

    /**
     * Return the program cover information.
     *
     * @param programId Program ID.
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved with the retrieved info.
     */
    async getUserCatalogueProgram(
        programId: number,
        siteId?: string,
    ): Promise<WorkplaceToolCatalogueGetUserCatalogueProgramWSResponse> {
        const site = await CoreSites.getSite(siteId);

        const preSets: CoreSiteWSPreSets = {
            cacheKey: this.getUserCatalogueProgramCacheKey(programId),
        };

        const params: WorkplaceToolCatalogueGetUserCatalogueProgramWSParams = {
            programid: programId,
        };

        return await site.read<WorkplaceToolCatalogueGetUserCatalogueProgramWSResponse>(
            'tool_catalogue_get_user_catalogue_program',
            params,
            preSets,
        );
    }

    /**
     * Return the program content divided between sets and courses.
     *
     * @param programId Program ID.
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved with the retrieved info.
     */
    async getUserCatalogueProgramContent(
        programId: number,
        siteId?: string,
    ): Promise<WorkplaceToolCatalogueGetUserCatalogueProgramContentWSResponse> {
        const site = await CoreSites.getSite(siteId);

        const preSets: CoreSiteWSPreSets = {
            cacheKey: this.getUserCatalogueProgramContentCacheKey(programId),
        };

        const params: WorkplaceToolCatalogueGetUserCatalogueProgramContentWSParams = {
            programid: programId,
        };

        return await site.read<WorkplaceToolCatalogueGetUserCatalogueProgramContentWSResponse>(
            'tool_catalogue_get_user_catalogue_program_content',
            params,
            preSets,
        );
    }

    /**
     * Check if tool_catalogue_get_user_catalogue_course WS is available.
     *
     * @param siteId Site ID. If not defined, use current site.
     * @returns Whether the webservice is available.
     * @since 4.0
     */
    async isUserCatalogueCourseAvailable(siteId?: string): Promise<boolean> {
        const site = await CoreSites.getSite(siteId);

        return site?.wsAvailable('tool_catalogue_get_user_catalogue_course');
    }

    /**
     * Return the course cover information.
     *
     * @param courseId Course ID.
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved with the retrieved info.
     */
    async getUserCatalogueCourse(
        courseId: number,
        siteId?: string,
    ): Promise<WorkplaceToolCatalogueGetUserCatalogueCourseWSResponse> {
        const site = await CoreSites.getSite(siteId);

        const preSets: CoreSiteWSPreSets = {
            cacheKey: this.getUserCatalogueCourseCacheKey(courseId),
        };

        const params: WorkplaceToolCatalogueGetUserCatalogueCourseWSParams = {
            courseid: courseId,
        };

        return await site.read<WorkplaceToolCatalogueGetUserCatalogueCourseWSResponse>(
            'tool_catalogue_get_user_catalogue_course',
            params,
            preSets,
        );
    }

    /**
     * Return the list of programs user is allocated to, with the list of the sets and courses and the user progress in each
     * program/course/set.
     *
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved with the retrieved info.
     * @deprecatedonmoodle since WP 4.0
     */
    async getUserPrograms(siteId?: string): Promise<WorkplaceToolProgramGetUserProgramsWSResponse> {
        const site = await CoreSites.getSite(siteId);

        const preSets: CoreSiteWSPreSets = {
            cacheKey: this.getUserProgramsCacheKey(),
        };

        return site.read('tool_program_get_user_programs', undefined, preSets);
    }

    /**
     * Invalidates get user programs WS call.
     *
     * @param siteId Site ID to invalidate. If not defined, use current site.
     * @returns Promise resolved when the data is invalidated.
     */
    async invalidateUserPrograms(siteId?: string): Promise<void> {
        const site = await CoreSites.getSite(siteId);

        return site.invalidateWsCacheForKey(this.getUserProgramsCacheKey());
    }

    /**
     * Invalidates get user catalogue WS call.
     *
     * @param siteId Site ID to invalidate. If not defined, use current site.
     * @returns Promise resolved when the data is invalidated.
     */
    async invalidateUserCatalogue(siteId?: string): Promise<void> {
        const site = await CoreSites.getSite(siteId);

        return site.invalidateWsCacheForKey(this.getUserCatalogueCacheKey());
    }

    /**
     * Invalidates get user catalogue program WS call.
     *
     * @param programId Program ID.
     * @param siteId Site ID to invalidate. If not defined, use current site.
     * @returns Promise resolved when the data is invalidated.
     */
    async invalidateUserCatalogueProgram(programId: number, siteId?: string): Promise<void> {
        const site = await CoreSites.getSite(siteId);

        return site.invalidateWsCacheForKey(this.getUserCatalogueProgramCacheKey(programId));
    }

    /**
     * Invalidates get user catalogue program content WS call.
     *
     * @param programId Program ID.
     * @param siteId Site ID to invalidate. If not defined, use current site.
     * @returns Promise resolved when the data is invalidated.
     */
    async invalidateUserCatalogueProgramContent(programId: number, siteId?: string): Promise<void> {
        const site = await CoreSites.getSite(siteId);

        return site.invalidateWsCacheForKey(this.getUserCatalogueProgramContentCacheKey(programId));
    }

    /**
     * Invalidates get user catalogue course WS call.
     *
     * @param courseId Course ID.
     * @param siteId Site ID to invalidate. If not defined, use current site.
     * @returns Promise resolved when the data is invalidated.
     */
    async invalidateUserCatalogueCourse(courseId: number, siteId?: string): Promise<void> {
        const site = await CoreSites.getSite(siteId);

        return site.invalidateWsCacheForKey(this.getUserCatalogueCourseCacheKey(courseId));
    }

    /**
     * Check if user programs WS is available.
     *
     * @param siteId Site ID. If not defined, use current site.
     * @returns Whether the webservice is available.
     * @since 3.6 until 3.11
     */
    async isGetUserProgramsAvailable(siteId?: string): Promise<boolean> {
        const site = await CoreSites.getSite(siteId);

        return site?.wsAvailable('tool_program_get_user_programs');
    }

    /**
     * Check if tool_catalogue_get_user_catalogue WS is available.
     *
     * @param siteId Site ID. If not defined, use current site.
     * @returns Whether the webservice is available.
     * @since 4.0
     */
    async isToolCatalogueAvailable(siteId?: string): Promise<boolean> {
        const site = await CoreSites.getSite(siteId);

        return site?.wsAvailable('tool_catalogue_get_user_catalogue');
    }

    /**
     * Check if my courses tab is enabled on settings.
     *
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved with the setting.
     */
    async isMyCoursesTabEnabled(siteId?: string): Promise<boolean> {
        // 4.0 onwards.
        const mycoursesAvailable = await Workplace.isToolCatalogueAvailable();
        if (mycoursesAvailable) {
            const disabled = await CoreCourses.isMyCoursesDisabled(siteId);

            return !disabled;
        }

        // 3.6 onwards. Remove all code from here when app require 4.0.
        const available = await this.isGetUserProgramsAvailable();
        if (!available) {
            return false;
        }

        const site = await CoreSites.getSite(siteId);

        try {
            // @deprecated since Moodle 4.0 (Setting removed on 4.0).
            const enabled =  await site?.getConfig('theme_workplace_dashboardlearning', true);

            return enabled != '0' && enabled != 'false';
        } catch {
            // Not available.
            return true;
        }
    }

    /**
     * Init production site listeners.
     */
    async initProductionSite(): Promise<void> {
        CoreEvents.on(CoreEvents.LOGIN, async ({ siteId }): Promise<void> => {
            const isProduction = await this.isProductionSite(siteId);

            document.body.classList.toggle('workplace-non-production', !isProduction);
        });
    }

    /**
     * Check if site is a production site
     *
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved with the setting.
     */
    async isProductionSite(siteId?: string): Promise<boolean> {
        const site = await CoreSites.getSite(siteId);

        try {
            const enabled = await site?.getConfig('workplace_productionstate', true);

            return enabled != '0' && enabled != 'false';
        } catch {
            // Not available.
            return true;
        }
    }

    /**
     * Get the tenant selector info.
     *
     * @param siteUrl You are about to access.
     * @returns Promise resolved with the list of tenant. Empty on error or disabled.
     */
    async getTenantsInfo(siteUrl: string): Promise<WorkplaceTenantLoginInfo[]> {
        const preSets: CoreWSAjaxPreSets = {
            siteUrl: siteUrl,
            noLogin: true,
        };

        try {
            const tenantConfig =
                await CoreWS.callAjax<WorkplaceToolTenantGetLoginSelectorTenantsWSResponse>(
                    'tool_tenant_get_login_selector_tenants',
                    {},
                    preSets,
                );

            if (tenantConfig.enabled) {
                return tenantConfig.tenants;
            }
        } catch {
            // Ignore errors.
        }

        return [];

    }

    /**
     * Get the tenant info.
     *
     * @param siteUrl Site Url.
     * @param tenantUrl Tenant Url.
     * @returns Promise resolved with tenant config.
     */
    async getTenantInfo(siteUrl: string, tenantUrl: string): Promise<WorkplaceToolTenantGetTenantLoginInfoWSResponse | undefined> {
        const preSets: CoreWSAjaxPreSets = {
            siteUrl: siteUrl,
            noLogin: true,
        };

        try {
            return await CoreWS.callAjax<WorkplaceToolTenantGetTenantLoginInfoWSResponse>(
                'tool_tenant_get_tenant_login_info',
                { url: tenantUrl },
                preSets,
            );

        } catch {
            // Ignore errors.
        }

    }

    /**
     * Set dismissable message as dismissed.
     *
     * @param identifier Identifier of the message.
     * @param local If dimiss status should be stored locally or remotely.
     * @param siteId Site ID. If not defined, use current site.
     */
    async dismissMessage(identifier: string, local = false, siteId?: string): Promise<void> {
        if (local) {
            const site = await CoreSites.getSite(siteId);

            await site.setLocalSiteConfig(`Workplace${identifier}`, 1);

            return;
        }
        await CoreUser.setUserPreference(identifier, '1', siteId);
    }

    /**
     * Get dismissable message status.
     *
     * @param identifier Identifier of the message.
     * @param local If dimiss status is stored locally or remotely.
     * @param siteId Site ID. If not defined, use current site.
     * @returns True if previously dismissed.
     */
    async getDismissMessageStatus(identifier: string, local = false, siteId?: string): Promise<boolean> {
        if (local) {
            const site = await CoreSites.getSite(siteId);

            const dismissed = await site.getLocalSiteConfig<number>(`Workplace${identifier}`, 0);

            return dismissed === 1;
        }

        const dismissed = await CoreUser.getUserPreference(identifier, siteId);

        return !!dismissed ;
    }

    /**
     * Prefetch program.
     *
     * @param programId Program to prefetch.
     * @param prefetchStatus Prefetch status.
     */
    async prefetchProgram(programId: number, prefetchStatus: CorePrefetchStatusInfo): Promise<void> {
        const initialIcon = prefetchStatus.icon;

        try {
            const programInfo = await WorkplaceHelper.getUserProgram(programId);
            const downloadableCourses = programInfo.programstructure.courses.filter(course =>
                course.isenrolled && !course.islocked && !course.unlockrequirement && !course.ishidden);

            const programCourses = downloadableCourses.map<CoreCourseListItem>((course) => ({
                id: course.courseid,
                fullname: course.fullname,
                shortname: '',
                categoryname: course.categoryname,
                summary: '',
                summaryformat: 0,
                hidden: course.ishidden,
                courseImage: course.image,
                completed: course.iscompleted,
                progress: course.progress,
            }));

            await Promise.all([
                CoreCourseHelper.prefetchCourses(programCourses, prefetchStatus),
                this.prefetchProgramInfo(programId, programInfo),
                this.loadProgramPreviewInfo(programInfo.programstructure.courses),
            ]);
        } catch (error) {
            CoreDomUtils.showErrorModalDefault(error, 'core.course.errordownloadingcourse', true);
            prefetchStatus.icon = initialIcon;
        }
    }

    /**
     * Prefetch program info.
     *
     * @param programId Program to prefetch.
     */
    async prefetchProgramInfo(
        programId: number,
        programInfo: WorkplaceToolCatalogueGetUserCatalogueProgramWSResponse,
    ): Promise<void> {
        const dismissed = await Workplace.getDismissMessageStatus(`tool_catalogue_show_program_content_${programId}`);

        if (!dismissed) {
            await Workplace.getDismissMessageStatus('tool_catalogue_hide_program_cover_help');
        }

        await Promise.all([
            this.getUserCatalogueProgram(programId),
            this.getUserCatalogueProgramContent(programId),
            CoreUser.getUserPreference('tool_catalogue_collapse_recently_accessed_courses'),
            this.prefetchProgramImages(programInfo),
        ]);
    }

    /**
     * Load the preview info of a course.
     *
     * @param courses Program structure courses.
     * @returns Promises to load the preview info.
     */
    async loadProgramPreviewInfo(courses: WorkplaceToolCatalogueCourseOnSetData[]): Promise<unknown[]> {
        return courses.map( async (course) => {
            const mappedCourse = {
                id: course.courseid,
                fullname: course.fullname,
                shortname: '',
                categoryname: course.categoryname,
                summary: '',
                summaryformat: 0,
                hidden: course.ishidden,
                courseImage: course.image,
                completed: course.iscompleted,
                progress: course.progress,
            };

            const data: Promise<unknown>[] = [
                this.getUserCatalogueCourse(course.courseid),
                CoreEnrol.getSupportedCourseEnrolmentMethods(course.courseid),
                CoreCourses.getCourseByField('id', course.courseid),
                CoreCoursesHelper.loadCourseColorAndImage(mappedCourse),
                CoreCourseOptionsDelegate.getMenuHandlersToDisplay(mappedCourse, false, true),
                CoreFilterHelper.getFilters(ContextLevel.COURSE, course.courseid),
            ];

            if (course.isenrolled) {
                data.push(CoreCourses.getUserCourse(course.courseid));
            }

            return Promise.all(data);
        });
    }

    /**
     * Prefetch program images.
     *
     * @param programInfo Program info to prefetch images.
     */
    async prefetchProgramImages(programInfo: WorkplaceToolCatalogueGetUserCatalogueProgramWSResponse): Promise<void> {
        const site = CoreSites.getRequiredCurrentSite();

        if (!site.id) {
            return;
        }

        const setsImages = programInfo.programstructure.sets.map((set) => {
            const promises: Promise<void>[] = [
                CoreFilepool.downloadOrPrefetchFiles(
                    site.getId(),
                    [{ fileurl: set.setcriteriaicon }],
                    true,
                ),
            ];

            if (set.mosaicimages) {
                promises.push(
                    CoreFilepool.downloadOrPrefetchFiles(
                        site.getId(),
                        set.mosaicimages.map(image => ({ fileurl: image })),
                        true,
                    ),
                );
            }

            return Promise.all(promises);
        });

        const coursesImages = programInfo.programstructure.courses.map(
            course => CoreFilepool.downloadOrPrefetchFiles(site.getId(), [{ fileurl: course.image }], true),
        );

        await Promise.all([setsImages, coursesImages]);
    }

    /**
     * Get courses from catalogue item.
     *
     * @param item Catalogue item.
     * @returns Courses.
     */
    async getCoursesFromCatalogueItem(item: WorkplaceToolCatalogueItemToRender): Promise<CoreCourseBasicData[]> {
        if (!item.isprogram) {
            const site = CoreSites.getRequiredCurrentSite();
            const course = await CoreCourseHelper.getCourse(item.itemid, site.id);

            return [course.course];
        }

        const programInfo = await WorkplaceHelper.getUserProgram(item.itemid);
        const courses = programInfo.programstructure.courses.filter((course) =>
            course.isenrolled && !course.islocked && !course.unlockrequirement && !course.ishidden);

        return courses.map((course) => ({
            id: course.courseid,
            fullname: course.fullname,
            shortname: '',
            summary: '',
            summaryformat: 0,
        }));
    }

    /**
     * Prefetch an item (program or course).
     *
     * @param item Item (program or course) to prefetch.
     * @returns Promise resolved when done.
     */
    async prefetchProgramOrCourse(item: WorkplaceToolCatalogueItemToRender, prefetchData: CorePrefetchStatusInfo): Promise<void> {
        // Prefetch course.
        if (!item.isprogram) {
            const initialIcon = prefetchData.icon;

            try {
                const site = CoreSites.getRequiredCurrentSite();
                const course = await CoreCourseHelper.getCourse(item.itemid, site.id);
                await CoreCourseHelper.confirmAndPrefetchCourse(prefetchData, course.course);
            } catch (error) {
                CoreDomUtils.showErrorModalDefault(error, 'core.course.errordownloadingcourse', true);
                prefetchData.icon = initialIcon;
            }

            return;
        }

        // Prefetch program
        await Workplace.prefetchProgram(item.itemid, prefetchData);
    }

}

export const Workplace = makeSingleton(WorkplaceService);

/**
 * Data returned by tool_tenant_get_login_selector_tenants WS.
 */
type WorkplaceToolTenantGetTenantLoginInfoWSResponse = {
    name: string;
    logourl: string;
    cssconfig: {
        name: string;
        value: string;
    }[];
};

/**
 * Data returned by tool_tenant_get_login_selector_tenants WS.
 */
type WorkplaceToolTenantGetLoginSelectorTenantsWSResponse = {
    enabled: false;
    tenants: WorkplaceTenantLoginInfo[];
};

export type WorkplaceTenantLoginInfo = {
    logourl: string;
    name: string;
    url: string;
};

/**
 * Data returned by tool_program_get_user_programs WS.
 */
export type WorkplaceToolProgramGetUserProgramsWSResponse = {
    status: boolean; // True if user programs successfully returned.
    programs: WorkplaceToolProgramProgramData[];
    courses?: WorkplaceCourse[];
    onlyenrolprogramcourseids?: number[]; // Array of course ids.
    warnings?: CoreWSExternalWarning[];
    origins: WorkplaceOrigin[];
};

/**
 * Workplace Program.
 */
export type WorkplaceToolProgramProgramData = {
    tenantid?: number; // Tenantid.
    fullname: string; // Fullname.
    idnumber?: string; // Idnumber.
    shared: number; // Shared.
    description?: string; // Description.
    descriptionformat?: number; // Description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN).
    startdatetype?: number; // Startdatetype.
    startdateabsolute?: number; // Startdateabsolute.
    startdaterelative?: string; // Startdaterelative.
    duedatetype?: number; // Duedatetype.
    duedateabsolute?: number; // Duedateabsolute.
    duedaterelative?: string; // Duedaterelative.
    enddatetype?: number; // Enddatetype.
    enddateabsolute?: number; // Enddateabsolute.
    enddaterelative?: string; // Enddaterelative.
    allocationstartdatetype?: number; // Allocationstartdatetype.
    allocationstartdateabsolute?: number; // Allocationstartdateabsolute.
    allocationenddatetype?: number; // Allocationenddatetype.
    allocationenddateabsolute?: number; // Allocationenddateabsolute.
    allocationenddaterelative?: string; // Allocationenddaterelative.
    autocreategroups?: number; // Autocreategroups.
    visible?: boolean; // Visible.
    archived?: boolean; // Archived.
    timearchived?: number; // Timearchived.
    allowdirectallocation?: boolean; // Allowdirectallocation.
    id: number; // Id.
    timecreated: number; // Timecreated.
    timemodified: number; // Timemodified.
    usermodified: number; // Usermodified.
    completeditems: number; // Completeditems.
    completionatleast: number; // Completionatleast.
    completiontype: string; // Completiontype.
    name: string; // Name.
    iscompleted: boolean; // Iscompleted.
    progress: number; // Progress.
    showprogress: boolean; // Showprogress.
    totalitems: number; // Totalitems.
    unlocked: boolean; // Unlocked.
    allocationstartdate: number; // Allocationstartdate.
    allocationenddate: number; // Allocationenddate.
    calltoaction: string; // Calltoaction.
    ongoingcourseid: number; // Ongoingcourseid.
    image: { // Image.
        filename?: string; // File name.
        filepath?: string; // File path.
        filesize?: number; // File size.
        fileurl?: string; // Downloadable file url.
        timemodified?: number; // Time modified.
        mimetype?: string; // File mime type.
        isexternalfile?: boolean; // Whether is an external file.
        repositorytype?: string; // The repository type for the external files.
    }[];
    descriptionfiles: { // Descriptionfiles.
        filename?: string; // File name.
        filepath?: string; // File path.
        filesize?: number; // File size.
        fileurl?: string; // Downloadable file url.
        timemodified?: number; // Time modified.
        mimetype?: string; // File mime type.
        isexternalfile?: boolean; // Whether is an external file.
        repositorytype?: string; // The repository type for the external files.
    }[];
    certifications: { // Certifications.
        tenantid?: number; // Tenantid.
        program?: number; // Program.
        fullname: string; // Fullname.
        idnumber?: string; // Idnumber.
        description?: string; // Description.
        descriptionformat?: number; // Description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN).
        startdatetype?: number; // Startdatetype.
        startdateabsolute?: number; // Startdateabsolute.
        startdaterelative?: string; // Startdaterelative.
        duedatetype?: number; // Duedatetype.
        duedateabsolute?: number; // Duedateabsolute.
        duedaterelative?: string; // Duedaterelative.
        expirydatetype?: number; // Expirydatetype.
        expirydateabsolute?: number; // Expirydateabsolute.
        expirydaterelative?: string; // Expirydaterelative.
        allocationstartdatetype?: number; // Allocationstartdatetype.
        allocationstartdateabsolute?: number; // Allocationstartdateabsolute.
        allocationenddatetype?: number; // Allocationenddatetype.
        allocationenddateabsolute?: number; // Allocationenddateabsolute.
        autocreategroups?: number; // Autocreategroups.
        archived?: boolean; // Archived.
        timearchived?: number; // Timearchived.
        requirerecertification?: number; // Requirerecertification.
        recertdifferentprogram?: number; // Recertdifferentprogram.
        recertificationprogram?: number; // Recertificationprogram.
        recertstartdaterelative?: string; // Recertstartdaterelative.
        recertgraceperiod?: string; // Recertgraceperiod.
        recertexpirydatetype?: number; // Recertexpirydatetype.
        recertexpirydaterelative?: string; // Recertexpirydaterelative.
        shared: number; // Shared.
        id: number; // Id.
        timecreated: number; // Timecreated.
        timemodified: number; // Timemodified.
        usermodified: number; // Usermodified.
        enddatetype: number; // Enddatetype.
        enddateabsolute: number; // Enddateabsolute.
    }[];
    allocations: { // Allocations.
        programid: number; // Programid.
        userid: number; // Userid.
        certificationid?: number; // Certificationid.
        startdate?: number; // Startdate.
        startdatelocked?: boolean; // Startdatelocked.
        duedate?: number; // Duedate.
        duedatelocked?: boolean; // Duedatelocked.
        enddate?: number; // Enddate.
        enddatelocked?: boolean; // Enddatelocked.
        status?: boolean; // Status.
        timesuspended?: number; // Timesuspended.
        allocationtype?: number; // Allocationtype.
        id: number; // Id.
        timecreated: number; // Timecreated.
        timemodified: number; // Timemodified.
        usermodified: number; // Usermodified.
    }[];
    programcourses: WorkplaceProgramCourse[]; // Programcourses.
    programsets: WorkplaceProgramSet[]; // Programsets.
    lastaccess: number; // Lastaccess.
};

/**
 * Workplace user course.
 */
export type WorkplaceCourse = {
    id: number; // Id.
    uniqueid: string; // Uniqueid.
    fullname: string; // Fullname.
    categoryname: string; // Categoryname.
    image: string; // Image.
    progress: number; // Progress.
    completionenabled: boolean; // Completionenabled.
    iscompleted: boolean; // Iscompleted.
    startdatestr: string; // Startdatestr.
    enddatestr: string; // Enddatestr.
    isprogram: boolean; // Isprogram.
    lastaccess: number; // Lastaccess.
    lowestduedate: number; // Lowestduedate.
};

/**
 * Workplace course in program.
 */
export type WorkplaceProgramCourse = {
    id: number; // Id.
    setid: number; // Setid.
    courseid: number; // Courseid.
    sortorder: number; // Sortorder.
    timecreated: number; // Timecreated.
    timemodified: number; // Timemodified.
    usermodified: number; // Usermodified.
    name: string; // Name.
    programid: number; // Programid.
    parentsetid: number; // Parentsetid.
    url: string; // Url.
    items?: string[]; // Items.
    warning: string; // Warning.
    level: number; // Level.
    iscompleted: boolean; // Iscompleted.
    totalitems: number; // Totalitems.
    weight: number; // Weight.
    completion: number; // Completion.
    completionenabled: boolean; // Completionenabled.
    isenrolled: boolean; // Isenrolled.
    progress: number; // Progress.
    unlocked: boolean; // Unlocked.
    showprogress: boolean; // Showprogress.
    statusmessage: string; // Statusmessage.
    statusmessagestr: string; // Statusmessagestr.
    ishidden: boolean; // Ishidden.
};

/**
 * Workplace Origin.
 */
export type WorkplaceOrigin = {
    programid: number;
    certificationid: number;
    alert: string;
    stringid: string;
    component: string;
    date: number;
    fullname?: string; // Calculated by the app.
};

/**
 * Workplace program set.
 */
export type WorkplaceProgramSet = {
    programid: number; // Programid.
    parent: number; // Parent.
    name: string; // Name.
    sortorder: number; // Sortorder.
    completioncriteria?: number; // Completioncriteria.
    completionatleast?: number; // Completionatleast.
    id: number; // Id.
    timecreated: number; // Timecreated.
    timemodified: number; // Timemodified.
    usermodified: number; // Usermodified.
    editablename: string; // Editablename.
    completeditems: number; // Completeditems.
    completiontype: string; // Completiontype.
    completiontypestr: string; // Completiontypestr.
    iscompleted: boolean; // Iscompleted.
    level: number; // Level.
    parentsetid: number; // Parentsetid.
    progress: number; // Progress.
    showprogress: boolean; // Showprogress.
    totalitems: number; // Totalitems.
    unlocked: boolean; // Unlocked.
    statusmessage: string; // Statusmessage.
    statusmessagestr: string; // Statusmessagestr.
    weight: number; // Weight.
    completion: number; // Completion.
};

/**
 * Data returned by tool_catalogue_get_user_catalogue WS.
 */
export type WorkplaceToolCatalogueGetUserCatalogueWSResponse = {
    catalogue: {
        listitems?: WorkplaceToolCatalogueItem[];
    };
    warnings?: CoreWSExternalWarning[];
};

/**
 * Catalogue item.
 */
export type WorkplaceToolCatalogueItem = {
    itemid: number; // Itemid.
    fullname: string; // Fullname.
    numcourses?: number; // Numcourses.
    image: string; // Image.
    progress: number; // Progress.
    duedate: number; // Duedate.
    duedatebadgetype: string; // Duedatebadgetype.
    duedatebadgestr: string; // Duedatebadgestr.
    lastaccess: number; // Lastaccess.
    url: string; // Url.
    showprogress: boolean; // Showprogress.
    isprogram: boolean; // Isprogram.
    islocked: boolean; // Islocked.
    categoryname?: string; // Categoryname.
    showhiddencoursebadge?: boolean; // Showhiddencoursebadge. @since 4.1
};

/**
 * Params of tool_catalogue_get_user_catalogue WS.
 */
export type WorkplaceToolCatalogueGetUserCatalogueWSParams = {
    userid?: number; // The user id.
    filter?: string; // Filter results by 'all', 'courses', 'programs', 'complete', 'incomplete'.
    sort?: string; // Sort results by 'duedate', 'name', 'lastaccess'.
    search?: string; // Search in item name.
};

/**
 * Params of tool_catalogue_get_user_catalogue_course WS.
 */
export type WorkplaceToolCatalogueGetUserCatalogueCourseWSParams = {
    courseid: number; // The course id.
    userid?: number; // The user id.
};

/**
 * Data returned by tool_catalogue_get_user_catalogue_course WS.
 */
export type WorkplaceToolCatalogueGetUserCatalogueCourseWSResponse = {
    course: WorkplaceToolCatalogueGetUserCatalogueCourseWSResponseMapped;
    warnings?: CoreWSExternalWarning[];
};

export type WorkplaceToolCatalogueGetUserCatalogueCourseWSResponseMapped = {
    course: { // Equivalent to CoreCourseBasicData.
        id: number; // Id.
        fullname: string; // Fullname.
        shortname: string; // Shortname.
        idnumber: string; // Idnumber.
        summary: string; // Summary.
        summaryformat: number; // Summary format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN).
        startdate: number; // Startdate.
        enddate: number; // Enddate.
        visible: boolean; // Visible.
        showactivitydates: boolean; // Showactivitydates.
        showcompletionconditions: boolean; // Showcompletionconditions.
        fullnamedisplay: string; // Fullnamedisplay.
        viewurl: string; // Viewurl.
        courseimage: string; // Courseimage.
        progress?: number; // Progress.
        hasprogress: boolean; // Hasprogress.
        isfavourite: boolean; // Isfavourite.
        hidden: boolean; // Hidden.
        timeaccess?: number; // Timeaccess.
        showshortname: boolean; // Showshortname.
        coursecategory: string; // Coursecategory.
    };
    duedate: number; // Duedate.
    duedatebadgestr: string; // Duedatebadgestr.
    duedatebadgetype: string; // Duedatebadgetype.
    linkedprograms: { // Linkedprograms.
        id: number; // Id
        name: string; // Name.
        url: string; // Url.
    }[];
    trainers: CoreUserSummary[]; // Trainers.
    restrictions: string[]; // Restrictions.
    coursefiles: { // Coursefiles.
        isimage: boolean; // Isimage.
        url: string; // Url.
        name?: string; // Name.
        image?: string; // Image.
    }[];
    customfields?: WorkplaceToolCourseCatalogueCustomField[];
};

/**
 * Course catalog custom field.
 */
export type WorkplaceToolCourseCatalogueCustomField = {
    shortname: string; // Shortname.
    name: string; // Name.
    value: string; // Value.
};

/**
 * Params of tool_catalogue_get_user_catalogue_program WS.
 */
export type WorkplaceToolCatalogueGetUserCatalogueProgramWSParams = {
    programid: number; // The program id.
    userid?: number; // The user id.
};

/**
 * Data returned by tool_catalogue_get_user_catalogue_program WS.
 */
export type WorkplaceToolCatalogueGetUserCatalogueProgramWSResponse = {
    program: WorkplaceToolCatalogueProgramData;
    programstructure: WorkplaceToolCatalogueGetUserCatalogueProgramStructure;
    warnings?: CoreWSExternalWarning[];
};

export type WorkplaceToolCatalogueGetUserCatalogueProgramStructure = {
    sets: WorkplaceToolCatalogueSetData[];
    courses: WorkplaceToolCatalogueCourseOnSetData[];
};

/**
 * Program data.
 */
export type WorkplaceToolCatalogueProgramData = {
    id: number; // Id.
    fullname: string; // Fullname.
    description: string; // Description.
    image: string; // Image.
    programstructure?: {
        baseset: WorkplaceToolCatalogueSetData;
    };
    basesetcriteria: string; // Basesetcriteria.
    progress: number; // Progress.
    certifications?: WorkplaceToolCatalogueCertification[]; // Certifications.
    startdate: number; // Startdate.
    startdateshow: boolean; // Startdateshow.
    startdatestr: string; // Startdatestr.
    duedate: number; // Duedate.
    duedateshow: boolean; // Duedateshow.
    duedatestr: string; // Duedatestr.
    duedatebadgetype: string; // Duedatebadgetype.
    duedatebadgestr: string; // Duedatebadgestr.
    enddate: number; // Enddate.
    enddateshow: boolean; // Enddateshow.
    enddatestr: string; // Enddatestr.
    numcourses: number; // Numcourses.
    lastaccess: number; // Lastaccess.
    customfields?: { // Customfields.
        shortname: string; // Shortname.
        name: string; // Name.
        value: string; // Value.
    }[];
    tags?: string[]; // Tags.
};

/**
 * Program certification data.
 */
export type WorkplaceToolCatalogueCertification = {
    message: string; // Message.
    type: string; // Type.
    hash: string; // Hash.
};

/**
 * Params of tool_catalogue_get_user_catalogue_program_content WS.
 */
export type WorkplaceToolCatalogueGetUserCatalogueProgramContentWSParams = {
    programid: number; // The program id.
    userid?: number; // The user id.
};

/**
 * Data returned by tool_catalogue_get_user_catalogue_program_content WS.
 */
export type WorkplaceToolCatalogueGetUserCatalogueProgramContentWSResponse = {
    sets: WorkplaceToolCatalogueSetData[];
    courses: WorkplaceToolCatalogueCourseOnSetData[];
    warnings?: CoreWSExternalWarning[];
};

/**
 * Set data.
 */
export type WorkplaceToolCatalogueSetData = {
    isset: boolean; // Isset.
    fullname: string; // Fullname.
    setid: number; // Setid.
    parentsetid: number; // Parentsetid.
    sortorder: number; // Sortorder.
    setcriteria: number; // Setcriteria.
    setcriteriastr: string; // Setcriteriastr.
    setcriteriaicon: string; // Setcriteriaicon.
    unlockrequirement?: string; // Unlockrequirement.
    completeditems: number; // Completeditems.
    numcourses: number; // Numcourses.
    iscompleted: boolean; // Iscompleted.
    progress: number; // Progress.
    totalitems: number; // Totalitems.
    islocked: boolean; // Islocked.
    url: string; // Url.
    mosaicimages?: string[]; // Mosaicimages.
};

/**
 * Course on Set data.
 */
export type WorkplaceToolCatalogueCourseOnSetData = {
    isset: boolean; // Isset.
    setid: number; // Setid.
    courseid: number; // Courseid.
    sortorder: number; // Sortorder.
    fullname: string; // Fullname.
    iscompleted: boolean; // Iscompleted.
    progress: number; // Progress.
    isenrolled: boolean; // Isenrolled.
    islocked: boolean; // Islocked.
    unlockrequirement?: string; // Unlockrequirement.
    ishidden: boolean; // Ishidden.
    image: string; // Image.
    url: string; // Url.
    categoryname: string; // Categoryname.
    lastaccess: number; // Lastaccess.
    showhiddencoursebadge?: boolean; // Showhiddencoursebadge. @since 4.1
    showprogress?: boolean; // Showprogress. @since 4.1
};

export type WorkplaceToolCatalogueItemToRender = WorkplaceToolCatalogueItem & {
    dataImage?: boolean;
};
