// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';
import { CoreEnrolAction, CoreEnrolSelfHandler } from '@features/enrol/services/enrol-delegate';
import { makeSingleton } from '@singletons';
import { WorkplaceEnrolProgram } from './program';
import { CoreEnrolEnrolmentMethod } from '@features/enrol/services/enrol';

/**
 * Enrol handler.
 */
@Injectable({ providedIn: 'root' })
export class WorkplaceEnrolProgramHandlerService implements CoreEnrolSelfHandler {

    name = 'WorkplaceEnrolProgram';
    type = 'program';
    enrolmentAction = <CoreEnrolAction.SELF> CoreEnrolAction.SELF;

    /**
     * @inheritdoc
     */
    async isEnabled(): Promise<boolean> {
        return WorkplaceEnrolProgram.isGetProgramEnrolInfoAvailable();
    }

    /**
     * @inheritdoc
     */
    async enrol(method: CoreEnrolEnrolmentMethod): Promise<boolean> {
        try {
            const info = await WorkplaceEnrolProgram.getProgramEnrolmentInfo(method.id);
            if (!info.canselfenrol) {
                return false;
            }

            await WorkplaceEnrolProgram.enrolToCourse(method.courseid, info.programid);
        } catch {
            // Fail silently.
            return false;
        }

        return true;
    }

}

export const WorkplaceEnrolProgramHandler = makeSingleton(WorkplaceEnrolProgramHandlerService);
