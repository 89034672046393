<ion-item-divider [sticky]="true">
    <ion-label>
        <h2 *ngIf="!courses.length">{{ 'workplace.myavailable.pluginname' | translate }}</h2>
        <h2 *ngIf="courses.length">{{ 'workplace.myavailable.blocktitle' | translate:{$a: courses.length} }}</h2>
    </ion-label>
    <div slot="end" class="flex-row">
        <core-horizontal-scroll-controls #scrollControls [aria-controls]="scrollElementId" />
    </div>
</ion-item-divider>
<core-loading [hideUntil]="loaded">
    <core-empty-box *ngIf="!courses.length" image="assets/img/icons/myavailable-no-courses.png"
        [message]="'workplace.myavailable.nocourses' | translate" />
    <!-- List of courses. -->
    <div [hidden]="!courses.length" [id]="scrollElementId" class="core-horizontal-scroll" (scroll)="scrollControls.updateScrollPosition()">
        <div (onResize)="scrollControls.updateScrollPosition()" class="flex-row">
            <div class="safe-area-pseudo-padding-start"></div>
            <workplace-course-summary-card *ngFor="let course of courses" [course]="course" class="workplace-myavailable" />
            <div class="safe-area-pseudo-padding-end"></div>
        </div>
    </div>
</core-loading>
