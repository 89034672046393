<!-- Add buttons to the nav bar. -->
<core-navbar-buttons slot="end" prepend>
    <core-context-menu>
        <core-context-menu-item *ngIf="canNavigate && !selectedMonthIsCurrent() && displayNavButtons" [priority]="900"
            [content]="'addon.calendar.currentmonth' | translate" iconAction="fas-calendar-day" (action)="goToCurrentMonth()" />
    </core-context-menu>
</core-navbar-buttons>

<core-loading [hideUntil]="loaded">
    <div class="core-swipe-slides-container">
        <!-- Period name and arrows to navigate. -->
        <ion-grid class="ion-no-padding addon-calendar-navigation">
            <ion-row class="ion-align-items-center">
                <ion-col class="ion-text-start" *ngIf="canNavigate">
                    <ion-button fill="clear" (click)="loadPrevious()" [ariaLabel]="'addon.calendar.monthprev' | translate">
                        <ion-icon name="fas-chevron-left" slot="icon-only" aria-hidden="true" />
                    </ion-button>
                </ion-col>
                <ion-col class="ion-text-center addon-calendar-period">
                    <h2 id="addon-calendar-monthname">
                        {{ periodName }}
                        <ion-spinner *ngIf="!selectedMonthLoaded()" class="addon-calendar-loading-month" />
                    </h2>
                </ion-col>
                <ion-col class="ion-text-end" *ngIf="canNavigate">
                    <ion-button fill="clear" (click)="loadNext()" [ariaLabel]="'addon.calendar.monthnext' | translate">
                        <ion-icon name="fas-chevron-right" slot="icon-only" aria-hidden="true" />
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>

        <core-swipe-slides [manager]="manager">
            <ng-template let-month="item" let-activeView="active">
                <!-- Calendar view. -->
                <ion-grid class="addon-calendar-months" role="table" aria-describedby="addon-calendar-monthname">
                    <div role="rowgroup">
                        <!-- List of days. -->
                        <ion-row role="row">
                            <ion-col class="ion-text-center addon-calendar-weekday" *ngFor="let day of month.weekDays" role="columnheader">
                                <span class="sr-only">{{ day.fullname | translate }}</span>
                                <span class="ion-hide-md-up" aria-hidden="true">{{ day.shortname | translate }}</span>
                                <span class="ion-hide-md-down" aria-hidden="true">{{ day.fullname | translate }}</span>
                            </ion-col>
                        </ion-row>
                    </div>
                    <div role="rowgroup">
                        <!-- Weeks. -->
                        <ion-row *ngFor="let week of month.weeks" class="addon-calendar-week" role="row">
                            <!-- Empty slots (first week). -->
                            <ion-col *ngFor="let value of week.prepadding" class="dayblank addon-calendar-day" role="cell" />
                            <ion-col *ngFor="let day of week.days" class="addon-calendar-day ion-text-center" [ngClass]='{
                                    "hasevents": day.hasevents,
                                    "today": month.isCurrentMonth && day.istoday,
                                    "weekend": day.isweekend,
                                    "duration_finish": day.haslastdayofevent
                                }' [class.addon-calendar-event-past-day]="month.isPastMonth || day.ispast" role="cell"
                                (ariaButtonClick)="dayClicked(day.mday)" [tabindex]="activeView ? 0 : -1"
                                [attr.aria-current]="month.isCurrentMonth && day.istoday ? 'date' : null">
                                <p class="addon-calendar-day-number">
                                    <span aria-hidden="true">{{ day.mday }}</span>
                                    <span class="sr-only">{{ day.periodName | translate }}</span>
                                </p>

                                <!-- In phone, display some dots to indicate the type of events. -->
                                <p class="ion-hide-md-up addon-calendar-dot-types"><span *ngFor="let type of day.calendareventtypes"
                                        class="calendar_event_type calendar_event_{{type}}"></span></p>

                                <!-- In tablet, display list of events. -->
                                <div class="ion-hide-md-down addon-calendar-day-events" *ngIf="day.filteredEvents">
                                    <ng-container *ngFor="let event of day.filteredEvents | slice:0:4; let index = index">
                                        <div *ngIf="index < 3 || day.filteredEvents.length === 4" class="addon-calendar-event"
                                            [class.addon-calendar-event-past]="event.ispast" (ariaButtonClick)="eventClicked(event, $event)"
                                            [tabindex]="activeView ? 0 : -1">
                                            <span class="calendar_event_type calendar_event_{{event.formattedType}}"></span>
                                            <ion-icon *ngIf="event.offline && !event.deleted" name="fas-clock"
                                                [attr.aria-label]="'core.notsent' | translate" />
                                            <ion-icon *ngIf="event.deleted" name="fas-trash"
                                                [attr.aria-label]="'core.deletedoffline' | translate" />
                                            <span class="addon-calendar-event-time">
                                                {{ event.timestart * 1000 | coreFormatDate: timeFormat }}
                                            </span>
                                            <!-- Add the icon title so accessibility tools read it. -->
                                            <span class="sr-only">
                                                {{ 'addon.calendar.type' + event.formattedType | translate }}
                                                <span class="sr-only" *ngIf="event.iconTitle">
                                                    {{ event.iconTitle }}
                                                </span>
                                            </span>
                                            <span class="addon-calendar-event-name">{{event.name}}</span>
                                        </div>
                                    </ng-container>
                                    <p *ngIf="day.filteredEvents.length > 4" class="addon-calendar-day-more">
                                        <strong>{{ 'core.nummore' | translate:{$a: day.filteredEvents.length - 3} }}</strong>
                                    </p>
                                </div>
                            </ion-col>
                            <!-- Empty slots (last week). -->
                            <ion-col *ngFor="let value of week.postpadding" class="dayblank addon-calendar-day" role="cell" />
                        </ion-row>
                    </div>
                </ion-grid>
            </ng-template>
        </core-swipe-slides>
    </div>

</core-loading>
