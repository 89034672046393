<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button [text]="'core.back' | translate" />
        </ion-buttons>
        <ion-title>
            <h1>{{ 'addon.messageoutput_airnotifier.processorsettingsdesc' | translate }}</h1>
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-refresher slot="fixed" [disabled]="!loaded" (ionRefresh)="refreshDevices($event.target)">
        <ion-refresher-content pullingText="{{ 'core.pulltorefresh' | translate }}" />
    </ion-refresher>
    <core-loading [hideUntil]="loaded">
        <ng-container *ngFor="let platform of platformDevices">
            <ion-item-divider class="ion-text-wrap">
                <ion-label>
                    <h2>{{ platform.platform }}</h2>
                </ion-label>
            </ion-item-divider>
            <ion-card>
                <ion-list>
                    <ion-item class="ion-text-wrap" *ngFor="let device of platform.devices" [class.item-current]="device.current"
                        lines="none">
                        <ion-label>
                            <p class="item-heading" id="device-{{device.id}}">
                                <strong>{{ device.name }} {{ device.model }}</strong> ({{platform.platform}} {{ device.version }})
                            </p>
                            <p *ngIf="device.current"><strong>{{ 'core.currentdevice' | translate }}</strong></p>
                            <p>
                                {{ 'core.lastmodified' | translate }}: {{ device.timemodified * 1000 |
                                coreFormatDate:'strftimedatetimeshort' }}
                            </p>
                        </ion-label>
                        <core-button-with-spinner [loading]="device.updating" slot="end">
                            <ion-toggle [(ngModel)]="device.enable" (ngModelChange)="enableDevice(device, device.enable)"
                                [attr.aria-labelledby]="'device-'+ device.id " />
                        </core-button-with-spinner>
                    </ion-item>
                </ion-list>
            </ion-card>
        </ng-container>
    </core-loading>
</ion-content>
