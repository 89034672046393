// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { CoreConstants, DownloadStatus } from '@/core/constants';
import { WorkplaceCourse } from '@workplace/services/workplace';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { CoreCourse } from '@features/course/services/course';
import { CoreCourseHelper, CorePrefetchStatusInfo } from '@features/course/services/course-helper';
import { CoreCourses } from '@features/courses/services/courses';
import { CoreCoursesHelper, CoreEnrolledCourseDataWithExtraInfoAndOptions } from '@features/courses/services/courses-helper';
import { CoreSites } from '@services/sites';
import { CoreDomUtils } from '@services/utils/dom';
import { CoreEventObserver, CoreEvents } from '@singletons/events';

/**
 * This component is meant to display a course for a list of courses with progress.
 * This component is not used on Workplace 4.0 onwards.
 *
 * Example usage:
 *
 * <workplace-programsoverview-course-progress [course]="course">
 * </workplace-programsoverview-course-progress>
 */
@Component({
    selector: 'workplace-programsoverview-course-progress',
    templateUrl: 'programsoverview-course-progress.html',
    styleUrls: ['../mylearning/mylearning.scss'],
})
export class WorkplaceProgramsOverviewCourseProgressComponent implements OnInit, OnDestroy {

    @Input() course!: WorkplaceCourseToDisplay; // The course to render.
    @Input() showDownload = true; // If true, will show download button. Only works if the options menu is not shown.

    isDownloading = false;
    prefetchCourseData: CorePrefetchStatusInfo = {
        icon: '',
        statusTranslatable: 'core.loading',
        status: DownloadStatus.DOWNLOADABLE_NOT_DOWNLOADED,
        loading: true,
    };

    showSpinner = false;
    downloadCourseEnabled = false;

    protected isDestroyed = false;
    protected courseStatusObserver?: CoreEventObserver;
    protected siteUpdatedObserver?: CoreEventObserver;

    /**
     * @inheritdoc
     */
    async ngOnInit(): Promise<void> {
        this.course.courseImage = this.course.courseImage || this.course.image;

        this.downloadCourseEnabled = !CoreCourses.isDownloadCourseDisabledInSite();

        if (this.downloadCourseEnabled) {
            this.initPrefetchCourse();
        }

        await CoreCoursesHelper.loadCourseColorAndImage(this.course);

        // Refresh the enabled flag if site is updated.
        this.siteUpdatedObserver = CoreEvents.on(CoreEvents.SITE_UPDATED, () => {
            const wasEnabled = this.downloadCourseEnabled;

            this.downloadCourseEnabled = !CoreCourses.isDownloadCourseDisabledInSite();

            if (!wasEnabled && this.downloadCourseEnabled) {
                // Download course is enabled now, initialize it.
                this.initPrefetchCourse();
            }
        }, CoreSites.getCurrentSiteId());
    }

    /**
     * Initialize prefetch course.
     */
    async initPrefetchCourse(): Promise<void> {
        if (typeof this.courseStatusObserver != 'undefined') {
            // Already initialized.
            return;
        }

        // Listen for status change in course.
        this.courseStatusObserver = CoreEvents.on(CoreEvents.COURSE_STATUS_CHANGED, (data) => {
            if (data.courseId == this.course.id) {
                this.updateCourseStatus(data.status);
            }
        }, CoreSites.getCurrentSiteId());

        // Determine course prefetch icon.
        const statusData = await CoreCourseHelper.getCourseStatusIconAndTitle(this.course.id);

        this.prefetchCourseData.status = statusData.status;
        this.prefetchCourseData.icon = statusData.icon;
        this.prefetchCourseData.statusTranslatable = statusData.statusTranslatable;
        this.prefetchCourseData.loading = statusData.loading;

        if (statusData.icon == CoreConstants.ICON_LOADING) {
            // Course is being downloaded. Get the download promise.
            const promise = CoreCourseHelper.getCourseDownloadPromise(this.course.id);
            if (promise) {
                // There is a download promise. If it fails, show an error.
                promise.catch((error) => {
                    if (!this.isDestroyed) {
                        CoreDomUtils.showErrorModalDefault(error, 'core.course.errordownloadingcourse', true);
                    }
                });
            } else {
                // No download, this probably means that the app was closed while downloading. Set previous status.
                CoreCourse.setCoursePreviousStatus(this.course.id);
            }
        }

    }

    /**
     * Open a course.
     *
     * @param course The course to open.
     */
    openCourse(course: {id: number}): void {
        CoreCourseHelper.openCourse(course);
    }

    /**
     * Prefetch the course.
     *
     * @param {Event} e Click event.
     */
    prefetchCourse(e: Event): void {
        e.preventDefault();
        e.stopPropagation();

        CoreCourseHelper.confirmAndPrefetchCourse(this.prefetchCourseData, this.course).catch((error) => {
            if (!this.isDestroyed) {
                CoreDomUtils.showErrorModalDefault(error, 'core.course.errordownloadingcourse', true);
            }
        });
    }

    /**
     * Update the course status icon and title.
     *
     * @param status Status to show.
     */
    protected updateCourseStatus(status: DownloadStatus): void {
        const statusData = CoreCourseHelper.getCoursePrefetchStatusInfo(status);

        this.prefetchCourseData.status = statusData.status;
        this.prefetchCourseData.icon = statusData.icon;
        this.prefetchCourseData.statusTranslatable = statusData.statusTranslatable;
        this.prefetchCourseData.loading = statusData.loading;
    }

    /**
     * @inheritdoc
     */
    ngOnDestroy(): void {
        this.isDestroyed = true;

        this.siteUpdatedObserver?.off();
        this.courseStatusObserver?.off();
    }

}

type WorkplaceCourseToDisplay = CoreEnrolledCourseDataWithExtraInfoAndOptions & WorkplaceCourse;
