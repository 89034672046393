<ion-header>
    <ion-toolbar>
        <ion-title>
            <h1>{{ 'core.dataprivacy.createnewdatarequest' | translate }}</h1>
        </ion-title>
        <ion-buttons slot="end">
            <ion-button fill="clear" (click)="close()" [ariaLabel]="'core.close' | translate">
                <ion-icon slot="icon-only" name="fas-xmark" aria-hidden="true" />
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <form [formGroup]="form" name="newRequest" (ngSubmit)="send($event)">
        <ion-item class="ion-text-wrap">
            <ion-label>
                <p>
                    {{ 'core.dataprivacy.requesttype_help' | translate }}
                </p>
                <p class="item-heading" [core-mark-required]="true">
                    {{ 'core.dataprivacy.requesttype' | translate }}
                </p>
            </ion-label>
        </ion-item>
        <ion-item class="ion-text-wrap">
            <ion-radio-group name="type" formControlName="type">
                <ion-radio [value]="1" *ngIf="accessInfo?.cancreatedatadownloadrequest">
                    {{ 'core.dataprivacy.requesttypeexport' | translate }}
                </ion-radio>
                <ion-radio [value]="2" *ngIf="accessInfo?.cancreatedatadeletionrequest">
                    {{ 'core.dataprivacy.requesttypedelete' | translate }}
                </ion-radio>
            </ion-radio-group>
        </ion-item>
        <ion-item>
            <ion-textarea labelPlacement="stacked" placeholder="{{ 'core.dataprivacy.requestcomments' | translate }}" rows="5"
                [(ngModel)]="message" name="text" formControlName="message">
                <div slot="label">
                    {{ 'core.dataprivacy.requestcomments' | translate }}
                </div>
            </ion-textarea>
        </ion-item>

    </form>
</ion-content>
<ion-footer slot="fixed" class="ion-padding">
    <ion-button expand="block" (click)="send($event)" [disabled]="!form.valid">
        {{ 'core.dataprivacy.send' | translate }}
    </ion-button>
</ion-footer>
