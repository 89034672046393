// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Component, OnInit, OnDestroy } from '@angular/core';

import {
    Workplace,
    WorkplaceService,
    WorkplaceToolCatalogueItem,
    WorkplaceToolCatalogueItemToRender,
} from '@workplace/services/workplace';
import { CoreBlockBaseComponent } from '@features/block/classes/base-block-component';
import { CoreEventObserver, CoreEvents } from '@singletons/events';
import { CoreUtils } from '@services/utils/utils';
import { CoreNavigator } from '@services/navigator';
import { CoreCourseHelper, CorePrefetchStatusInfo } from '@features/course/services/course-helper';
import { CoreCourses } from '@features/courses/services/courses';
import { CoreUser } from '@features/user/services/user';
import { CoreSites } from '@services/sites';
import { CoreNetwork } from '@services/network';

/**
 * Component to render a workplace my courses block.
 */
@Component({
    selector: 'block-mycourses',
    templateUrl: 'block-mycourses.html',
    styleUrls: ['../../program.scss', './block-mycourses.scss'],
})
export class WorkplaceBlockMyCoursesComponent extends CoreBlockBaseComponent implements OnInit, OnDestroy {

    filteredItems: WorkplaceToolCatalogueItemToRender[] = [];
    prefetchIcons: Record<number, CorePrefetchStatusInfo & { isprogram: boolean }> = {};
    prefetchIconsInitialized = false;

    controls: {
        filter: WorkplaceMyCoursesFilterValues;
        sort: WorkplaceMyCoursesSortValues;
    } = {
        filter: WorkplaceMyCoursesFilterValues.ALL,
        sort: WorkplaceMyCoursesSortValues.LASTACCESS,
    };

    readonly FILTERS = [
        { value: WorkplaceMyCoursesFilterValues.ALL, label: 'workplace.all' },
        { value: WorkplaceMyCoursesFilterValues.COURSES, label: 'workplace.courses' },
        { value: WorkplaceMyCoursesFilterValues.PROGRAMS, label: 'workplace.programs', class: 'core-select-option-border-bottom' },
        { value: WorkplaceMyCoursesFilterValues.INCOMPLETE, label: 'workplace.incomplete' },
        { value: WorkplaceMyCoursesFilterValues.COMPLETE, label: 'workplace.complete' },
    ];

    readonly SORTINGS = [
        { value: WorkplaceMyCoursesSortValues.NAME, label: 'workplace.sortbyname' },
        { value: WorkplaceMyCoursesSortValues.DUEDATE, label: 'workplace.sortbyduedate' },
        { value: WorkplaceMyCoursesSortValues.LASTACCESS, label: 'workplace.sortbylastaccess' },
    ];

    hasContent = false;
    downloadCourseEnabled = false;
    searchEnabled = false;

    protected catalogueItems: WorkplaceToolCatalogueItemToRender[] = [];
    protected courseStatusObserver?: CoreEventObserver;
    protected isDestroyed = false;
    protected completionObserver?: CoreEventObserver;
    protected enrolObserver?: CoreEventObserver;
    protected updateSiteObserver?: CoreEventObserver;

    constructor() {
        super('WorkplaceBlockMyCoursesComponent');
    }

    /**
     * @inheritdoc
     */
    async ngOnInit(): Promise<void> {
        this.completionObserver = CoreEvents.onMultiple([
            CoreEvents.MANUAL_COMPLETION_CHANGED,
            CoreEvents.COMPLETION_MODULE_VIEWED,
        ], async () => {
            if (CoreNetwork.isOnline()) {
                await this.refreshContent(false);

                return;
            }

            await this.invalidateContent();
        });

        this.enrolObserver = CoreEvents.on(WorkplaceService.PROGRAMS_OVERVIEW_ENROL_EVENT, () => {
            this.refreshContent(false);
        });

        // Refresh the enabled flags if enabled.
        const site = CoreSites.getRequiredCurrentSite();
        this.downloadCourseEnabled = !CoreCourses.isDownloadCourseDisabledInSite() && site.isVersionGreaterEqualThan('4.0');
        this.searchEnabled = !CoreCourses.isSearchCoursesDisabledInSite();

        // Refresh the enabled flags if site is updated.
        this.updateSiteObserver = CoreEvents.on(CoreEvents.SITE_UPDATED, () => {
            const site = CoreSites.getRequiredCurrentSite();
            this.downloadCourseEnabled = !CoreCourses.isDownloadCourseDisabledInSite() && site.isVersionGreaterEqualThan('4.0');

            this.searchEnabled = !CoreCourses.isSearchCoursesDisabledInSite();
        }, CoreSites.getCurrentSiteId());

        // Listen for changes in course status.
        this.courseStatusObserver = CoreEvents.on(
            CoreEvents.COURSE_STATUS_CHANGED,
            async () => await this.loadPrefetchIcons(),
            CoreSites.getCurrentSiteId(),
        );

        super.ngOnInit();
    }

    /**
     * Perform the invalidate content function.
     *
     * @returns Resolved when done.
     */
    async invalidateContent(): Promise<void> {
        const promises: Promise<void>[] = [];

        promises.push(Workplace.invalidateUserCatalogue());
        promises.push(CoreUser.invalidateUserPreference('tool_catalogue_my_courses_filter'));
        promises.push(CoreUser.invalidateUserPreference('tool_catalogue_my_courses_sort'));

        await CoreUtils.allPromises(promises);
    }

    /**
     * Fetch the info for programs overview.
     *
     * @returns Promise resolved when done.
     */
    protected async fetchContent(): Promise<void> {
        await this.loadPreferences();

        this.catalogueItems = await Workplace.getUserCatalogue();

        this.catalogueItems.forEach((item) => {
            item.dataImage = item.image?.startsWith('data');
        });
        this.hasContent = this.catalogueItems.length > 0;

        this.filterAndSort();

        if (!this.downloadCourseEnabled) {
            return;
        }

        await this.loadPrefetchIcons();
    }

    /**
     * Filter changed, save and apply.
     *
     * @param filter New value.
     */
    filterChanged(filter: WorkplaceMyCoursesFilterValues): void {
        this.controls.filter = filter;
        CoreUser.setUserPreference('tool_catalogue_my_courses_filter', filter);

        this.filterAndSort();
    }

    /**
     * Sort changed, save and apply.
     *
     * @param sort New value.
     */
    sortChanged(sort: WorkplaceMyCoursesSortValues): void {
        this.controls.sort = sort;
        CoreUser.setUserPreference('tool_catalogue_my_courses_sort', sort);

        this.filterAndSort();
    }

    /**
     * Filters and sort catalogue items.
     */
    protected filterAndSort(): void {
        // Filter.
        switch (this.controls.filter) {
            case WorkplaceMyCoursesFilterValues.COURSES:
                this.filteredItems = this.catalogueItems.filter((item) => !item.isprogram);
                break;
            case WorkplaceMyCoursesFilterValues.PROGRAMS:
                this.filteredItems = this.catalogueItems.filter((item) => item.isprogram);
                break;
            case WorkplaceMyCoursesFilterValues.COMPLETE:
                this.filteredItems = this.catalogueItems.filter((item) => item.progress === 100);
                break;
            case WorkplaceMyCoursesFilterValues.INCOMPLETE:
                this.filteredItems = this.catalogueItems.filter((item) => item.progress !== 100);
                break;
            case WorkplaceMyCoursesFilterValues.ALL:
            default:
                this.filteredItems = this.catalogueItems;
                break;
        }

        // Sort.
        switch (this.controls.sort) {
            case WorkplaceMyCoursesSortValues.DUEDATE:
                this.filteredItems = this.filteredItems.sort((a, b) => (a.duedate || 0) > (b.duedate || 0) ? 1 : -1);
                break;
            case WorkplaceMyCoursesSortValues.LASTACCESS:
                // Last Access DESC sorting.
                this.filteredItems = this.filteredItems.sort((a, b) => (b.lastaccess || 0) > (a.lastaccess || 0) ? 1 : -1);
                break;
            case WorkplaceMyCoursesSortValues.NAME:
            default:
                this.filteredItems = this.filteredItems.sort((a, b) => {
                    const compareA = a.fullname.toLowerCase();
                    const compareB = b.fullname.toLowerCase();

                    return compareA.localeCompare(compareB);
                });
                break;
        }
    }

    /**
     * Load filter and sorting preferences.
     */
    protected async loadPreferences(): Promise<void> {
        const filter = await CoreUser.getUserPreference('tool_catalogue_my_courses_filter');
        this.controls.filter = <WorkplaceMyCoursesFilterValues> filter || this.controls.filter;

        let sort = await CoreUser.getUserPreference('tool_catalogue_my_courses_sort');
        if (!sort) {
            // Default site sort.
            sort = await CoreUtils.ignoreErrors(
                CoreSites.getRequiredCurrentSite().getConfig('wp_tool_catalogue_mycourses_defaultsortorder'),
                null,
            );
        }

        this.controls.sort = <WorkplaceMyCoursesSortValues> sort || this.controls.sort;
    }

    /**
     * Open the program or course contents.
     *
     * @param item The program to open the content.
     */
    async open(item: WorkplaceToolCatalogueItem): Promise<void> {
        if (item.isprogram) {
            CoreNavigator.navigateToSitePath('program/' + item.itemid);
        } else {
            try {
                const userCourse = await CoreCourses.getUserCourse(item.itemid);
                CoreCourseHelper.openCourse(userCourse);
            } catch {
                // Ignore fails. Use provided info.
                CoreCourseHelper.openCourse({ id: item.itemid, displayname: item.fullname });
            }
        }
    }

    /**
     * Go to search courses.
     */
    async openSearch(): Promise<void> {
        CoreNavigator.navigateToSitePath('courses/list', { params : { mode: 'search' } });
    }

    /**
     * Prefetch an item (program or course).
     *
     * @param item Item (program or course) to prefetch.
     * @returns Promise resolved when done.
     */
    async prefetchProgramOrCourse(item: WorkplaceToolCatalogueItemToRender): Promise<void> {
        await Workplace.prefetchProgramOrCourse(item, this.prefetchIcons[this.getPrefetchIconKey(item)]);
    }

    /**
     * Prefetch icons for programs and courses.
     */
    protected async loadPrefetchIcons(): Promise<void> {
        if (!this.downloadCourseEnabled) {
            return;
        }

        await Promise.all(
            this.filteredItems.map(async (item) => {
                if (item.islocked) {
                    return;
                }

                const courses = await Workplace.getCoursesFromCatalogueItem(item);

                if (courses.length <= 0) {
                    return;
                }

                const prefetchedIcon = await CoreCourseHelper.initPrefetchCoursesIcons(
                    courses,
                    this.prefetchIcons[this.getPrefetchIconKey(item)] || {},
                );

                this.prefetchIcons[this.getPrefetchIconKey(item)] = { ...prefetchedIcon, isprogram: item.isprogram };
            }),
        );
    }

    getPrefetchIconKey(item: WorkplaceToolCatalogueItemToRender): string | number {
        return item.isprogram ? ('program-' + item.itemid) : item.itemid;
    }

    /**
     * @inheritdoc
     */
    ngOnDestroy(): void {
        this.isDestroyed = true;
        this.completionObserver?.off();
        this.enrolObserver?.off();
        this.courseStatusObserver?.off();
        this.updateSiteObserver?.off();
    }

}

export enum WorkplaceMyCoursesFilterValues {
    ALL = 'all',
    COURSES = 'courses',
    PROGRAMS = 'programs',
    INCOMPLETE = 'incomplete',
    COMPLETE = 'complete',
}

export enum WorkplaceMyCoursesSortValues {
    NAME = 'name',
    DUEDATE = 'duedate',
    LASTACCESS = 'lastaccess',
}
