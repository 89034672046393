// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { APP_INITIALIZER, NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Routes } from '@angular/router';
import { CoreMainMenuHomeRoutingModule } from '@features/mainmenu/mainmenu-home-routing.module';
import { WorkplaceCustompagePage } from './pages/custompage/custompage';
import { CoreSharedModule } from '@/core/shared.module';
import { CoreBlockComponentsModule } from '@features/block/components/components.module';
import { WorkplaceCustompage } from './services/custompage';
import { WorkplaceCustomPageHomeHandlerService } from './services/handlers/custompage-home';

const mainMenuHomeChildrenRoutes: Routes = [
    {
        path: WorkplaceCustomPageHomeHandlerService.PAGE_NAME + '/:id',
        component: WorkplaceCustompagePage,
    },
];

@NgModule({
    imports: [
        CoreMainMenuHomeRoutingModule.forChild({
            children: mainMenuHomeChildrenRoutes,
        }),
        IonicModule,
        TranslateModule.forChild(),
        CoreSharedModule,
        CoreBlockComponentsModule,
    ],
    exports: [CoreMainMenuHomeRoutingModule],
    declarations: [
        WorkplaceCustompagePage,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            multi: true,
            useValue: () => {
                WorkplaceCustompage.initialize();
            },
        },
    ],
})
export class WorkplaceCustompageModule {}
