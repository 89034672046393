// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';
import { CoreUserProfile } from '@features/user/services/user';
import {
    CoreUserProfileHandler,
    CoreUserProfileHandlerData,
    CoreUserProfileHandlerType,
} from '@features/user/services/user-delegate';
import { CoreNavigator } from '@services/navigator';
import { makeSingleton } from '@singletons';
import { WorkplaceTeams } from '../teams';

/**
 * Profile workplace handler.
 */
@Injectable({ providedIn: 'root' })
export class WorkplaceLearningUserHandlerService implements CoreUserProfileHandler {

    name = 'Workplace';
    priority = 500;
    type = CoreUserProfileHandlerType.LIST_ITEM;

    /**
     * @inheritdoc
     */
    async isEnabled(): Promise<boolean> {
        const available = await WorkplaceTeams.isGetTeamOverviewAvailable();
        if (available) {
            return true;
        }

        return WorkplaceTeams.isGetLearningStatusAvailable();
    }

    /**
     * @inheritdoc
     */
    async isEnabledForUser(user: CoreUserProfile): Promise<boolean> {
        try {
            const sections = await WorkplaceTeams.getUserLearningInfo(user.id);

            return sections.length > 0;
        } catch {
            return false;
        }
    }

    /**
     * @inheritdoc
     */
    getDisplayData(): CoreUserProfileHandlerData {
        return {
            icon: 'wp-course',
            title: 'workplace.teams.learning',
            class: 'workplace-learning-status',
            action: (event, user): void => {
                event.preventDefault();
                event.stopPropagation();
                CoreNavigator.navigateToSitePath('/learningstatus', {
                    params: { userId: user.id },
                });
            },
        };
    }

}
export const WorkplaceLearningUserHandler = makeSingleton(WorkplaceLearningUserHandlerService);
