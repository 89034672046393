// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';

import { CoreConstants } from '@/core/constants';
import { CoreObject } from '@singletons/object';
import { CoreUtils } from '@services/utils/utils';
import { CoreWS } from '@/core/services/ws';
import { makeSingleton } from '@singletons';

import { FreemiumUnauthenticatedSite } from '@freemium/overrides/core/classes/sites/unauthenticated-site';
import { SiteSubscription, SiteSubscriptionJson } from '@freemium/classes/site-subscription';
import { SubscriptionResponse } from '@freemium/utils/subscriptions';
import { CorePlatform } from '@services/platform';

/**
 * Service that interacts with the Apps Services external functions.
 */
@Injectable({ providedIn: 'root' })
export class SiteSubscriptionsService {

    /**
     * Fetch subscription info for a site.
     *
     * @param site Site.
     * @returns Promise that resolves with the subscription info.
     */
    async fetchSubscription(site: FreemiumUnauthenticatedSite): Promise<SiteSubscriptionFetch | null> {
        if (CorePlatform.isAutomated()) {
            return null;
        }

        try {
            const response = await CoreWS.callAjax<SubscriptionResponse>(
                'local_apps_get_subscription',
                { siteurl: site.siteUrl, appid: CoreConstants.CONFIG.app_id },
                { siteUrl: CoreConstants.CONFIG.appServicesUrl, noLogin: true },
            );
            const subscription = this.parseSubscriptionResponse(response);

            if (!subscription) {
                return null;
            }

            return { freshSubscription: subscription };
        } catch {
            return null;
        }
    }

    /**
     * Parse a response made on the App Services endpoint.
     *
     * @param response Response.
     * @param response.subscription Response subscription.
     * @returns Subscription info.
     */
    protected parseSubscriptionResponse({ subscription }: SubscriptionResponse): SiteSubscription | null {
        if (!subscription) {
            return null;
        }

        const json: SiteSubscriptionJson = CoreObject.withoutUndefined({
            plan: subscription.plan,
            createdTimestamp: subscription.timecreated * CoreConstants.MILLISECONDS_SECOND,
            updatedTimestamp: subscription.timemodified * CoreConstants.MILLISECONDS_SECOND,
            validUntilTimestamp: subscription.expiretime ? subscription.expiretime * CoreConstants.MILLISECONDS_SECOND : undefined,
            name: subscription.name,
            description: subscription.description,
            informationUrl: subscription.informationurl,
            limits: subscription.limits ? CoreUtils.objectToKeyValueMap(subscription.limits, 'name', 'value') : undefined,
            brandingColors: subscription.brandingcolors ?
                CoreUtils.objectToKeyValueMap(subscription.brandingcolors, 'name', 'value') : undefined,
            customCSS: subscription.brandingcss,
            restrictAccess: subscription.restrictaccess,
            biometricLogin: subscription.biometriclogin,
            matomo: subscription.matomo
                ? {
                    enabled: subscription.matomo.enabled,
                    siteId: subscription.matomo.siteid,
                    trackingUrl: subscription.matomo.trackingurl,
                    staticParams: CoreUtils.objectToKeyValueMap<string>(subscription.matomo.staticparams, 'name', 'value'),
                } : undefined,
        });

        // Parse app config.
        if (subscription.appconfig) {
            json.appConfig = JSON.parse(subscription.appconfig);
        }

        // Convert platforms to store config.
        if (subscription.platforms) {
            json.storeConfig = {};

            for (const platform in subscription.platforms) {
                const data = subscription.platforms[platform];

                json.storeConfig[platform] = data.downloadurl || data.appstoreid;
            }
        }

        // Convert features from number to boolean.
        if (subscription.features) {
            json.features = CoreUtils.objectToKeyValueMap(subscription.features, 'name', 'value');

            for (const name in json.features) {
                json.features[name] = !!json.features[name];
            }
        }

        return SiteSubscription.fromJSON(json);
    }

}

/**
 * This type is obtuse on purpose to avoid getting fresh subscriptions unintentionally. Most of the time,
 * subscriptions should be accessed through the `FreemiumSite.getSubscription` method respecting the cache.
 */
export type SiteSubscriptionFetch = {
    freshSubscription: SiteSubscription;
};

export const SiteSubscriptions = makeSingleton(SiteSubscriptionsService);
