// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { CoreMainMenuHomeHandler, CoreMainMenuHomeHandlerToDisplay } from '@features/mainmenu/services/home-delegate';
import { CoreSites } from '@services/sites';

/**
 * Handler to add custom pages into home page.
 */
export class WorkplaceCustomPageHomeHandlerService implements CoreMainMenuHomeHandler {

    static readonly PAGE_NAME = 'custompage';

    name = 'WorkplaceCustomPage';
    priority: number;

    constructor(
        protected id: number,
        protected label: string,
        protected title: string,
        weight: number,
    ) {
        this.name = this.name + '_' + id;
        this.priority = 1000 - weight;
    }

    /**
     * @inheritdoc
     */
    async isEnabled(): Promise<boolean> {
        // Feature name contains an id so we need to check the generic feature.
        const site = CoreSites.getCurrentSite();

        if (!site) {
            return false;
        }

        return !site.isFeatureDisabled('CoreMainMenuHomeDelegate_WorkplaceCustomPage');
    }

    /**
     * @inheritdoc
     */
    getDisplayData(): CoreMainMenuHomeHandlerToDisplay {
        return {
            title: this.label || this.title,
            class: 'workplace-custompage-home-handler',
            page: WorkplaceCustomPageHomeHandlerService.PAGE_NAME + '/' + this.id,
            pageParams: {
                title: this.title,
            },
        };
    }

}
