// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { CoreConstants } from '@/core/constants';

import { EnvironmentConfig } from '@/types/config';

const GRACE_PERIOD = CoreConstants.MILLISECONDS_DAY;

/**
 * Subscription info.
 */
export class Subscription {

    /**
     * App configuration overrides.
     */
    appConfig?: Partial<EnvironmentConfig>;

    /**
     * Expiration date of the subscription.
     */
    validUntil?: Date;

    constructor(validUntil?: Date) {
        this.validUntil = validUntil;
    }

    /**
     * Check if a subscription is active.
     *
     * Subscriptions can remain active after expiring if they are within the grace period.
     *
     * @returns Boolean indicating if the subscription is active.
     */
    isActive(): boolean {
        if (!this.hasValidUntil()) {
            return true;
        }

        const expirationDate = this.validUntil.getTime();

        return expirationDate + GRACE_PERIOD > Date.now();
    }

    /**
     * Check if a subscription has an expiration date.
     *
     * @returns Boolean indicating if the subscription has an expiration date.
     */
    hasValidUntil(): this is { validUntil: Date } {
        return typeof this.validUntil != 'undefined' && this.validUntil !== null;
    }

    /**
     * Check if a subscription has expired.
     *
     * Expired subscriptions can remain active within the grace period.
     *
     * @returns boolean indicating if the subscription has expired.
     */
    hasExpired(): boolean {
        if (!this.hasValidUntil()) {
            return false;
        }

        const expirationDate = this.validUntil.getTime();

        return expirationDate < Date.now();
    }

    /**
     * Get the timestamp in milliseconds when the plan expires. Null if the plan doesn't expire.
     *
     * @returns The timestamp.
     */
    getValidUntilTime(): number | null {
        return this.hasValidUntil() ? this.validUntil.getTime() : null;
    }

}
