<ion-header [collapsible]="(tabsComponent?.selectedIndex === 0 || tabsComponent?.selectedIndex === undefined) && !fullScreenEnabled">
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button [text]="'core.back' | translate" />
        </ion-buttons>
        <ion-title>
            <h1>
                <core-format-text [text]="title" contextLevel="course" [contextInstanceId]="course?.id" />
            </h1>
        </ion-title>

        <ion-buttons slot="end">
            <ion-button fill="clear" (click)="openCourseSummary()" [ariaLabel]="'core.course.coursesummary' | translate">
                <ion-icon name="fas-circle-info" slot="icon-only" aria-hidden="true" />
            </ion-button>
            <!-- Add an empty context menu so tab pages can add items, otherwise the menu disappears in some cases. -->
            <core-context-menu />
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<div class="core-course-header">
    <ion-item class="core-format-progress-list ion-text-wrap list-item-limited-width" collapsible>
        <ng-container *ngIf="course">
            <div *ngIf="!course.courseimage" #courseThumb slot="start" class="core-course-thumb">
                <ion-icon name="fas-graduation-cap" class="course-icon" aria-hidden="true" />
            </div>
            <ion-avatar *ngIf="course.courseimage" slot="start" class="core-course-thumb">
                <img [url]="course.courseimage" core-external-content alt="" />
            </ion-avatar>
        </ng-container>

        <ion-label>
            <h1>
                <core-format-text [text]="title" contextLevel="course" [contextInstanceId]="course?.id" />
            </h1>
            <div class="core-course-progress" *ngIf="progress !== undefined">
                <core-progress-bar [progress]="progress" a11yText="core.course.aria:sectionprogress" />
            </div>
        </ion-label>
    </ion-item>
</div>
<core-tabs-outlet [tabs]="tabs" [hideUntil]="loaded" (ionChange)="tabSelected()" />
