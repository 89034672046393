// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { FreemiumBiometricLogin, FreemiumBiometricMethodAvailable } from '@freemium/services/biometric-login';
import { CoreDomUtils, ToastDuration } from '@services/utils/dom';
import { ModalController, Translate } from '@singletons';
import { CoreEventObserver, CoreEvents } from '@singletons/events';

interface FreemiumBiometricLoginModalButton {
    title: string;
    button: string;
    icon: string;
    type: string;
}

@Component({
    templateUrl: 'modal.html',
    styleUrls: ['modal.scss'],
    selector: 'freemium-biometric-login-modal',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreemiumBiometricLoginModalComponent implements OnDestroy {

    /**
     * Device biometric method available (finger or faceid).
     */
    @Input() biometricMethod: FreemiumBiometricMethodAvailable = 'finger';

    readonly LOGIN_METHODS: Record<FreemiumBiometricMethodAvailable, FreemiumBiometricLoginModalButton> = {
        face: {
            title: 'freemium.biometricloginenablefaceid',
            button: 'freemium.biometricloginenablefaceid',
            icon: 'moodle-face-id',
            type: 'freemium.biometriclogintypefaceid',
        },
        finger: {
            title: 'freemium.biometricloginenabletouchid',
            button: 'freemium.biometricloginenabletouchid',
            icon: 'finger-print',
            type: 'freemium.biometriclogintypetouchid',
        },
        biometric: {
            title: 'freemium.biometricloginenablefingerprint',
            button: 'freemium.biometricloginenablefingerprint',
            icon: 'finger-print',
            type: 'freemium.biometriclogintypefingerprint',
        },
    };

    sessionExpiredObserver: CoreEventObserver;

    constructor() {
        this.sessionExpiredObserver = CoreEvents.on(CoreEvents.SESSION_EXPIRED, async () => {
            await ModalController.dismiss();
        });
    }

    /**
     * Enable biometric login and close modal.
     */
    async enableBiometricLogin(): Promise<void> {
        await FreemiumBiometricLogin.setUserEnabledBiometricLogin(true);
        await ModalController.dismiss();
        const biometricMethod = Translate.instant(this.LOGIN_METHODS[this.biometricMethod].type);
        const toastText = Translate.instant('freemium.biometricloginsuccessfullyenabled', { biometricMethod });
        await CoreDomUtils.showToast(toastText, false, ToastDuration.LONG);
    }

    /**
     * Increment attemps retries and close modal.
     */
    async skip(): Promise<void> {
        await ModalController.dismiss(true);
    }

    /**
     * @inheritdoc
     */
    ngOnDestroy(): void {
        this.sessionExpiredObserver.off();
    }

}
