<span *ngIf="inputMode && form" [formGroup]="form">
    <span *ngIf="editMode" [core-mark-required]="field.required" class="core-mark-required"></span>
    <ion-select [formControlName]="'f_'+field.id" multiple="true" [placeholder]="'addon.mod_data.menuchoose' | translate"
        [cancelText]="'core.cancel' | translate" [okText]="'core.ok' | translate" [interfaceOptions]="{header: field.name}"
        interface="alert">
        <ion-select-option *ngFor="let option of options" [value]="option.value">
            <core-format-text [text]="option.key" contextLevel="module" [contextInstanceId]="database?.coursemodule"
                [courseId]="database?.course" [wsNotFiltered]="true" />
        </ion-select-option>
    </ion-select>
    <core-input-errors *ngIf="error && editMode" [control]="form.controls['f_'+field.id]" [errorText]="error" />

    <ion-item *ngIf="searchMode" class="ion-text-wrap">
        <ion-checkbox [formControlName]="'f_'+field.id+'_allreq'" [(ngModel)]="searchFields!['f_'+field.id+'_allreq']">
            {{ 'addon.mod_data.selectedrequired' | translate }}
        </ion-checkbox>
    </ion-item>
</span>

<core-format-text *ngIf="displayMode && content" [text]="content" [filter]="false" />
