<ion-card (click)="openSet()" button [attr.aria-label]="set.fullname">
    <ion-item class="ion-text-wrap has-corner-button" lines="none">
        <ng-container>
            <ng-container *ngIf="!set.mosaicimages">
                <ion-icon name="wp-folder" slot="start" *ngIf="!set.islocked && !set.iscompleted" aria-hidden="true" />
                <ion-icon name="wp-locked" slot="start" *ngIf="set.islocked && !set.iscompleted" aria-hidden="true" />
                <ion-icon name="wp-folder-completed" slot="start" *ngIf="set.iscompleted" aria-hidden="true" />
            </ng-container>
            <ion-avatar *ngIf="set.mosaicimages" slot="start" class="workplace-mosaic-thumb">
                <ng-container *ngFor="let image of set.mosaicimages">
                    <img *ngIf="image" [url]="image" core-external-content alt="" />
                </ng-container>
            </ion-avatar>
        </ng-container>

        <ion-label>
            <p class="item-heading">
                <core-format-text [text]="set.fullname" />
                <ion-icon *ngIf="prefetchStatus.status === 'downloaded'" class="downloaded-icon" name="fam-cloud-done" color="success"
                    role="status" [attr.aria-label]="'core.downloaded' | translate" />
            </p>
            <ion-badge class="wp-set-courses" color="secondary">
                {{ set.numcourses }} {{ 'workplace.courses' | translate }}
            </ion-badge>
            <div *ngIf="!set.islocked && set.progress !== null && set.progress >= 0" class="core-course-progress">
                <core-progress-bar [progress]="set.progress" [a11yText]="'workplace.progress' | translate:{$a: set.progress}" />
            </div>
        </ion-label>

        <div class="core-button-spinner corner-button" *ngIf="!set.islocked && downloadEnabled && setCourses.length" slot="end">
            <core-download-refresh [status]="prefetchStatus.status" [enabled]="downloadEnabled"
                [statusTranslatable]="prefetchStatus.statusTranslatable" [canTrustDownload]="false" [loading]="prefetchStatus.loading"
                (action)="prefetchSet()" />
        </div>

    </ion-item>
    <ion-item class="ion-text-wrap" lines="none" *ngIf="set.islocked && set.unlockrequirement">
        <ion-label>
            <p class="workplace-unlockrequirement">
                <ion-icon name="wp-locked" aria-hidden="true" />
                <core-format-text [text]="set.unlockrequirement" />
            </p>
        </ion-label>
    </ion-item>
</ion-card>
