// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { NgModule } from '@angular/core';

import { AddonRemoteThemesHandlerService } from '@addons/remotethemes/services/remotethemes-handler';
import { CoreCourseProvider } from '@features/course/services/course';
import { CoreCourseHelperProvider } from '@features/course/services/course-helper';
import { CoreCustomURLSchemesProvider } from '@services/urlschemes';
import { CoreDomUtilsProvider } from '@services/utils/dom';
import { CoreLoginHelperProvider } from '@features/login/services/login-helper';
import { CoreMainMenuProvider } from '@features/mainmenu/services/mainmenu';
import { CoreSettingsHelperProvider } from '@features/settings/services/settings-helper';
import { CoreSitesFactoryService } from '@services/sites-factory';
import { CoreSitesProvider } from '@services/sites';

import { FreemiumCourseProvider } from './core/features/course/services/course';
import { FreemiumCourseHelperProvider } from './core/features/course/services/course-helper';
import { FreemiumCustomURLSchemesProvider } from './core/services/urlschemes';
import { FreemiumDomUtilsProvider } from './core/services/dom';
import { FreemiumLoginHelperProvider } from './core/features/login/providers/login-helper';
import { FreemiumMainMenuProvider } from './core/features/mainmenu/services/mainmenu';
import { FreemiumRemoteThemesService } from './addons/remotethemes/services/remotethemes-handler';
import { FreemiumSettingsHelperProvider } from './core/features/settings/providers/helper';
import { FreemiumSitesFactoryService } from './core/services/sites-factory';
import { FreemiumSitesProvider } from './core/services/sites';
import { CoreSiteInfoCronHandlerService } from '@services/handlers/site-info-cron';
import { FreemiumSiteInfoCronHandlerService } from './core/services/handlers/site-info-cron';
import { CoreConfigProvider } from '@services/config';
import { FreemiumConfigProvider } from './core/services/config';

@NgModule({
    providers: [
        { provide: AddonRemoteThemesHandlerService, useClass: FreemiumRemoteThemesService },
        { provide: CoreConfigProvider, useClass: FreemiumConfigProvider },
        { provide: CoreCourseProvider, useClass: FreemiumCourseProvider },
        { provide: CoreCourseHelperProvider, useClass: FreemiumCourseHelperProvider },
        { provide: CoreMainMenuProvider, useClass: FreemiumMainMenuProvider },
        { provide: CoreLoginHelperProvider, useClass: FreemiumLoginHelperProvider },
        { provide: CoreSettingsHelperProvider, useClass: FreemiumSettingsHelperProvider },
        { provide: CoreSitesProvider, useClass: FreemiumSitesProvider },
        { provide: CoreSitesFactoryService, useClass: FreemiumSitesFactoryService },
        { provide: CoreDomUtilsProvider, useClass: FreemiumDomUtilsProvider },
        { provide: CoreSiteInfoCronHandlerService, useClass: FreemiumSiteInfoCronHandlerService },
        { provide: CoreCustomURLSchemesProvider, useClass: FreemiumCustomURLSchemesProvider },
    ],
})
export class FreemiumOverridesModule {}
