<ion-refresher slot="fixed" [disabled]="!filesLoaded" (ionRefresh)="refreshFiles($event.target)">
    <ion-refresher-content pullingText="{{ 'core.pulltorefresh' | translate }}" />
</ion-refresher>

<!-- Allow selecting the site to view. -->
<core-site-picker *ngIf="showSitePicker" [hidden]="!filesLoaded" [initialSite]="siteId" (siteSelected)="changeSite($event)" />

<core-loading [hideUntil]="filesLoaded" [fullscreen]="false">
    <ion-list *ngIf="files && files.length > 0">
        <ng-container *ngFor="let file of files; let idx = index">
            <core-local-file *ngIf="file.isFile" [file]="file" [manage]="manage" [overrideClick]="pick" (onClick)="filePicked(file)"
                (onDelete)="fileDeleted(idx)" (onRename)="fileRenamed(idx, $event)" />

            <ion-item button *ngIf="!file.isFile" class="ion-text-wrap item-file item-directory" (click)="openFolder(file)" [detail]="true">
                <ion-icon name="fas-folder" slot="start" [attr.aria-label]="'core.folder' | translate" />
                <ion-label>{{ file.name }}</ion-label>
            </ion-item>
        </ng-container>
    </ion-list>

    <core-empty-box *ngIf="files && !files.length && manage" icon="fas-folder" [message]="'core.sharedfiles.nosharedfiles' | translate" />

    <core-empty-box *ngIf="files && !files.length && !manage" icon="fas-folder"
        [message]="'core.sharedfiles.nosharedfilestoupload' | translate" />
</core-loading>
