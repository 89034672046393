// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Component, OnInit, OnDestroy } from '@angular/core';
import { CoreEventObserver, CoreEvents } from '@singletons/events';
import { CoreSites } from '@services/sites';
import {
    CoreCoursesProvider,
    CoreCoursesMyCoursesUpdatedEventData,
} from '@features/courses/services/courses';
import { CoreBlockBaseComponent } from '@features/block/classes/base-block-component';
import { CoreUtils } from '@services/utils/utils';
import { CoreSite } from '@classes/sites/site';
import { WorkplaceMyAvailable, WorkplaceMyAvailableCourse } from '../../services/myavailable';
import {
    CoreCourseWithImageAndColor,
} from '@features/courses/services/courses-helper';

/**
 * Component to render a my available courses block.
 */
@Component({
    selector: 'workplace-myavailable',
    templateUrl: 'myavailable.html',
})
export class WorkplaceMyAvailableComponent extends CoreBlockBaseComponent implements OnInit, OnDestroy {

    courses: WorkplaceMyAvailableCourseToRender[] = [];

    scrollElementId!: string;

    protected site: CoreSite;
    protected isDestroyed = false;
    protected coursesObserver?: CoreEventObserver;
    protected fetchContentDefaultError = 'Error getting new available courses data.';

    constructor() {
        super('WorkplaceMyAvailableComponent');

        this.site = CoreSites.getRequiredCurrentSite();
    }

    /**
     * @inheritdoc
     */
    async ngOnInit(): Promise<void> {
        // Generate unique id for scroll element.
        const scrollId = CoreUtils.getUniqueId('WorkplaceMyAvailableComponent-Scroll');

        this.scrollElementId = `workplace-myavailable-scroll-${scrollId}`;

        this.coursesObserver = CoreEvents.on(
            CoreCoursesProvider.EVENT_MY_COURSES_UPDATED,
            (data) => {
                this.refreshCourseList(data);
            },
            this.site.getId(),
        );

        super.ngOnInit();
    }

    /**
     * @inheritdoc
     */
    async invalidateContent(): Promise<void> {
        await WorkplaceMyAvailable.invalidateMyAvailableCourses();
    }

    /**
     * @inheritdoc
     */
    protected async fetchContent(): Promise<void> {
        const courses = await WorkplaceMyAvailable.getMyAvailableCourses();

        this.courses = courses.map((course) => Object.assign(course, { courseImage: course.courseimage }));
    }

    /**
     * Refresh course list based on a EVENT_MY_COURSES_UPDATED event.
     *
     * @param data Event data.
     * @returns Promise resolved when done.
     */
    protected async refreshCourseList(data: CoreCoursesMyCoursesUpdatedEventData): Promise<void> {
        if (data.action != CoreCoursesProvider.ACTION_ENROL) {
            return;
        }
        const courseIndex = this.courses.findIndex((course) => course.id == data.courseId);
        if (courseIndex < 0) {
            return;
        }

        // Remove it.
        this.courses.splice(courseIndex, 1);

        await this.invalidateContent();
    }

    /**
     * @inheritdoc
     */
    ngOnDestroy(): void {
        this.isDestroyed = true;
        this.coursesObserver?.off();
    }

}

type WorkplaceMyAvailableCourseToRender = WorkplaceMyAvailableCourse & CoreCourseWithImageAndColor;
