// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';

import { CoreCourse } from '@features/course/services/course';
import { CoreCourseAnyCourseData } from '@features/courses/services/courses';
import {
    CoreCourseWithImageAndColor,
    CoreEnrolledCourseDataWithExtraInfoAndOptions,
} from '@features/courses/services/courses-helper';
import { makeSingleton, Translate } from '@singletons';
import {
    CoreCourseHelperProvider,
    CorePrefetchStatusInfo,
    CoreCoursePrefetchCourseOptions,
    CoreCourseConfirmPrefetchCoursesOptions,
} from '@features/course/services/course-helper';

import { FreemiumError } from '@freemium/classes/error';
import { FreemiumFeature } from '@freemium/constants';
import { FreemiumSites } from '@freemium/overrides/core/services/sites';
import { CoreDomUtils } from '@services/utils/dom';
import { WorkplaceCourseSummaryPage } from '@workplace/overrides/core/features/pages/course-summary/course-summary';
import { LazyRoutesModule } from '@/app/app-routing.module';
import { Params } from '@angular/router';

/**
 * Override the base course helper provider to limit and disable features based on subscription.
 */
@Injectable({ providedIn: 'root' })
export class FreemiumCourseHelperProvider extends CoreCourseHelperProvider {

    /**
     * @inheritdoc
     */
    async confirmAndPrefetchCourse(
        data: CorePrefetchStatusInfo,
        course: CoreCourseAnyCourseData,
        options: CoreCoursePrefetchCourseOptions = {},
    ): Promise<void> {
        const site = FreemiumSites.getCurrentSite();
        const downloadLimit = site.subscription.getFeatureLimit(FreemiumFeature.CoursesDownload);

        if (downloadLimit !== null) {
            const offlineCourses = await CoreCourse.getDownloadedCourseIds();

            if (offlineCourses.indexOf(course.id) === -1 && offlineCourses.length >= downloadLimit) {
                const errorMessage = Translate.instant('freemium.coursesdownloadlimitreached', { limit: downloadLimit });

                throw new FreemiumError(errorMessage);
            }
        }

        return super.confirmAndPrefetchCourse(data, course, options);
    }

    /**
     * @inheritdoc
     */
    async confirmAndPrefetchCourses(
        courses: CoreEnrolledCourseDataWithExtraInfoAndOptions[],
        options: CoreCourseConfirmPrefetchCoursesOptions = {},
    ): Promise<void> {
        const site = FreemiumSites.getCurrentSite();
        const downloadLimit = site.subscription.getFeatureLimit(FreemiumFeature.CoursesDownload);

        if (downloadLimit !== null) {
            throw new FreemiumError(Translate.instant('freemium.coursesbatchdownloaddisabled'));
        }

        return super.confirmAndPrefetchCourses(courses, options);
    }

    /**
     * @inheritdoc
     */
    async getCourseSummaryRouteModule(): Promise<LazyRoutesModule> {
        return import('@workplace/overrides/core/features/pages/course-summary/course-summary.module')
            .then(m => m.WorkplaceCourseSummaryPageModule);
    }

    /**
     * @inheritdoc
     */
    openCourseSummary(course: CoreCourseWithImageAndColor & CoreCourseAnyCourseData, options: Params): void {
        CoreDomUtils.openSideModal<void>({
            component: WorkplaceCourseSummaryPage,
            componentProps: {
                courseId: course.id,
                course: course,
                ...options,
            },
        });
    }

}

export const FreemiumCourseHelper = makeSingleton(FreemiumCourseHelperProvider);
