<ion-card (click)="!course.ishidden && openCourse()" [button]="!course.ishidden" [attr.aria-label]="course.fullname">
    <ion-item class="ion-text-wrap has-corner-button" lines="none">
        <ng-container *ngIf="!course.image">
            <ion-icon name="wp-alert" slot="start" *ngIf="course.ishidden" />
            <ion-icon name="wp-completed" slot="start" color="success" *ngIf="!course.ishidden && course.iscompleted" />
            <ion-icon name="wp-locked" slot="start" *ngIf="!course.ishidden && !course.iscompleted && course.islocked" />
            <ion-icon name="wp-arrow-right" slot="start"
                *ngIf="!course.ishidden && (course.isenrolled || (!course.iscompleted && !course.islocked && course.progress > 0))" />
            <ion-icon name="wp-course" slot="start"
                *ngIf="!course.isenrolled && !course.iscompleted && !course.ishidden && !course.islocked && course.progress === 0" />
        </ng-container>
        <ion-avatar *ngIf="course.image" slot="start" class="core-course-thumb">
            <img *ngIf="!course.dataCourseImage" [url]="course.image" core-external-content alt="" />
            <div class="data-image" *ngIf="course.dataCourseImage" alt="" style='background-image: url("{{course.image}}")'>
            </div>
        </ion-avatar>
        <ion-label>
            <p class="item-heading" *ngIf="!course.ishidden">
                <core-format-text [text]="course.fullname" />
                <ion-icon
                    *ngIf="prefetchCourseData.status === 'downloaded' && !course.ishidden && !course.islocked && !course.unlockrequirement"
                    class="downloaded-icon" name="fam-cloud-done" color="success" role="status"
                    [attr.aria-label]="'core.downloaded' | translate" />
            </p>
            <p class="item-heading" *ngIf="course.ishidden">
                {{ 'workplace.coursenotavailable' | translate }}
            </p>

            <ion-chip color="primary" *ngIf="course.categoryname" class="core-course-category core-course-additional-info ion-text-nowrap">
                <span class="sr-only">{{ 'core.courses.aria:coursecategory' | translate }}</span>
                <ion-label>
                    <core-format-text [text]="course.categoryname" [contextLevel]="'coursecat'" />
                </ion-label>
            </ion-chip>

            <ion-chip color="secondary" *ngIf="course.showhiddencoursebadge" class="ion-text-nowrap">
                <ion-label>
                    {{ 'workplace.hiddenfromlearners' | translate}}
                </ion-label>
            </ion-chip>

            <div *ngIf="course.showprogress !== false && course.isenrolled && !course.islocked &&
                !course.ishidden && course.progress !== null && course.progress >= 0" class="core-course-progress">
                <core-progress-bar [progress]="course.progress" [a11yText]="'workplace.progress' | translate:{$a: course.progress}" />
            </div>
        </ion-label>
        <div class="core-button-spinner corner-button"
            *ngIf="!course.islocked && !course.ishidden && course.isenrolled && downloadCourseEnabled" slot="end">
            <!-- Download course. -->
            <core-download-refresh [status]="prefetchCourseData.status" [enabled]="downloadCourseEnabled"
                [statusTranslatable]="prefetchCourseData.statusTranslatable" [canTrustDownload]="false"
                [loading]="prefetchCourseData.loading" (action)="prefetchCourse()" />
        </div>
    </ion-item>
    <ion-item class="ion-text-wrap" lines="none" *ngIf="!course.ishidden && course.islocked && course.unlockrequirement">
        <ion-label>
            <p class="workplace-unlockrequirement">
                <ion-icon name="wp-locked" aria-hidden="true" />
                <core-format-text [text]="course.unlockrequirement" />
            </p>
        </ion-label>
    </ion-item>
</ion-card>
