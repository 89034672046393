<ion-card (click)="!course.hidden && openCourse()" [button]="!course.hidden" [attr.aria-label]="course.fullname">
    <div (click)="!course.hidden && openCourse()" class="core-course-thumb" [class.core-course-color-img]="course.courseImage">
        <img *ngIf="course.courseImage && !course.dataCourseImage" [url]="course.courseImage" core-external-content alt=""
            style='background-image: url("{{course.courseImage}}")' />
        <div class="data-image" *ngIf="course.courseImage && course.dataCourseImage" alt=""
            style='background-image: url("{{course.courseImage}}")'></div>
        <ion-icon *ngIf="!course.courseImage" name="wp-course" class="course-icon" aria-hidden="true" />
    </div>
    <ng-content />
    <ion-item class="ion-text-wrap" [detail]="false" lines="none">
        <ion-label>
            <div class="core-course-maininfo">
                <p class="item-heading" *ngIf="!course.hidden">
                    <core-format-text [text]="course.fullname" />
                </p>
                <ion-chip *ngIf="!course.hidden && extraBadge">{{extraBadge}}</ion-chip>
                <p class="item-heading" *ngIf="course.hidden">
                    {{ 'workplace.coursenotavailable' | translate }}
                </p>

                <ion-chip color="primary" *ngIf="course.categoryname"
                    class="core-course-category core-course-additional-info ion-text-nowrap">
                    <span class="sr-only">{{ 'workplace.aria:coursecategory' | translate }}</span>
                    <ion-label>
                        <core-format-text [text]="course.categoryname" [contextLevel]="'coursecat'" />
                    </ion-label>
                </ion-chip>
            </div>
            <div class="core-course-progress" *ngIf="!course.hidden && course.hasprogress !== false && course.progress !== null  &&
                course.progress !== undefined && course.progress >= 0">
                <core-progress-bar [progress]="course.progress" [a11yText]="'workplace.progress' | translate:{$a: course.progress}" />
            </div>
        </ion-label>
    </ion-item>
</ion-card>
