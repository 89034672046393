// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';
import { CoreStyleHandler, CoreStylesService } from '@features/styles/services/styles';
import { makeSingleton } from '@singletons';
import { CoreColors } from '@singletons/colors';
import { CoreSites } from '@services/sites';
import { CoreSitePublicConfigResponse, CoreUnauthenticatedSite } from '@classes/sites/unauthenticated-site';
import { Workplace } from '../workplace';
import { CoreUtils } from '@services/utils/utils';
import { WorkplaceTenantsHelper } from '@workplace/features/tenants/services/tenants-helper';
import { CoreLoginHelper } from '@features/login/services/login-helper';
import { CoreSitesFactory } from '@services/sites-factory';
import { CoreSite } from '@classes/sites/site';

/**
 * Types of branding.
 */
export enum WorkplaceTenantBrandingColor {
    Brand4  = 'brand4', // 4.0 Primary color.
    Links = 'primary', // Links. Not used since 4.0
    NavigationBar  = 'brand', // Navigation color. Not used since 4.0. Can be replaced with brand when 4.0 is the min.
    PrimaryButtons  = 'button', // Primary buttons color.
    Drawer = 'drawer', // Drawer background.
}

const TENANT_BRANDING_CSS_VARIABLES: Record<WorkplaceTenantBrandingColor, ColorVariantVariables> = {
    [WorkplaceTenantBrandingColor.Links]: {
        base: ['--core-link-color', '--core-tab-border-color-active'],
    },
    [WorkplaceTenantBrandingColor.NavigationBar]: {
        base: ['--core-header-toolbar-background', '--core-progressbar-color'],
        contrast: ['--core-header-toolbar-color'],
        tint: ['--core-progressbar-background'],
    },
    [WorkplaceTenantBrandingColor.PrimaryButtons]: {
        base: ['--primary', '--ion-color-primary', '--ion-color-primary-base'],
        baseRGB: ['--ion-color-primary-rgb'],
        contrast: ['--primary-contrast', '--ion-color-primary-contrast'],
        contrastRGB: ['--ion-color-primary-contrast-rgb'],
        tint: ['--primary-tint', '--ion-color-primary-tint'],
        shade: ['--primary-shade', '--ion-color-primary-shade'],
    },
    [WorkplaceTenantBrandingColor.Drawer]: {
        base: [
            '--core-bottom-tabs-background-selected: rgba(0, 0, 0, .2); --core-bottom-tabs-background',
            '--core-bottom-tabs-badge-text-color',
        ],
        contrast: [
            '--core-bottom-tabs-color-selected',
            '--core-bottom-tabs-badge-color',
            '--core-bottom-tabs-color',
        ],
    },
    [WorkplaceTenantBrandingColor.Brand4]: {
        base: [
            '--core-link-color',
            '--core-tab-border-color-active',
            '--primary', '--ion-color-primary',
            '--ion-color-primary-base',
            '--core-progressbar-color',
        ],
        baseRGB: ['--ion-color-primary-rgb'],
        contrast: ['--primary-contrast', '--ion-color-primary-contrast'],
        contrastRGB: ['--ion-color-primary-contrast-rgb'],
        tint: ['--primary-tint', '--ion-color-primary-tint', '--core-progressbar-background'],
        shade: ['--primary-shade', '--ion-color-primary-shade'],
    },
};

const CONFIG_PREFIX = 'wp_tool_tenant_config_';

/**
 * Service to handle freemium basic branding based on subscription.
 */
@Injectable({ providedIn: 'root' })
export class WorkplaceTenantBrandingHandlerService implements CoreStyleHandler {

    name = 'WorkplaceTenant';
    priority = 1000;

    /**
     * @inheritDoc
     */
    async isEnabled(siteId: string, config?: CoreSitePublicConfigResponse): Promise<boolean> {
        // This is not a real delegate, so we need to check the feature directly.
        const site: CoreSite | CoreUnauthenticatedSite =
            siteId === CoreStylesService.TMP_SITE_ID && config
            ? CoreSitesFactory.makeUnauthenticatedSite(config.httpswwwroot, config)
            : await CoreSites.getSite(siteId);

        if (!site) {
            return false;
        }

        return !site.isFeatureDisabled('CoreStyles_WorkplaceTenant');
    }

    /**
     * @inheritDoc
     */
    async getStyle(siteId: string, publicConfig?: CoreSitePublicConfigResponse): Promise<string> {
        let brandingColors: BrandingColors = {};

        if (siteId === CoreStylesService.TMP_SITE_ID) {
            if (!publicConfig) {
                return '';
            }

            const tenantUrl = await WorkplaceTenantsHelper.getTenantUrlNoTest(publicConfig.httpswwwroot);

            if (!tenantUrl) {
                return '';
            }

            const tenantInfo = await Workplace.getTenantInfo(publicConfig.httpswwwroot, tenantUrl);
            if (tenantInfo) {
                const cssConfig = CoreUtils.objectToKeyValueMap<string>(tenantInfo.cssconfig, 'name', 'value') || {};

                const after4_1 = publicConfig.supportpage !== undefined && cssConfig.navbar !== undefined;
                // Hack to know if version is 4.0 or lower.
                const version4_0 = !after4_1 && publicConfig.supportpage !== undefined;

                if (after4_1) {
                    brandingColors = this.getBrandingColorsAfter4(cssConfig);
                } else if (version4_0) {
                    brandingColors = this.getBrandingColorsVersion4(cssConfig);
                } else {
                    brandingColors = this.getBrandingColorsBefore4(cssConfig);
                }
            }
        } else {
            const site = await CoreSites.getSite(siteId);
            const config = await site.getConfig();

            const after4_1 = site.isVersionGreaterEqualThan('4.1');
            const version4_0 = !after4_1 && site.isVersionGreaterEqualThan('4.0');

            if (after4_1) {
                brandingColors = this.getBrandingColorsAfter4(config, CONFIG_PREFIX);
            } else if (version4_0) {
                brandingColors = this.getBrandingColorsVersion4(config, CONFIG_PREFIX);
            } else {
                brandingColors = this.getBrandingColorsBefore4(config, CONFIG_PREFIX);
            }
        }

        return await this.loadWorkplaceBasicBrandingStyles(brandingColors);

    }

    /**
     * Branding options for version 4.1 or greater.
     *
     * @param config Configuration options.
     * @param prefix Prefix if any.
     * @returns Branding colors.
     */
    protected getBrandingColorsAfter4(config: { [name: string]: string }, prefix = ''): BrandingColors {
        const brandingColors: BrandingColors = {};

        brandingColors[WorkplaceTenantBrandingColor.Brand4] = config[prefix + 'brand'] || '';
        brandingColors[WorkplaceTenantBrandingColor.PrimaryButtons] = config[prefix + 'button'] || '';
        brandingColors[WorkplaceTenantBrandingColor.Drawer] = config[prefix + 'navbar'] || '';

        return brandingColors;
    }

    /**
     * Branding options for version 4.0.
     *
     * @param config Configuration options.
     * @param prefix Prefix if any.
     * @returns Branding colors.
     */
    protected getBrandingColorsVersion4(config: { [name: string]: string }, prefix = ''): BrandingColors {
        const brandingColors: BrandingColors = {};

        brandingColors[WorkplaceTenantBrandingColor.Brand4] = config[prefix + 'brand'] || '';

        const css = config[prefix + 'customcss'];
        if (css) {
            // Only this 2 varnames will be detected.
            const cssVarnames = [WorkplaceTenantBrandingColor.PrimaryButtons, WorkplaceTenantBrandingColor.Drawer];
            cssVarnames.forEach((type) => {
                const match = css.match(`--${CONFIG_PREFIX}${type}:(.+);`);
                if (match && match[1]) {
                    brandingColors[type] = match[1].trim();
                }
            });
        }

        return brandingColors;
    }

    /**
     * Branding options previous to 4.0.
     *
     * @param config Configuration options.
     * @param prefix Prefix if any.
     * @returns Branding colors.
     */
    protected getBrandingColorsBefore4(config: { [name: string]: string }, prefix = ''): BrandingColors {
        const brandingColors: BrandingColors = {};

        Object.values(WorkplaceTenantBrandingColor).forEach((type) => {
            if (config[prefix + type]) {
                brandingColors[type] = config[prefix + type];
            }
        });

        // Fix base branding
        if (
            !brandingColors[WorkplaceTenantBrandingColor.PrimaryButtons] &&
                brandingColors[WorkplaceTenantBrandingColor.NavigationBar]
        ) {
            brandingColors[WorkplaceTenantBrandingColor.PrimaryButtons] =
                    brandingColors[WorkplaceTenantBrandingColor.NavigationBar];
        }

        if (!CoreLoginHelper.isSingleFixedSite()) {
            if (!brandingColors[WorkplaceTenantBrandingColor.NavigationBar]) {
                // Use brand color on topbar if any is selected.
                const color = getComputedStyle(document.body).getPropertyValue('--brand').trim();
                if (color) {
                    brandingColors[WorkplaceTenantBrandingColor.NavigationBar] = color;
                }
            }

            if (!brandingColors[WorkplaceTenantBrandingColor.Drawer]) {
                // Use brand color on topbar if any is selected.
                const color = getComputedStyle(document.body).getPropertyValue('--workplace-title-text-color');
                if (color) {
                    brandingColors[WorkplaceTenantBrandingColor.Drawer] = color;
                }
            }
        }

        return brandingColors;
    }

    /**
     * Load basic branding styles for a certain site.
     *
     * @param colors Branding colors.
     * @returns CSS loaded from subscription.
     */
    protected async loadWorkplaceBasicBrandingStyles(colors: BrandingColors): Promise<string> {

        const brandingCssParts =
            Object
                .keys(colors)
                .filter((type) => type in colors && !!colors[type])
                .map((type: WorkplaceTenantBrandingColor) => this.getBrandingColorCss(type, colors[type]));

        await this.addTenantClass();

        if (brandingCssParts.length > 0) {
            return ':root{' + brandingCssParts.join('') + '}';
        }

        return '';
    }

    /**
     * Get the branding color CSS for a certain type.
     *
     * @param type The type to get.
     * @param value The value to set to the variable.
     * @returns CSS code.
     */
    protected getBrandingColorCss(type: WorkplaceTenantBrandingColor, value = ''): string {
        const variantVariables = TENANT_BRANDING_CSS_VARIABLES[type];

        let css = variantVariables.base.map((name: string) => `${name}: ${value};`).join('');
        if (variantVariables.baseRGB?.length) {
            const baseRGB = CoreColors.getColorRGBA(value).join(', ');
            css += variantVariables.baseRGB.map((name: string) => `${name}: ${baseRGB};`).join('');
        }

        if (variantVariables.contrast?.length) {
            const contrast = CoreColors.isWhiteContrastingBetter(value) ? 'white' : 'black';
            css += variantVariables.contrast.map((name: string) => `${name}: ${contrast};`).join('');

            if (variantVariables.contrastRGB?.length) {
                const contrastRgb = CoreColors.getColorRGBA(contrast).join(', ');
                css += variantVariables.contrastRGB.map((name: string) => `${name}: ${contrastRgb};`).join('');
            }
        }

        if (variantVariables.tint?.length) {
            const tint = CoreColors.lighter(value);
            css += variantVariables.tint.map((name: string) => `${name}: ${tint};`).join('');
        }

        if (variantVariables.shade?.length) {
            const shade = CoreColors.darker(value);
            css += variantVariables.shade.map((name: string) => `${name}: ${shade};`).join('');
        }

        return css;
    }

    /**
     * Convenience function to add tenant id to body classes.
     */
    protected async addTenantClass(): Promise<void> {
        await WorkplaceTenantsHelper.initTenant();
        const id = await WorkplaceTenantsHelper.getTenant();
        const classList = document.documentElement.classList;
        if (id && !classList.contains(`tenant-${id}`)) {
            classList.forEach(className => {
                if (className.includes('tenant-')) {
                    classList.remove(className);
                }
            });
            classList.add(`tenant-${id}`);
        }
    }

}
export const WorkplaceTenantBrandingHandler = makeSingleton(WorkplaceTenantBrandingHandlerService);

type BrandingColors = { [key in WorkplaceTenantBrandingColor]?: string };

type ColorVariantVariables = {
    base: string[];
    baseRGB?: string[];
    contrast?: string[];
    contrastRGB?: string[];
    tint?: string[];
    shade?: string[];
};
