// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Inject, Injectable, Optional } from '@angular/core';

import { AsyncInstance, asyncInstance } from '@/core/utils/async-instance';
import { CoreApp } from '@services/app';
import { CoreDatabaseCachingStrategy, CoreDatabaseTableProxy } from '@classes/database/database-table-proxy';
import { CoreDatabaseTable } from '@classes/database/database-table';
import { CoreSingletonProxy, makeSingleton } from '@singletons';
import { SQLiteDB } from '@classes/sqlitedb';

import { FreemiumSite } from '@freemium/overrides/core/classes/sites/site';

import { APP_SCHEMA, FreemiumStorageRecord, TABLE_NAME } from './database/storage';
import { FreemiumSites } from '@freemium/overrides/core/services/sites';
import { CorePromisedValue } from '@classes/promised-value';
import { NULL_INJECTION_TOKEN } from '@/core/constants';

/**
 * Service to store data using key-value pairs.
 *
 * The data can be scoped to a single site using FreemiumStorage.forSite(site), and it will be automatically cleared
 * when the site is deleted.
 *
 * For tabular data, use CoreAppProvider.getDB() or CoreSite.getDb().
 */
@Injectable({ providedIn: 'root' })
export class FreemiumStorageService {

    table: AsyncInstance<FreemiumStorageTable>;

    constructor(@Optional() @Inject(NULL_INJECTION_TOKEN) lazyTableConstructor?: () => Promise<FreemiumStorageTable>) {
        this.table = asyncInstance(lazyTableConstructor);
    }

    /**
     * Initialize database.
     */
    async initializeDatabase(): Promise<void> {
        try {
            await CoreApp.createTablesFromSchema(APP_SCHEMA);
        } catch (e) {
            // Ignore errors.
        }

        await this.initializeTable(CoreApp.getDB());
    }

    /**
     * Initialize table.
     *
     * @param database Database.
     */
    async initializeTable(database: SQLiteDB): Promise<void> {
        const table = await getStorageTable(database);

        this.table.setInstance(table);
    }

    /**
     * Get value.
     *
     * @param key Data key.
     * @param defaultValue Value to return if the key wasn't found.
     * @returns Data value.
     */
    async get<T=unknown>(key: string): Promise<T | null>;
    async get<T>(key: string, defaultValue: T): Promise<T>;
    async get<T=unknown>(key: string, defaultValue: T | null = null): Promise<T | null> {
        try {
            const { value } = await this.table.getOneByPrimaryKey({ key });

            return JSON.parse(value);
        } catch (error) {
            return defaultValue;
        }
    }

    /**
     * Get value directly from the database, without using any optimizations..
     *
     * @param key Data key.
     * @param defaultValue Value to return if the key wasn't found.
     * @returns Data value.
     */
    async getFromDB<T=unknown>(key: string): Promise<T | null>;
    async getFromDB<T>(key: string, defaultValue: T): Promise<T>;
    async getFromDB<T=unknown>(key: string, defaultValue: T | null = null): Promise<T | null> {
        try {
            const db = CoreApp.getDB();
            const { value } = await db.getRecord<FreemiumStorageRecord>(TABLE_NAME, { key });

            return JSON.parse(value);
        } catch (error) {
            return defaultValue;
        }
    }

    /**
     * Set value.
     *
     * @param key Data key.
     * @param value Data value.
     */
    async set(key: string, value: unknown): Promise<void> {
        await this.table.insert({ key, value: JSON.stringify(value) });
    }

    /**
     * Check if value exists.
     *
     * @param key Data key.
     * @returns Whether key exists or not.
     */
    async has(key: string): Promise<boolean> {
        return this.table.hasAny({ key });
    }

    /**
     * Remove value.
     *
     * @param key Data key.
     */
    async remove(key: string): Promise<void> {
        await this.table.deleteByPrimaryKey({ key });
    }

}

export const FreemiumStorage = makeSingleton(FreemiumStorageService) as FreemiumStorageSingleton;

const SERVICE_INSTANCES: Record<string, AsyncInstance<FreemiumStorageService>> = {};
const TABLE_INSTANCES: WeakMap<SQLiteDB, CorePromisedValue<FreemiumStorageTable>> = new WeakMap();

/**
 * Helper function to get a storage table for the given database.
 *
 * @param database Database.
 * @returns Storage table.
 */
function getStorageTable(database: SQLiteDB): CorePromisedValue<FreemiumStorageTable> {
    const existingTable = TABLE_INSTANCES.get(database);

    if (existingTable) {
        return existingTable;
    }

    const table = CorePromisedValue.from(new Promise<FreemiumStorageTable>((resolve, reject) => {
        const table = new CoreDatabaseTableProxy<FreemiumStorageRecord, 'key'>(
            { cachingStrategy: CoreDatabaseCachingStrategy.Eager },
            database,
            TABLE_NAME,
            ['key'],
        );

        table.initialize()
            .then(() => resolve(table))
            .catch(reject);
    }));

    TABLE_INSTANCES.set(database, table);

    return table;
}

/**
 * Singleton providing static access to instances.
 */
type FreemiumStorageSingleton = CoreSingletonProxy<FreemiumStorageService & {
    forSite: (site: FreemiumSite) => AsyncInstance<FreemiumStorageService>;
    forCurrentSite: () => FreemiumStorageService;
}>;

/**
 * Storage table.
 */
type FreemiumStorageTable = CoreDatabaseTable<FreemiumStorageRecord, 'key'>;

Object.defineProperty(FreemiumStorage, 'forSite', {
    value(site: FreemiumSite): AsyncInstance<FreemiumStorageService> {
        const siteId = site.getId();

        if (!(siteId in SERVICE_INSTANCES)) {
            SERVICE_INSTANCES[siteId] = asyncInstance(async () => {
                const instance = new FreemiumStorageService();

                await instance.initializeTable(site.getDb());

                return instance;
            });
        }

        return SERVICE_INSTANCES[siteId];
    },
});

Object.defineProperty(FreemiumStorage, 'forCurrentSite', {
    value: (): FreemiumStorageService => new FreemiumStorageService(async () => {
        const siteId = await FreemiumSites.getStoredCurrentSiteId();
        const site = await FreemiumSites.getSite(siteId);
        const table = await getStorageTable(site.getDb());

        return table;
    }),
});
