<ion-item-divider [sticky]="true">
    <ion-label>
        <h2 *ngIf="!courses.length">{{ 'workplace.myinprogress.pluginname' | translate }}</h2>
        <h2 *ngIf="courses.length">{{ 'workplace.myinprogress.blocktitle' | translate:{$a: courses.length} }}</h2>
    </ion-label>
    <div slot="end" class="flex-row">
        <core-horizontal-scroll-controls #scrollControls [aria-controls]="scrollElementId" />
    </div>
</ion-item-divider>
<core-loading [hideUntil]="loaded">
    <core-empty-box *ngIf="!courses.length" image="assets/img/icons/myinprogress-no-courses.png"
        [message]="'workplace.myinprogress.nocourses' | translate" />
    <!-- List of courses. -->
    <div [hidden]="!courses.length" [id]="scrollElementId" class="core-horizontal-scroll" (scroll)="scrollControls.updateScrollPosition()">
        <div (onResize)="scrollControls.updateScrollPosition()" class="flex-row">
            <div class="safe-area-pseudo-padding-start"></div>
            <ng-container *ngFor="let course of courses">
                <workplace-course-summary-card *ngIf="showHidden || !course.hiddeninblock" [course]="course" class="workplace-myinprogress"
                    [extraBadge]="course.hiddeninblock ? ('workplace.myinprogress.hidden' | translate) : ''">
                    <div class="core-button-spinner">
                        <!-- Options menu. -->
                        <ion-button fill="clear" (click)="showCourseOptionsMenu($event, course)" *ngIf="!showSpinner"
                            [ariaLabel]="('workplace.myinprogress.aria:courseactions' | translate)">
                            <ion-icon name="ellipsis-vertical" slot="icon-only" aria-hidden="true" />
                        </ion-button>

                        <!-- Loading options course spinner. -->
                        <ion-spinner *ngIf="showSpinner" [attr.aria-label]="'core.loading' | translate" />
                    </div>
                </workplace-course-summary-card>
            </ng-container>
            <div class="safe-area-pseudo-padding-end"></div>
        </div>
    </div>
    <ion-item *ngIf="hiddenCoursesIds.length">
        <ion-toggle [(ngModel)]="showHidden">
            {{ 'workplace.myinprogress.showhidden' | translate }} ({{ hiddenCoursesIds.length }})
        </ion-toggle>
    </ion-item>
</core-loading>
