// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { CoreConstants } from '@/core/constants';
import { Injectable } from '@angular/core';
import { CoreAnalyticsEvent, CoreAnalyticsEventType, CoreAnalyticsHandler } from '@services/analytics';
import { CoreNetwork } from '@services/network';
import { CoreUtils } from '@services/utils/utils';
import { makeSingleton } from '@singletons';
import { CoreObject } from '@singletons/object';

/**
 * Analytics handler.
 */
@Injectable({ providedIn: 'root' })
export class FreemiumFirebaseAnalyticsHandlerService implements CoreAnalyticsHandler {

    name = 'FreemiumFirebaseAnalyticsHandler';
    appLevelEnabled = true;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected win = <any> window; // Analytics is only present in our fork of the push plugin. @todo Move it to a new plugin.

    /**
     * @inheritdoc
     */
    async isEnabled(): Promise<boolean> {
        return !!(CoreConstants.CONFIG.firebaseAnalytics && this.win.PushNotification?.logEvent);
    }

    /**
     * @inheritdoc
     */
    async enableAnalytics(enable: boolean): Promise<void> {
        if (!this.win.PushNotification?.enableAnalytics) {
            return;
        }

        await new Promise<void>((resolve, reject) => {
            this.win.PushNotification.enableAnalytics(resolve, reject, !!enable);
        });
    }

    /**
     * @inheritdoc
     */
    async logEvent(event: CoreAnalyticsEvent): Promise<void> {
        if (
            !CoreNetwork.isOnline() ||
            (event.type !== CoreAnalyticsEventType.PUSH_NOTIFICATION &&
            event.type !== CoreAnalyticsEventType.VIEW_ITEM &&
            event.type !== CoreAnalyticsEventType.VIEW_ITEM_LIST)
        ) {
            return;
        }

        await new Promise<void>((resolve, reject) => {
            if (event.type === CoreAnalyticsEventType.PUSH_NOTIFICATION) {
                this.win.PushNotification.logEvent(resolve, reject, event.eventName, event.data, true);

                return;
            }

            const name = event.type === CoreAnalyticsEventType.VIEW_ITEM ? 'view_item' : 'view_item_list';
            let data: Record<string, unknown> = {};
            if (event.data) {
                // Format the data before sending it.
                const itemId = CoreObject.consumeKey(event.data, 'id');
                const itemCategory = CoreObject.consumeKey(event.data, 'category');

                // Add "moodle" to the name of all extra params.
                data = CoreUtils.prefixKeys(CoreObject.withoutUndefined({ ...event.data }), 'moodle');
                data.moodleaction = event.ws;
                data.moodlesiteid = event.siteId;
                data.moodleurl = event.url;
                data.item_name = event.name;

                if (itemId) {
                    data.item_id = itemId;
                }
                if (itemCategory) {
                    data.item_category = itemCategory;
                }
            }

            this.win.PushNotification.logEvent(resolve, reject, name, data, false);
        });
    }

}

export const FreemiumFirebaseAnalyticsHandler = makeSingleton(FreemiumFirebaseAnalyticsHandlerService);
