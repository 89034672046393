<ng-container *ngIf="completion && !completion.isautomatic">
    <ng-container *ngIf="completion.istrackeduser">
        <ion-button *ngIf="completion.state" color="success" [ariaLabel]="accessibleDescription" (click)="completionClicked($event)"
            class="ion-text-wrap chip">
            <ion-icon name="fas-check" slot="start" aria-hidden="true" />
            {{ 'core.course.completion_manual:done' | translate }}
            <ion-icon *ngIf="completion?.offline" name="fas-arrows-rotate"
                [attr.aria-label]="'core.course.manualcompletionnotsynced' | translate" slot="end" />
        </ion-button>
        <ion-button *ngIf="!completion.state" fill="outline" [ariaLabel]="accessibleDescription" (click)="completionClicked($event)"
            class="ion-text-wrap chip">
            {{ 'core.course.completion_manual:markdone' | translate }}
            <ion-icon *ngIf="completion?.offline" name="fas-arrows-rotate"
                [attr.aria-label]="'core.course.manualcompletionnotsynced' | translate" slot="end" />
        </ion-button>
    </ng-container>

    <ng-container *ngIf="!completion.istrackeduser">
        <ion-button disabled="true" fill="outline" class="ion-text-wrap chip">
            {{ 'core.course.completion_manual:markdone' | translate }}
        </ion-button>
    </ng-container>
</ng-container>
