<ng-container *ngIf="showInline && contentText">
    <core-format-text [text]="contentText" [filter]="false" />
</ng-container>
<ng-container *ngFor="let file of files; let i = index">
    <!-- Files already attached to the filearea. -->
    <core-file *ngIf="!file.name && !file.embedType" [file]="file" [component]="component" [componentId]="componentId"
        [alwaysDownload]="alwaysDownload" [canDownload]="canDownload" [showSize]="showSize" [showTime]="showTime" />

    <!-- Files stored in offline to be sent later. -->
    <core-local-file *ngIf="file.name && !file.embedType" [file]="file" />

    <ion-item class="ion-text-wrap" *ngIf="extraHtml && extraHtml[i]">
        <ion-label>
            <core-format-text [component]="component" [componentId]="componentId" [text]="extraHtml[i]" />
        </ion-label>
    </ion-item>
</ng-container>
