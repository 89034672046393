<ion-select *ngIf="interface !== 'modal'" class="ion-text-start" [(ngModel)]="selection" (ngModelChange)="onValueChanged(selection)"
    interface="popover" [disabled]="disabled" [class.combobox-icon-only]="icon"
    [interfaceOptions]="{alignment: 'start', arrow: false, cssClass: 'core-combobox-select'}">
    <div slot="label">
        <span class="sr-only" *ngIf="label">{{ label }}</span>
        <ion-icon *ngIf="icon" [name]="icon" aria-hidden="true" />
    </div>

    <ng-content />
</ion-select>

<ion-button *ngIf="interface === 'modal'" aria-haspopup="listbox" [attr.aria-controls]="listboxId" [attr.aria-owns]="listboxId"
    [attr.aria-expanded]="expanded" (click)="openModal()" [disabled]="disabled" expand="block" role="combobox">
    <ion-icon *ngIf="icon" [name]="icon" slot="start" aria-hidden="true" />

    <span class="sr-only" *ngIf="label">{{ label }},</span>
    <div class="select-text">
        <slot name="text">{{selection}}</slot>
    </div>

    <div class="select-icon" role="presentation" aria-hidden="true" slot="end">
        <div class="select-icon-inner"></div>
    </div>
</ion-button>
