// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { CoreConstants } from '@/core/constants';
import { FreemiumSitePublicConfig } from '@freemium/overrides/core/classes/sites/unauthenticated-site';
import { FreemiumSites } from '@freemium/overrides/core/services/sites';
import { CoreConfig } from '@services/config';
import { CoreSites } from '@services/sites';
import { CoreEvents } from '@singletons/events';

/**
 * Initialize config listeners.
 */
export default function(): void {
    CoreEvents.on(CoreEvents.LOGIN_SITE_CHECKED, ({ config }) => {
        const { subscription } = config as FreemiumSitePublicConfig;

        CoreConfig.patchEnvironment({
            ...CoreConstants.CONFIG.siteOverrides?.[config.httpswwwroot],
            ...subscription?.appConfig,
        });
    });

    CoreEvents.on(CoreEvents.LOGIN, () => {
        const site = FreemiumSites.getCurrentSite();
        const subscription = site.subscription;

        CoreConfig.patchEnvironment({
            ...CoreConstants.CONFIG.siteOverrides?.[site.siteUrl],
            ...subscription.appConfig,
        });
    });

    CoreEvents.on(CoreEvents.SITE_UPDATED, ({ siteId }) => {
        if (siteId !== CoreSites.getCurrentSiteId()) {
            return;
        }

        const site = FreemiumSites.getCurrentSite();
        const subscription = site.subscription;

        CoreConfig.patchEnvironment(
            {
                ...CoreConstants.CONFIG.siteOverrides?.[site.siteUrl],
                ...subscription.appConfig,
            },
            { reset: true },
        );
    });

    CoreEvents.on(CoreEvents.LOGIN_SITE_UNCHECKED, ({ loginSuccessful }) => loginSuccessful || CoreConfig.resetEnvironment());
    CoreEvents.on(CoreEvents.LOGOUT, () => CoreConfig.resetEnvironment());
}
