// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';
import { makeSingleton } from '@singletons';
import { SiteSubscriptionFetch, SiteSubscriptionsService } from '@freemium/services/site-subscriptions';
import { FreemiumUnauthenticatedSite } from '@freemium/overrides/core/classes/sites/unauthenticated-site';
import { SiteSubscription } from '@freemium/classes/site-subscription';
import { SiteSubscriptionPlan } from '@freemium/constants';

/**
 * Mock for SiteSubscriptionsService.
 */
@Injectable({ providedIn: 'root' })
export class SiteSubscriptionsServiceMock extends SiteSubscriptionsService {

    private subscriptionStub?: SiteSubscription;

    /**
     * @inheritdoc
     */
    async fetchSubscription(site: FreemiumUnauthenticatedSite): Promise<SiteSubscriptionFetch | null> {
        if (this.subscriptionStub) {
            return { freshSubscription: this.subscriptionStub };
        }

        return super.fetchSubscription(site);
    }

    /**
     * Mock a subscription for any site.
     *
     * @param options Options.
     */
    mockSubscription(options: MockSubscriptionOptions = {} ): void {
        const plan = options.plan ?? SiteSubscriptionPlan.Free;

        this.subscriptionStub = new SiteSubscription(plan, new Date(), new Date());
        this.subscriptionStub.customCSS = options.customCSS;
        this.subscriptionStub.restrictAccess = options.restrictAccess;
    }

}

export const SiteSubscriptionsMock = makeSingleton(SiteSubscriptionsServiceMock);

/**
 * Options to configure a subscription mock.
 */
type MockSubscriptionOptions = Partial<{
    plan: SiteSubscriptionPlan;
    customCSS: string;
    restrictAccess: {
        enforce: boolean;
        message: string;
    };
}>;
