<ion-item class="ion-text-wrap">
    <ion-label>
        <p class="item-heading">{{ 'addon.mod_quiz.quizpassword' | translate }}</p>
        <p>{{ 'addon.mod_quiz.requirepasswordmessage' | translate}}</p>
    </ion-label>
</ion-item>
<ion-item [formGroup]="form">
    <ion-input id="addon-mod_quiz-accessrule-password-input" name="quizpassword" type="password"
        placeholder="{{ 'addon.mod_quiz.quizpassword' | translate }}" [formControlName]="'quizpassword'" [clearOnEdit]="false"
        [attr.aria-label]="'addon.mod_quiz.quizpassword' | translate">
        <core-show-password slot="end" />
    </ion-input>
</ion-item>
