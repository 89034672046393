// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable, OnDestroy } from '@angular/core';
import { makeSingleton } from '@singletons';
import { CoreSettingsHandler, CoreSettingsHandlerData } from '@features/settings/services/settings-delegate';
import {
    FreemiumBiometricLogin,
    FreemiumBiometricMethodAvailable,
    USER_BIOMETRIC_LOGIN_CHANGED,
} from '@freemium/services/biometric-login';
import { CoreError } from '@classes/errors/error';
import { CoreEventObserver, CoreEvents } from '@singletons/events';

/**
 * Biometric login session handler
 */
@Injectable({ providedIn: 'root' })
export class FreemiumBiometricLoginSettingsHandlerService implements CoreSettingsHandler, OnDestroy {

    name = 'FreemiumBiometricLogin';
    priority = 200;
    protected availableMethod?: FreemiumBiometricMethodAvailable | null;
    protected enabledByUser?: boolean;
    protected loginChangedObserver?: CoreEventObserver;

    constructor() {
        this.loginChangedObserver = CoreEvents.on(
            USER_BIOMETRIC_LOGIN_CHANGED,
            ({ enabled }: {enabled: boolean}) => {
                this.enabledByUser = enabled;
            },
        );
    }

    /**
     * Check if the handler is enabled on a site level.
     *
     * @returns Whether or not the handler is enabled on a site level.
     */
    async isEnabled(): Promise<boolean> {
        try {
            const isEnabledBySite = await FreemiumBiometricLogin.isEnabledBySite();
            this.availableMethod = await FreemiumBiometricLogin.getBiometricMethodAvailable();

            // This is called because we cannot do async operations in `getDisplayData` function
            // and need to know if user enabled biometric login.
            await this.initializeEnabledByUser();

            return !!this.availableMethod && isEnabledBySite;
        } catch (error) {
            return false;
        }
    }

    /**
     * Check if user has enabled biometric login and store the result if isn't init.
     */
    async initializeEnabledByUser(): Promise<void> {
        if (this.enabledByUser !== undefined) {
            return;
        }

        this.enabledByUser = await FreemiumBiometricLogin.isEnabledByUser();
    }

    /**
     * Returns the data needed to render the handler.
     *
     * @returns Data needed to render the handler.
     */
    getDisplayData(): CoreSettingsHandlerData {
        if (!this.availableMethod) {
            throw new CoreError('Biometric method is required.');
        }

        const options: Record<FreemiumBiometricMethodAvailable, Omit<CoreSettingsHandlerData, 'toggle' | 'toggleChecked'>> = {
            biometric: {
                title: 'freemium.settingsoptionsfingerprint',
                icon: 'finger-print',
            },
            finger: {
                title: 'freemium.settingsoptionsfingerprint',
                icon: 'finger-print',
            },
            face: {
                title: 'freemium.settingsoptionsfacial',
                icon: 'moodle-face-id',
            },
        };
        const option = options[this.availableMethod];

        if (!option) {
            throw new CoreError('Unknown biometric method.');
        }

        return {
            ...option,
            toggleChecked: !!this.availableMethod && !!this.enabledByUser,
            toggle: async (checked: boolean) => await FreemiumBiometricLogin.setUserEnabledBiometricLogin(checked),
        };
    }

    /**
     * @inheritdoc
     */
    ngOnDestroy(): void {
        this.loginChangedObserver?.off();
    }

}

export const FreemiumBiometricLoginSettingsHandler = makeSingleton(FreemiumBiometricLoginSettingsHandlerService);
