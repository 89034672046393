<ion-header class="no-title">
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button fill="clear" (click)="closeModal()" [attr.aria-label]="'core.close' | translate">
                <ion-icon slot="icon-only" name="fas-times" aria-hidden="true" />
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <div class="workplace-program-thumb" *ngIf="program.image">
        <img [url]="program.image" core-external-content alt="" />
    </div>
    <div class="program-container" [class.thumb-shown]="program.image">
        <ion-card class="wp-whatisaprogram" *ngIf="!dismissed">
            <ion-item class="ion-text-wrap">
                <ion-label>
                    <p class="item-heading">{{'workplace.programhelptitle' | translate}}</p>
                    <div [innerHTML]="'workplace.programhelptext' | translate"></div>
                    <div class="wp-whatisaprogram-buttons">
                        <a href="https://docs.moodle.org/en_wp/Programs" target="_blank">{{'workplace.moreinfo' | translate}}</a>
                        <ion-button color="dark" fill="outline" class="ion-text-wrap" (click)="dismiss($event)">
                            {{'workplace.dontshowagain' | translate}}
                        </ion-button>
                    </div>
                </ion-label>
            </ion-item>
        </ion-card>

        <ion-item class="ion-text-wrap program-name">
            <ion-label>
                <h1>
                    <core-format-text [text]="program.fullname" />
                </h1>
            </ion-label>
        </ion-item>
        <ion-list *ngIf="program.description || program.tags?.length">
            <ion-item button class="ion-text-wrap expandable-item" (click)="toggle('description')"
                [attr.aria-label]="(collapsed['description'] ? 'core.expand' : 'core.collapse') | translate" [detail]="false">
                <ion-icon name="fas-chevron-right" flip-rtl slot="start" aria-hidden="true" class="expandable-status-icon"
                    [class.expandable-status-icon-expanded]="!collapsed['description']" />
                <ion-label>
                    <p class="item-heading">
                        {{'workplace.aboutthisprogram' | translate}}
                    </p>
                </ion-label>
            </ion-item>
            <ion-item class="ion-text-wrap" [detail]="false" *ngIf="program.description && !collapsed['description']">
                <ion-label>
                    <core-format-text [text]="program.description" />
                </ion-label>
            </ion-item>
            <ion-item class="ion-text-wrap" [detail]="false" *ngIf="program.tags?.length && !collapsed['description']">
                <ion-label>
                    <p class="item-heading">
                        <strong>{{'core.tag.tags' | translate}}</strong>:
                        <ion-badge color="dark" *ngFor="let tag of program.tags" class="square">{{tag}}</ion-badge>
                    </p>
                </ion-label>
            </ion-item>
            <core-spacer />
        </ion-list>

        <ion-list>
            <ion-item button class="ion-text-wrap expandable-item" (click)="toggle('dates')"
                [attr.aria-label]="(collapsed['dates'] ? 'core.expand' : 'core.collapse') | translate" [detail]="false">
                <ion-icon name="fas-chevron-right" flip-rtl slot="start" aria-hidden="true" class="expandable-status-icon"
                    [class.expandable-status-icon-expanded]="!collapsed['dates']" />
                <ion-label>
                    <p class="item-heading">
                        {{'workplace.dates' | translate}}
                    </p>
                </ion-label>
            </ion-item>
            <ion-item class="ion-text-wrap" [detail]="false" *ngIf="!collapsed['dates']">
                <ion-label>
                    <p>
                        <strong>{{ 'workplace.startdate' | translate }}: </strong>
                        {{ program.startdate ? (program.startdate * 1000 | coreFormatDate: 'strftimedatefullshort') :
                        'workplace.notset' | translate }}
                    </p>
                    <p *ngIf="program.duedate">
                        <strong>{{ 'workplace.duedate' | translate }}: </strong>
                        {{ program.duedate * 1000 | coreFormatDate: 'strftimedatefullshort' }}
                        <ion-badge *ngIf="program.duedatebadgestr" [color]="program.duedatebadgetype">
                            {{ program.duedatebadgestr }}
                        </ion-badge>
                    </p>
                    <p>
                        <strong>{{ 'workplace.enddate' | translate }}: </strong>
                        {{ program.enddate ? (program.enddate * 1000 | coreFormatDate: 'strftimedatefullshort') :
                        'workplace.notset' | translate }}
                    </p>
                </ion-label>
            </ion-item>
            <core-spacer />
        </ion-list>

        <ion-list *ngIf="program.certifications && program.certifications.length">
            <ion-item button class="ion-text-wrap expandable-item" (click)="toggle('certifications')"
                [attr.aria-label]="(collapsed['certifications'] ? 'core.expand' : 'core.collapse') | translate" [detail]="false">
                <ion-icon name="fas-chevron-right" flip-rtl slot="start" aria-hidden="true" class="expandable-status-icon"
                    [class.expandable-status-icon-expanded]="!collapsed['certifications']" />
                <ion-label>
                    <p class="item-heading">
                        {{'workplace.certifications' | translate}}
                    </p>
                </ion-label>
            </ion-item>
            <ng-container *ngIf="!collapsed['certifications']">
                <ion-card *ngFor="let message of program?.certifications" class="core-{{message.type}}-card">
                    <ion-item class="ion-text-wrap">
                        <ion-icon name="wp-certificate" slot="start" aria-hidden="true" />
                        <ion-label>
                            <p>
                                <core-format-text [text]="message.message" />
                            </p>
                        </ion-label>
                    </ion-item>
                </ion-card>
            </ng-container>
            <core-spacer />
        </ion-list>

        <ion-list>
            <ion-item *ngIf="baseSet" button class="ion-text-wrap expandable-item" (click)="toggle('structure')"
                [attr.aria-label]="(collapsed['structure'] ? 'core.expand' : 'core.collapse') | translate" [detail]="false">
                <ion-icon name="fas-chevron-right" flip-rtl slot="start" aria-hidden="true" class="expandable-status-icon"
                    [class.expandable-status-icon-expanded]="!collapsed['structure']" />
                <ion-label>
                    <p class="item-heading">
                        {{'workplace.programstructure' | translate}}
                    </p>
                    <p class="workplace-criteria"> · {{baseSet.setcriteriastr}}

                        <span *ngIf="baseSet.progress !== null && baseSet.progress >= 0">
                            · {{ 'workplace.progress' | translate:{$a: baseSet.progress} }}
                        </span>
                    </p>
                </ion-label>
            </ion-item>
            <ng-container *ngIf="!collapsed['structure'] && structure">
                <ng-container *ngTemplateOutlet="tree; context: {item: structure}" />
            </ng-container>
            <core-spacer />
        </ion-list>
    </div>

    <div collapsible-footer *ngIf="firstEnter" slot="fixed">
        <ion-button (click)="closeModal()" class="ion-text-wrap" expand="block">
            {{'workplace.proceedtoprogram' | translate}}
        </ion-button>
    </div>
</ion-content>

<ng-template #tree let-item="item">
    <ion-list *ngIf="item.children">
        <ng-container *ngFor="let subSet of item.children">
            <ng-container *ngIf="subSet.isset">
                <ion-item button class="ion-text-wrap workplace-structure-set" (click)="toggle('structure-'+subSet.setid)"
                    [attr.aria-label]="(collapsed['structure-'+subSet.setid] ? 'core.expand' : 'core.collapse') | translate"
                    [detail]="false">
                    <ion-icon name="fas-chevron-right" flip-rtl slot="start" aria-hidden="true" class="expandable-status-icon"
                        [class.expandable-status-icon-expanded]="!collapsed['structure-'+subSet.setid]" />
                    <ion-icon name="wp-folder" slot="start" />
                    <ion-label>
                        <p class="item-heading">
                            {{subSet.fullname}}
                        </p>
                        <p class="workplace-criteria"> · {{subSet.setcriteriastr}}

                            <span *ngIf="subSet.progress !== null && subSet.progress >= 0">
                                · {{ 'workplace.progress' | translate:{$a: subSet.progress} }}
                            </span>
                        </p>
                    </ion-label>
                </ion-item>
                <ng-container *ngIf="!collapsed['structure-'+subSet.setid]">
                    <ng-container *ngTemplateOutlet="tree; context: {item: subSet}" />
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!subSet.isset">
                <ion-item class="ion-text-wrap workplace-structure-course" [detail]="false">
                    <ion-label>
                        <p class="item-heading">
                            {{subSet.fullname}}
                        </p>
                    </ion-label>
                </ion-item>
            </ng-container>
        </ng-container>
    </ion-list>
</ng-template>
