<section class="addon-qtype-match-container" *ngIf="question && question.loaded">
    <ion-item class="ion-text-wrap question-text">
        <ion-label>
            <core-format-text [component]="component" [componentId]="componentId" [text]="question.text" [contextLevel]="contextLevel"
                [contextInstanceId]="contextInstanceId" [courseId]="courseId" />
        </ion-label>
    </ion-item>
    <ion-item class="ion-text-wrap question-rows" *ngFor="let row of question.rows">

        <ion-select id="{{row.id}}" [name]="row.name" [(ngModel)]="row.selected" interface="action-sheet" [disabled]="row.disabled"
            [cancelText]="'core.cancel' | translate"
            [ngClass]="{'addon-qtype-match-correct': row.isCorrect === 1,'addon-qtype-match-incorrect': row.isCorrect === 0}">
            <div slot="label" class="flex-row ion-text-wrap">
                <div>
                    <core-format-text [component]="component" [componentId]="componentId" [text]="row.text" [contextLevel]="contextLevel"
                        [contextInstanceId]="contextInstanceId" [courseId]="courseId" />
                </div>
                <label class="accesshide" for="{{row.id}}" *ngIf="row.accessibilityLabel">
                    {{ row.accessibilityLabel }}
                </label>
            </div>
            <ion-icon *ngIf="row.isCorrect === 1" class="core-correct-icon" name="fas-check" color="success" slot="start"
                [attr.aria-label]="'core.question.correct' | translate" />
            <ion-icon *ngIf="row.isCorrect === 0" class="core-correct-icon" name="fas-xmark" color="danger" slot="start"
                [attr.aria-label]="'core.question.incorrect' | translate" />
            <ion-select-option *ngFor="let option of row.options" [value]="option.value">
                {{option.label}}
            </ion-select-option>
        </ion-select>
    </ion-item>
</section>
