// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';

import {
    CoreCustomURLSchemesProvider,
    CoreCustomURLSchemesParams,
    CoreCustomURLSchemesHandleError,
} from '@/core/services/urlschemes';
import { CoreWS, CoreWSExternalWarning } from '@/core/services/ws';

import { FreemiumSites } from '@freemium/overrides/core/services/sites';
import { FreemiumFeature } from '@freemium/constants';
import { FreemiumError } from '@freemium/classes/error';
import { CoreUrlUtils } from '@services/utils/url';
import { Translate } from '@singletons';

/**
 * Override URL schemes provider to add functionality specific to freemium.
 */
@Injectable({ providedIn: 'root' })
export class FreemiumCustomURLSchemesProvider extends CoreCustomURLSchemesProvider {

    /**
     * @inheritdoc
     */
    protected async getCustomURLData(url: string): Promise<CoreCustomURLSchemesParams> {
        if (!this.isQRLoginURL(url)) {
            return super.getCustomURLData(url);
        }

        // Handle SSO via a QR code.
        url = this.removeCustomURLScheme(url);

        // First of all, check if the subscription allows QR login.
        const siteUrl = url.substring(0, url.indexOf('?'));
        let isEnabled: boolean;
        const urlParams = CoreUrlUtils.extractUrlParams(url);

        try {
            const subscription = await FreemiumSites.getSubscriptionForSiteUrl(siteUrl);

            isEnabled = subscription.isFeatureEnabled(FreemiumFeature.QRLogin) || urlParams.enabled == '1';
        } catch (err) {
            // Allow using it if couldn't retrieve the subscription.
            isEnabled = true;
        }

        try {
            if (!isEnabled) {
                const errorMessage = Translate.instant('freemium.qrlogindisabled', { url: siteUrl });

                throw new FreemiumError(errorMessage, false);
            }

            const params: ToolMobileGetTokensForQrLoginWSParams = {
                qrloginkey: urlParams.qrlogin,
                userid: Number(urlParams.userid),
            };

            const result = await CoreWS.callAjax<ToolMobileGetTokensForQrLoginWSResponse>(
                'tool_mobile_get_tokens_for_qr_login',
                params,
                { siteUrl: siteUrl, noLogin: true },
            );

            return {
                siteUrl: siteUrl,
                token: result.token,
                privateToken: result.privatetoken,
                isAuthenticationURL: true,
            };
        } catch (error) {
            if (error) {
                // Make some error messages more specific.
                if (error.errorcode == 'ipmismatch') {
                    error.message = Translate.instant('freemium.qrloginipmismatch');
                } else if (error.errorcode == 'expiredkey' || error.errorcode == 'invalidkey') {
                    error.message = Translate.instant('freemium.qrloginexpiredkey');
                }
            }

            throw new CoreCustomURLSchemesHandleError(error, {
                siteUrl: siteUrl,
                isAuthenticationURL: true,
            });
        }
    }

    /**
     * Check if a custom URL is a QRLogin URL.
     *
     * @param url URL to check.
     * @returns Whether it's a QRLogin URL.
     */
    protected isQRLoginURL(url: string): boolean {
        if (!this.isCustomURL(url)) {
            return false;
        }

        const params = CoreUrlUtils.extractUrlParams(url);

        return typeof params.qrlogin != 'undefined';
    }

}

/**
 * Params of tool_mobile_get_tokens_for_qr_login WS.
 */
export type ToolMobileGetTokensForQrLoginWSParams = {
    qrloginkey: string; // The user key for validating the request.
    userid: number; // The user the key belongs to.
};

/**
 * Data returned by tool_mobile_get_tokens_for_qr_login WS.
 */
export type ToolMobileGetTokensForQrLoginWSResponse = {
    token: string; // A valid WebService token for the official mobile app service.
    privatetoken: string; // Private token used for auto-login processes.
    warnings?: CoreWSExternalWarning[];
};
