<ion-card [attr.course-color]="course.color ? null : course.colorNumber">
    <div (click)="openCourse(course)" class="core-course-thumb" [class.core-course-color-img]="course.courseImage"
        [style.background-color]="course.color">
        <img *ngIf="course.courseImage" [url]="course.courseImage" core-external-content alt="" />
        <ion-icon *ngIf="!course.courseImage" name="fas-graduation-cap" class="course-icon" aria-hidden="true" />
    </div>
    <ion-item button lines="none" (click)="openCourse(course)" class="core-course-header"
        [attr.aria-label]="course.displayname || course.fullname" [class.item-disabled]="course.visible === 0"
        [class.core-course-only-title]="course.progress! < 0 && course.completionusertracked === false" [detail]="false">
        <ion-label class="ion-text-wrap core-course-title" [class.core-course-with-buttons]="downloadCourseEnabled && showDownload"
            [class.core-course-with-spinner]="(downloadCourseEnabled && prefetchCourseData.icon === 'spinner') || showSpinner">
            <p class="addon-programsoverview-type">
                {{ 'workplace.course' | translate }}
                <span *ngIf="course.categoryname" class="addon-programsoverview-category">
                    <core-format-text [text]="course.categoryname" />
                </span>
            </p>
            <p class="item-heading">
                <core-format-text [text]="course.fullname" />
            </p>
        </ion-label>

        <div class="core-button-spinner" *ngIf="downloadCourseEnabled && showDownload" slot="end">
            <!-- Download course. -->
            <core-download-refresh [status]="prefetchCourseData.status" [enabled]="downloadCourseEnabled"
                [statusTranslatable]="prefetchCourseData.statusTranslatable" [canTrustDownload]="false"
                [loading]="prefetchCourseData.loading" (action)="prefetchCourse($event)" />
        </div>
    </ion-item>
    <ion-item *ngIf="course.progress !== null && course.progress >= 0" class="addon-programsoverview-more core-course-progress"
        lines="none">
        <ion-label>{{ course.progress }}% {{ 'workplace.completed' | translate }}</ion-label>
    </ion-item>
    <ion-item class="addon-programsoverview-actions" *ngIf="course.progress !== null && course.progress >= 0" lines="none">
        <ion-label>
            <ion-button expand="block" (click)="openCourse(course)">
                <ng-container *ngIf="course.progress === 0">{{ 'workplace.start' | translate }}</ng-container>
                <ng-container *ngIf="course.progress > 0 && course.progress < 100">{{ 'workplace.continue' | translate }}</ng-container>
                <ng-container *ngIf="course.progress >= 100">{{ 'workplace.review' | translate }}</ng-container>
            </ion-button>
        </ion-label>
    </ion-item>
</ion-card>
