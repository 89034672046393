<ion-item-divider [sticky]="true">
    <ion-label>
        <h2>{{ 'addon.block_timeline.pluginname' | translate }}</h2>
    </ion-label>
</ion-item-divider>
<core-loading [hideUntil]="loaded">
    <ion-row class="ion-hide-md-up addon-block-timeline-filter" *ngIf="(search$ | async) !== null">
        <ion-col>
            <!-- Filter courses. -->
            <core-search-box (onSubmit)="searchChanged($event)" (onClear)="searchChanged('')"
                [placeholder]="'addon.block_timeline.searchevents' | translate" autocorrect="off" spellcheck="false" [lengthCheck]="2"
                searchArea="AddonBlockTimeline" />
        </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-between ion-align-items-center addon-block-timeline-filter">
        <ion-col size="auto">
            <core-combobox [formControl]="filter" (onChange)="filterChanged($event)"
                [label]="'addon.block_timeline.ariadayfilter' | translate">
                <ion-select-option *ngFor="let option of statusFilterOptions; last as last"
                    [attr.class]="'ion-text-wrap' + last ? ' core-select-option-border-bottom' : ''" [value]="option.value">
                    {{ option.name | translate }}
                </ion-select-option>
                <ion-select-option class="ion-text-wrap core-select-option-title" disabled value="disabled">
                    {{ 'addon.block_timeline.duedate' | translate }}
                </ion-select-option>
                <ion-select-option *ngFor="let option of dateFilterOptions" class="ion-text-wrap" [value]="option.value">
                    {{ option.name | translate }}
                </ion-select-option>
            </core-combobox>
        </ion-col>
        <ion-col class="ion-hide-md-down" *ngIf="(search$ | async) !== null">
            <!-- Filter courses. -->
            <core-search-box (onSubmit)="searchChanged($event)" (onClear)="searchChanged('')"
                [placeholder]="'addon.block_timeline.searchevents' | translate" autocorrect="off" spellcheck="false" [lengthCheck]="2"
                searchArea="AddonBlockTimeline" />
        </ion-col>
        <ion-col size="auto">
            <core-combobox [label]="'core.sortby' | translate" [formControl]="sort" (onChange)="sortChanged($event)"
                icon="fas-arrow-down-short-wide" class="no-border">
                <ion-select-option *ngFor="let option of sortOptions" class="ion-text-wrap" [value]="option.value">
                    {{ option.name | translate }}
                </ion-select-option>
            </core-combobox>
        </ion-col>
    </ion-row>
    <ng-container *ngIf="sections$ | async as sections">
        <ng-container *ngFor="let section of sections">
            <addon-block-timeline-events *ngIf="section.data$ | async as data" [events]="data.events"
                [showInlineCourse]="(sort$ | async) === AddonBlockTimelineSort.ByDates" [canLoadMore]="data.canLoadMore"
                [loadingMore]="data.loadingMore" (loadMore)="section.loadMore()" [course]="section.course" />
        </ng-container>
        <core-empty-box *ngIf="sections && sections.length === 0" image="assets/img/icons/courses.svg"
            [message]="'addon.block_timeline.noevents' | translate" />
    </ng-container>
</core-loading>
