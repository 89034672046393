<!-- Render (no edit). -->
<ion-item *ngIf="!edit && field && field.name">
    <ion-label>
        <p class="item-heading">
            <core-format-text [text]="field.name" [contextLevel]="contextLevel" [contextInstanceId]="contextInstanceId"
                [courseId]="courseId" [wsNotFiltered]="true" />
        </p>
        <p>
            <core-format-text [text]="value" [contextLevel]="contextLevel" [contextInstanceId]="contextInstanceId" [courseId]="courseId"
                [wsNotFiltered]="valueNotFiltered" />
        </p>
    </ion-label>
</ion-item>

<!-- Edit. -->
<ion-item *ngIf="edit && field && field.shortname" class="ion-text-wrap" [formGroup]="form">
    <ion-label position="stacked">
        <span [core-mark-required]="required">
            <core-format-text [text]="field.name" [contextLevel]="contextLevel" [contextInstanceId]="contextInstanceId"
                [courseId]="courseId" [wsNotFiltered]="true" />
        </span>
    </ion-label>
    <core-rich-text-editor [control]="control" [placeholder]="field.name" [autoSave]="true" [contextLevel]="contextLevel"
        [contextInstanceId]="contextInstanceId" [elementId]="modelName" />
    <core-input-errors [control]="control" />
</ion-item>
