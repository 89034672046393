// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { CoreSharedModule } from '@/core/shared.module';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CoreSettingsDelegate } from '@features/settings/services/settings-delegate';
import { USER_BIOMETRIC_LOGIN_CHANGED } from '@freemium/services/biometric-login';
import { CoreEvents } from '@singletons/events';
import { FreemiumBiometricLoginModalComponent } from './components/modal/modal';
import { FreemiumBiometricLoginSettingsHandler } from './services/handlers/settings';

@NgModule({
    imports: [CoreSharedModule],
    declarations: [FreemiumBiometricLoginModalComponent],
    providers: [
        {
            provide: APP_INITIALIZER,
            multi: true,
            useValue: () => {
                CoreSettingsDelegate.registerHandler(FreemiumBiometricLoginSettingsHandler.instance);

                CoreEvents.on(USER_BIOMETRIC_LOGIN_CHANGED, () => {
                    CoreSettingsDelegate.updateHandlers();
                });
            },
        },
    ],
    exports: [FreemiumBiometricLoginModalComponent],
})
export class FreemiumBiometricLoginModule {}
