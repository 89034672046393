// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { AlertButton } from '@ionic/core';
import { Injectable } from '@angular/core';

import { CoreDomUtilsProvider } from '@/core/services/utils/dom';

import { FreemiumError } from '@freemium/classes/error';
import { AlertController, Translate } from '@singletons';
import { CoreNavigator } from '@services/navigator';
import { CoreAnyError } from '@classes/errors/error';

/**
 * Override dom utils provider to add functionality specific to freemium.
 */
@Injectable({ providedIn: 'root' })
export class FreemiumDomUtilsProvider extends CoreDomUtilsProvider {

    /**
     * @inheritdoc
     */
    async showErrorModalDefault(
        error: CoreAnyError,
        defaultError: string,
        needsTranslate = false,
        autocloseTime?: number,
    ): Promise<HTMLIonAlertElement | null> {
        if (!(error instanceof FreemiumError)) {
            return super.showErrorModalDefault(error, defaultError, needsTranslate, autocloseTime);
        }

        const buttons: AlertButton[] = [];

        if (error.showLearnMore) {
            buttons.push({
                text: Translate.instant('freemium.subscriptionplanlearnmore'),
                handler: () => CoreNavigator.navigateToSitePath('subscription'),
            });
        }

        buttons.push({ text: Translate.instant('core.ok') });

        const alert = await AlertController.create({
            header: Translate.instant('freemium.notallowed'),
            message: error.message,
            buttons: buttons,
        });

        await alert.present();

        if (autocloseTime && autocloseTime > 0) {
            setTimeout(() => alert.dismiss(), autocloseTime);
        }

        return alert;
    }

}
