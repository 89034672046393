<!-- Render (no edit). -->
<ion-item *ngIf="!edit && field && field.name">
    <ion-label>
        <p class="item-heading">
            <core-format-text [text]="field.name" [contextLevel]="contextLevel" [contextInstanceId]="contextInstanceId"
                [courseId]="courseId" [wsNotFiltered]="true" />
        </p>
        <p>
            <core-format-text [text]="value" [contextLevel]="contextLevel" [contextInstanceId]="contextInstanceId" [courseId]="courseId"
                [wsNotFiltered]="valueNotFiltered" />
        </p>
    </ion-label>
</ion-item>

<!-- Edit. -->
<ion-item *ngIf="edit && field && field.shortname && form" class="ion-text-wrap" [formGroup]="form">
    <ion-select labelPlacement="stacked" [formControlName]="modelName" [placeholder]="'core.choosedots' | translate"
        interface="action-sheet" [cancelText]="'core.cancel' | translate" [interfaceOptions]="{header: field.name}">
        <div [core-mark-required]="required" slot="label">
            <core-format-text [text]="field.name" [contextLevel]="contextLevel" [contextInstanceId]="contextInstanceId"
                [courseId]="courseId" [wsNotFiltered]="true" />
        </div>
        <ion-select-option value="">{{ 'core.choosedots' | translate }}</ion-select-option>
        <ion-select-option *ngFor="let option of options" [value]="option">{{option}}</ion-select-option>
    </ion-select>
    <core-input-errors [control]="form.controls[modelName]" />
</ion-item>
