// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { WorkplaceToolCatalogueCourseOnSetData } from '@workplace/services/workplace';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { WorkplaceHelper } from '@workplace/services/workplace-helper';
import { CoreNavigator } from '@services/navigator';
import { CoreCourseHelper, CorePrefetchStatusInfo } from '@features/course/services/course-helper';
import { CoreDomUtils } from '@services/utils/dom';
import { CoreEventObserver, CoreEvents } from '@singletons/events';
import { CoreSites } from '@services/sites';
import { CoreConstants, DownloadStatus } from '@/core/constants';
import { CoreCourse } from '@features/course/services/course';
import { CoreCourses } from '@features/courses/services/courses';

/**
 * This component is meant to display a course card on the program navigation.
 *
 * Example usage:
 *
 * <workplace-program-course-card [course]="course">
 * </workplace-program-course-card>
 */
@Component({
    selector: 'workplace-program-course-card',
    templateUrl: 'course-card.html',
    styleUrls: ['../../program.scss'],
})
export class WorkplaceProgramCourseCardComponent implements OnChanges, OnDestroy, OnInit {

    @Input() course!: WorkplaceToolCatalogueCourseOnSetData & {
        dataCourseImage?: boolean;
    }; // The course to render.

    @Input() programId!: number;

    downloadCourseEnabled = false;
    prefetchCourseData: CorePrefetchStatusInfo = {
        icon: '',
        statusTranslatable: 'core.loading',
        status: DownloadStatus.DOWNLOADABLE_NOT_DOWNLOADED,
        loading: true,
    };

    protected isDestroyed = false;
    protected courseStatusObserver?: CoreEventObserver;

    /**
     * @inheritdoc
     */
    async ngOnInit(): Promise<void> {
        this.downloadCourseEnabled = !CoreCourses.isDownloadCourseDisabledInSite();

        if (this.downloadCourseEnabled) {
            await this.initPrefetchCourse();
        }
    }

    /**
     * @inheritdoc
     */
    ngOnChanges(): void {
        if (!this.course) {
            return;
        }

        this.course.dataCourseImage = this.course.image?.startsWith('data');
    }

    /**
     * Open the course.
     */
    async openCourse(): Promise<void> {
        if (this.course.islocked) {
            const { course } = await CoreCourseHelper.getCourse(this.course.courseid);
            CoreNavigator.navigateToSitePath(
                `/course/${this.course.courseid}/summary`,
                { params: { course: course } },
            );

            return;
        }
        WorkplaceHelper.enrolAndEnterCourse(this.course, this.programId);
    }

    /**
     * Prefetch the course.
     */
    async prefetchCourse(): Promise<void> {
        const { course } = await CoreCourseHelper.getCourse(this.course.courseid);

        CoreCourseHelper.confirmAndPrefetchCourse(this.prefetchCourseData, course).catch((error) => {
            if (!this.isDestroyed) {
                CoreDomUtils.showErrorModalDefault(error, 'core.course.errordownloadingcourse', true);
            }
        });
    }

    /**
     * Init prefetch course.
     */
    async initPrefetchCourse(): Promise<void> {
        if (this.courseStatusObserver !== undefined) {
            // Already initialized.
            return;
        }

        // Listen for status change in course.
        this.courseStatusObserver = CoreEvents.on(CoreEvents.COURSE_STATUS_CHANGED, (data) => {
            if (data.courseId === this.course.courseid) {
                this.updateCourseStatus(data.status);
            }
        }, CoreSites.getCurrentSiteId());

        // Determine course prefetch icon.
        const statusData = await CoreCourseHelper.getCourseStatusIconAndTitle(this.course.courseid);

        this.prefetchCourseData.status = statusData.status;
        this.prefetchCourseData.icon = statusData.icon;
        this.prefetchCourseData.statusTranslatable = statusData.statusTranslatable;
        this.prefetchCourseData.loading = statusData.loading;

        if (statusData.icon !== CoreConstants.ICON_LOADING) {
            return;
        }
        // Course is being downloaded. Get the download promise.
        const promise = CoreCourseHelper.getCourseDownloadPromise(this.course.courseid);

        if (!promise) {
            // No download, this probably means that the app was closed while downloading. Set previous status.
            CoreCourse.setCoursePreviousStatus(this.course.courseid);

            return;
        }

        // There is a download promise. If it fails, show an error.
        promise.catch((error) => {
            if (this.isDestroyed) {
                return;
            }

            CoreDomUtils.showErrorModalDefault(error, 'core.course.errordownloadingcourse', true);
        });
    }

    /**
     * Update the course status icon and title.
     *
     * @param status Status to show.
     */
    protected updateCourseStatus(status: DownloadStatus): void {
        const statusData = CoreCourseHelper.getCoursePrefetchStatusInfo(status);

        this.prefetchCourseData.status = statusData.status;
        this.prefetchCourseData.icon = statusData.icon;
        this.prefetchCourseData.statusTranslatable = statusData.statusTranslatable;
        this.prefetchCourseData.loading = statusData.loading;
    }

    /**
     * @inheritdoc
     */
    ngOnDestroy(): void {
        this.isDestroyed = true;
    }

}
