// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { SQLiteDB, SQLiteDBTableSchema } from '@classes/sqlitedb';
import { CoreAppSchema } from '@services/app';
import { CoreSiteSchema } from '@services/sites';

/**
 * Migrate datatabase.
 *
 * @param db Database.
 * @param oldVersion Previous version number.
 */
async function migrateDb(db: SQLiteDB, oldVersion: number): Promise<void> {
    if (oldVersion < 2) {
        // Delete old table.
        try {
            await db.dropTable('freemium_storage');
        } catch (err) {
            // Old table does not exist, ignore.
        }
    }
}

export const TABLE_NAME = 'freemium_storage_2';

export const TABLE_SCHEMA: SQLiteDBTableSchema = {
    name: TABLE_NAME,
    columns: [
        {
            name: 'key',
            type: 'TEXT',
            primaryKey: true,
        },
        {
            name: 'value',
            type: 'TEXT',
            notNull: true,
        },
    ],
};

export const APP_SCHEMA: CoreAppSchema = {
    name: 'FreemiumStorageService',
    version: 2,
    tables: [TABLE_SCHEMA],
    migrate: (db: SQLiteDB, oldVersion: number): Promise<void> => migrateDb(db, oldVersion),
};

export const SITE_SCHEMA: CoreSiteSchema = {
    name: 'FreemiumStorageService',
    version: 2,
    tables: [TABLE_SCHEMA],
    migrate: (db: SQLiteDB, oldVersion: number): Promise<void> | void => migrateDb(db, oldVersion),
};

/**
 * Storage table record type.
 */
export type FreemiumStorageRecord = {
    key: string;
    value: string;
};
