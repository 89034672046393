// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Component, OnInit, OnDestroy } from '@angular/core';
import { CoreEventObserver, CoreEvents } from '@singletons/events';
import { CoreSites } from '@services/sites';
import {
    CoreCoursesProvider,
    CoreCoursesMyCoursesUpdatedEventData,
} from '@features/courses/services/courses';
import { CoreBlockBaseComponent } from '@features/block/classes/base-block-component';
import { CoreUtils } from '@services/utils/utils';
import { CoreSite } from '@classes/sites/site';
import { WorkplaceMyInProgress, WorkplaceMyInProgressCourse } from '../../services/myinprogress';
import {
    CoreCourseWithImageAndColor,
} from '@features/courses/services/courses-helper';
import { CoreDomUtils } from '@services/utils/dom';
import { WorkplaceMyInProgressCourseOptionsMenuComponent } from '../course-options-menu/course-options-menu';

/**
 * Component to render a my in progress courses block.
 */
@Component({
    selector: 'workplace-myinprogress',
    templateUrl: 'myinprogress.html',
    styleUrls: ['myinprogress.scss'],
})
export class WorkplaceMyInProgressComponent extends CoreBlockBaseComponent implements OnInit, OnDestroy {

    courses: WorkplaceMyInProgressCourseToRender[] = [];
    showHidden = false;
    scrollElementId!: string;
    showSpinner = false;
    hiddenCoursesIds: number[] = [];

    protected site: CoreSite;
    protected isDestroyed = false;
    protected coursesObserver?: CoreEventObserver;
    protected fetchContentDefaultError = 'Error getting new available courses data.';

    constructor() {
        super('WorkplaceMyInProgressComponent');

        this.site = CoreSites.getRequiredCurrentSite();
    }

    /**
     * @inheritdoc
     */
    async ngOnInit(): Promise<void> {
        // Generate unique id for scroll element.
        const scrollId = CoreUtils.getUniqueId('WorkplaceMyInProgressComponent-Scroll');

        this.scrollElementId = `workplace-myinprogress-scroll-${scrollId}`;

        this.coursesObserver = CoreEvents.on(
            CoreCoursesProvider.EVENT_MY_COURSES_UPDATED,
            (data) => {
                this.refreshCourseList(data);
            },
            this.site.getId(),
        );

        super.ngOnInit();
    }

    /**
     * @inheritdoc
     */
    async invalidateContent(): Promise<void> {
        await Promise.all([
            WorkplaceMyInProgress.invalidateMyInProgressCourses(),
            WorkplaceMyInProgress.invalidateHiddenCourses(),
        ]);
    }

    /**
     * @inheritdoc
     */
    protected async fetchContent(): Promise<void> {
        const courses = await WorkplaceMyInProgress.getMyInProgressCourses();

        this.courses = courses.map((course) => Object.assign(course, {
            courseImage: course.courseimage,
            hiddeninblock: false,
        }));

        if (this.courses.length) {
            this.hiddenCoursesIds = await WorkplaceMyInProgress.getHiddenCourses();

            this.hiddenCoursesIds.forEach((id) => {
                const course = this.courses.find((course) => course.id === id);
                if (course) {
                    course.hiddeninblock = true;
                }
            });
        } else {
            this.hiddenCoursesIds = [];
        }
    }

    /**
     * Refresh course list based on a EVENT_MY_COURSES_UPDATED event.
     *
     * @param data Event data.
     * @returns Promise resolved when done.
     */
    protected async refreshCourseList(data: CoreCoursesMyCoursesUpdatedEventData): Promise<void> {
        if (data.action != CoreCoursesProvider.ACTION_ENROL) {
            return;
        }
        const courseIndex = this.courses.findIndex((course) => course.id == data.courseId);
        if (courseIndex < 0) {
            return;
        }

        // Remove it.
        this.courses.splice(courseIndex, 1);

        await this.invalidateContent();
    }

    /**
     * Show the context menu.
     *
     * @param event Click Event.
     */
    async showCourseOptionsMenu(event: Event, course: WorkplaceMyInProgressCourseToRender): Promise<void> {
        event.preventDefault();
        event.stopPropagation();

        const popoverData = await CoreDomUtils.openPopover<string>({
            component: WorkplaceMyInProgressCourseOptionsMenuComponent,
            componentProps: {
                hidden: course.hiddeninblock,
            },
            event: event,
        });

        switch (popoverData) {
            case 'hide':
                this.setCourseHidden(course, true);
                break;
            case 'show':
                this.setCourseHidden(course, false);
                break;
            default:
                break;
        }

    }

    /**
     * Hide/Unhide the course from the course list.
     *
     * @param course Course to hide/unhide.
     * @param hide True to hide and false to show.
     */
    protected async setCourseHidden(course: WorkplaceMyInProgressCourseToRender, hide: boolean): Promise<void> {
        this.showSpinner = true;

        try {
            if (hide) {
                this.hiddenCoursesIds.push(course.id);
            } else {
                const index = this.hiddenCoursesIds.findIndex((courseId) => courseId === course.id);
                this.hiddenCoursesIds.splice(index, 1);
            }
            this.hiddenCoursesIds = await WorkplaceMyInProgress.setHiddenCourses(this.hiddenCoursesIds);

            course.hiddeninblock = hide;
        } catch (error) {
            if (!this.isDestroyed) {
                CoreDomUtils.showErrorModalDefault(error, 'Error changing course visibility.');
            }
        } finally {
            this.showSpinner = false;
        }
    }

    /**
     * @inheritdoc
     */
    ngOnDestroy(): void {
        this.isDestroyed = true;
        this.coursesObserver?.off();
    }

}

type WorkplaceMyInProgressCourseToRender = WorkplaceMyInProgressCourse & CoreCourseWithImageAndColor & {
    hiddeninblock?: boolean;
};
