// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';

import { CoreSiteInfoCronHandlerService } from '@/core/services/handlers/site-info-cron';

import { FreemiumSites } from '@freemium/overrides/core/services/sites';
import { CoreSites } from '@services/sites';
import { makeSingleton } from '@singletons';

/**
 * Override site info cron handler to add functionality specific to freemium.
 */
@Injectable({ providedIn: 'root' })
export class FreemiumSiteInfoCronHandlerService extends CoreSiteInfoCronHandlerService {

    /**
     * @inheritdoc
     */
    async execute(siteId?: string): Promise<void> {
        try {
            let siteIds: string[];

            if (!siteId) {
                siteIds = await CoreSites.getSitesIds();
            } else {
                siteIds = [siteId];
            }

            await Promise.all(siteIds.map(async (siteId) => {
                const site = await FreemiumSites.getSite(siteId);

                await site.removeCachedSubscription();
            }));
        } catch (err) {
            // Ignore errors.
        }

        return super.execute(siteId);
    }

}

export const FreemiumSiteInfoCronHandler = makeSingleton(FreemiumSiteInfoCronHandlerService);
