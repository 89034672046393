<div class="freemium-enable-biometric">
    <div class="freemium-enable-biometric__header">
        <ion-icon class="freemium-enable-biometric__header__image" [name]="LOGIN_METHODS[biometricMethod].icon" aria-hidden="true">
        </ion-icon>
        <h4 class="item-heading"> {{ LOGIN_METHODS[biometricMethod].title | translate }} </h4>
    </div>
    <div class="freemium-enable-biometric__body">
        <p> {{ 'freemium.biometricloginwithfingerorface' | translate }} </p>
        <p> {{ 'freemium.biometricloginturnoff' | translate }} </p>
    </div>
    <div class="freemium-enable-biometric__footer">
        <ion-button expand="full" (click)="enableBiometricLogin()">
            {{ LOGIN_METHODS[biometricMethod].button | translate }}
        </ion-button>
        <ion-button expand="full" fill="clear" class="primary-button" (click)="skip()">
            {{ 'freemium.biometricloginskip' | translate }}
        </ion-button>
    </div>
</div>
