<ion-header>
    <ion-toolbar>
        <ion-title>
            <h1>{{ 'workplace.teams.filterstab' | translate }}</h1>
        </ion-title>
        <ion-buttons slot="end">
            <ion-button fill="clear" (click)="closeModal()" [attr.aria-label]="'core.close' | translate">
                <ion-icon name="fas-times" slot="icon-only" aria-hidden="true" />
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <core-loading [hideUntil]="loaded">
        <ion-list>
            <ion-item [lines]="filters.fullnametype === 0 ? 'full' : 'none'" *ngIf="availableFilters.fullname_filter">
                <ion-label>
                    <p class="item-heading">{{ 'workplace.teams.fullname' | translate }}</p>
                    <core-combobox [label]="'workplace.teams.fullname' | translate" [selection]="filters.fullnametype"
                        (onChange)="setFullnameType($event)">
                        <ion-select-option *ngFor="let filter of availableFilters.fullname_filter" [value]="filter.id">
                            {{'workplace.teams.' + filter.identifier | translate}}</ion-select-option>
                    </core-combobox>
                </ion-label>
            </ion-item>
            <ion-item lines="full" *ngIf="availableFilters.fullname_filter && filters.fullnametype !== 0">
                <ion-input [ariaLabel]="" type="fullname" [(ngModel)]="filters.fullname" name="fullname" />
            </ion-item>

            <ion-list lines="none" *ngIf="availableFilters.orgstructuretype_filter && orgFilterEnabled">
                <ion-radio-group [(ngModel)]="filters.orgstructuretype">
                    <ion-list-header>
                        <ion-label>
                            <p class="item-heading">{{ 'workplace.teams.orgstructure' | translate }}</p>
                        </ion-label>
                    </ion-list-header>
                    <ion-item class="ion-text-wrap" *ngFor="let filter of availableFilters.orgstructuretype_filter">
                        <ion-radio [value]="filter.id">
                            {{'workplace.teams.' + filter.identifier | translate}}
                        </ion-radio>
                    </ion-item>
                </ion-radio-group>
            </ion-list>

            <ion-item lines="none" *ngIf="availableFilters.departments &&
                ((orgFilterEnabled && filters.orgstructuretype === CUSTOM_ORG_TYPE) || !orgFilterEnabled)">
                <ion-label position="stacked">
                    <p class="item-heading">{{ 'workplace.teams.department' | translate }}</p>
                </ion-label>
                <core-combobox [modalOptions]="departmentModalOptions" listboxId="myteams-department-selector"
                    [label]="('workplace.teams.department' | translate)" (onChange)="setDepartment($event)" [selection]="selectedDepartment"
                    interface="modal" />
            </ion-item>
            <ion-item lines="none" class="ion-text-wrap" *ngIf="availableFilters.departments &&
                ((orgFilterEnabled && filters.orgstructuretype === CUSTOM_ORG_TYPE) || !orgFilterEnabled)">
                <ion-checkbox value="1" [(ngModel)]="filters.includesubdepts" labelPlacement="end" justify="start">
                    <p class="item-heading">{{ 'workplace.teams.withsubdepartments' | translate }}</p>
                </ion-checkbox>
            </ion-item>

            <ion-item lines="none" *ngIf="availableFilters.positions &&
                ((orgFilterEnabled && filters.orgstructuretype === CUSTOM_ORG_TYPE) || !orgFilterEnabled)">
                <ion-label position="stacked">
                    <p class="item-heading">{{ 'workplace.teams.position' | translate }}</p>
                </ion-label>
                <core-combobox [modalOptions]="positionModalOptions" listboxId="myteams-position-selector"
                    [label]="('workplace.teams.position' | translate)" (onChange)="setPosition($event)" [selection]="selectedPosition"
                    interface="modal" />
            </ion-item>
            <ion-item *ngIf="availableFilters.positions && ((orgFilterEnabled && filters.orgstructuretype === CUSTOM_ORG_TYPE)
                || !orgFilterEnabled)" lines="none" class="ion-text-wrap">
                <ion-checkbox value="1" [(ngModel)]="filters.includesubpos" labelPlacement="end" justify="start">
                    <p class="item-heading">{{ 'workplace.teams.withsubpositions' | translate }}</p>
                </ion-checkbox>
            </ion-item>
        </ion-list>

        <div class="ion-padding">
            <ion-button expand="block" (click)="filter()" color="primary">
                {{ 'workplace.teams.apply' | translate }}
            </ion-button>
            <ion-button expand="block" (click)="reset()" color="light">
                {{ 'workplace.teams.resetall' | translate }}
            </ion-button>
        </div>
    </core-loading>
</ion-content>
