<ion-item button class="ion-text-wrap" (click)="action('hide')" *ngIf="!hidden" [detail]="false">
    <ion-icon name="fas-eye" slot="start" aria-hidden="true" />
    <ion-label>
        <p class="item-heading">{{ 'workplace.myinprogress.hidefromview' | translate }}</p>
    </ion-label>
</ion-item>
<ion-item button class="ion-text-wrap" (click)="action('show')" *ngIf="hidden" [detail]="false">
    <ion-icon name="fas-eye-slash" slot="start" aria-hidden="true" />
    <ion-label>
        <p class="item-heading">{{ 'workplace.myinprogress.donthide' | translate }}</p>
    </ion-label>
</ion-item>
