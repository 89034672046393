<ion-item-divider [sticky]="true">
    <ion-label>
        <h2>{{ 'addon.block_sitemainmenu.pluginname' | translate }}</h2>
    </ion-label>
</ion-item-divider>
<core-loading [hideUntil]="loaded">
    <ion-list *ngIf="mainMenuBlock" class="core-course-module-list-wrapper">
        <ion-item class="ion-text-wrap" *ngIf="mainMenuBlock.summary">
            <ion-label>
                <core-format-text [text]="mainMenuBlock.summary" [component]="component" [componentId]="siteHomeId" contextLevel="course"
                    [contextInstanceId]="siteHomeId" />
            </ion-label>
        </ion-item>

        <core-course-module *ngFor="let module of mainMenuBlock.modules" [module]="module" [section]="mainMenuBlock" />
    </ion-list>
</core-loading>
