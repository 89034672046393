// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { SiteSubscription } from '@freemium/classes/site-subscription';
import { Constructor } from '@/core/utils/types';
import { CoreAuthenticatedSite } from '@classes/sites/authenticated-site';
import { applyFreemiumUnauthenticatedSiteCode, FreemiumUnauthenticatedSite, FreemiumSiteInfo } from './unauthenticated-site';

/**
 * Function to override a site class to add authenticated site freemium-specific code.
 *
 * @returns Class.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function applyFreemiumAuthenticatedSiteCode<TBase extends Constructor<CoreAuthenticatedSite & FreemiumUnauthenticatedSite>>(
    Base: TBase,
) {
    return class FreemiumAuthenticatedSite extends Base {

        infos?: FreemiumSiteInfo;

        /**
         * @inheritdoc
         */
        async fetchSiteInfo(): Promise<FreemiumSiteInfo> {
            const info: FreemiumSiteInfo = await super.fetchSiteInfo();

            try {
                const subscription = await this.getSubscription();

                info.subscription = subscription.toJSON();

                // Update subscription object.
                if (this._subscription) {
                    this._subscription = SiteSubscription.fromJSON(info.subscription);
                }
            } catch (error) {
                // If there was a problem obtaining the subscription, use the previous one
                if (this.hasInfo('subscription')) {
                    info.subscription = this.infos?.subscription;
                }
            }

            return info;
        }

    };
}

/**
 * Override the base authenticated site to add freemium-specific limitations.
 */
export class FreemiumAuthenticatedSite extends
    applyFreemiumAuthenticatedSiteCode(applyFreemiumUnauthenticatedSiteCode(CoreAuthenticatedSite)) {}
