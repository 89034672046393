// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';
import { CoreSiteWSPreSets } from '@classes/sites/authenticated-site';
import { CoreSites } from '@services/sites';
import { CoreWSExternalWarning } from '@services/ws';
import { makeSingleton } from '@singletons';
import { CoreCourseSummary } from '@features/course/services/course';
import { CoreUser } from '@features/user/services/user';
import { CoreTextUtils } from '@services/utils/text';

/**
 * Service that provides workplace my available block features.
 */
@Injectable({ providedIn: 'root' })
export class WorkplaceMyInProgressService {

    static readonly ROOT_CACHE_KEY = 'Workplace:MyInProgress';

    /**
     * Get cache key for get my available courses WS call.
     *
     * @returns Cache key.
     */
    protected getMyInProgressCoursesCacheKey(): string {
        return WorkplaceMyInProgressService.ROOT_CACHE_KEY;
    }

    /**
     * Check if block WS is available
     *
     * @param siteId Site ID. If not defined, use current site.
     * @returns Whether the webservice is available.
     * @since 4.0
     */
    async isWsAvailable(siteId?: string): Promise<boolean> {
        const site = await CoreSites.getSite(siteId);

        return site?.wsAvailable('block_myinprogress_get_inprogress_courses');
    }

    /**
     * Invalidates get my available courses WS call.
     *
     * @param siteId Site ID to invalidate. If not defined, use current site.
     * @returns Promise resolved when the data is invalidated.
     */
    async invalidateMyInProgressCourses(siteId?: string): Promise<void> {
        const site = await CoreSites.getSite(siteId);

        return site.invalidateWsCacheForKey(this.getMyInProgressCoursesCacheKey());
    }

    /**
     * Return the list of courses to be shown in the block.
     *
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved with the retrieved info.
     */
    async getMyInProgressCourses(siteId?: string): Promise<WorkplaceMyInProgressCourse[]> {
        const site = await CoreSites.getSite(siteId);

        const preSets: CoreSiteWSPreSets = {
            cacheKey: this.getMyInProgressCoursesCacheKey(),
        };

        const params: WorkplaceMyInprogressGetInprogressCoursesWSParams = {};

        const result =
            await site.read<WorkplaceMyInProgressGetInProgressCoursesWSResponse>(
                'block_myinprogress_get_inprogress_courses',
                params,
                preSets,
            );

        return result.data.courses || [];
    }

    /**
     * Get hidden courses Id's
     *
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved with the retrieved info.
     */
    async getHiddenCourses(siteId?: string): Promise<number[]> {
        const json = await CoreUser.getUserPreference('block_myinprogress_hidden_courses', siteId);

        if (!json) {
            return [];
        }

        return CoreTextUtils.parseJSON<string[]>(json, []).map((id) => parseInt(id, 10));
    }

    /**
     * Save hidden courses.
     *
     * @param courseIds Array of hidden course ids.
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved when done with new array.
     */
    async setHiddenCourses(courseIds: number[], siteId?: string): Promise<number[]> {
        courseIds = courseIds.filter((courseId, index) => courseIds.indexOf(courseId) === index);
        courseIds.sort((a, b) => a - b);

        const value = JSON.stringify(courseIds.map((id) => id + ''));

        await CoreUser.setUserPreference(
            'block_myinprogress_hidden_courses',
            value,
            siteId,
        );

        return courseIds;
    }

    /**
     * Invalidate hidden courses Id's
     *
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved when done.
     */
    async invalidateHiddenCourses(siteId?: string): Promise<void> {
        return CoreUser.invalidateUserPreference('block_myinprogress_hidden_courses', siteId);
    }

}

export const WorkplaceMyInProgress = makeSingleton(WorkplaceMyInProgressService);

/**
 * Params of block_myinprogress_get_inprogress_courses WS.
 */
type WorkplaceMyInprogressGetInprogressCoursesWSParams = {
    userid?: number; // The user id.
    onlywithcompletion?: boolean; // Return only enrolled courses with completion enabled.
};

/**
 * Data returned by block_myinprogress_get_inprogress_courses WS.
 */
type WorkplaceMyInProgressGetInProgressCoursesWSResponse = {
    data: {
        courses: WorkplaceMyInProgressCourse[]; // Courses.
    };
    warnings?: CoreWSExternalWarning[];
};

export type WorkplaceMyInProgressCourse = Omit<CoreCourseSummary, 'timemodified'>;
