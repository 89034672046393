<ion-header class="no-title">
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button fill="clear" (click)="closeModal()" [ariaLabel]="'core.close' | translate">
                <ion-icon name="fas-xmark" slot="icon-only" aria-hidden="true" />
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content [fullscreen]="true">
    <ion-list>
        <ion-item *ngFor="let type of types" class="addon-calendar-event" [ngClass]="['addon-calendar-eventtype-'+type]">
            <ion-icon [name]="typeIcons[type]" slot="start" aria-hidden="true" />
            <ion-toggle [(ngModel)]="filter[type]" (ionChange)="onChange()">
                {{ 'addon.calendar.' + type + 'events' | translate}}
            </ion-toggle>
        </ion-item>
        <core-spacer *ngIf="filter.course || filter.category || filter.group" />
        <ng-container *ngIf="filter.course || filter.category || filter.group">
            <ion-radio-group [(ngModel)]="courseId" (ionChange)="onChange()">
                <ion-item class="ion-text-wrap" *ngFor="let course of sortedCourses">
                    <ion-radio [value]="course.id">
                        <core-format-text [text]="course.shortname" />
                    </ion-radio>
                </ion-item>
            </ion-radio-group>
        </ng-container>
    </ion-list>
</ion-content>
