// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';
import { CoreError } from '@classes/errors/error';
import { CoreWSError } from '@classes/errors/wserror';
import { CoreSiteWSPreSets } from '@classes/sites/authenticated-site';
import { CoreSites } from '@services/sites';
import { CoreWSExternalWarning } from '@services/ws';
import { makeSingleton } from '@singletons';

/**
 * Service that provides some features to manage program enrolment.
 */
@Injectable({ providedIn: 'root' })
export class WorkplaceEnrolProgramService {

    /**
     * Get info from a course program enrolment method.
     *
     * @param instanceId Program instance ID.
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved when the info is retrieved.
     */
    async getProgramEnrolmentInfo(instanceId: number, siteId?: string): Promise<WorkplaceEnrolProgramGetInstanceInfo> {
        const site = await CoreSites.getSite(siteId);
        const params: WorkplaceEnrolProgramGetInstanceInfoWSParams = {
            instanceid: instanceId,
        };

        const preSets: CoreSiteWSPreSets = {
            getFromCache: false,
            saveToCache: false,
        };

        const result =
            await site.read<WorkplaceEnrolProgramGetInstanceInfoWSResponse>(
                'enrol_program_get_instance_info',
                params,
                preSets,
            );

        if (result.instanceinfo) {
            return result.instanceinfo;
        }

        throw new CoreError('Error on enrol_program_get_instance_info');
    }

    /**
     * Enrols a user to a course.
     *
     * @param courseId Course ID you want to enrol to.
     * @param programId Program ID where the course belongs to.
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved when the info is retrieved.
     */
    async enrolToCourse(courseId: number, programId: number, siteId?: string): Promise<boolean> {
        const site = await CoreSites.getSite(siteId);

        const params: WorkplaceToolProgramEnrolUserToCourseWSParams = {
            courseid: courseId,
            programid: programId,
        };

        const response = await site.write<WorkplaceToolProgramEnrolUserToCourseWSResponse>(
            'tool_program_enrol_user_to_course',
            params,
        );

        if (response.status) {
            return true;
        }

        if (response.warnings && response.warnings.length) {
            throw new CoreWSError(response.warnings[0]);
        }

        throw Error('WS tool_program_enrol_user_to_course failed without warnings');
    }

    /**
     * Check if enrol_program_get_instance_info WS is available.
     *
     * @param siteId Site ID. If not defined, use current site.
     * @returns Whether the webservice is available.
     * @since 3.11
     */
    async isGetProgramEnrolInfoAvailable(siteId?: string): Promise<boolean> {
        const site = await CoreSites.getSite(siteId);

        return site?.wsAvailable('enrol_program_get_instance_info');
    }

}
export const WorkplaceEnrolProgram = makeSingleton(WorkplaceEnrolProgramService);

/**
 * Params of enrol_self_get_instance_info WS.
 */
type WorkplaceEnrolProgramGetInstanceInfoWSParams = {
    instanceid: number; // Instance id of self enrolment plugin.
};

/**
 * Data returned by enrol_program_get_instance_info WS.
 */
type WorkplaceEnrolProgramGetInstanceInfoWSResponse = {
    instanceinfo: WorkplaceEnrolProgramGetInstanceInfo;
    warnings?: CoreWSExternalWarning[];
};

/**
 * Program enrol instance info.
 */
export type WorkplaceEnrolProgramGetInstanceInfo = {
    id: number; // Id of course enrolment instance.
    courseid: number; // Id of course.
    programid: number; // Id of program.
    type: string; // Type of enrolment plugin.
    name: string; // Name of enrolment plugin.
    canselfenrol: boolean; // Is the current user able to enrol by itself in the course?.
};

/**
 * Params of tool_program_enrol_user_to_course WS.
 */
type WorkplaceToolProgramEnrolUserToCourseWSParams = {
    courseid: number;
    programid: number;
};

/**
 * Data returned by tool_program_enrol_user_to_course WS.
 */
type WorkplaceToolProgramEnrolUserToCourseWSResponse = {
    status: boolean; // True if the user is successfully enrolled.
    redirecturl: string; // URL to the course the user has just enrolled.
    warnings?: CoreWSExternalWarning[];
};
