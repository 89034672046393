// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Component, Input, OnInit } from '@angular/core';
import { ModalOptions } from '@ionic/angular';
import { ModalController, Translate } from '@singletons';
import {
    WorkplaceTeamsFilterValues,
    WorkplaceTeams,
    WorkplaceBlockMyteamsGetTeamOverviewFiltersOptionsWSResponse,
    WorkplaceTeamsOrgStructure,
    WorkplaceBlockMyteamsTreeListItem,
} from '../../services/teams';
import { WorkplaceTreeSelectorComponent } from '../tree-selector/tree-selector';

/**
 * Component that displays the teams filters modal.
 */
@Component({
    selector: 'page-workplace-teams-filter',
    templateUrl: 'teamsfilter.html',
    styleUrls: ['teamsfilter.scss'],
})
export class WorkplaceTeamsFilterComponent implements OnInit {

    @Input() filters: WorkplaceTeamsFilterValues = {
        departmentid: 0,
        includesubdepts: 0,
        positionid: 0,
        includesubpos: 0,
        fullnametype: 0,
        orgstructuretype: WorkplaceTeamsOrgStructure.OPERATOR_DIRECT_ONLY,
        fullname: '',
    };

    protected initialValues: WorkplaceTeamsFilterValues = {
        departmentid: 0,
        includesubdepts: 0,
        positionid: 0,
        includesubpos: 0,
        fullnametype: 0,
        orgstructuretype: WorkplaceTeamsOrgStructure.OPERATOR_DIRECT_ONLY,
        fullname: '',
    };

    availableFilters: WorkplaceBlockMyteamsGetTeamOverviewFiltersOptionsWSResponse = {
        departments: [],
        positions: [],
        fullname_filter: [], // eslint-disable-line @typescript-eslint/naming-convention
        orgstructuretype_filter: [], // eslint-disable-line @typescript-eslint/naming-convention
    };

    departmentModalOptions: ModalOptions = {
        component: WorkplaceTreeSelectorComponent,
        componentProps: {
            listId: 'myteams-department-selector',
            title: Translate.instant('workplace.teams.department'),
            emptyLabel: Translate.instant('workplace.teams.anydepartment'),
        },
    };

    positionModalOptions: ModalOptions = {
        component: WorkplaceTreeSelectorComponent,
        componentProps: {
            listId: 'myteams-position-selector',
            title: Translate.instant('workplace.teams.position'),
            emptyLabel: Translate.instant('workplace.teams.anyposition'),
        },
    };

    selectedDepartment = Translate.instant('workplace.teams.anydepartment');
    selectedPosition = Translate.instant('workplace.teams.anyposition');

    orgFilterEnabled = false;
    loaded = false;

    readonly CUSTOM_ORG_TYPE = WorkplaceTeamsOrgStructure.OPERATOR_CUSTOM;

    /**
     * @inheritdoc
     */
    async ngOnInit(): Promise<void> {
        this.filters = Object.assign(this.initialValues, this.filters);

        this.availableFilters = await WorkplaceTeams.getTeamFilters();

        this.orgFilterEnabled = this.availableFilters.orgstructuretype_filter.length > 0;

        if (this.departmentModalOptions.componentProps) {
            this.departmentModalOptions.componentProps.list = this.availableFilters.departments;
            this.departmentModalOptions.componentProps.selected = this.filters.departmentid;
        }

        if (this.filters.departmentid !== 0) {
            const department = this.availableFilters.departments.find((department) => department.id === this.filters.departmentid);
            if (department) {
                this.selectedDepartment = department.name;
            }
        }

        if (this.positionModalOptions.componentProps) {
            this.positionModalOptions.componentProps.list = this.availableFilters.positions;
            this.positionModalOptions.componentProps.selected = this.filters.positionid;
        }

        if (this.filters.positionid !== 0) {
            const position = this.availableFilters.positions.find((position) => position.id === this.filters.positionid);
            if (position) {
                this.selectedPosition = position.name;
            }
        }

        this.loaded = true;
    }

    /**
     * Changes the department.
     *
     * @param item Selected Item.
     */
    setDepartment(item: WorkplaceBlockMyteamsTreeListItem): void {
        this.selectedDepartment = item.name;
        this.filters.departmentid = item.id;
        if (this.departmentModalOptions.componentProps) {
            this.departmentModalOptions.componentProps.selected = item.id;
        }
    }

    /**
     * Changes the position.
     *
     * @param item Selected Item.
     */
    setPosition(item: WorkplaceBlockMyteamsTreeListItem): void {
        this.selectedPosition = item.name;
        this.filters.positionid = item.id;
        if (this.positionModalOptions.componentProps) {
            this.positionModalOptions.componentProps.selected = item.id;
        }
    }

    /**
     * Changes the fullname type.
     *
     * @param selected Selected value.
     */
    setFullnameType(selected: number | undefined): void {
        this.filters.fullnametype = selected;
    }

    /**
     * Reset filters.
     */
    reset(): void {
        ModalController.dismiss({});
    }

    /**
     * Close modal applying changes.
     */
    filter(): void {
        ModalController.dismiss(this.filters);
    }

    /**
     * Close modal.
     */
    closeModal(): void {
        ModalController.dismiss();
    }

}
