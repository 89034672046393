// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CoreEvents } from '@singletons/events';
import { WorkplaceTenantsComponentsModule } from './components/components.module';
import { WorkplaceTenantsHelper } from './services/tenants-helper';

@NgModule({
    imports: [
        IonicModule,
        TranslateModule.forChild(),
        WorkplaceTenantsComponentsModule,
    ],
})
export class WorkplaceTenantsModule {

    constructor() {
        CoreEvents.on(CoreEvents.LOGIN, async (): Promise<void> => {
            await WorkplaceTenantsHelper.initTenant();
        });

        CoreEvents.on(CoreEvents.LOGOUT, (): void => {
            WorkplaceTenantsHelper.resetTempTenant();
        });

        // Remove site saved tenant.
        CoreEvents.on(CoreEvents.SITE_DELETED, (site) => {
            if (site?.id) {
                WorkplaceTenantsHelper.removeTenant(site.id);
            }
        });
    }

}
