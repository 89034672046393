// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Component, Input, OnInit } from '@angular/core';
import { CoreUtils, TreeNode } from '@services/utils/utils';
import { ModalController } from '@singletons';
import { WorkplaceBlockMyteamsTreeListItem } from '../../services/teams';

/**
 * Page that displays a tree selector.
 */
@Component({
    selector: 'page-workplace-tree-selector',
    templateUrl: 'tree-selector.html',
    styleUrls: ['tree-selector.scss'],
})
export class WorkplaceTreeSelectorComponent implements OnInit {

    @Input() list!: WorkplaceBlockMyteamsTreeListItem[];
    @Input() title!: string;
    @Input() listId!: string;
    @Input() emptyLabel!: string;
    @Input() selected = 0;

    itemsTree: TreeNode<WorkplaceBlockMyteamsTreeListItem>[] = [];

    /**
     * @inheritdoc
     */
    ngOnInit(): void {
        this.itemsTree = CoreUtils.formatTree(CoreUtils.clone(this.list), 'parentid');
    }

    /**
     * Close the modal.
     */
    closeModal(): void {
        ModalController.dismiss();
    }

    /**
     * Select an item.
     */
    select(): void {
        const item = this.list.find((item) => item.id === this.selected);

        if (item) {
            ModalController.dismiss(item);
        } else {
            ModalController.dismiss({ id: 0, name: this.emptyLabel });
        }
    }

}
