<ion-list *ngIf="question && (question.text || question.text === '')">
    <ion-item class="ion-text-wrap addon-qtype-shortanswer-text">
        <ion-label>
            <core-format-text [component]="component" [componentId]="componentId" [text]="question.text" [contextLevel]="contextLevel"
                [contextInstanceId]="contextInstanceId" [courseId]="courseId" />
        </ion-label>
    </ion-item>
    <ion-item *ngIf="question.input && !question.input.isInline"
        class="ion-text-wrap addon-qtype-shortanswer-input core-{{question.input.correctIconColor}}-item">
        <ion-input labelPlacement="stacked" type="text" [placeholder]="question.input.readOnly ? '' : 'core.question.answer' | translate"
            [attr.name]="question.input.name" [value]="question.input.value" autocorrect="off" [disabled]="question.input.readOnly"
            [label]="'addon.mod_quiz.answercolon' | translate" />
        <ion-icon *ngIf="question.input.correctIcon" class="core-correct-icon" slot="end" [name]="question.input.correctIcon"
            [color]="[question.input.correctIconColor]" />
    </ion-item>
</ion-list>
