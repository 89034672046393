// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { APP_INITIALIZER, NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Routes } from '@angular/router';

import { CoreMainMenuHomeRoutingModule } from '@features/mainmenu/mainmenu-home-routing.module';
import { CoreMainMenuTabRoutingModule } from '@features/mainmenu/mainmenu-tab-routing.module';
import { CoreBlockDelegate } from '@features/block/services/block-delegate';
import { WorkplaceBlockMyLearningHandler } from './services/handlers/mylearning-block';
import { WorkplaceLearningComponentsModule } from './components/components.module';
import { WorkplaceBlockMyCoursesHandler } from './services/handlers/mycourses-block';
import { CoreContentLinksDelegate } from '@features/contentlinks/services/contentlinks-delegate';
import { WorkplaceSetLinkHandler } from './services/handlers/set-link';

const routes: Routes = [
    {
        path: 'program',
        loadChildren: () => import('./learning-program-lazy.module').then(m => m.WorkplaceLearningProgramLazyModule),
    },
];

@NgModule({
    imports: [
        CoreMainMenuTabRoutingModule.forChild(routes),
        IonicModule,
        TranslateModule.forChild(),
        WorkplaceLearningComponentsModule,
    ],
    exports: [CoreMainMenuHomeRoutingModule],
    providers: [
        {
            provide: APP_INITIALIZER,
            multi: true,
            useValue: () => {
                CoreBlockDelegate.registerHandler(WorkplaceBlockMyLearningHandler.instance);
                CoreBlockDelegate.registerHandler(WorkplaceBlockMyCoursesHandler.instance);
                CoreContentLinksDelegate.registerHandler(WorkplaceSetLinkHandler.instance);
            },
        },
    ],
})
export class WorkplaceLearningModule {}
