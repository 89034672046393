// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';
import { CoreBlockHandlerData } from '@features/block/services/block-delegate';
import { CoreBlockOnlyTitleComponent } from '@features/block/components/only-title-block/only-title-block';
import { CoreBlockBaseHandler } from '@features/block/classes/base-block-handler';
import { CoreCourseBlock } from '@features/course/services/course';
import { Params } from '@angular/router';
import { makeSingleton } from '@singletons';
import { CoreReportBuilderHandlerService } from '@features/reportbuilder/services/handlers/reportbuilder';
import { CoreTextUtils } from '@services/utils/text';
import { ContextLevel } from '@/core/constants';
import { CoreReportBuilder } from '@features/reportbuilder/services/reportbuilder';
import { CoreSites } from '@services/sites';

/**
 * Block handler.
 */
@Injectable({ providedIn: 'root' })
export class WorkplaceReportBuilderHandlerService extends CoreBlockBaseHandler {

    name = 'WorkplaceReportBuilder';
    blockName = 'rbreport';

    /**
     * @inheritdoc
     */
    async isEnabled(): Promise<boolean> {
        const site = CoreSites.getRequiredCurrentSite();
        const isFeatureEnabled = await CoreReportBuilder.isEnabled();

        return isFeatureEnabled && site.wsAvailable('core_reportbuilder_retrieve_report');
    }

    /**
     * @inheritdoc
     */
    getDisplayData(block: CoreCourseBlock, contextLevel: string, instanceId: number): CoreBlockHandlerData {
        const linkParams: Params = contextLevel === ContextLevel.COURSE ? { courseId: instanceId } : {};
        const config = block.configs?.find(config => config.name === 'corereport');
        const link = config
            ? `${CoreReportBuilderHandlerService.PAGE_NAME}/${CoreTextUtils.parseJSON(config.value)}`
            : CoreReportBuilderHandlerService.PAGE_NAME;

        return {
            title: block.contents?.title ?? '',
            class: 'addon-block-report-builder',
            component: CoreBlockOnlyTitleComponent,
            link,
            linkParams: linkParams,
        };
    }

}

export const WorkplaceReportBuilderHandler = makeSingleton(WorkplaceReportBuilderHandlerService);
