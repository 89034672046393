<div *ngIf="question && (question.text || question.text === '')">
    <!-- Content is outside the core-loading to let the script calculate drag items position -->
    <core-loading [hideUntil]="question.loaded" />

    <ion-card *ngIf="!question.readOnly" class="core-info-card" [hidden]="!question.loaded">
        <ion-item>
            <ion-icon name="fas-circle-info" slot="start" aria-hidden="true" />
            <ion-label>{{ 'core.question.howtodraganddrop' | translate }}</ion-label>
        </ion-item>
    </ion-card>

    <div class="fake-ion-item ion-text-wrap" [hidden]="!question.loaded">
        <div class="addon-qtype-ddwtos-container">
            <core-format-text [component]="component" [componentId]="componentId" [text]="question.text" [contextLevel]="contextLevel"
                [contextInstanceId]="contextInstanceId" [courseId]="courseId" #questiontext (afterRender)="textRendered()" />

            <core-format-text *ngIf="question.answers" [component]="component" [componentId]="componentId" [text]="question.answers"
                [filter]="false" (afterRender)="answersRendered()" />

            <div class="drags"></div>
        </div>
    </div>
</div>
