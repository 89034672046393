<ion-header>
    <ion-toolbar>
        <ion-title>
            <h1>{{ 'workplace.lookingfordifferentsite' | translate }}</h1>
        </ion-title>
        <ion-buttons slot="end">
            <ion-button fill="clear" (click)="closeModal()" [attr.aria-label]="'core.close' | translate">
                <ion-icon name="fas-times" slot="icon-only" aria-hidden=true />
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-card *ngFor="let tenant of tenants">
        <ion-item button (click)="select(tenant)" [detail]="false" class="ion-text-center">
            <ion-label>
                <img *ngIf="tenant.logourl" core-external-content [url]="tenant.logourl" role="presentation" class="tenant-logo" alt="">
                <p class="item-heading">{{ tenant.name}}</p>
            </ion-label>
        </ion-item>
    </ion-card>
</ion-content>
