<ion-header>
    <ion-toolbar>
        <ion-title>
            <h1 id="core-course-section-selector-label">{{ 'core.course.courseindex' | translate }}</h1>
        </ion-title>
        <ion-buttons slot="end">
            <ion-button fill="clear" (click)="closeModal()" [ariaLabel]="'core.close' | translate">
                <ion-icon slot="icon-only" name="fas-xmark" aria-hidden="true" />
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <core-loading [hideUntil]="loaded">
        <ion-list *ngIf="loaded" id="core-course-section-selector" role="listbox" aria-labelledby="core-course-section-selector-label">
            <ng-container *ngFor="let section of sectionsToRender">
                <ion-item *ngIf="allSectionId === section.id" class="divider core-course-index-all"
                    (click)="selectSectionOrModule($event, section.id)" button [class.item-current]="selectedId === section.id"
                    [detail]="false">
                    <ion-label>
                        <h2>
                            <core-format-text [text]="section.name" contextLevel="course" [contextInstanceId]="course?.id" />
                        </h2>
                    </ion-label>
                </ion-item>
                <ng-container *ngIf="allSectionId !== section.id">
                    <ion-item class="divider section" (click)="selectSectionOrModule($event, section.id)" button
                        [class.item-current]="selectedId === section.id" [class.item-dimmed]="!section.visible"
                        [class.item-hightlighted]="section.highlighted" [detail]="false">
                        <ion-icon *ngIf="section.hasVisibleModules" name="fas-chevron-right" flip-rtl slot="start"
                            class="expandable-status-icon" (ariaButtonClick)="toggleExpand($event, section)"
                            [attr.aria-label]="(section.expanded ? 'core.collapse' : 'core.expand') | translate"
                            [attr.aria-expanded]="section.expanded" [attr.aria-controls]="'core-course-index-section-' + section.id"
                            [class.expandable-status-icon-expanded]="section.expanded" />
                        <ion-icon *ngIf="!section.hasVisibleModules" name="" slot="start" aria-hidden="true"
                            class="expandable-status-icon" />
                        <ion-label>
                            <h2>
                                <core-format-text [text]="section.name" contextLevel="course" [contextInstanceId]="course?.id" />
                            </h2>
                        </ion-label>
                        <ion-badge *ngIf="section.highlighted && highlighted" slot="end">{{highlighted}}</ion-badge>
                        <ion-icon name="fas-lock" *ngIf="section.availabilityinfo" slot="end" class="restricted"
                            [attr.aria-label]="'core.restricted' | translate" />
                        <ion-icon name="fas-eye-slash" *ngIf="!section.visible && !section.uservisible" slot="end" class="restricted"
                            [attr.aria-label]="'core.notavailable' | translate" />
                        <ion-icon name="fas-eye-slash" *ngIf="!section.visible && section.uservisible" slot="end" class="restricted"
                            [attr.aria-label]="'core.course.hiddenfromstudents' | translate" />
                    </ion-item>
                    <div id="core-course-index-section-{{section.id}}">
                        <ng-container *ngIf="section.expanded">
                            <ng-container *ngFor="let module of section.modules">
                                <ion-item class="module" [class.item-dimmed]="!module.visible" [class.indented]="module.indented"
                                    [class.item-hightlighted]="section.highlighted"
                                    (click)="selectSectionOrModule($event, section.id, module.id)" button>
                                    <ion-icon class="completioninfo completion_none" name="" *ngIf="module.completionStatus === undefined"
                                        slot="start" aria-hidden="true" />
                                    <ion-icon class="completioninfo completion_incomplete" name="far-circle"
                                        *ngIf="module.completionStatus === 0" slot="start"
                                        [attr.aria-label]="'core.course.todo' | translate" />
                                    <ion-icon class="completioninfo completion_complete" name="fas-circle"
                                        *ngIf="module.completionStatus === 1 || module.completionStatus === 2" color="success" slot="start"
                                        [attr.aria-label]="'core.course.done' | translate" />
                                    <ion-icon class="completioninfo completion_fail" name="fas-xmark" *ngIf="module.completionStatus === 3"
                                        color="danger" slot="start" [attr.aria-label]="'core.course.failed' | translate" />
                                    <ion-label>
                                        <p class="item-heading">
                                            <core-format-text [text]="module.name" contextLevel="module" [contextInstanceId]="module.id"
                                                [courseId]="module.course" />
                                        </p>
                                    </ion-label>
                                    <ion-icon name="fas-lock" *ngIf="!module.uservisible" slot="end" class="restricted"
                                        [attr.aria-label]="'core.restricted' | translate" />
                                </ion-item>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </ion-list>
    </core-loading>
</ion-content>
