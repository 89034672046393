<ion-header class="no-title">
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button fill="clear" (click)="closeModal()" [ariaLabel]="'core.close' | translate">
                <ion-icon name="fas-xmark" slot="icon-only" aria-hidden="true" />
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content [fullscreen]="true">
    <ion-refresher slot="fixed" [disabled]="!loaded" (ionRefresh)="doRefresh($event.target)">
        <ion-refresher-content pullingText="{{ 'core.pulltorefresh' | translate }}" />
    </ion-refresher>
    <core-loading [hideUntil]="loaded">
        <ion-list *ngIf="blocks.length > 0">
            <ng-container *ngFor="let block of blocks">
                <core-block *ngIf="block.visible" [block]="block" [contextLevel]="contextLevel" [instanceId]="instanceId" />
            </ng-container>
        </ion-list>

        <core-empty-box *ngIf="blocks.length === 0" icon="fas-table-cells-large" [message]="'core.block.noblocks' | translate" />
    </core-loading>
</ion-content>
