// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';
import { CoreError } from '@classes/errors/error';
import { CoreCourseHelper } from '@features/course/services/course-helper';
import { CoreCourses } from '@features/courses/services/courses';
import { CoreSites } from '@services/sites';
import { CoreDomUtils } from '@services/utils/dom';
import { CoreTimeUtils } from '@services/utils/time';
import { makeSingleton, Translate } from '@singletons';
import { CoreEvents } from '@singletons/events';
import {
    Workplace,
    WorkplaceProgramCourse,
    WorkplaceService,
    WorkplaceToolCatalogueCourseOnSetData,
    WorkplaceToolCatalogueGetUserCatalogueProgramWSResponse,
} from './workplace';
import { WorkplaceEnrolProgram } from '@workplace/features/enrol_program/services/program';

/**
 * Service that provides helper functions to workplace.
 */
@Injectable({ providedIn: 'root' })
export class WorkplaceHelperService {

    // Cache to test tenantUrl param on ajax calls.
    protected tenantUrlEnabled: Record<string, boolean> = {};

    /**
     * Enrols a user to a course if needed and enter into it.
     *
     * @param course Course you want to enter.
     * @param programId Program ID where the course belongs to.
     * @returns Promise resolved when done.
     */
    async enrolAndEnterCourse(
        course: WorkplaceProgramCourse | WorkplaceToolCatalogueCourseOnSetData,
        programId: number,
    ): Promise<void> {
        if ('isenrolled' in course && !course.isenrolled) {
            try {
                // Enrol the user to that course.
                await WorkplaceEnrolProgram.enrolToCourse(course.courseid, programId);

                CoreCourses.invalidateUserCourses();
                CoreEvents.trigger(
                    WorkplaceService.PROGRAMS_OVERVIEW_ENROL_EVENT,
                    { courseId: course.courseid, programId: programId },
                    CoreSites.getCurrentSiteId(),
                );
            } catch (error) {
                CoreDomUtils.showErrorModalDefault(error, 'Cannot enrol user');

                return;
            }
        }

        try {
            const userCourse = await CoreCourses.getUserCourse(course.courseid);
            CoreCourseHelper.openCourse(userCourse);
        } catch {
            // Ignore fails. Use provided info.
            const name = 'fullname' in course ? course.fullname : course.name;
            CoreCourseHelper.openCourse({ id: course.courseid, displayname: name });
        }
    }

    /**
     * Return a program where the user is allocated to, with the list of the sets and courses and the user progress in each
     * program/course/set.
     *
     * @param programId Program ID.
     * @param siteId Site ID. If not defined, use current site.
     * @returns Promise resolved with the retrieved info.
     */
    async getUserProgram(programId: number, siteId?: string): Promise<WorkplaceToolCatalogueGetUserCatalogueProgramWSResponse> {
        const catalogueAvailable = await Workplace.isToolCatalogueAvailable();

        if (catalogueAvailable) {
            return await Workplace.getUserCatalogueProgram(programId, siteId);
        }

        // Fallback, adapt old fields to new ones.
        const programs = await Workplace.getUserPrograms(siteId);

        const program = programs.programs.find((program) => program.id === programId);
        if (!program) {
            throw new CoreError('Program not found');
        }

        const criteriaStr =  Translate.instant('workplace.' + program.completiontype);
        const parentSetId = program.programsets.find((set) => set.level === 1)?.parentsetid ||
            program.programcourses.find((course) => course.level === 1)?.parentsetid || 0;

        const allocation = program.allocations.find((alloc) => alloc.certificationid === 0);

        const enrolledStatus = ['completed', 'inprogress', 'enrolled'];

        const courses = program.programcourses.map((course) => ({
            isset: false,
            setid: course.setid,
            courseid: course.courseid,
            sortorder: course.sortorder,
            fullname: course.name,
            iscompleted: course.iscompleted,
            progress: course.progress,
            islocked: !course.unlocked,
            ishidden: course.ishidden,
            isenrolled: course.isenrolled ?? enrolledStatus.includes(course.statusmessage),
            image: '',
            url: course.url,
            categoryname: '',
            lastaccess: 0,
        }));

        const sets = program.programsets.map((set) => ({
            isset: true,
            fullname: set.name,
            setid: set.id,
            parentsetid: set.parentsetid,
            sortorder: set.sortorder,
            setcriteria: set.completioncriteria || 0,
            setcriteriastr: set.completiontypestr,
            setcriteriaicon: '',
            completeditems: set.completeditems,
            numcourses: courses.filter((course) => course.setid === set.id).length,
            iscompleted: set.iscompleted,
            progress: set.progress,
            totalitems: set.totalitems,
            islocked: !set.unlocked,
            url: '',
        }));

        // Add base set.
        sets.push({
            isset: true,
            fullname: '',
            setid: parentSetId,
            parentsetid: 0,
            sortorder: 1,
            setcriteria: 0,
            setcriteriastr: criteriaStr,
            setcriteriaicon: '',
            completeditems: program.completeditems,
            numcourses: program.programcourses.length,
            iscompleted: program.iscompleted,
            progress: program.progress,
            totalitems: program.totalitems,
            islocked: !program.unlocked,
            url: '',
        });

        return {
            program: {
                id: program.id,
                fullname: program.fullname,
                description: program.description || '',
                image: program.image[0]?.fileurl || '',
                basesetcriteria: criteriaStr,
                progress: program.progress,
                startdate: allocation?.startdate || 0,
                startdateshow: !!allocation?.startdate,
                duedate: allocation?.duedate || 0,
                duedateshow: !!allocation?.duedate,
                enddate: allocation?.enddate || 0,
                enddateshow: !!allocation?.enddate,
                numcourses: courses.length,
                lastaccess: program.lastaccess,
                startdatestr: allocation?.startdate
                    ? CoreTimeUtils.userDate(allocation?.startdate * 1000, 'core.strftimedatefullshort')
                    :  Translate.instant('workplace.notset'),
                duedatestr: allocation?.duedate
                    ? CoreTimeUtils.userDate(allocation.duedate * 1000, 'core.strftimedatefullshort')
                    :  Translate.instant('workplace.notset'),
                enddatestr: allocation?.enddate
                    ? CoreTimeUtils.userDate(allocation.enddate * 1000, 'core.strftimedatefullshort')
                    :  Translate.instant('workplace.notset'),
                duedatebadgetype: allocation?.duedate && Date.now() > allocation.duedate ? 'danger' : '',
                duedatebadgestr: allocation?.duedate && Date.now() > allocation.duedate ?
                    Translate.instant('workplace.overdue') : '',
                tags: [],

                customfields: [],
                certifications: programs.origins.map((message) => {
                    const certification = program.certifications.find((cert) => cert.id == message.certificationid);

                    return {
                        message: Translate.instant(
                            'workplace.' + message.stringid,
                            { $a: {
                                fullname: certification?.fullname || '',
                                date: CoreTimeUtils.userDate(message.date * 1000, 'core.strftimedatefullshort'),
                            } },
                        ),
                        type: message.alert,
                        hash: '',
                    };
                }),
            },
            programstructure: {
                sets,
                courses,
            },
        };
    }

}

export const WorkplaceHelper = makeSingleton(WorkplaceHelperService);
