<ion-header class="no-title">
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button [text]="'core.back' | translate" />
        </ion-buttons>
        <ion-title />
        <ion-buttons slot="end" *ngIf="isModal">
            <ion-button fill="clear" (click)="closeModal()" [ariaLabel]="'core.close' | translate">
                <ion-icon slot="icon-only" name="fas-times" aria-hidden="true" />
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-refresher slot="fixed" [disabled]="!dataLoaded" (ionRefresh)="refreshData($event.target)">
        <ion-refresher-content pullingText="{{ 'core.pulltorefresh' | translate }}" />
    </ion-refresher>
    <core-loading [hideUntil]="dataLoaded">
        <div *ngIf="course" class="core-course-thumb" #courseThumb>
            <img *ngIf="course.courseImage" [url]="course.courseImage" core-external-content alt="" />
            <ion-icon *ngIf="!course.courseImage" name="fas-graduation-cap" class="course-icon" aria-hidden="true" />
        </div>
        <div *ngIf="course" class="course-container">
            <div class="list-item-limited-width">

                <ion-item class="ion-text-wrap course-name">
                    <ion-label>
                        <p *ngIf="course.displayname && course.shortname && course.fullname !== course.displayname"
                            class="core-course-shortname">
                            <core-format-text [text]="course.shortname" contextLevel="course" [contextInstanceId]="course.id" />
                        </p>
                        <h1>
                            <span class="sr-only">{{ 'core.courses.aria:coursename' | translate }}</span>
                            <core-format-text [text]="course.fullname" contextLevel="course" [contextInstanceId]="course.id" />
                        </h1>
                        <ion-chip color="primary" *ngIf="course.categoryname" class="core-course-category ion-text-nowrap">
                            <span class="sr-only">{{ 'core.courses.aria:coursecategory' | translate }}</span>
                            <ion-label>
                                <core-format-text [text]="course.categoryname" contextLevel="coursecat"
                                    [contextInstanceId]="course.categoryid" />
                            </ion-label>
                        </ion-chip>
                    </ion-label>
                    <ion-button *ngIf="displayOpenInBrowser && (!isModal || !firstEnter)" fill="clear" [href]="courseUrl" core-link
                        [showBrowserWarning]="false" [ariaLabel]="'core.openinbrowser' | translate" slot="end" [class.hidden]="!isTeacher"
                        class="core-course-oib-button">
                        <ion-icon name="fas-up-right-from-square" slot="icon-only" aria-hidden="true" />
                    </ion-button>
                </ion-item>

                <ion-card class="workplace-course-heading-card" *ngIf="userCatalogueCourse?.linkedprograms?.length">
                    <ion-item class="ion-text-wrap">
                        <ion-label>
                            <p class="item-heading" *ngIf="userCatalogueCourse?.linkedprograms.length === 1">
                                {{ 'workplace.coursecoverhelp' | translate: { $a: userCatalogueCourse?.linkedprograms[0].name } }}
                            </p>
                            <p class="item-heading" *ngIf="userCatalogueCourse?.linkedprograms.length > 1">
                                {{ 'workplace.coursecoverhelpmultiprogram' | translate }}
                            </p>
                            <div [innerHTML]="'workplace.coursecoverhelptext' | translate"></div>
                        </ion-label>
                    </ion-item>
                    <ion-item>
                        <ion-label class="workplace-programdetails">
                            <a [href]="userCatalogueCourse?.linkedprograms[0].url" *ngIf="userCatalogueCourse?.linkedprograms.length === 1"
                                [capture]="true" core-link>
                                {{ 'workplace.programlinksingle' | translate }}
                            </a>
                            <ng-container *ngIf="userCatalogueCourse?.linkedprograms.length > 1">
                                <p *ngFor="let link of userCatalogueCourse?.linkedprograms">
                                    <a [href]="link.url" [capture]="true" core-link>
                                        {{ 'workplace.programlink' | translate: { $a: link.name } }}
                                    </a>
                                </p>
                            </ng-container>
                        </ion-label>
                    </ion-item>
                </ion-card>

                <ion-item class="ion-text-wrap" *ngIf="progress">
                    <ion-label>
                        <div class="core-course-progress">
                            <core-progress-bar [progress]="progress" a11yText="core.course.aria:sectionprogress" />
                        </div>
                    </ion-label>
                </ion-item>

                <ng-container *ngIf="userCatalogueCourse?.restrictions?.length">
                    <ng-container *ngFor="let restriction of userCatalogueCourse.restrictions">
                        <ion-card *ngIf="restriction" class="core-warning-card">
                            <ion-item class="ion-text-wrap">
                                <ion-label>
                                    <p>
                                        <core-format-text [text]="restriction" />
                                    </p>
                                </ion-label>
                            </ion-item>
                        </ion-card>
                    </ng-container>
                </ng-container>

                <ion-list *ngIf="course.summary">
                    <ion-item button class="ion-text-wrap expandable-item" (click)="toggle('description')"
                        [attr.aria-label]="(collapsed['description'] ? 'core.expand' : 'core.collapse') | translate" [detail]="false">
                        <ion-icon name="fas-chevron-right" flip-rtl slot="start" aria-hidden="true" class="expandable-status-icon"
                            [class.expandable-status-icon-expanded]="!collapsed['description']" />
                        <ion-label>
                            <p class="item-heading"> {{ 'workplace.aboutthiscourse' | translate }} </p>
                        </ion-label>
                    </ion-item>
                    <ion-item class="ion-text-wrap" [detail]="false" *ngIf="course.summary && !collapsed['description']">
                        <ion-label>
                            <core-format-text [text]="course.summary" />
                        </ion-label>
                    </ion-item>
                </ion-list>

                <ion-list>
                    <ion-item button class="ion-text-wrap expandable-item" (click)="toggle('dates')"
                        [attr.aria-label]="(collapsed['dates'] ? 'core.expand' : 'core.collapse') | translate" [detail]="false">
                        <ion-icon name="fas-chevron-right" flip-rtl slot="start" aria-hidden="true" class="expandable-status-icon"
                            [class.expandable-status-icon-expanded]="!collapsed['dates']" />
                        <ion-label>
                            <p class="item-heading"> {{ 'workplace.dates' | translate }} </p>
                        </ion-label>
                    </ion-item>
                    <ion-item>
                        <ion-label *ngIf="!collapsed['dates'] && userCatalogueCourse?.course">
                            <p>
                                <strong> {{ 'workplace.startdate' | translate }}: </strong>
                                {{ userCatalogueCourse.course.startdate
                                ? (userCatalogueCourse.course.startdate * 1000 | coreFormatDate: 'strftimedatefullshort')
                                : 'workplace.notset' | translate }}
                            </p>
                            <p>
                                <strong> {{ 'workplace.enddate' | translate }}: </strong>
                                {{ userCatalogueCourse.course.enddate
                                ? (userCatalogueCourse.course.enddate * 1000 | coreFormatDate: 'strftimedatefullshort')
                                : 'workplace.notset' | translate }}
                                <ion-badge *ngIf="userCatalogueCourse?.duedatebadgetype && userCatalogueCourse?.duedate"
                                    [color]="userCatalogueCourse?.duedatebadgetype">
                                    {{ userCatalogueCourse?.duedatebadgestr }}
                                </ion-badge>
                            </p>
                        </ion-label>
                    </ion-item>
                    <core-spacer />
                </ion-list>

                <ion-list *ngIf="userCatalogueCourse?.trainers && userCatalogueCourse?.trainers?.length">
                    <ion-item button class="ion-text-wrap expandable-item" (click)="toggle('trainers')"
                        [attr.aria-label]="(collapsed['trainers'] ? 'core.expand' : 'core.collapse') | translate" [detail]="false">
                        <ion-icon name="fas-chevron-right" flip-rtl slot="start" aria-hidden="true" class="expandable-status-icon"
                            [class.expandable-status-icon-expanded]="!collapsed['trainers']" />
                        <ion-label>
                            <p class="item-heading"> {{ 'core.teachers' | translate }} </p>
                        </ion-label>
                    </ion-item>
                    <ng-container *ngIf="!collapsed['trainers']">
                        <ion-item class="ion-text-wrap" *ngFor="let trainer of userCatalogueCourse.trainers" [userId]="trainer.id"
                            [courseId]="course.id" core-user-link>
                            <core-user-avatar [slot]="'start'" [user]="{
                                id: trainer.id,
                                userpictureurl: trainer.profileimageurl,
                                courseId: course.id
                            }" />
                            <ion-label>
                                <p class="item-heading">{{ trainer.fullname }}</p>
                            </ion-label>
                        </ion-item>
                    </ng-container>
                    <core-spacer />
                </ion-list>

                <ion-item class="ion-text-wrap" *ngIf="customFields">
                    <ion-label>
                        <ng-container *ngFor="let field of customFields">
                            <div *ngIf="field.value" class="core-customfield core-customfield_{{field.shortname}}">
                                <span class="core-customfieldname">
                                    <core-format-text [text]="field.name" contextLevel="course" [contextInstanceId]="course.id" />
                                    <span class="core-customfieldseparator">: </span>
                                </span>
                                <span class="core-customfieldvalue">
                                    <core-format-text [text]="field.value" contextLevel="course" [contextInstanceId]="course.id" />
                                </span>
                            </div>
                        </ng-container>
                    </ion-label>
                </ion-item>
            </div>
        </div>
    </core-loading>

    <div collapsible-footer *ngIf="course && dataLoaded && isModal && firstEnter" slot="fixed">
        <ion-button (click)="closeModal()" class="ion-text-wrap" expand="block">
            <ion-label>{{'workplace.proceedtocourse' | translate}}</ion-label>
        </ion-button>
    </div>
</ion-content>

<ion-footer *ngIf="course && dataLoaded">
    <div class="list-item-limited-width">
        <ng-container *ngIf="canAccessCourse">
            <ion-button *ngFor="let item of courseMenuHandlers" (click)="openMenuItem(item)" [class]="'ion-text-wrap '+ item.data.class"
                expand="block">
                <ion-icon *ngIf="item.data.icon" [name]="item.data.icon" slot="start" aria-hidden="true" />
                <ion-label>{{item.data.title | translate }}</ion-label>
            </ion-button>
        </ng-container>
        <ng-container *ngIf="!isEnrolled">
            <ion-button expand="block" (click)="enrolMe()" *ngIf="selfEnrolInstances.length || hasBrowserEnrolments" class="ion-text-wrap">
                {{ 'core.courses.enrolme' | translate }}
            </ion-button>

            <ion-card class="core-info-card ion-text-wrap" *ngIf="!selfEnrolInstances.length && !hasBrowserEnrolments">
                <ion-item>
                    <ion-icon name="fas-info-circle" slot="start" aria-hidden="true" />
                    <ion-label>{{ 'core.courses.notenrollable' | translate }}</ion-label>
                </ion-item>
            </ion-card>
        </ng-container>

        <ion-button (click)="openCourse()" *ngIf="!isModal && canAccessCourse" expand="block" fill="outline" class="ion-text-wrap">
            <ion-icon name="fas-eye" slot="start" aria-hidden="true" />
            {{ 'core.course.viewcourse' | translate }}
        </ion-button>
    </div>
</ion-footer>
