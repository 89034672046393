// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Component, Input, OnInit } from '@angular/core';
import { CoreCourseSummaryPage } from '@features/course/pages/course-summary/course-summary.page';
import { WorkplaceEnrolProgram } from '@workplace/features/enrol_program/services/program';
import {
    Workplace,
    WorkplaceToolCatalogueGetUserCatalogueCourseWSResponseMapped,
    WorkplaceToolCourseCatalogueCustomField,
} from '@workplace/services/workplace';

/**
 * Page that shows the summary of a course including buttons to enrol and other available options.
 */
@Component({
    selector: 'page-core-course-summary',
    templateUrl: 'course-summary.html',
    styleUrls: ['course-summary.scss'],
})
export class WorkplaceCourseSummaryPage extends CoreCourseSummaryPage implements OnInit {

    @Input() firstEnter = false;

    collapsed: Record<string, boolean> = {};
    userCatalogueCourse!: WorkplaceToolCatalogueGetUserCatalogueCourseWSResponseMapped;
    customFields?: WorkplaceToolCourseCatalogueCustomField[];

    /**
     * @inheritdoc
     */
    async ngOnInit(): Promise<void> {
        await super.ngOnInit();

        await this.autoEnrolInCourse();
    }

    /**
     * @inheritdoc
     */
    protected async getCourse(refresh = false): Promise<void> {
        await this.initUserCatalogue();
        await super.getCourse(refresh);
    }

    /**
     * Auto enrol in a course on a program and redirect if success.
     *
     * @returns Promise resolved when self enrolled.
     */
    protected async autoEnrolInCourse(): Promise<void> {
        if (this.isModal || !this.selfEnrolInstances) {
            return;
        }

        const getProgramInfoAvailable = await WorkplaceEnrolProgram.isGetProgramEnrolInfoAvailable();
        if (!getProgramInfoAvailable) {
            return;
        }

        for (const i in this.selfEnrolInstances) {
            const enrolMethod = this.selfEnrolInstances[i];
            if (enrolMethod.type !== 'program') {
                continue;
            }

            await this.selfEnrolInCourse(enrolMethod);
            if (this.isEnrolled) {
                break;
            }
        }
    }

    /**
     * @inheritdoc
     */
    async refreshData(refresher?: HTMLIonRefresherElement): Promise<void> {
        await Workplace.invalidateUserCatalogueCourse(this.courseId);
        await super.refreshData(refresher);
    }

    /**
     * Init user catalogue course.
     */
    async initUserCatalogue(): Promise<void> {
        const available = await Workplace.isUserCatalogueCourseAvailable();
        if (!available) {
            return;
        }

        const { course } = await Workplace.getUserCatalogueCourse(this.courseId);
        this.userCatalogueCourse = course;

        if (this.userCatalogueCourse.restrictions.length) {
            this.userCatalogueCourse.restrictions = [...new Set(this.userCatalogueCourse.restrictions)];
        }

        if (course) {
            this.customFields = course.customfields;
        }
    }

    /**
     * Collapse a template section.
     *
     * @param identifier template section to collapse.
     */
    toggle(identifier: string): void {
        this.collapsed[identifier] = !this.collapsed[identifier];
    }

}
