<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button [text]="'core.back' | translate" />
        </ion-buttons>
        <ion-title>
            <h1>{{ title }}</h1>
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-refresher slot="fixed" [disabled]="!loaded" (ionRefresh)="refreshData($event.target)">
        <ion-refresher-content pullingText="{{ 'core.pulltorefresh' | translate }}" />
    </ion-refresher>
    <core-loading [hideUntil]="loaded">
        <core-empty-box *ngIf="!sections || !sections.length" icon="fas-box-open"
            [message]="'core.course.nocontentavailable' | translate" />

        <ion-list class="core-course-module-list-wrapper">
            <ng-container *ngFor="let section of sections; index as i">
                <ng-container *ngIf="i <= lastShownSectionIndex">
                    <ion-item-divider class="course-section ion-text-wrap" *ngIf="section.name">
                        <ion-label>
                            <h2>
                                <core-format-text [text]="section.name" contextLevel="course" [contextInstanceId]="courseId" />
                            </h2>
                        </ion-label>
                    </ion-item-divider>
                    <ng-container *ngFor="let module of section.modules">
                        <core-course-module [module]="module" [section]="section" [showActivityDates]="false" [showAvailability]="false"
                            [showExtra]="false" [showDownloadStatus]="false" [showCompletion]="false" [showIndentation]="false" />
                    </ng-container>
                </ng-container>
            </ng-container>
        </ion-list>
        <core-infinite-loading [enabled]="canLoadMore" (action)="showMoreActivities($event)" />
    </core-loading>
</ion-content>
