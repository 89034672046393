<span *ngIf="inputMode && form" [formGroup]="form">
    <span *ngIf="editMode" [core-mark-required]="field.required" class="core-mark-required"></span>

    <ion-datetime-button datetime="datetime" />
    <ion-modal [keepContentsMounted]="true">
        <ng-template>
            <ion-datetime id="datetime" [formControlName]="'f_'+field.id" [max]="maxDate" [min]="minDate"
                [disabled]="searchMode && searchFields && !searchFields['f_'+field.id+'_z']" presentation="date"
                [showDefaultButtons]="true" />
        </ng-template>
    </ion-modal>
    <core-input-errors *ngIf="error && editMode" [control]="form.controls['f_'+field.id]" [errorText]="error" />

    <ion-item *ngIf="searchMode && searchFields" class="ion-text-wrap">
        <ion-checkbox [formControlName]="'f_'+field.id+'_z'" [(ngModel)]="searchFields['f_'+field.id+'_z']">
            {{ 'addon.mod_data.usedate' | translate }}
        </ion-checkbox>
    </ion-item>
</span>

<span *ngIf="displayMode && displayDate">
    {{ displayDate | coreFormatDate:'strftimedate' }}
</span>
