// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

/**
 * Subscription plans.
 */
export enum SiteSubscriptionPlan {
    Free = 'free',
    Pro = 'pro',
    Premium = 'premium',
    BMA = 'bma',
}

declare module '@/types/config' {

    export interface EnvironmentConfig {
        appServicesUrl: string;
        enableAppSubscription?: boolean;
        disableappratings?: boolean;
        defaultsubscription?: SiteSubscriptionPlan;
        siteOverrides?: Record<string, Record<string, unknown>>;
    }

}

export enum FreemiumFeature {
    CoursesDownload = 'coursesdownload',
    RemoteThemes = 'remotethemes',
    DisabledFeatures = 'disabledfeatures',
    CustomLanguageStrings = 'customlanguagestrings',
    CustomMenuItems = 'custommenuitems',
    CustomModuleIcons = 'custommoduleicons',
    MultimediaPushNotifications = 'multimediapushnotifications',
    PushNotificationsDevices = 'pushnotificationsdevices',
    SiteLogo = 'sitelogo',
    QRLogin = 'qrlogin',
    BiometricLogin = 'biometriclogin',
}

export const FREEMIUM_PLAN_DEFAULTS = {
    [FreemiumFeature.CoursesDownload]: {
        [SiteSubscriptionPlan.Free]: 2,
        [SiteSubscriptionPlan.Pro]: 4,
    },
    [FreemiumFeature.RemoteThemes]: {
        [SiteSubscriptionPlan.Premium]: true,
        [SiteSubscriptionPlan.BMA]: true,
    },
    [FreemiumFeature.DisabledFeatures]: {
        [SiteSubscriptionPlan.Free]: 1,
        [SiteSubscriptionPlan.Pro]: 2,
    },
    [FreemiumFeature.CustomLanguageStrings]: {
        [SiteSubscriptionPlan.Free]: 10,
        [SiteSubscriptionPlan.Pro]: 20,
    },
    [FreemiumFeature.CustomMenuItems]: {
        [SiteSubscriptionPlan.Free]: 1,
        [SiteSubscriptionPlan.Pro]: 2,
    },
    [FreemiumFeature.CustomModuleIcons]: {
        [SiteSubscriptionPlan.Premium]: true,
        [SiteSubscriptionPlan.BMA]: true,
    },
    [FreemiumFeature.MultimediaPushNotifications]: {
        [SiteSubscriptionPlan.Pro]: true,
        [SiteSubscriptionPlan.Premium]: true,
        [SiteSubscriptionPlan.BMA]: true,
    },
    [FreemiumFeature.PushNotificationsDevices]: {
        [SiteSubscriptionPlan.Free]: 50,
        [SiteSubscriptionPlan.Pro]: 500,
    },
    [FreemiumFeature.SiteLogo]: {
        [SiteSubscriptionPlan.Premium]: true,
        [SiteSubscriptionPlan.BMA]: true,
    },
    [FreemiumFeature.QRLogin]: {
        [SiteSubscriptionPlan.Pro]: true,
        [SiteSubscriptionPlan.Premium]: true,
        [SiteSubscriptionPlan.BMA]: true,
    },
    [FreemiumFeature.BiometricLogin]: {
        [SiteSubscriptionPlan.Pro]: true,
        [SiteSubscriptionPlan.Premium]: true,
        [SiteSubscriptionPlan.BMA]: true,
    },
};
