// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import {
    WorkplaceToolCatalogueGetUserCatalogueProgramStructure,
    WorkplaceToolCatalogueSetData,
} from '@workplace/services/workplace';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CoreNavigator } from '@services/navigator';
import { CoreCourseBasicData, CoreCourses } from '@features/courses/services/courses';
import { CoreCourseHelper, CorePrefetchStatusInfo } from '@features/course/services/course-helper';
import { CoreDomUtils } from '@services/utils/dom';
import { CoreEventObserver, CoreEvents } from '@singletons/events';
import { CoreSites } from '@services/sites';
import { DownloadStatus } from '@/core/constants';
import { CoreFilepool } from '@services/filepool';

/**
 * This component is meant to display a set card on the program navigation.
 *
 * Example usage:
 *
 * <workplace-program-set-card [set]="set">
 * </workplace-program-set-card>
 */
@Component({
    selector: 'workplace-program-set-card',
    templateUrl: 'set-card.html',
    styleUrls: ['../../program.scss'],
})
export class WorkplaceProgramSetCardComponent implements OnInit, OnDestroy {

    @Input() set!: WorkplaceToolCatalogueSetData; // The set to render.
    @Input() currentSetId?: number; // The current set Id, if any.
    @Input() programstructure?: WorkplaceToolCatalogueGetUserCatalogueProgramStructure; // Program.

    setCourses: CoreCourseBasicData[] = [];
    downloadEnabled!: boolean; // Download courses enabled.
    prefetchStatus: CorePrefetchStatusInfo = {
        icon: '',
        statusTranslatable: 'core.loading',
        status: DownloadStatus.DOWNLOADABLE_NOT_DOWNLOADED,
        loading: true,
    };

    protected courseStatusObserver?: CoreEventObserver;

    /**
     * @inheritdoc
     */
    async ngOnInit(): Promise<void> {
        this.downloadEnabled = !CoreCourses.isDownloadCoursesDisabledInSite();

        if (!this.programstructure || !this.downloadEnabled) {
            return;
        }

        this.setCourses = this.programstructure.courses.filter(course =>
            course.isenrolled && !course.islocked && course.setid === this.set.setid)
            .map((course) => ({
                id: course.courseid,
                fullname: '',
                shortname: '',
                summary: '',
                summaryformat: 0,
            }));

        await this.initPrefetchIcon();
        const site = CoreSites.getRequiredCurrentSite();

        // Listen for status change in course.
        this.courseStatusObserver = CoreEvents.on(
            CoreEvents.COURSE_STATUS_CHANGED,
            async() => await this.initPrefetchIcon(),
            site.getId(),
        );
    }

    /**
     * Open the set.
     */
    async openSet(): Promise<void> {
        await CoreNavigator.navigate(!this.currentSetId ? `set/${this.set.setid}` : `../${this.set.setid}`);
    }

    /**
     * Init prefetch icon.
     */
    protected async initPrefetchIcon(): Promise<void> {
        if (!this.downloadEnabled || this.setCourses.length <= 0) {
            return;
        }

        const prefetchedIcon = await CoreCourseHelper.initPrefetchCoursesIcons(
            this.setCourses,
            this.prefetchStatus,
        );

        this.prefetchStatus = { ...prefetchedIcon };
    }

    /**
     * Prefetch set.
     */
    async prefetchSet(): Promise<void> {
        if (!this.programstructure) {
            return;
        }

        const initialIcon = this.prefetchStatus.icon;
        const siteId = CoreSites.getCurrentSiteId();

        try {
            await CoreCourseHelper.prefetchCourses(this.setCourses, this.prefetchStatus);

            const promises = this.setCourses.map(async (course) => {
                const courseInfo = await CoreCourseHelper.getCourse(course.id);

                if (!courseInfo.course.overviewfiles) {
                    return;
                }

                return CoreFilepool.downloadOrPrefetchFiles(
                    siteId,
                    courseInfo.course.overviewfiles.map(file => ({ fileurl: file.fileurl })),
                    true,
                );
            });

            if (!promises.length) {
                return;
            }

            await Promise.all(promises);
        } catch (error) {
            CoreDomUtils.showErrorModalDefault(error, 'core.course.errordownloadingcourse', true);
            this.prefetchStatus.icon = initialIcon;
        }
    }

    /**
     * @inheritdoc
     */
    ngOnDestroy(): void {
        this.courseStatusObserver?.off();
    }

}
