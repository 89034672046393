<ion-item-divider [sticky]="true">
    <ion-label>
        <h2>{{ 'workplace.teams.pluginname' | translate }}</h2>
    </ion-label>
    <ion-button slot="end" *ngIf="loaded && (users.length || filtered)" fill="clear" color="dark" (click)="openFilter()"
        [ariaLabel]="'core.filter' | translate">
        <ion-icon name="fas-filter" slot="icon-only" aria-hidden="true" />
    </ion-button>
</ion-item-divider>
<core-loading [hideUntil]="loaded" [fullscreen]="false" class="margin">
    <core-empty-box *ngIf="!users.length" icon="fas-user-group" [message]="'workplace.nothingtodisplay' | translate" />

    <ion-list *ngIf="users && users.length">
        <ion-item button class="ion-text-wrap" *ngFor="let user of users" [attr.aria-label]="user.user.fullname" (click)="gotoUser(user)"
            [detail]="true">
            <core-user-avatar [user]="user.user" slot="start" [userId]="user.user.id" [checkOnline]="true" [linkProfile]="false" />
            <ion-label>
                <p class="item-heading">
                    {{ user.user.fullname }}
                    <ion-icon *ngIf="user.isoverdue" name="fas-circle" color="danger"
                        [attr.aria-label]="'workplace.teams.isoverdue' | translate" class="overdue-dot" />
                </p>
                <p *ngIf="user.lastaccess"><strong>{{ 'core.lastaccess' | translate }}: </strong>{{ user.lastaccess | coreTimeAgo }}</p>
            </ion-label>

        </ion-item>
    </ion-list>

    <core-infinite-loading [enabled]="loaded && canLoadMore" (action)="loadMoreData($event)" [error]="loadMoreError" />
</core-loading>
