// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';
import { CoreApp } from '@/core/services/app';
import { CoreEvents } from '@singletons/events';
import { CoreDomUtils } from '@/core/services/utils/dom';
import { CoreTimeUtils } from '@/core/services/utils/time';
import { CoreUtils } from '@/core/services/utils/utils';
import { CoreConstants } from '@/core/constants';
import { CoreWS } from '@/core/services/ws';
import { FreemiumStorage } from '@freemium/services/storage';
import { makeSingleton, ToastController, Translate } from '@singletons';
import { CoreNetwork } from '@services/network';

/**
 * Service to manage App Ratings.
 */
@Injectable({ providedIn: 'root' })
export class FreemiumAppRatingsService {

    static readonly APP_RATED = 'app_rated';

    /**
     * Register service listeners to rate the app.
     */
    registerListeners(): void {
        if (CoreConstants.CONFIG.disableappratings) {
            return;
        }

        CoreEvents.on(CoreEvents.ACTIVITY_DATA_SENT, async (data): Promise<void> => {
            // Avoid rating the app after the user did high performance activities.
            if (data.module == 'quiz' || data.module == 'scorm') {
                return;
            }

            return this.rateTheApp();
        });
    }

    /**
     * Gets the app has needs rating.
     *
     * @returns If rating is needed.
     */
    protected async shouldOpenAppRate(): Promise<boolean> {
        const ratedValue = await FreemiumStorage.get(FreemiumAppRatingsService.APP_RATED, { completed: false, timemodified: 0 });

        // Already rated.
        if (ratedValue.completed) {
            return false;
        }

        // At least 24h later from last save.
        return CoreTimeUtils.timestamp() > (ratedValue.timemodified + CoreConstants.SECONDS_DAY);
    }

    /**
     * Saves the app has been rated.
     *
     * @param done If done, will set -1, timestamp otherwise.
     * @returns Resolved when done.
     */
    protected async setAppRated(done: boolean): Promise<void> {
        await FreemiumStorage.set(
            FreemiumAppRatingsService.APP_RATED,
            { completed: done, timemodified: CoreTimeUtils.timestamp() },
        );
    }

    /**
     * Opens the browser with the store page to rate the app.
     */
    protected openStorePage(): void {
        const url = CoreApp.getAppStoreUrl(CoreConstants.CONFIG.appstores);

        if (url) {
            const buttons = [
                {
                    text: Translate.instant('freemium.nottoday'),
                    role: 'cancel',
                    handler: (): void => {
                        this.setAppRated(false);
                    },
                },
                {
                    text: Translate.instant('core.yes'),
                    handler: (): void => {
                        CoreUtils.openInBrowser(url, { showBrowserWarning: false });
                        this.setAppRated(true);
                    },
                },
            ];

            CoreDomUtils.showAlertWithOptions({
                header: Translate.instant('freemium.ratetheapp_feedbackok'),
                message: Translate.instant('freemium.ratetheapp_sendtostore'),
                buttons,
            });
        } else {
            this.sendFeedback(true);
        }
    }

    /**
     * Opens the browser to send feedback by email.
     *
     * @param positiveAnswer If the answer to the previous question was positive or not.
     */
    protected async sendFeedback(positiveAnswer: boolean): Promise<void> {
        if (!CoreConstants.CONFIG.appServicesUrl) {
            return;
        }

        const title = positiveAnswer ? 'freemium.ratetheapp_feedbackok' : 'freemium.ratetheapp_feedbackko';
        const buttons = [
            {
                text: Translate.instant('freemium.nottoday'),
                role: 'cancel',
                handler: (): void => {
                    this.setAppRated(false);
                },
            },
            { text: Translate.instant('core.send') },
        ];

        const message = await CoreDomUtils.showTextareaPrompt(
            Translate.instant(title),
            Translate.instant('freemium.ratetheapp_feedbackmessage'),
            buttons,
            Translate.instant('freemium.ratetheapp_feedbackplaceholder'),
        );

        if (!message) {
            return;
        }

        await this.submitFeedback(message);
        await this.setAppRated(true);

        CoreDomUtils.showToast('freemium.ratetheapp_feedbacksent', true);
    }

    /**
     * Submits feedback to the server
     *
     * @param  feedbacktext Text to submit.
     * @returns Resolved when done.
     */
    protected async submitFeedback(feedbacktext: string): Promise<void> {
        return CoreWS.callAjax(
            'local_apps_submit_feedback',
            { feedbacktext, appid: CoreConstants.CONFIG.app_id },
            { siteUrl: CoreConstants.CONFIG.appServicesUrl, noLogin: true },
        );
    }

    /**
     * Opens a modal window to tate the app.
     *
     * @param force If modal is forced, whether the user previously rate the app or not.
     * @returns Resolved when done.
     */
    protected async rateTheApp(force: boolean = false): Promise<void> {
        // Not configured or offline.
        if (typeof CoreConstants.CONFIG.appstores != 'object') {
            return;
        }

        const open = force || (await this.shouldOpenAppRate());
        if (!open) {
            return;
        }

        // Do not show when offline.
        if (!CoreNetwork.isOnline()) {
            return;
        }

        const toast = await ToastController.create({
            message: Translate.instant('freemium.ratetheapp'),
            duration: 5000,
            buttons: [
                {
                    text: Translate.instant('core.yes'),
                    handler: (): void => {
                        this.openStorePage();
                    },
                },
                {
                    text: Translate.instant('core.no'),
                    handler: (): void => {
                        this.sendFeedback(false);
                    },
                },
                {
                    text: Translate.instant('freemium.remindmelater'),
                    handler: (): void => {
                        this.setAppRated(false);
                    },
                },
            ],
        });

        toast.present();
    }

}

export const FreemiumAppRatings = makeSingleton(FreemiumAppRatingsService);
