// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Md5 } from 'ts-md5/dist/md5';

import { CoreSiteInfo, CoreSitePublicConfigResponse, CoreUnauthenticatedSite } from '@classes/sites/unauthenticated-site';

import { FreemiumFeature } from '@freemium/constants';
import { SiteSubscriptions } from '@freemium/services/site-subscriptions';
import { FreemiumStorage } from '@freemium/services/storage';
import { SiteSubscription, SiteSubscriptionJson, DefaultSiteSubscription } from '@freemium/classes/site-subscription';
import { Constructor } from '@/core/utils/types';
import { CoreConstants } from '@/core/constants';

/**
 * Function to override a site class to add unauthenticated site freemium-specific code.
 *
 * @returns Class.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function applyFreemiumUnauthenticatedSiteCode<TBase extends Constructor<CoreUnauthenticatedSite>>(
    Base: TBase,
) {
    return class FreemiumUnauthenticatedSite extends Base {

        /**
         * Subscription that will be used if one hasn't been fetched.
         */
        protected static readonly DEFAULT_SUBSCRIPTION = new DefaultSiteSubscription();

        // Storage keys.
        protected static readonly SUBSCRIPTION_KEY_PREFIX = 'subscription-';
        protected static readonly LAST_SUBSCRIPTION_UPDATE_KEY_PREFIX = 'lastSubscriptionUpdate-';

        /**
         * Expiration time of the subscription, in milliseconds.
         */
        protected static readonly SUBSCRIPTION_EXPIRATION_TIME = CoreConstants.MILLISECONDS_DAY;

        /**
         * Limit disabled features based on site subscription.
         *
         * @todo This method should probably be somewhere else, but it should be refactored in the public repository.
         *
         * @param subscription Site subscription.
         * @param disabledFeatures String containing comma-separated disabled features.
         * @returns String with limited comma-separated disabled features.
         */
        static limitDisabledFeatures(subscription: SiteSubscription, disabledFeatures?: string): string {
            disabledFeatures = disabledFeatures || '';

            const disabledFeaturesArray = FreemiumUnauthenticatedSite.parseFeaturesConfigValue(disabledFeatures);
            const maxDisabledFeatures = subscription.getFeatureLimit(FreemiumFeature.DisabledFeatures);

            return maxDisabledFeatures !== null && disabledFeaturesArray.length > maxDisabledFeatures
                ? disabledFeaturesArray.slice(0, maxDisabledFeatures).join(',')
                : disabledFeatures;
        }

        /**
         * Parse a config value that contains language strings.
         *
         * @param value Config value.
         * @returns A map of strings classified by their language.
         */
        static parseStringsConfigValue(value: string): { [language: string]: string[] } {
            const languageStrings = value.split(/(?:\r\n|\r|\n)/);
            const languageStringsMap = {};

            for (const languageString of languageStrings) {
                const values = languageString.split('|');

                if (values.length < 3) {
                    // Not enough data, ignore the entry.
                    continue;
                }

                const language = values[2].replace(/_/g, '-'); // Use the app format instead of Moodle format.

                if (!(language in languageStringsMap)) {
                    languageStringsMap[language] = [];
                }

                languageStringsMap[language].push(languageString);
            }

            return languageStringsMap;
        }

        /**
         * Parse a config value that contains features.
         *
         * @param value Config value.
         * @returns An array of strings containing the features.
         */
        static parseFeaturesConfigValue(value: string): string[] {
            return value.split(',');
        }

        /**
         * @inheritdoc
         */
        getInfo!: () => FreemiumSiteInfo | undefined;

        /**
         * @inheritdoc
         */
        protected getDisabledFeatures(): string | undefined {
            return FreemiumUnauthenticatedSite.limitDisabledFeatures(this.subscription, super.getDisabledFeatures());
        }

        /**
         * @inheritdoc
         */
        getLogoUrl(config?: FreemiumSitePublicConfig): string | undefined {
            const subscription = config?.subscription ?
                SiteSubscription.fromJSON(config.subscription) :
                new DefaultSiteSubscription();

            if (!subscription.isFeatureEnabled(FreemiumFeature.SiteLogo)) {
                // Don't display the logo.
                return;
            }

            return super.getLogoUrl(config);
        }

        /**
         * @inheritdoc
         */
        async getPublicConfig(): Promise<FreemiumSitePublicConfig> {
            const config = await super.getPublicConfig();

            try {
                const subscription = await this.getSubscription();

                (config as FreemiumSitePublicConfig).subscription = subscription.toJSON();
            } catch (error) {
                // Silence error, we'll consider the site to have no subscription.
            }

            return config;
        }

        /**
         * Site subscription instance.
         */
        // eslint-disable-next-line @typescript-eslint/naming-convention
        protected _subscription?: SiteSubscription;

        /**
         * Site subscription.
         *
         * @returns Site subscription.
         */
        get subscription(): SiteSubscription {
            if (!this._subscription && this.hasInfo('subscription')) {
                this._subscription = SiteSubscription.fromJSON(this.getInfo()?.subscription as SiteSubscriptionJson);
            }

            return this._subscription || FreemiumUnauthenticatedSite.DEFAULT_SUBSCRIPTION;
        }

        /**
         * Get site subscription.
         *
         * @returns Promise resolved with the subscription.
         */
        async getSubscription(): Promise<SiteSubscription> {
            let subscription = await this.getCachedSubscription();

            if (!subscription) {
                // Get the subscription from the server.
                const data = await SiteSubscriptions.fetchSubscription(this);
                subscription = data?.freshSubscription ?? null;

                if (subscription === null) {
                    subscription = FreemiumUnauthenticatedSite.DEFAULT_SUBSCRIPTION;
                }

                try {
                    await this.storeSubscriptionInCache(subscription);
                } catch (err) {
                    // Ignore errors.
                }
            }

            return subscription;
        }

        /**
         * Get a subscription from the cache if it isn't expired.
         *
         * @returns Promise resolved with the subscription. Null if not found or it's expired.
         */
        protected async getCachedSubscription(): Promise<SiteSubscription | null> {
            // Check if the subscription data has expired.
            const lastSubscriptionUpdate = await FreemiumStorage.get(this.getLastSubscriptionUpdateCacheKey(), 0);

            if (Date.now() < lastSubscriptionUpdate + FreemiumUnauthenticatedSite.SUBSCRIPTION_EXPIRATION_TIME) {
                // Not expired, get the subscription from the storage.
                const subscriptionJson = await FreemiumStorage.get<SiteSubscriptionJson>(this.getSubscriptionCacheKey());

                return subscriptionJson ? SiteSubscription.fromJSON(subscriptionJson) : null;
            }

            return null;
        }

        /**
         * Get a subscription from the cache if it isn't expired directly from the database, without using any optimizations.
         *
         * @returns Promise resolved with the subscription. Null if not found or it's expired.
         */
        async getCachedSubscriptionFromDB(): Promise<SiteSubscription | null> {
            // Check if the subscription data has expired.
            const lastSubscriptionUpdate = await FreemiumStorage.getFromDB(this.getLastSubscriptionUpdateCacheKey(), 0);

            if (Date.now() < lastSubscriptionUpdate + FreemiumUnauthenticatedSite.SUBSCRIPTION_EXPIRATION_TIME) {
                // Not expired, get the subscription from the storage.
                const subscriptionJson = await FreemiumStorage.getFromDB<SiteSubscriptionJson>(this.getSubscriptionCacheKey());

                return subscriptionJson ? SiteSubscription.fromJSON(subscriptionJson) : null;
            }

            return null;
        }

        /**
         * Invalidate the cached subscription so it's expired, to force getting it from server the next time it's retrieved.
         *
         * @returns Promise resolved when done.
         */
        async removeCachedSubscription(): Promise<void> {
            await Promise.all([
                FreemiumStorage.remove(this.getSubscriptionCacheKey()),
                FreemiumStorage.remove(this.getLastSubscriptionUpdateCacheKey()),
            ]);
        }

        /**
         * Store subscription in cache.
         *
         * @param subscription Subscription to store.
         * @returns Promise resolved when done.
         */
        protected async storeSubscriptionInCache(subscription: SiteSubscription): Promise<void> {
            await Promise.all([
                FreemiumStorage.set(this.getSubscriptionCacheKey(), subscription.toJSON()),
                FreemiumStorage.set(this.getLastSubscriptionUpdateCacheKey(), Date.now()),
            ]);
        }

        /**
         * Get subscription cache key.
         *
         * @returns Subscription cache key.
         */
        protected getSubscriptionCacheKey(): string {
            return FreemiumUnauthenticatedSite.SUBSCRIPTION_KEY_PREFIX + Md5.hashAsciiStr(this.siteUrl);
        }

        /**
         * Get last subscription update cache key.
         *
         * @returns Last subscription update cache key.
         */
        protected getLastSubscriptionUpdateCacheKey(): string {
            return FreemiumUnauthenticatedSite.LAST_SUBSCRIPTION_UPDATE_KEY_PREFIX + Md5.hashAsciiStr(this.siteUrl);
        }

        /**
         * Limit custom language strings based on site subscription.
         *
         * @param value String containing custom language strings.
         * @returns String with limited custom language strings.
         */
        protected limitCustomLanguageStrings(value?: string): string | undefined {
            const maxStrings = this.subscription.getFeatureLimit(FreemiumFeature.CustomLanguageStrings);

            if (maxStrings === null || !value) {
                return value;
            }

            // Remove strings past the limit.
            const languageStringsMap = FreemiumUnauthenticatedSite.parseStringsConfigValue(value);

            for (const language in languageStringsMap) {
                if (languageStringsMap[language].length <= maxStrings) {
                    continue;
                }

                languageStringsMap[language].splice(
                    maxStrings,
                    languageStringsMap[language].length - maxStrings,
                );
            }

            // Rebuild value.
            return Object.values(languageStringsMap).flat().join('\n');
        }

    };
}

/**
 * Override the base unauthenticated site to add freemium-specific limitations.
 */
export class FreemiumUnauthenticatedSite extends applyFreemiumUnauthenticatedSiteCode(CoreUnauthenticatedSite) {}

export type FreemiumSiteInfo = { subscription?: SiteSubscriptionJson } & CoreSiteInfo;
export type FreemiumSitePublicConfig = { subscription?: SiteSubscriptionJson } & CoreSitePublicConfigResponse;
