<ion-header>
    <ion-toolbar>
        <ion-title>
            <h1>{{ 'core.dataprivacy.contactdataprotectionofficer' | translate }}</h1>
        </ion-title>
        <ion-buttons slot="end">
            <ion-button fill="clear" (click)="close()" [ariaLabel]="'core.close' | translate">
                <ion-icon slot="icon-only" name="fas-xmark" aria-hidden="true" />
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <form [formGroup]="form" name="contactDPO" (ngSubmit)="send($event)">
        <ion-item *ngIf="email">
            <ion-label>
                <p class="item-heading">
                    {{ 'core.dataprivacy.replyto' | translate }}
                </p>
                <p>{{ email }}</p>
            </ion-label>
        </ion-item>
        <ion-item>
            <ion-textarea labelPlacement="floating" placeholder="{{ 'core.dataprivacy.message' | translate }}" rows="5"
                [(ngModel)]="message" name="text" [required]="true" formControlName="message">
                <div [core-mark-required]="true" slot="label">
                    {{ 'core.dataprivacy.message' | translate }}
                </div>
            </ion-textarea>
        </ion-item>
    </form>
</ion-content>
<ion-footer slot="fixed" class="ion-padding">
    <ion-button expand="block" (click)="send($event)" [disabled]="!form.valid">
        {{ 'core.dataprivacy.send' | translate }}
    </ion-button>
</ion-footer>
