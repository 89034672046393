// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Constructor } from '@/core/utils/types';
import { applyFreemiumAuthenticatedSiteCode, FreemiumAuthenticatedSite } from './authenticated-site';
import { CoreSite, CoreSiteConfig } from '@classes/sites/site';
import { FreemiumSitePublicConfig, applyFreemiumUnauthenticatedSiteCode } from './unauthenticated-site';
import { FreemiumStorage } from '@freemium/services/storage';

/**
 * Function to override a site class to add site freemium-specific code.
 *
 * @returns Class.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function applyFreemiumSiteCode<TBase extends Constructor<CoreSite & FreemiumAuthenticatedSite>>(
    Base: TBase,
) {
    return class FreemiumSite extends Base {

        /**
         * Subscription that will be used if one hasn't been fetched.
         */
        protected static readonly HAS_LOGO_KEY = 'hasSiteLogoConfigured';

        /**
         * @inheritdoc
         */
        getStoredConfig(): CoreSiteConfig | undefined;
        getStoredConfig(name: string): string | undefined;
        getStoredConfig(name?: string): string | CoreSiteConfig | undefined {
            const value = super.getStoredConfig(name as string) as string | CoreSiteConfig | undefined;

            switch (name) {
                case 'tool_mobile_customlangstrings':
                    return this.limitCustomLanguageStrings(value as string | undefined);
                default:
                    return value;
            }
        }

        /**
         * Get the stored config of this site without applying freemium restrictions.
         *
         * @param name Name of the setting to get. If not set, all settings will be returned.
         * @returns Site config or a specific setting without freemium restrictions.
         */
        getUnrestrictedStoredConfig(): CoreSiteConfig | undefined;
        getUnrestrictedStoredConfig(name: string): string | undefined;
        getUnrestrictedStoredConfig(name?: string): string | CoreSiteConfig | undefined {
            return super.getStoredConfig(name as string);
        }

        /**
         * @inheritdoc
         */
        async getPublicConfig(): Promise<FreemiumSitePublicConfig> {
            const config = await super.getPublicConfig();

            if (this.id) {
                const hasLogo = !!(config.logourl || config.compactlogourl);
                const storage = FreemiumStorage.forSite(this);

                try {
                    await storage.set(FreemiumSite.HAS_LOGO_KEY, hasLogo);
                } catch (ex) {
                    // Ignore errrors.
                }
            }

            return config;
        }

        /**
         * Whether the site has a logo configured.
         *
         * @returns Promise resolved with boolean: whether the site has a logo configured.
         */
        async hasSiteLogoConfigured(): Promise<boolean> {
            const storage = FreemiumStorage.forSite(this);

            const hasLogo = await storage.get<boolean>(FreemiumSite.HAS_LOGO_KEY);

            if (hasLogo !== null) {
                return hasLogo;
            }

            // We don't know if site has logo. Get public config to store it.
            try {
                await this.getPublicConfig();

                return storage.get<boolean>(FreemiumSite.HAS_LOGO_KEY, false);
            } catch (ex) {
                return false;
            }
        }

        /**
         * @inheritdoc
         */
        async invalidateCaches(): Promise<void> {
            await Promise.all([
                super.invalidateCaches(),
                this.removeCachedSubscription(),
            ]);
        }

    };
}

/**
 * Override the base site to add freemium-specific limitations.
 */
export class FreemiumSite extends
    applyFreemiumSiteCode(applyFreemiumAuthenticatedSiteCode(applyFreemiumUnauthenticatedSiteCode(CoreSite))) {}
