// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CoreCoursesCourseListItemComponent } from '@features/courses/components/course-list-item/course-list-item';
import { CoreCourseListItem, CoreCourses } from '@features/courses/services/courses';
import { CoreUtils } from '@services/utils/utils';

/**
 * This component is meant to display a course card on the program navigation.
 *
 * Example usage:
 *
 * <workplace-course-summary-card [course]="course">
 * </workplace-course-summary-card>
 */
@Component({
    selector: 'workplace-course-summary-card',
    templateUrl: 'course-summary-card.html',
    styleUrls: ['../../program.scss', 'course-summary-card.scss'],
})
export class WorkplaceCourseSummaryCardComponent extends CoreCoursesCourseListItemComponent implements OnInit, OnChanges {

    @Input() course!: CoreCourseListItem & {
        hasprogress?: boolean;
        dataCourseImage?: boolean;
    }; // The course to render.

    @Input() extraBadge = '';

    /**
     * @inheritdoc
     */
    async ngOnInit(): Promise<void> {
        this.isEnrolled = await CoreUtils.promiseWorks(CoreCourses.getUserCourse(this.course.id));
    }

    /**
     * @inheritdoc
     */
    ngOnChanges(): void {
        this.course.dataCourseImage = this.course.courseImage?.startsWith('data');
    }

}
