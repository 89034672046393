<ion-header>
    <ion-toolbar>
        <ion-title>
            <h1 *ngIf="title">{{ title | translate }}</h1>
        </ion-title>

        <ion-buttons slot="end">
            <ion-button fill="clear" (click)="cancel()">{{ 'core.cancel' | translate }}</ion-button>
            <ion-button *ngIf="hasCaptured" (click)="done()">{{ 'core.done' | translate }}</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <core-loading [hideUntil]="readyToCapture">
        <div class="core-av-wrapper">
            <!-- Video stream for image and video. -->
            <video [hidden]="hasCaptured" class="core-webcam-stream" autoplay #streamVideo></video>

            <!-- For video recording, use 2 videos and show/hide them because a CSS rule caused problems with the controls. -->
            <video *ngIf="isVideo" [hidden]="!hasCaptured" class="core-webcam-video-captured" controls #previewVideo
                controlsList="nodownload">
            </video>

            <!-- Canvas to treat the image and an img to show the result. -->
            <canvas *ngIf="isImage" class="core-webcam-image-canvas" #imgCanvas></canvas>
            <img *ngIf="isImage" [hidden]="!hasCaptured" class="core-webcam-image" alt="{{ 'core.capturedimage' | translate }}"
                #previewImage>
        </div>
    </core-loading>
</ion-content>

<ion-footer *ngIf="readyToCapture">
    <ion-row>
        <ion-col />
        <ion-col class="ion-text-center">
            <ion-button fill="clear" *ngIf="!hasCaptured" (click)="actionClicked()" [ariaLabel]="title">
                <ion-icon *ngIf="!isCapturing && isVideo" name="fas-video" slot="icon-only" aria-hidden="true" />
                <ion-icon *ngIf="isImage" name="fas-camera" slot="icon-only" aria-hidden="true" />
                <ion-icon *ngIf="isCapturing" name="fas-square" slot="icon-only" aria-hidden="true" />
            </ion-button>
            <ion-button fill="clear" *ngIf="hasCaptured" (click)="discard()" [ariaLabel]="'core.discard' | translate">
                <ion-icon color="danger" name="fas-trash" slot="icon-only" aria-hidden="true" />
            </ion-button>
        </ion-col>
        <ion-col class="ion-padding ion-text-end chrono-container">
            <core-chrono *ngIf="!isImage" [hidden]="hasCaptured" [running]="isCapturing" [reset]="resetChrono" [endTime]="maxTime"
                (onEnd)="stopCapturing()" />
        </ion-col>
    </ion-row>
</ion-footer>
