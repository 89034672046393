<ion-header collapsible>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button [text]="'core.back' | translate" />
        </ion-buttons>
        <ion-title>
            <h1>{{ title }}</h1>
        </ion-title>

        <ion-buttons slot="end" />
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-refresher slot="fixed" [disabled]="!content || !content.ptrEnabled || !content.content || !content.content.dataLoaded"
        (ionRefresh)="refreshData($event.target)">
        <ion-refresher-content pullingText="{{ 'core.pulltorefresh' | translate }}" />
    </ion-refresher>
    <core-site-plugins-module-index [module]="module" [courseId]="courseId" [pageTitle]="title" />
</ion-content>
