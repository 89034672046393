<ion-item-divider [sticky]="true">
    <ion-label>
        <h2>{{ 'addon.block_starredcourses.pluginname' | translate }}</h2>
    </ion-label>
    <div slot="end" class="flex-row">
        <core-horizontal-scroll-controls #scrollControls [aria-controls]="scrollElementId" />
    </div>
</ion-item-divider>
<core-loading [hideUntil]="loaded">
    <core-empty-box *ngIf="courses.length === 0" image="assets/img/icons/courses.svg"
        [message]="'addon.block_starredcourses.nocourses' | translate" />
    <!-- List of courses. -->
    <div [hidden]="courses.length === 0" [id]="scrollElementId" class="core-horizontal-scroll"
        (scroll)="scrollControls.updateScrollPosition()">
        <div (onResize)="scrollControls.updateScrollPosition()" class="flex-row">
            <div class="safe-area-pseudo-padding-start"></div>
            <ng-container *ngFor="let course of courses">
                <core-courses-course-list-item [course]="course" class="core-block_starredcourses" layout="summarycard" />
            </ng-container>
            <div class="safe-area-pseudo-padding-end"></div>
        </div>
    </div>
</core-loading>
