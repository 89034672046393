<ion-card class="card-file">
    <ion-item *ngIf="file" button class="ion-text-wrap item-file" (click)="download($event, true)" [detail]="false">
        <ion-thumbnail slot="start">
            <img [src]="fileIcon" alt="" role="presentation" />
        </ion-thumbnail>
        <ion-label>
            <p class="item-heading">{{fileName}}</p>
            <p *ngIf="fileSizeReadable || showTime">
                <ng-container *ngIf="fileSizeReadable">{{ fileSizeReadable }}</ng-container>
                <ng-container *ngIf="fileSizeReadable && showTime"> · </ng-container>
                <ng-container *ngIf="showTime">{{ timemodified * 1000 | coreFormatDate }}</ng-container>
            </p>
        </ion-label>
        <div slot="end" class="flex-row">
            <core-download-refresh [status]="state" [enabled]="canDownload" [loading]="isDownloading" [canTrustDownload]="!alwaysDownload"
                (action)="download()" />

            <ion-button fill="clear" *ngIf="isDownloaded && isIOS" (click)="openFile($event, true)" [title]="openButtonLabel | translate">
                <ion-icon slot="icon-only" [name]="openButtonIcon" aria-hidden="true" />
            </ion-button>

            <ion-button fill="clear" *ngIf="!isDownloading && canDelete" (click)="delete($event)" [ariaLabel]="'core.delete' | translate"
                color="danger">
                <ion-icon slot="icon-only" name="fas-trash" aria-hidden="true" />
            </ion-button>
        </div>
    </ion-item>
</ion-card>
