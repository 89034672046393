// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { NgModule, Type } from '@angular/core';

import { AddonMessageOutputAirnotifierModule } from './airnotifier/airnotifier.module';

/**
 * Get message output services.
 *
 * @returns Returns message output services.
 */
export async function getMessageOutputServices(): Promise<Type<unknown>[]> {
    const { AddonMessageOutputDelegateService } = await import('@addons/messageoutput/services/messageoutput-delegate');

    return [
        AddonMessageOutputDelegateService,
    ];
}

@NgModule({
    imports: [
        AddonMessageOutputAirnotifierModule,
    ],
})
export class AddonMessageOutputModule {}
