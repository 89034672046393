<ion-header>
    <ion-toolbar>
        <ion-title>
            <h1>{{ 'addon.notes.addnewnote' | translate }}</h1>
        </ion-title>
        <ion-buttons slot="end">
            <ion-button fill="clear" (click)="closeModal()" [ariaLabel]="'core.close' | translate">
                <ion-icon name="fas-xmark" slot="icon-only" aria-hidden="true" />
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <form name="itemEdit" (ngSubmit)="addNote($event)" #itemEdit>
        <ion-item>
            <ion-select [(ngModel)]="type" name="publishState" interface="popover" [label]="'addon.notes.publishstate' | translate">
                <ion-select-option value="personal">{{ 'addon.notes.personalnotes' | translate }}</ion-select-option>
                <ion-select-option value="course">{{ 'addon.notes.coursenotes' | translate }}</ion-select-option>
                <ion-select-option value="site">{{ 'addon.notes.sitenotes' | translate }}</ion-select-option>
            </ion-select>
        </ion-item>
        <ion-item>
            <ion-textarea [ariaLabel]="'addon.notes.note' | translate" placeholder="{{ 'addon.notes.note' | translate }}" rows="5"
                [(ngModel)]="text" name="text" required="required" />
        </ion-item>
        <div class="ion-padding">
            <ion-button expand="block" type="submit" [disabled]="processing || text.length < 2">
                {{ 'addon.notes.addnewnote' | translate }}
            </ion-button>
        </div>
    </form>
</ion-content>
