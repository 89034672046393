// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';

import { CoreSettingsHelperProvider } from '@features/settings/services/settings-helper';

import { FreemiumSites } from '@freemium/overrides/core/services/sites';
import { makeSingleton } from '@singletons';

/**
 * Override the settings helper provider to add functionality specific to freemium.
 */
@Injectable({ providedIn: 'root' })
export class FreemiumSettingsHelperProvider extends CoreSettingsHelperProvider {

    /**
     * @inheritdoc
     */
    async synchronizeSite(syncOnlyOnWifi: boolean, siteId: string): Promise<void> {
        const site = await FreemiumSites.getSite(siteId);

        try {
            await site.removeCachedSubscription();
        } catch (err) {
            // Ignore errors.
        }

        return super.synchronizeSite(syncOnlyOnWifi, siteId);
    }

}

export const FreemiumSettingsHelper = makeSingleton<FreemiumSettingsHelperProvider>(CoreSettingsHelperProvider);
