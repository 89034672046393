<ion-item-divider [sticky]="true">
    <ion-label>
        <h2>{{ 'workplace.mylearning' | translate }}</h2>
    </ion-label>
</ion-item-divider>
<core-loading [hideUntil]="loaded" [fullscreen]="false" class="margin">
    <ion-row class="ion-justify-content-between ion-align-items-center addon-block-mycourses-filter" *ngIf="hasContent">
        <ion-col size="auto">
            <core-combobox [label]="'core.courses.filtermycourses' | translate" [selection]="controls.filter"
                (onChange)="filterChanged($event)">
                <ion-select-option value="all">{{ 'workplace.all' | translate }}</ion-select-option>
                <ion-select-option value="courses">{{ 'workplace.courses' | translate }}</ion-select-option>
                <ion-select-option value="programs" class="core-select-option-border-bottom">{{ 'workplace.programs' | translate }}
                </ion-select-option>
                <ion-select-option value="incomplete">{{ 'workplace.incomplete' | translate }}</ion-select-option>
                <ion-select-option value="complete">{{ 'workplace.complete' | translate }}</ion-select-option>
            </core-combobox>
        </ion-col>
        <ion-col size="auto">
            <core-combobox [label]="'core.sortby' | translate" [selection]="controls.sort" (onChange)="sortChanged($event)"
                icon="fas-arrow-down-short-wide" class="no-border">
                <ion-select-option class="ion-text-wrap" value="name">
                    {{'workplace.sortbyname' | translate}}
                </ion-select-option>
                <ion-select-option class="ion-text-wrap" value="duedate" *ngIf="!oldWS">
                    {{'workplace.sortbyduedate' | translate}}
                </ion-select-option>
                <ion-select-option class="ion-text-wrap" value="lastaccess">
                    {{'workplace.sortbylastaccess' | translate}}
                </ion-select-option>
            </core-combobox>
        </ion-col>
    </ion-row>

    @if (filteredItems.length === 0) {
        <core-empty-box image="assets/img/icons/courses.svg">
            <p class="item-heading">
                @if (hasContent) {
                    {{'addon.block_myoverview.noresult' | translate}}
                } @else {
                    {{'addon.block_myoverview.nocoursesenrolled' | translate}}
                }
            </p>
        </core-empty-box>
    }

    <!-- List of programs. -->
    <div class="safe-area-page">
        <ion-grid class="ion-no-padding">
            <ion-row class="ion-no-padding">
                <ion-col *ngFor="let programsOrCourse of filteredItems" class="ion-no-padding" size="12" size-sm="6" size-md="6" size-lg="4"
                    size-xl="3">
                    <ion-card *ngIf="programsOrCourse.isprogram" [attr.program-color]="programsOrCourse.id % 10">
                        <div (click)="openProgram(programsOrCourse)" class="addon-programsoverview-program-thumb"
                            [class.core-course-color-img]="programsOrCourse.titleImage" [style.background-color]="programsOrCourse.color">
                            <img *ngIf="programsOrCourse.titleImage" [url]="programsOrCourse.image[0].fileurl" core-external-content
                                alt="" />
                            <ion-badge *ngIf="programsOrCourse.certificationscount > 0" class="addon-programsoverview-program-allocations">
                                <ion-icon name="wp-certificate" aria-hidden="true" />{{programsOrCourse.certificationscount}}
                            </ion-badge>
                        </div>
                        <ion-item button class="ion-text-wrap addon-programsoverview-program-link" [detail]="false" lines="none"
                            (click)="openProgram(programsOrCourse)" [attr.aria-label]="programsOrCourse.fullname"
                            [class.item-disabled]="programsOrCourse.visible === 0">
                            <ion-label class="addon-programsoverview-program-title">
                                <p class="addon-programsoverview-type">{{ 'workplace.program' | translate }}</p>
                                <p class="item-heading">
                                    <core-format-text [text]="programsOrCourse.fullname" />
                                </p>
                            </ion-label>
                            <div class="core-button-spinner" slot="end"
                                *ngIf="downloadCourseEnabled && downloadEnabled && prefetchProgramData[programsOrCourse.id]">
                                <!-- Download course. -->
                                <core-download-refresh [status]="prefetchProgramData[programsOrCourse.id].status"
                                    [enabled]="downloadCourseEnabled"
                                    [statusTranslatable]="prefetchProgramData[programsOrCourse.id].statusTranslatable"
                                    [canTrustDownload]="false" [loading]="prefetchProgramData[programsOrCourse.id].loading"
                                    (action)="prefetchProgram(programsOrCourse, $event)" />
                            </div>
                        </ion-item>
                        <ion-item class="addon-programsoverview-more core-course-progress" *ngIf="programsOrCourse.duedate > 0 ||
                                (programsOrCourse.showprogress && programsOrCourse.progress !== null &&
                                    programsOrCourse.progress >= 0)" lines="none">
                            <ion-label>
                                <span *ngIf="programsOrCourse.duedate > 0">
                                    {{ 'workplace.duedate' | translate }}:
                                    {{ programsOrCourse.duedate * 1000 | coreFormatDate: "strftimedatefullshort" }}
                                </span>
                                <span *ngIf="programsOrCourse.showprogress && programsOrCourse.progress !== null &&
                                    programsOrCourse.progress >= 0">
                                    {{ programsOrCourse.progress }}% {{ 'workplace.completed' | translate }}
                                </span>
                            </ion-label>
                        </ion-item>
                        <ion-item class="addon-programsoverview-actions" lines="none">
                            <ion-label>
                                <ion-grid class="ion-no-padding">
                                    <ion-row class="ion-no-padding ion-align-items-center">
                                        <ion-col>
                                            <ion-button expand="block" fill="outline" (click)="openProgram(programsOrCourse)">
                                                {{ 'workplace.seecontent' | translate }}
                                            </ion-button>
                                        </ion-col>
                                        <ion-col *ngIf="programsOrCourse.ongoingcourseid">
                                            <ion-button expand="block" (click)="openCourse(programsOrCourse)">
                                                {{ 'workplace.' + programsOrCourse.calltoaction | translate }}
                                            </ion-button>
                                        </ion-col>
                                        <ion-col *ngIf="!programsOrCourse.ongoingcourseid && programsOrCourse.iscompleted">
                                            <ion-button expand="block" fill="outline">
                                                <ion-icon name="wp-completed" color="success" slot="start" aria-hidden="true" />
                                                {{ 'workplace.' + programsOrCourse.calltoaction | translate }}
                                            </ion-button>
                                        </ion-col>
                                    </ion-row>
                                </ion-grid>
                            </ion-label>
                        </ion-item>
                    </ion-card>
                    <workplace-programsoverview-course-progress *ngIf="!programsOrCourse.isprogram" [course]="programsOrCourse"
                        class="core-courseoverview" [showDownload]="downloadCourseEnabled && downloadEnabled" />
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</core-loading>
