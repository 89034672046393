// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { APP_INITIALIZER, NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Routes } from '@angular/router';

import { CoreMainMenuHomeRoutingModule } from '@features/mainmenu/mainmenu-home-routing.module';
import { CoreMainMenuHomeDelegate } from '@features/mainmenu/services/home-delegate';
import { WorkplaceTeamsHomeHandler, WorkplaceTeamsHomeHandlerService } from './services/handlers/teams-home';
import { WorkplaceTeamsComponentsModule } from './components/components.module';
import { CoreBlockDelegate } from '@features/block/services/block-delegate';
import { WorkplaceBlockMyTeamsHandler } from './services/handlers/myteams-block';
import { CoreMainMenuTabRoutingModule } from '@features/mainmenu/mainmenu-tab-routing.module';
import { conditionalRoutes } from '@/app/app-routing.module';
import { CoreScreen } from '@services/screen';
import { CoreUserDelegate } from '@features/user/services/user-delegate';
import { WorkplaceLearningUserHandler } from './services/handlers/learning-user';

const mainMenuHomeRoutes: Routes = [
    {
        path: WorkplaceTeamsHomeHandlerService.PAGE_NAME,
        loadChildren: () => import('./teams-lazy.module').then(m => m.WorkplaceTeamsLazyModule),
    },
];

const mobileRoutes: Routes = [
    {
        path: `${WorkplaceTeamsHomeHandlerService.PAGE_NAME}/:userId`,
        loadChildren: () =>
            import('./pages/learningstatus/learningstatus.module').then(m => m.WorkplaceLearningStatusPageModule),
    },
];

const routes: Routes = [
    ...conditionalRoutes(mobileRoutes, () => CoreScreen.isMobile),
    {
        path: 'learningstatus',
        loadChildren: () =>
            import('./pages/learningstatus/learningstatus.module').then(m => m.WorkplaceLearningStatusPageModule),
    },
];

@NgModule({
    imports: [
        CoreMainMenuHomeRoutingModule.forChild({ children: mainMenuHomeRoutes }),
        CoreMainMenuTabRoutingModule.forChild(routes),
        IonicModule,
        TranslateModule.forChild(),
        WorkplaceTeamsComponentsModule,
    ],
    exports: [CoreMainMenuHomeRoutingModule],
    providers: [
        {
            provide: APP_INITIALIZER,
            multi: true,
            useValue: () => {
                CoreMainMenuHomeDelegate.registerHandler(WorkplaceTeamsHomeHandler.instance);
                CoreBlockDelegate.registerHandler(WorkplaceBlockMyTeamsHandler.instance);
                CoreUserDelegate.registerHandler(WorkplaceLearningUserHandler.instance);
            },
        },
    ],
})
export class WorkplaceTeamsModule {}
