<!-- Read only. -->
<ion-item class="ion-text-wrap" *ngIf="files && files.length && !edit">
    <ion-label>
        <h2>{{ plugin.name }}</h2>
        <div>
            <core-files [files]="files" [component]="component" [componentId]="assign.cmid" [alwaysDownload]="true" />
        </div>
    </ion-label>
</ion-item>

<!-- Edit -->
<div *ngIf="edit">
    <ion-item-divider class="ion-text-wrap" [sticky]="true">
        <ion-label>
            <h2>{{ plugin.name }}</h2>
        </ion-label>
    </ion-item-divider>
    <core-attachments [files]="files" [maxSize]="maxSize" [maxSubmissions]="maxSubmissions" [courseId]="assign.course"
        [component]="component" [componentId]="assign.cmid" [acceptedTypes]="acceptedTypes" [allowOffline]="allowOffline" />
</div>
