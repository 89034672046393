<ion-header>
    <ion-toolbar>
        <ion-title>
            <h2 [id]="listId+'-label'">{{ title }}</h2>
        </ion-title>
        <ion-buttons slot="end">
            <ion-button fill="clear" (click)="closeModal()" [attr.aria-label]="'core.close' | translate">
                <ion-icon name="fas-times" slot="icon-only" aria-hidden="true" />
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-radio-group [id]="listId" [attr.aria-labelledby]="listId+'-label'" [(ngModel)]="selected" (ionChange)="select()">
        <ion-item class="ion-text-wrap">
            <ion-radio [value]="0">
                <p class="item-heading">{{ emptyLabel }}</p>
            </ion-radio>
        </ion-item>
        <ng-container *ngFor="let subSet of itemsTree">
            <ng-container *ngTemplateOutlet="tree; context: {item: subSet}" />
        </ng-container>
    </ion-radio-group>
</ion-content>

<ng-template #tree let-item="item">
    <ion-item class="ion-text-wrap" *ngIf="item.parentid === 0">
        <ion-label>
            <h2>{{ item.name }}</h2>
        </ion-label>
    </ion-item>
    <ion-item class="ion-text-wrap" *ngIf="item.parentid !== 0">
        <ion-radio [value]="item.id">
            <p>{{ item.name }}</p>
        </ion-radio>
    </ion-item>
    <ion-list *ngIf="item.children">
        <ng-container *ngFor="let subSet of item.children">
            <ng-container *ngTemplateOutlet="tree; context: {item: subSet}" />
        </ng-container>
    </ion-list>
</ng-template>
