// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Workplace, WorkplaceCourse, WorkplaceToolProgramProgramData, WorkplaceService } from '@workplace/services/workplace';
import { CoreBlockBaseComponent } from '@features/block/classes/base-block-component';
import { CoreCourseBasicData, CoreCourses, CoreCoursesProvider } from '@features/courses/services/courses';
import { CoreSites } from '@services/sites';
import { CoreEventObserver, CoreEvents } from '@singletons/events';
import { CoreSite } from '@classes/sites/site';
import { CoreCourseHelper, CorePrefetchStatusInfo } from '@features/course/services/course-helper';
import { CoreCourseOptionsDelegate } from '@features/course/services/course-options-delegate';
import { AddonCourseCompletion } from '@addons/coursecompletion/services/coursecompletion';
import { CoreUtils } from '@services/utils/utils';
import { CoreDomUtils } from '@services/utils/dom';
import { CoreCoursesHelper, CoreEnrolledCourseDataWithExtraInfoAndOptions } from '@features/courses/services/courses-helper';
import { WorkplaceHelper } from '@workplace/services/workplace-helper';
import { CoreNavigator } from '@services/navigator';
import { WorkplaceMyCoursesFilterValues, WorkplaceMyCoursesSortValues } from '../mycourses/mycourses';

/**
 * Component to render a programs overview block.
 * This block is not used on Workplace 4.0 onwards.
 */
@Component({
    selector: 'block-mylearning',
    templateUrl: 'block-mylearning.html',
    styleUrls: ['mylearning.scss'],
})
export class WorkplaceBlockMyLearningComponent extends CoreBlockBaseComponent implements OnInit, OnDestroy {

    @Input() downloadEnabled = false;
    downloadCourseEnabled = false;

    filteredItems: (WorkplaceProgramToDisplay | WorkplaceCourseToDisplay)[] = [];
    prefetchProgramData: Record<number, CorePrefetchStatusInfo> = {};

    controls: {
        filter: WorkplaceMyCoursesFilterValues;
        sort: WorkplaceMyCoursesSortValues;
    } = {
        filter: WorkplaceMyCoursesFilterValues.ALL,
        sort: WorkplaceMyCoursesSortValues.NAME,
    };

    hasContent = false;

    protected isDestroyed = false;
    protected currentSite: CoreSite;
    protected prefetchIconsInitialized = false;
    protected downloadButtonObserver?: CoreEventObserver;
    protected siteUpdatedObserver?: CoreEventObserver;
    protected courseStatusObserver?: CoreEventObserver;
    protected completionObserver?: CoreEventObserver;
    protected enrolObserver?: CoreEventObserver;
    protected programs: WorkplaceProgramToDisplay[] = [];
    protected courses: WorkplaceCourseToDisplay[] = [];

    oldWS = false;

    constructor() {
        super('WorkplaceBlockMyLearningComponent');
        this.currentSite = CoreSites.getRequiredCurrentSite();
    }

    /**
     * @inheritdoc
     */
    async ngOnInit(): Promise<void> {
        // Refresh the enabled flags if enabled.
        this.downloadButtonObserver = CoreEvents.on(
            CoreCoursesProvider.EVENT_DASHBOARD_DOWNLOAD_ENABLED_CHANGED,
            (data) => {
                const wasEnabled = this.downloadEnabled;

                this.downloadEnabled = data.enabled;

                if (!wasEnabled && this.downloadCourseEnabled && this.downloadEnabled && this.loaded) {
                // Download all courses is enabled now, initialize it.
                    this.initPrefetchIcons();
                }
            },
        );

        this.completionObserver = CoreEvents.onMultiple([
            CoreEvents.MANUAL_COMPLETION_CHANGED,
            CoreEvents.COMPLETION_MODULE_VIEWED,
        ], () => {
            this.refreshContent(false);
        });

        this.enrolObserver = CoreEvents.on(WorkplaceService.PROGRAMS_OVERVIEW_ENROL_EVENT, () => {
            this.refreshContent(false);
        });

        this.downloadCourseEnabled = !CoreCourses.isDownloadCourseDisabledInSite();

        if (this.downloadCourseEnabled && this.downloadEnabled) {
            this.initPrefetchIcons();
        }

        // Refresh the enabled flag if site is updated.
        this.siteUpdatedObserver = CoreEvents.on(CoreEvents.SITE_UPDATED, () => {
            const wasEnabled = this.downloadCourseEnabled;

            this.downloadCourseEnabled = !CoreCourses.isDownloadCourseDisabledInSite();

            if (!wasEnabled && this.downloadCourseEnabled && this.downloadEnabled) {
                // Download course is enabled now, initialize it.
                this.initPrefetchIcons();
            }
        }, this.currentSite.getId());

        // Listen for status change in course.
        this.courseStatusObserver = CoreEvents.on(CoreEvents.COURSE_STATUS_CHANGED, (data) => {
            this.programs.forEach((program) => {
                const found = program.programcourses.find((course) => data.courseId == course.courseid);

                if (!found) {
                    return;
                }

                const statusData = CoreCourseHelper.getCoursePrefetchStatusInfo(data.status);

                if (!this.prefetchProgramData[program.id]) {
                    this.prefetchProgramData[program.id] = statusData;

                    return;
                }

                this.prefetchProgramData[program.id].icon = statusData.icon;
            });
        }, this.currentSite.getId());

        const promises: Promise<void>[] = [];

        promises.push(this.currentSite.getLocalSiteConfig(
            'WorkplaceMyCoursesFilter',
            this.controls.filter,
        ).then((value) => {
            this.controls.filter = value;

            return;
        }));

        promises.push(this.currentSite.getLocalSiteConfig(
            'WorkplaceMyCoursesSort',
            this.controls.sort,
        ).then((value) => {
            this.controls.sort = value;

            return;
        }));

        Promise.all(promises).finally(() => {
            super.ngOnInit();
        });
    }

    /**
     * Perform the invalidate content function.
     *
     * @returns Resolved when done.
     */
    async invalidateContent(): Promise<void> {
        const promises: Promise<void>[] = [];

        promises.push(Workplace.invalidateUserPrograms());
        if (this.oldWS) {
            promises.push(CoreCourses.invalidateUserCourses().finally(() =>
                // Invalidate course completion data.
                CoreUtils.allPromises(this.courses.map((course) => AddonCourseCompletion.invalidateCourseCompletion(course.id)))));

            promises.push(CoreCourseOptionsDelegate.clearAndInvalidateCoursesOptions());
            if (this.courses.length > 0) {
                promises.push(CoreCourses.invalidateCoursesByField(
                    'ids',
                    this.courses.map((course) => course.id).join(','),
                ));
            }
        }

        await CoreUtils.allPromises(promises).finally(() => {
            this.prefetchIconsInitialized = false;
        });
    }

    /**
     * Fetch the info for programs overview.
     *
     * @returns Promise resolved when done.
     */
    protected async fetchContent(): Promise<void> {
        try {
            const userPrograms = await Workplace.getUserPrograms();
            this.programs = userPrograms.programs || [];

            this.programs.forEach((program) => {
                program.isprogram = true;
                program.titleImage = program.image && program.image[0] && program.image[0].fileurl;
                program.certificationscount = program.allocations.filter((alloc) =>
                    alloc.certificationid && alloc.certificationid > 0).length;

                const allocation = program.allocations.find((alloc) => alloc.certificationid == 0);

                program.duedate = allocation?.duedate ||  0;
            });

            this.oldWS = userPrograms.courses === undefined; // Use old before 3.9.3 and 3.10
            if (this.oldWS) {
                // @deprecatedonmoodle Since WP 3.9.3 and 3.10
                this.courses = await CoreCoursesHelper.getUserCoursesWithOptions('lastaccess', undefined, undefined, true);
                this.courses = this.courses.filter((course) => {
                    const index = userPrograms.onlyenrolprogramcourseids?.indexOf(course.id) || -1;

                    return index < 0;
                });
            } else {
                this.courses = userPrograms.courses || [];
            }

            this.courses.forEach((course) => {
                // Avoid type confussion, sometimes we got an string here.
                const progress = parseInt(String(course.progress), 10);
                course.progress = isNaN(progress) ? null : progress;
                course.isprogram = false;
            });

            this.hasContent = this.courses.length + this.programs.length > 0;

            this.filterAndSort();
        } finally {
            this.initPrefetchIcons();
        }
    }

    /**
     * Filter changed, save and apply.
     *
     * @param filter New value.
     */
    filterChanged(filter: WorkplaceMyCoursesFilterValues): void {
        this.controls.filter = filter;
        this.currentSite.setLocalSiteConfig('WorkplaceMyCoursesFilter', filter);

        this.filterAndSort();
    }

    /**
     * Sort changed, save and apply.
     *
     * @param sort New value.
     */
    sortChanged(sort: WorkplaceMyCoursesSortValues): void {
        this.controls.sort = sort;
        this.currentSite.setLocalSiteConfig('WorkplaceMyCoursesSort', sort);

        this.filterAndSort();
    }

    /**
     * Filters and sort catalogue items.
     */
    protected filterAndSort(): void {

        // Filter.
        switch (this.controls.filter) {
            case WorkplaceMyCoursesFilterValues.COURSES:
                this.filteredItems = this.courses;
                break;
            case WorkplaceMyCoursesFilterValues.PROGRAMS:
                this.filteredItems = this.programs;
                break;
            case WorkplaceMyCoursesFilterValues.COMPLETE:
                this.filteredItems = this.programs;
                this.filteredItems = this.filteredItems.concat(this.courses);

                this.filteredItems = this.filteredItems.filter((item) => item.progress === 100);
                break;
            case WorkplaceMyCoursesFilterValues.INCOMPLETE:
                this.filteredItems = this.programs;
                this.filteredItems = this.filteredItems.concat(this.courses);

                this.filteredItems = this.filteredItems.filter((item) => item.progress !== 100);
                break;
            case WorkplaceMyCoursesFilterValues.ALL:
            default:
                this.filteredItems = this.programs;
                this.filteredItems = this.filteredItems.concat(this.courses);
                // Already set.
                break;
        }

        // Sort first by name.
        this.filteredItems = this.filteredItems.sort((a, b) => {
            const compareA = a.fullname.toLowerCase();
            const compareB = b.fullname.toLowerCase();

            return compareA.localeCompare(compareB);
        });

        // Sort.
        switch (this.controls.sort) {
            case WorkplaceMyCoursesSortValues.DUEDATE:
                this.filteredItems = this.filteredItems.sort((a, b) => (a.duedate || 0) - (b.duedate || 0));
                break;
            case WorkplaceMyCoursesSortValues.LASTACCESS:
                // Last Access DESC sorting.
                this.filteredItems = this.filteredItems.sort((a, b) => (b.lastaccess || 0) - (a.lastaccess || 0));
                break;
            case WorkplaceMyCoursesSortValues.NAME:
            default:
                // Already sorted.
                break;
        }
    }

    /**
     * Initialize the prefetch icon for selected courses.
     */
    protected async initPrefetchIcons(): Promise<void> {
        if (this.prefetchIconsInitialized || !this.downloadEnabled) {
            // Already initialized.
            return;
        }

        this.prefetchIconsInitialized = true;

        this.programs.forEach(async (program) => {
            const programCourses: CoreCourseBasicData[] = program.programcourses
                .filter((course) => course.isenrolled && !course.iscompleted)
                .map((course) => ({
                    id: course.courseid,
                    fullname: '',
                    shortname: '',
                    summary: '',
                    summaryformat: 0,
                }));

            if (programCourses.length > 0) {
                this.prefetchProgramData[program.id] =
                    await CoreCourseHelper.initPrefetchCoursesIcons(programCourses, this.prefetchProgramData[program.id] || {});
            } else {
                delete this.prefetchProgramData[program.id];
            }
        });
    }

    /**
     * Open a course.
     *
     * @param program The program to open the ongoing course.
     */
    openCourse(program: WorkplaceProgramToDisplay): void {
        const course = program.programcourses.find((course) => course.courseid == program.ongoingcourseid);

        if (course) {
            WorkplaceHelper.enrolAndEnterCourse(course, program.id);
        }
    }

    /**
     * Open the program contents.
     *
     * @param program The program to open the content.
     */
    openProgram(program: WorkplaceProgramToDisplay): void {
        CoreNavigator.navigateToSitePath('program/' + program.id);
    }

    /**
     * Prefetch all the current program courses.
     *
     * @param program The program to prefetch.
     * @param e Click event.
     * @returns Promise resolved when done.
     */
    async prefetchProgram(program: WorkplaceProgramToDisplay, e: Event): Promise<void> {
        e.preventDefault();
        e.stopPropagation();

        const programCourses = program.programcourses.filter((course) =>
            course.isenrolled && !course.iscompleted).map((course) => ({
            id: course.courseid,
            fullname: '',
            shortname: '',
            summary: '',
            summaryformat: 0,
        }));

        const initialIcon = this.prefetchProgramData[program.id].icon;

        try {
            await CoreCourseHelper.prefetchCourses(programCourses, this.prefetchProgramData[program.id]);
        } catch (error) {
            if (!this.isDestroyed) {
                CoreDomUtils.showErrorModalDefault(error, 'core.course.errordownloadingcourse', true);
                this.prefetchProgramData[program.id].icon = initialIcon;
            }
        }
    }

    /**
     * @inheritdoc
     */
    ngOnDestroy(): void {
        this.isDestroyed = true;
        this.courseStatusObserver?.off();
        this.siteUpdatedObserver?.off();
        this.downloadButtonObserver?.off();
        this.completionObserver?.off();
        this.enrolObserver?.off();
    }

}

type WorkplaceProgramToDisplay = WorkplaceToolProgramProgramData & {
    isprogram?: boolean;
    titleImage?: string;
    certificationscount?: number;
    duedate?: number;
};

type WorkplaceCourseToDisplay = (CoreEnrolledCourseDataWithExtraInfoAndOptions | WorkplaceCourse) & {
    isprogram?: boolean;
    duedate?: number;
};
