// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';

import { CoreLoginHelperProvider, CoreLoginMethod } from '@features/login/services/login-helper';
import { SiteSubscription, DefaultSiteSubscription } from '@freemium/classes/site-subscription';
import { FreemiumFeature } from '@freemium/constants';
import { FreemiumBiometricLogin, FreemiumBiometricMethodAvailable } from '@freemium/services/biometric-login';
import { CoreDomUtils } from '@services/utils/dom';
import { FreemiumSites } from '@freemium/overrides/core/services/sites';
import { CoreNavigator, CoreRedirectPayload } from '@services/navigator';
import { Translate, makeSingleton } from '@singletons';
import { CoreSites } from '@services/sites';
import { CoreEventSessionExpiredData, CoreEventSiteData, CoreEvents } from '@singletons/events';
import { CoreUtils } from '@services/utils/utils';
import { FreemiumSitePublicConfig, FreemiumUnauthenticatedSite } from '@freemium/overrides/core/classes/sites/unauthenticated-site';
import { APP_UNSUPPORTED_CHURN } from '@features/login/constants';
import { FreemiumErrorTracking } from '@freemium/services/error-tracking';
import { FreemiumStorage } from '@freemium/services/storage';
import { CoreSiteErrorDebug } from '@classes/errors/siteerror';

/**
 * Override the base login helper provider to limit disabled features.
 */
@Injectable({ providedIn: 'root' })
export class FreemiumLoginHelperProvider extends CoreLoginHelperProvider {

    private static readonly CHURN_REPORTS = 'churn-reports';

    /**
     * Register service listeners.
     */
    registerListeners(): void {
        CoreEvents.on(APP_UNSUPPORTED_CHURN, async ({  siteUrl, debug }) => {
            const shouldReportChurn = await this.shouldReportChurn(siteUrl);

            if (!shouldReportChurn) {
                return;
            }

            await this.reportChurn(siteUrl, debug);
        });
    }

    /**
     * Biometric login possible options.
     */
    protected readonly BIOMETRICLOGIN_BUTTON: Record<FreemiumBiometricMethodAvailable, BiometricLoginButton> = {
        face: {
            text: 'freemium.biometricloginloginwithfaceid',
            icon: 'moodle-face-id',
        },
        finger: {
            text: 'freemium.biometricloginloginwithtouchid',
            icon: 'finger-print',
        },
        biometric: {
            text: 'freemium.biometricloginloginwithfingerprint',
            icon: 'finger-print',
        },
    };

    /**
     * @inheritdoc
     * @deprecated since 4.4. No longer needed.
     */
    getDisabledFeatures(config?: FreemiumSitePublicConfig): string {
        // eslint-disable-next-line deprecation/deprecation
        const disabledFeatures = super.getDisabledFeatures(config);
        const subscription = config?.subscription ? SiteSubscription.fromJSON(config.subscription) : new DefaultSiteSubscription();

        return FreemiumUnauthenticatedSite.limitDisabledFeatures(subscription, disabledFeatures);
    }

    /**
     * @inheritdoc
     * @deprecated since 4.4. Please use getLogoUrl in a site instance.
     */
    getLogoUrl(config: FreemiumSitePublicConfig): string | undefined {
        const subscription = config.subscription ? SiteSubscription.fromJSON(config.subscription) : new DefaultSiteSubscription();

        if (!subscription.isFeatureEnabled(FreemiumFeature.SiteLogo)) {
            // Don't display the logo.
            return;
        }

        // eslint-disable-next-line deprecation/deprecation
        return super.getLogoUrl(config);
    }

    /**
     * @inheritdoc
     */
    async getLoginMethods(): Promise<CoreLoginMethod[]> {

        const currentSite = CoreSites.getCurrentSite();
        const isValidToken = !currentSite || currentSite.isLoggedOut();

        if (!isValidToken) {
            return [];
        }

        const canShowBiometricLogin = await FreemiumBiometricLogin.canShowBiometricLogin();

        if (!canShowBiometricLogin) {
            return [];
        }

        const methodAvailable = await FreemiumBiometricLogin.getBiometricMethodAvailable();

        if (!methodAvailable) {
            return [];
        }

        return [
            {
                name: Translate.instant(this.BIOMETRICLOGIN_BUTTON[methodAvailable].text),
                icon: this.BIOMETRICLOGIN_BUTTON[methodAvailable].icon,
                action: () => this.openBiometricLogin(),
            },
        ];
    }

    /**
     * @inheritdoc
     */
    async getDefaultLoginMethod(): Promise<CoreLoginMethod | null> {
        const loginMethods = await this.getLoginMethods();

        return loginMethods[0] ?? null;
    }

    /**
     * Retrieve redirection payload.
     *
     * @returns Redirection payload or undefined.
     */
    protected getRedirectData(): CoreRedirectPayload | undefined {
        const redirectPath = CoreNavigator.getRouteParam('redirectPath');
        const urlToOpen = CoreNavigator.getRouteParam('urlToOpen');
        if (!redirectPath || !urlToOpen) {
            return;
        }

        return {
            redirectPath,
            redirectOptions: CoreNavigator.getRouteParam('redirectOptions'),
            urlToOpen,
        };
    }

    /**
     * Open biometric login (Fingerprint, FaceID, etc.)
     */
    protected async openBiometricLogin(): Promise<void> {
        const loadingModal = await CoreDomUtils.showModalLoading();
        const biometricLogin = await FreemiumBiometricLogin.login();

        if (!biometricLogin) {
            loadingModal.dismiss();

            return;
        }

        try {
            const siteId = CoreNavigator.getRequiredRouteParam<string>('siteId');
            await FreemiumSites.setSiteLoggedOut(siteId, false);
            await FreemiumSites.login(siteId);
            // Update site info.
            await FreemiumSites.updateSiteInfo(siteId);
            const redirectParams = this.getRedirectData();
            // Go to the site initial page.
            await CoreNavigator.navigateToSiteHome({ params: redirectParams });
        } finally {
            loadingModal.dismiss();
        }
    }

    /**
     * @inheritdoc
     */
    async sessionExpired(data: CoreEventSessionExpiredData & CoreEventSiteData): Promise<void> {
        const isBiometricLoginEnabled = await FreemiumBiometricLogin.canShowBiometricLogin();

        if (!isBiometricLoginEnabled) {
            await super.sessionExpired(data);

            return;
        }

        const siteId = data?.siteId;
        const currentSite = CoreSites.getCurrentSite();
        const siteUrl = currentSite?.getURL();

        if (!currentSite || !siteUrl) {
            return;
        }

        if (siteId && siteId !== currentSite.getId()) {
            return; // Site that triggered the event is not current site.
        }

        if (this.sessionExpiredCheckingSite[siteId || '']) {
            return; // Operation pending.
        }

        this.sessionExpiredCheckingSite[siteId || ''] = true;

        const redirectData: CoreRedirectPayload = {
            redirectPath: data.redirectPath,
            redirectOptions: data.redirectOptions,
            urlToOpen: data.urlToOpen,
        };

        try {
            const info = currentSite.getInfo();

            if (info === undefined || info.username === undefined || CoreNavigator.isCurrent('/login/reconnect')) {
                return;
            }

            await CoreUtils.ignoreErrors(CoreNavigator.navigate('/login/reconnect', {
                params: {
                    siteId,
                    ...redirectData,
                },
                reset: true,
            }));
        } catch (error) {
            // Error checking site.
            if (currentSite.isLoggedOut()) {
                // Site is logged out, show error and logout the user.
                CoreDomUtils.showErrorModalDefault(error, 'core.networkerrormsg', true);
                CoreSites.logout();
            }
        } finally {
            this.sessionExpiredCheckingSite[siteId || ''] = false;
        }
    }

    /**
     * Check whether app unsupported churn should be reported for the given site.
     *
     * @param siteUrl Site url.
     * @returns Whether app unsupported churn should be reported for the given site.
     */
    private async shouldReportChurn(siteUrl: string): Promise<boolean> {
        const churnReports = await FreemiumStorage.get<string[]>(FreemiumLoginHelperProvider.CHURN_REPORTS, []);

        return !churnReports.includes(siteUrl);
    }

    /**
     * Report app unsupported churn for the given site.
     *
     * @param siteUrl Site url.
     * @param debug Error info.
     */
    private async reportChurn(siteUrl: string, debug?: CoreSiteErrorDebug): Promise<void> {
        const churnReports = await FreemiumStorage.get<string[]>(FreemiumLoginHelperProvider.CHURN_REPORTS, []);

        await FreemiumErrorTracking.report(siteUrl, {
            errorCode: debug?.code,
            errorDetails: debug?.details,
        });

        churnReports.push(siteUrl);

        await FreemiumStorage.set(FreemiumLoginHelperProvider.CHURN_REPORTS, churnReports);
    }

}

export const FreemiumLoginHelper = makeSingleton<FreemiumLoginHelperProvider>(CoreLoginHelperProvider);

interface BiometricLoginButton {
    text: string;
    icon: string;
}
