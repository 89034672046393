// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { APP_INITIALIZER, NgModule } from '@angular/core';
import { Routes } from '@angular/router';

import { AppRoutingModule } from '@/app/app-routing.module';
import { CoreMainMenuDelegate } from '@features/mainmenu/services/mainmenu-delegate';
import { CoreMainMenuTabRoutingModule } from '@features/mainmenu/mainmenu-tab-routing.module';

import { FreemiumSubscriptionMainMenuHandlerService, FreemiumSubscriptionMainMenuHandler } from './services/mainmenu-handler';
import { FreemiumBasicBrandingHandler } from './services/basic-branding-handler';
import { CoreStyles } from '@features/styles/services/styles';

const appRoutes: Routes = [
    {
        path: 'settings',
        loadChildren: () => import('./subscription-lazy.module').then(m => m.FreemiumSubscriptionLazyModule),
    },
];

const mainMenuMoreRoutes: Routes = [
    {
        path: FreemiumSubscriptionMainMenuHandlerService.PAGE_NAME,
        loadChildren: () => import('./subscription-lazy.module').then(m => m.FreemiumSubscriptionLazyModule),
    },
];

@NgModule({
    imports: [
        AppRoutingModule.forChild(appRoutes),
        CoreMainMenuTabRoutingModule.forChild(mainMenuMoreRoutes),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            multi: true,
            useValue() {
                CoreMainMenuDelegate.registerHandler(FreemiumSubscriptionMainMenuHandler.instance);
                CoreStyles.registerStyleHandler(FreemiumBasicBrandingHandler.instance);
            },
        },
    ],
})
export class FreemiumSubscriptionModule {}
