// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { CoreConstants } from '@/core/constants';

import { CoreObject } from '@singletons/object';
import { CoreApp, CoreStoreConfig } from '@services/app';
import { Translate } from '@singletons';
import { BasicBrandingColor } from '@freemium/features/subscription/services/basic-branding-handler';
import { EnvironmentConfig } from '@/types/config';
import { Subscription } from '@freemium/classes/subscription';
import { FREEMIUM_PLAN_DEFAULTS, FreemiumFeature, SiteSubscriptionPlan } from '@freemium/constants';

/**
 * Subscription basic branding colors.
 */
export type SiteSubscriptionBrandingColors = { [key in BasicBrandingColor]?: string };

export type MatomoConfig = {
    enabled: boolean;
    trackingUrl: string;
    siteId: string;
    staticParams: Record<string, unknown>;
};

/**
 * JSON representation of a site subscription, used for serialization.
 */
export interface SiteSubscriptionJson {
    plan: SiteSubscriptionPlan;
    createdTimestamp: number;
    updatedTimestamp: number;
    validUntilTimestamp?: number;
    name?: string;
    description?: string;
    informationUrl?: string | null;
    limits?: SiteSubscriptionLimits;
    features?: SiteSubscriptionFeatures;
    brandingColors?: SiteSubscriptionBrandingColors;
    customCSS?: string;
    appConfig?: Partial<EnvironmentConfig>;
    storeConfig?: CoreStoreConfig;
    restrictAccess?: {
        enforce: boolean;
        message: string;
    };
    biometricLogin?: boolean;
    matomo?: MatomoConfig;
}

type SiteSubscriptionLimits = { [feature in FreemiumFeature]?: number };
type SiteSubscriptionFeatures = { [feature in FreemiumFeature]?: boolean };

// Optional json attributes mapped to class attributes
const OPTIONAL_ATTRIBUTES_MAP = {
    name: 'name',
    description: 'description',
    informationUrl: 'informationUrl',
    limits: 'limits',
    features: 'features',
    brandingColors: 'brandingColors',
    customCSS: 'customCSS',
    appConfig: 'appConfig',
    storeConfig: 'storeConfig',
    restrictAccess: 'restrictAccess',
    biometricLogin: 'biometricLogin',
    matomo: 'matomo',
};

/**
 * Subscription info.
 */
export class SiteSubscription extends Subscription {

    /**
     * Parse a site subscription json object.
     *
     * @param json Subscription json.
     * @returns Site subscription.
     */
    static fromJSON(json: SiteSubscriptionJson): SiteSubscription {
        const subscription = new SiteSubscription(
            json.plan,
            new Date(json.createdTimestamp),
            new Date(json.updatedTimestamp),
            json.validUntilTimestamp ? new Date(json.validUntilTimestamp) : undefined,
        );

        for (const [jsonAttribute, classAttribute] of Object.entries(OPTIONAL_ATTRIBUTES_MAP)) {
            if (jsonAttribute in json) {
                subscription[classAttribute] = json[jsonAttribute];
            }
        }

        this.migrateLegacyAttributes(subscription, json);

        return subscription;
    }

    /**
     * Migrate legacy attributes from v3.9.4.
     *
     * @param subscription Site Subscription.
     */
    private static migrateLegacyAttributes(subscription: SiteSubscription, json: SiteSubscriptionJson): void {
        const mainAppstoreUrl = CoreApp.instance.getAppStoreUrl(subscription.storeConfig || {});
        const mainAppId = 'mainAppId' in json ? json['mainAppId'] : undefined;

        if (!mainAppstoreUrl || !mainAppId || CoreConstants.CONFIG.app_id === mainAppId) {
            return;
        }

        const standardAppLogin = 'standardAppLogin' in json ? json['standardAppLogin'] : 'allowed';

        if (standardAppLogin !== 'allowed') {
            const enforce = standardAppLogin === 'disabled';
            const message = Translate.instant(
                'freemium.downloadmainapplication' + (enforce ? 'required' : 'suggestion'),
            );

            subscription.restrictAccess = { enforce, message };
        }
    }

    /**
     * Subscription plan.
     */
    plan: SiteSubscriptionPlan;

    /**
     * Creation date of the subscription.
     */
    created: Date;

    /**
     * Date when the subscription was updated for the last time.
     */
    updated: Date;

    /**
     * Subscription name.
     */
    name?: string;

    /**
     * Subscription description.
     */
    description?: string;

    /**
     * Information url or null to indicate that it should be omitted.
     */
    informationUrl?: string | null;

    /**
     * Limits to features specific to this subscription.
     */
    limits: SiteSubscriptionLimits = {};

    /**
     * Enabled or disabled features specific to this subscription.
     */
    features: SiteSubscriptionFeatures = {};

    /**
     * Basic branding colors.
     */
    brandingColors?: SiteSubscriptionBrandingColors;

    /**
     * Custom CSS.
     */
    customCSS?: string;

    /**
     * Appstore configuration for main app.
     */
    storeConfig?: CoreStoreConfig;

    /**
     * Access restriction.
     */
    restrictAccess?: {
        enforce: boolean;
        message: string;
    };

    /**
     * Biometric login state (true = enabled, false = disabled)
     */
    biometricLogin?: boolean;

    /*
     * Matomo analytics configuration.
     */
    matomo?: MatomoConfig;

    constructor(plan: SiteSubscriptionPlan, created: Date, updated: Date, validUntil?: Date) {
        super(validUntil);

        this.plan = plan;
        this.created = created;
        this.updated = updated;
    }

    /**
     * Get active subscription plan.
     *
     * @returns Active subscription plan.
     */
    getActivePlan(): SiteSubscriptionPlan {
        if (!this.isActive()) {
            return SiteSubscriptionPlan.Free;
        }

        return this.plan;
    }

    /**
     * Get feature limit.
     *
     * @param feature Name of the feature.
     * @param defaultValue Default limit if it wasn't found in the subscription nor defaults.
     *
     * @returns Number indicating the feature limit or null if there isn't any limitation.
     */
    getFeatureLimit(feature: string, defaultValue: number | null = null): number | null {
        if (this.isActive() && feature in this.limits) {
            return this.limits[feature];
        }

        const activePlan = this.getActivePlan();
        const planDefaults = FREEMIUM_PLAN_DEFAULTS[feature] || {};

        return activePlan in planDefaults ? planDefaults[activePlan] : defaultValue;
    }

    /**
     * Check if a feature is enabled.
     *
     * @param feature Name of the feature.
     * @param defaultValue Default value if it wasn't found in the subscription nor defaults.
     *
     * @returns Boolean indicating whether the feature is enabled.
     */
    isFeatureEnabled(feature: string, defaultValue: boolean = false): boolean {
        if (this.isActive() && feature in this.features) {
            return this.features[feature];
        }

        const activePlan = this.getActivePlan();
        const planDefaults = FREEMIUM_PLAN_DEFAULTS[feature] || {};

        return activePlan in planDefaults ? planDefaults[activePlan] : defaultValue;
    }

    /**
     * Serialize object into its JSON representation.
     *
     * @returns JSON serialized site subscription.
     */
    toJSON(): SiteSubscriptionJson {
        return CoreObject.withoutUndefined({
            plan: this.plan,
            createdTimestamp: this.created.getTime(),
            updatedTimestamp: this.updated.getTime(),
            validUntilTimestamp: this.validUntil ? this.validUntil.getTime() : null,
            name: this.name,
            description: this.description,
            informationUrl: this.informationUrl,
            limits: this.limits,
            features: this.features,
            brandingColors: this.brandingColors,
            customCSS: this.customCSS,
            appConfig: this.appConfig,
            storeConfig: this.storeConfig,
            restrictAccess: this.restrictAccess,
            biometricLogin: this.biometricLogin,
            matomo: this.matomo,
        }) as SiteSubscriptionJson;
    }

}

/**
 * Default subscription for non-registered sites.
 */
export class DefaultSiteSubscription extends SiteSubscription {

    constructor() {
        const defaultSubscription = CoreConstants.CONFIG.defaultsubscription ?? SiteSubscriptionPlan.Free;

        super(defaultSubscription, new Date(), new Date(), new Date(Date.now() + CoreConstants.MILLISECONDS_YEAR));
    }

}
