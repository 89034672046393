// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';

import { CoreSitesFactoryService } from '@/core/services/sites-factory';
import { FreemiumSite } from '@freemium/overrides/core/classes/sites/site';
import { makeSingleton } from '@singletons';
import { CoreSiteOptionalData } from '@classes/sites/site';
import { FreemiumUnauthenticatedSite } from '../classes/sites/unauthenticated-site';
import { CoreAuthenticatedSiteOptionalData } from '@classes/sites/authenticated-site';
import { FreemiumAuthenticatedSite } from '../classes/sites/authenticated-site';
import { CoreSitePublicConfigResponse } from '@classes/sites/unauthenticated-site';

/**
 * Override the base sites factory to create FreemiumSite instances.
 */
@Injectable({ providedIn: 'root' })
export class FreemiumSitesFactoryService extends CoreSitesFactoryService {

    /**
     * @inheritDoc
     */
    makeSite(
        id: string,
        siteUrl: string,
        token: string,
        otherData: CoreSiteOptionalData = {},
    ): FreemiumSite {
        return new FreemiumSite(id, siteUrl, token, otherData);
    }

    /**
     * @inheritdoc
     */
    makeAuthenticatedSite(
        siteUrl: string,
        token: string,
        options: CoreAuthenticatedSiteOptionalData = {},
    ): FreemiumAuthenticatedSite {
        return new FreemiumAuthenticatedSite(siteUrl, token, options);
    }

    /**
     * @inheritdoc
     */
    makeUnauthenticatedSite(siteUrl: string, publicConfig?: CoreSitePublicConfigResponse): FreemiumUnauthenticatedSite {
        return new FreemiumUnauthenticatedSite(siteUrl, publicConfig);
    }

}

export const FreemiumSitesFactory = makeSingleton<FreemiumSitesFactoryService>(CoreSitesFactoryService);
