// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';
import { CoreSites } from '@services/sites';
import { CoreDomUtils } from '@services/utils/dom';
import { CoreUrlUtils } from '@services/utils/url';
import { CoreUtils } from '@services/utils/utils';
import { CoreWS, CoreWSAjaxPreSets } from '@services/ws';
import { makeSingleton } from '@singletons';
import { WorkplaceTenantsSelectorComponent } from '@workplace/features/tenants/components/tenantsselector/tenantsselector';
import { Workplace, WorkplaceTenantLoginInfo } from '@workplace/services/workplace';

/**
 * Service that provides helper functions to workplace.
 */
@Injectable({ providedIn: 'root' })
export class WorkplaceTenantsHelperService {

    // Cache to test tenantUrl param on ajax calls.
    protected tenantUrlEnabled: Record<string, boolean> = {};

    /**
     * Check and saves the tenant id from the URL.
     *
     * @param url URL to check.
     */
    async checkTenantFromUrl(url: string): Promise<void> {
        const params = CoreUrlUtils.extractUrlParams(url);
        if (params.tenantid) {
            localStorage.setItem('tempTenant', params.tenantid);

            return;
        }
        url = CoreUrlUtils.formatURL(url);
        const tenants = await Workplace.getTenantsInfo(url);
        if (!tenants.length) {
            this.resetTempTenant();

            return;
        }
        const defaultTenantUrlParams = CoreUrlUtils.extractUrlParams(tenants[0].url);
        localStorage.setItem('tempTenant', defaultTenantUrlParams.tenantid);
    }

    /**
     * Retrieves tenant for a siteId or temporary if not set.
     *
     * @returns Tenant id or null.
     */
    async getTenant(): Promise<number | void> {
        try {
            const currentSite = CoreSites.getRequiredCurrentSite();
            const configTenant = await CoreUtils.ignoreErrors(currentSite.getConfig('wp_tool_tenant_config_id'));

            if (configTenant) {
                return parseInt(configTenant, 10);
            }
        } catch {
            const siteId = CoreSites.getCurrentSiteId();
            const itemName = siteId ? 'tenant-' + siteId : 'tempTenant';
            const tenant = localStorage.getItem(itemName);

            if (tenant) {
                return parseInt(tenant, 10);
            }
        }

    }

    /**
     * Resets temp tenant id.
     */
    resetTempTenant(): void {
        localStorage.removeItem('tempTenant');
    }

    /**
     * Resets site tenant id.
     *
     * @param siteId Site Id.
     */
    removeTenant(siteId: string): void {
        localStorage.removeItem('tenant-' + siteId);
    }

    /**
     * Get stored tenant url.
     *
     * @param siteUrl Site url.
     * @returns Tenant url or undefined.
     */
    async getTenantUrl(siteUrl: string): Promise<string | undefined> {
        const tenantId = await this.getTenant();

        if (!tenantId) {
            return undefined;
        }

        const tenantUrl = siteUrl + '?tenantid=' + tenantId;

        if (this.tenantUrlEnabled[siteUrl] !== undefined) {
            return this.tenantUrlEnabled[siteUrl] ? tenantUrl : undefined;
        }

        try {
            // This checks, the ws is capable of reading the tenanturl param.
            const preSets: CoreWSAjaxPreSets = {
                siteUrl: siteUrl,
                noLogin: true,
                useGet: true,
            };
            await CoreWS.callAjax(
                'tool_mobile_get_public_config',
                { tenanturl: tenantUrl },
                preSets,
            );

            this.tenantUrlEnabled[siteUrl] = true;
        } catch {
            this.tenantUrlEnabled[siteUrl] = false;

            // tenanturl is not supported, do not use it.
            return undefined;
        }

        return tenantUrl;
    }

    /**
     * Same as getTenantUrl but without testing the tenanturl param.
     *
     * @param siteUrl Site url.
     * @returns Tenant url or undefined.
     */
    async getTenantUrlNoTest(siteUrl: string): Promise<string | undefined> {
        const tenantId = await this.getTenant();

        if (!tenantId) {
            return undefined;
        }

        return siteUrl + '?tenantid=' + tenantId;
    }

    /**
     * Saves temp tenant for the current siteId.
     */
    async initTenant(): Promise<void> {
        const siteId = CoreSites.getCurrentSiteId();
        const site = await CoreUtils.ignoreErrors(CoreSites.getSite(siteId));

        if (!siteId) {
            return;
        }

        const tenant = site ? await CoreUtils.ignoreErrors(this.getTenant()) : localStorage.getItem('tempTenant');

        if (!tenant) {
            return;
        }

        localStorage.setItem('tenant-' + siteId, tenant + '');
        this.resetTempTenant();
    }

    /**
     * Open change tenant selector.
     *
     * @param tenants List of tenants
     * @returns Selected tenant.
     */
    async changeTenant(tenants: WorkplaceTenantLoginInfo[]): Promise<WorkplaceTenantLoginInfo | undefined> {
        // Create the navigation modal.
        return await CoreDomUtils.openModal<WorkplaceTenantLoginInfo>({
            component: WorkplaceTenantsSelectorComponent,
            componentProps: {
                tenants: tenants,
            },
        });
    }

}

export const WorkplaceTenantsHelper = makeSingleton(WorkplaceTenantsHelperService);
