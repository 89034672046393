// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';
import { AddonRemoteThemesHandlerService } from '@addons/remotethemes/services/remotethemes-handler';
import { FreemiumFeature } from '@freemium/constants';
import { FreemiumSitePublicConfig } from '@freemium/overrides/core/classes/sites/unauthenticated-site';
import { FreemiumSites } from '@freemium/overrides/core/services/sites';
import { SiteSubscription, DefaultSiteSubscription } from '@freemium/classes/site-subscription';
import { CoreStyleHandler, CoreStylesService } from '@features/styles/services/styles';

/**
 * Override the base remote themes provider to disable features based on subscription.
 */
@Injectable({ providedIn: 'root' })
export class FreemiumRemoteThemesService extends AddonRemoteThemesHandlerService implements CoreStyleHandler {

    /**
     * @inheritDoc
     */
    async isEnabled(siteId: string, config?: FreemiumSitePublicConfig): Promise<boolean> {
        let subscription: SiteSubscription;
        if (siteId == CoreStylesService.TMP_SITE_ID) {
            // TMP site.
            subscription = config?.subscription ? SiteSubscription.fromJSON(config.subscription) : new DefaultSiteSubscription();
        } else {
            const site = await FreemiumSites.getSite(siteId);

            subscription = site.subscription;
        }

        return subscription.isFeatureEnabled(FreemiumFeature.RemoteThemes);
    }

}
