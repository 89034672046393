// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';
import { FreemiumSitePublicConfig } from '@freemium/overrides/core/classes/sites/unauthenticated-site';
import { FreemiumSites } from '@freemium/overrides/core/services/sites';
import { SiteSubscription, DefaultSiteSubscription } from '@freemium/classes/site-subscription';
import { CoreStyleHandler, CoreStylesService } from '@features/styles/services/styles';
import { makeSingleton } from '@singletons';
import { CoreColors } from '@singletons/colors';

/**
 * Types of basic branding.
 */
export enum BasicBrandingColor {
    Primary = 'primary',
    Text = 'text',
    HeaderToolbarBackground = 'headertoolbarbackground',
    HeaderToolbarText = 'headertoolbartext',
    BottomTabsBackground = 'bottomtabsbackground',
    BottomTabsActiveText = 'bottomtabsactivetext',
    BottomTabsInactiveText = 'bottomtabsinactivetext',
}

const BASIC_BRANDING_CSS_VARIABLES: Record<BasicBrandingColor, ColorVariantVariables> = {
    [BasicBrandingColor.Primary]: {
        base: ['--primary', '--ion-color-primary', '--ion-color-primary-base'],
        baseRGB: ['--ion-color-primary-rgb'],
        contrast: ['--primary-contrast', '--ion-color-primary-contrast'],
        contrastRGB: ['--ion-color-primary-contrast-rgb'],
        tint: ['--primary-tint', '--ion-color-primary-tint'],
        shade: ['--primary-shade', '--ion-color-primary-shade'],
    },
    [BasicBrandingColor.Text]: {
        base: ['--text-color'],
    },
    [BasicBrandingColor.HeaderToolbarBackground]: {
        base: ['--core-header-toolbar-background'],
        contrast: ['--core-header-toolbar-color'],
    },
    [BasicBrandingColor.HeaderToolbarText]: {
        base: ['--core-header-toolbar-color'],
    },
    [BasicBrandingColor.BottomTabsBackground]: {
        base: ['--core-bottom-tabs-background'],
    },
    [BasicBrandingColor.BottomTabsActiveText]: {
        base: ['--core-bottom-tabs-color-selected', '--core-bottom-tabs-badge-color'],
        contrast: ['--core-bottom-tabs-badge-text-color'],
    },
    [BasicBrandingColor.BottomTabsInactiveText]: {
        base: ['--core-bottom-tabs-color'],
    },
};

/**
 * Service to handle freemium basic branding based on subscription.
 */
@Injectable({ providedIn: 'root' })
export class FreemiumBasicBrandingHandlerService implements CoreStyleHandler {

    name = 'basicbranding';
    priority = 900;

    /**
     * @inheritDoc
     */
    async getStyle(siteId: string, config?: FreemiumSitePublicConfig): Promise<string> {
        let subscription: SiteSubscription;
        if (siteId == CoreStylesService.TMP_SITE_ID) {
            if (!config) {
                return '';
            }

            // TMP site.
            subscription = config.subscription ? SiteSubscription.fromJSON(config.subscription) : new DefaultSiteSubscription();
        } else {
            const site = await FreemiumSites.getSite(siteId);
            subscription = site.subscription;
        }

        return this.loadBasicBrandingStyles(subscription);
    }

    /**
     * @inheritDoc
     */
    async isEnabled(): Promise<boolean> {
        return true;
    }

    /**
     * Load basic branding styles for a certain site.
     *
     * @param subscription Site subscription.
     * @returns Css loaded from subscription.
     */
    protected loadBasicBrandingStyles(subscription: SiteSubscription): string {
        let styles = '';

        // Colors.
        const colors = subscription.brandingColors;

        if (colors) {
            const brandingCssParts = Object
                .values(BasicBrandingColor)
                .filter((type) => type in colors && !!colors[type])
                .map((type) => this.getBrandingColorCss(type, colors[type] as string));

            if (brandingCssParts.length > 0) {
                styles += ':root{' + brandingCssParts.join('') + '}';
            }
        }

        // Custom CSS.
        styles += subscription.customCSS ?? '';

        return styles;
    }

    /**
     * Get the branding color CSS for a certain type.
     *
     * @param type The type to get.
     * @param value The value to set to the variable.
     * @returns CSS code.
     */
    protected getBrandingColorCss(type: BasicBrandingColor, value: string): string {
        const variantVariables = BASIC_BRANDING_CSS_VARIABLES[type];

        let css = variantVariables.base.map((name: string) => `${name}: ${value};`).join('');
        if (variantVariables.baseRGB?.length) {
            const baseRGB = CoreColors.getColorRGBA(value).join(', ');
            css += variantVariables.baseRGB.map((name: string) => `${name}: ${baseRGB};`).join('');
        }

        if (variantVariables.contrast?.length) {
            const contrast = CoreColors.isWhiteContrastingBetter(value) ? 'white' : 'black';
            css += variantVariables.contrast.map((name: string) => `${name}: ${contrast};`).join('');

            if (variantVariables.contrastRGB?.length) {
                const contrastRgb = CoreColors.getColorRGBA(contrast).join(', ');
                css += variantVariables.contrastRGB.map((name: string) => `${name}: ${contrastRgb};`).join('');
            }
        }

        if (variantVariables.tint?.length) {
            const tint = CoreColors.lighter(value);
            css += variantVariables.tint.map((name: string) => `${name}: ${tint};`).join('');
        }

        if (variantVariables.shade?.length) {
            const shade = CoreColors.darker(value);
            css += variantVariables.shade.map((name: string) => `${name}: ${shade};`).join('');
        }

        return css;
    }

}

export const FreemiumBasicBrandingHandler = makeSingleton(FreemiumBasicBrandingHandlerService);

type ColorVariantVariables = {
    base: string[];
    baseRGB?: string[];
    contrast?: string[];
    contrastRGB?: string[];
    tint?: string[];
    shade?: string[];
};
