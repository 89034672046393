// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { CoreConstants } from '@/core/constants';

import { CoreObject } from '@singletons/object';
import { EnvironmentConfig } from '@/types/config';
import { Subscription } from '@freemium/classes/subscription';

/**
 * JSON representation of an app subscription, used for serialization.
 */
export interface AppSubscriptionJson {
    appConfig?: Partial<EnvironmentConfig>;
    validUntilTimestamp?: number;
}

/**
 * Subscription info.
 */
export class AppSubscription extends Subscription {

    /**
     * Parse an app subscription json object.
     *
     * @param json Subscription json.
     * @returns App subscription.
     */
    static fromJSON(json: AppSubscriptionJson): AppSubscription {
        const subscription = new AppSubscription(
            json.validUntilTimestamp ? new Date(json.validUntilTimestamp) : undefined,
        );

        if ('appConfig' in json) {
            subscription.appConfig = json.appConfig;
        }

        return subscription;
    }

    /**
     * Serialize object into its JSON representation.
     *
     * @returns JSON serialized app subscription.
     */
    toJSON(): AppSubscriptionJson {
        return CoreObject.withoutUndefined({
            validUntilTimestamp: this.validUntil ? this.validUntil.getTime() : null,
            appConfig: this.appConfig,
        }) as AppSubscriptionJson;
    }

}

/**
 * Default subscription.
 */
export class DefaultAppSubscription extends AppSubscription {

    constructor() {
        super(new Date(Date.now() + CoreConstants.MILLISECONDS_YEAR));
    }

}
