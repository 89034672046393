<ng-container *ngIf="enabled && !loading">
    <!-- Download button. -->
    <ion-button *ngIf="status === statusNotDownloaded" fill="clear" (click)="download($event, false)" @coreShowHideAnimation
        [ariaLabel]="(statusTranslatable || 'core.download') | translate">
        <ion-icon slot="icon-only" name="fas-cloud-arrow-down" aria-hidden="true" />
    </ion-button>

    <!-- Refresh button. -->
    <ion-button *ngIf="status === statusOutdated || (status === statusDownloaded && !canTrustDownload)" fill="clear"
        (click)="download($event, true)" @coreShowHideAnimation [ariaLabel]="(statusTranslatable || 'core.refresh') | translate">
        <ion-icon slot="icon-only" name="fam-cloud-refresh" aria-hidden="true" />
    </ion-button>

    <!-- Downloaded status icon. -->
    <ion-icon *ngIf="status === statusDownloaded && canTrustDownload" class="core-icon-downloaded ion-padding-horizontal" color="success"
        name="fam-cloud-done" [attr.aria-label]="(statusTranslatable || 'core.downloaded') | translate" role="status" />

    <ion-spinner *ngIf="status === statusDownloading" @coreShowHideAnimation
        [attr.aria-label]="(statusTranslatable || 'core.downloading') | translate" />
</ng-container>

<!-- Spinner. -->
<ion-spinner *ngIf="loading" @coreShowHideAnimation [attr.aria-label]="'core.loading' | translate" />
