// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Component } from '@angular/core';
import { CoreBlockBaseComponent } from '@features/block/classes/base-block-component';
import { CoreNavigator } from '@services/navigator';
import { CoreDomUtils } from '@services/utils/dom';
import { CoreUtils } from '@services/utils/utils';
import { WorkplaceTeams, WorkplaceTeamsFilterValues, WorkplaceTeamsManagedUser } from '../../services/teams';
import { WorkplaceTeamsFilterComponent } from '../teamsfilter/teamsfilter';

/**
 * Component to render a teams block.
 */
@Component({
    selector: 'block-myteams',
    templateUrl: 'block-myteams.html',
})
export class WorkplaceBlockMyTeamsComponent extends CoreBlockBaseComponent {

    users: WorkplaceTeamsManagedUser[] = [];
    loadMoreError = false;
    filters: WorkplaceTeamsFilterValues = {};
    filtered = false;
    canLoadMore = false;

    constructor() {
        super('WorkplaceBlockMyTeamsComponent');
    }

    /**
     * @inheritdoc
     */
    async invalidateContent(): Promise<void> {
        await WorkplaceTeams.invalidateTeamOverview();
    }

    /**
     * Convenience function to fetch the data.
     *
     * @param refresh Empty events array first.
     * @returns Promise resolved when done.
     */
    protected async fetchContent(refresh = false): Promise<void> {
        const isManager = await WorkplaceTeams.isManager();
        if (!isManager) {
            this.users = [];
            this.filtered = false;

            return;
        }
        try {
            const data = await WorkplaceTeams.getTeamOverview(refresh ? 0 : this.users.length, this.filters);
            if (refresh) {
                this.users = data.managedusers;
            } else {
                this.users = this.users.concat(data.managedusers);
            }

            this.canLoadMore = data.totalcount > this.users.length;
        } catch (error) {
            CoreDomUtils.showErrorModalDefault(error, 'Error loading users');
            this.loadMoreError = true; // Set to prevent infinite calls with infinite-loading.
        }
    }

    /**
     * Function to load more data.
     *
     * @param infiniteComplete Infinite scroll complete function. Only used from core-infinite-loading.
     * @returns Resolved when done.
     */
    async loadMoreData(infiniteComplete?: () => void): Promise<void> {
        await CoreUtils.ignoreErrors(this.fetchContent());

        infiniteComplete && infiniteComplete();
    }

    /**
     * Navigate to a particular user profile.
     *
     * @param user User object where to navigate.
     */
    async gotoUser(user: WorkplaceTeamsManagedUser): Promise<void> {
        await CoreNavigator.navigateToSitePath(
            '/learningstatus',
            { params: { userId: user.user.id } },
        );
    }

    /**
     * Opens the filter page.
     */
    async openFilter(): Promise<void> {
        const data = await CoreDomUtils.openModal<WorkplaceTeamsFilterValues>({
            component: WorkplaceTeamsFilterComponent,
            componentProps: {
                filters: this.filters,
            },
        });

        // Add data to search object.
        if (data) {
            const keys = Object.keys(data).concat(Object.keys(this.filters));
            const changed = keys.some((filter) => this.filters[filter] != data[filter]);
            if (changed) {
                this.filters = data;
                this.filtered = keys.some((filter) => this.filters[filter]);

                this.fetchContent(true);
            }
        }
    }

}
