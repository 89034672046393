// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { APP_INITIALIZER, NgModule } from '@angular/core';
import { SiteSubscriptions, SiteSubscriptionsService } from '@freemium/services/site-subscriptions';
import { SiteSubscriptionsServiceMock } from './mocks/services/site-subscriptions';
import { CorePlatform } from '@services/platform';

type AutomatedTestsWindow = Window & {
    siteSubscriptions?: SiteSubscriptionsService;
};

/**
 * Initialize test window.
 *
 * @param window Window.
 */
function initializeAutomatedTestsWindow(window: AutomatedTestsWindow) {
    window.siteSubscriptions = SiteSubscriptions.instance;
}

@NgModule({
    providers: [
        {
            provide: APP_INITIALIZER,
            multi: true,
            useValue: () => CorePlatform.isAutomated() && initializeAutomatedTestsWindow(window),
        },
        { provide: SiteSubscriptionsService, useClass: SiteSubscriptionsServiceMock },
    ],
})
export class FreemiumTestsModule {}
