<ion-header>
    <ion-toolbar>
        <ion-title>
            <h1>{{ 'core.contentlinks.chooseaccount' | translate }}</h1>
        </ion-title>
        <ion-buttons slot="end">
            <ion-button fill="clear" (click)="closeModal()" [ariaLabel]="'core.close' | translate">
                <ion-icon slot="icon-only" name="fas-xmark" aria-hidden="true" />
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <core-loading [hideUntil]="loaded">
        <ion-list>
            <ion-item class="ion-text-wrap">
                <ion-label>
                    <h2 class="item-heading">{{ 'core.contentlinks.chooseaccounttoopenlink' | translate }}</h2>
                    <p *ngIf="displaySiteUrl">{{ url }}</p>
                </ion-label>
            </ion-item>
            <ion-item *ngFor="let site of sites" (click)="siteClicked(site.id)" [detail]="false" button>
                <core-user-avatar [site]="site" slot="start" [linkProfile]="false" />

                <ion-label>
                    <p class="item-heading">{{site.fullname}}</p>
                    <p>
                        <core-format-text [text]="site.siteName" clean="true" [siteId]="site.id" />
                    </p>
                    <p *ngIf="displaySiteUrl">{{site.siteUrl}}</p>
                </ion-label>
            </ion-item>
        </ion-list>
    </core-loading>
</ion-content>
