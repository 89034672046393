// (C) Copyright 2015 Moodle Pty Ltd.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Injectable } from '@angular/core';
import { CoreCourseProvider } from '@features/course/services/course';
import { FreemiumFeature } from '@freemium/constants';
import { FreemiumSites } from '@freemium/overrides/core/services/sites';
import { CoreUrlUtils } from '@services/utils/url';
import { CoreEvents } from '@singletons/events';

/**
 * Override the base course provider to limit and disable features based on subscription.
 */
@Injectable({ providedIn: 'root' })
export class FreemiumCourseProvider extends CoreCourseProvider {

    protected activityModuleIcons: Record<string, Record<string, string>> = {};
    protected showCustomIcons?: boolean;
    protected siteTheme?: string;

    constructor() {
        super();

        // Clear activity module icons lists cache on new login.
        CoreEvents.on(CoreEvents.LOGIN, () => {
            this.activityModuleIcons = {};
            this.showCustomIcons = undefined;

            const info = FreemiumSites.getCurrentSite().getInfo();

            this.siteTheme = info?.theme || undefined;
        });
    }

    /**
     * @inheritdoc
     */
    getModuleIconSrc(moduleName: string, modicon?: string, mimetypeIcon = ''): string {
        // Cache site subscription feature checking.
        if (this.showCustomIcons === undefined) {
            const subscription = FreemiumSites.getCurrentSite().subscription;

            this.showCustomIcons = subscription.isFeatureEnabled(FreemiumFeature.CustomModuleIcons);
        }

        if (!this.showCustomIcons) {
            // Use app module icon
            return super.getModuleIconSrc(moduleName, modicon, mimetypeIcon);
        }

        const theme = this.guessThemeFromIconInfo(modicon);

        // Cache modicon by theme.
        if (theme) {
            if (this.activityModuleIcons[theme] === undefined) {
                this.activityModuleIcons[theme] = {};
            }

            const iconName = mimetypeIcon || moduleName;
            if (modicon) {
                this.activityModuleIcons[theme][iconName] = modicon;

                return modicon;

            }

            if (this.activityModuleIcons[theme][iconName] !== undefined) {
                return this.activityModuleIcons[theme][iconName];
            }

            // Create fake url, do not cache.
            return FreemiumSites.getCurrentSite().getURL() + `/theme/image.php/${theme}/${moduleName}/-1/icon`;
        }

        // Modicon from unknown theme, probably a resource file.
        if (modicon) {
            return modicon;
        }

        // Use app module icon
        return super.getModuleIconSrc(moduleName, modicon, mimetypeIcon);
    }

    /**
     * Returns the theme name from the icon info.
     *
     * @param modicon The mod icon URL string.
     * @returns Theme name from modicon.
     */
    protected guessThemeFromIconInfo(modicon?: string): string | undefined {
        if (!modicon) {
            if (this.siteTheme === undefined) {
                const info = FreemiumSites.getCurrentSite().getInfo();

                this.siteTheme = info?.theme || undefined;
            }

            return this.siteTheme;
        }

        return CoreUrlUtils.getThemeImageUrlParam(modicon, 'theme');
    }

}
