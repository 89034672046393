<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button [text]="'core.back' | translate" />
        </ion-buttons>
        <ion-title>
            <h1 *ngIf="title">{{ title | translate }}</h1>
        </ion-title>

        <ion-buttons slot="end" />
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-refresher slot="fixed" [disabled]="!ptrEnabled || !content || !content.dataLoaded" (ionRefresh)="refreshData($event.target)">
        <ion-refresher-content pullingText="{{ 'core.pulltorefresh' | translate }}" />
    </ion-refresher>
    <core-site-plugins-plugin-content [component]="component" [method]="method" [args]="args" [preSets]="preSets" [initResult]="initResult"
        [data]="jsData" [pageTitle]="title" />
</ion-content>
